import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  buttonMobile: {
    display: 'flex',
    fontWeight: theme.fontWeight.semibold,
    height: 48,
    marginLeft: `${theme.space[1]}px`,
    marginRight: `${theme.space[2]}px`,
  },
  fakeInput: {
    borderRadius: `${theme.borderRadius[0]}px`,
    borderStyle: 'solid',
    borderWidth: '1px',
    flexDirection: 'row',
    height: 48,
    marginBottom: 0,
    marginLeft: `${theme.space[1]}px`,
    [theme.breakpoints.down('md')]: {
      width: 360,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      width: '30vw',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.space[4]}px`,
      width: '100%',
    },
  },
  inputBase: {
    marginTop: `${theme.space[0]}`,
    paddingRight: `${theme.space[1]}px`,
    width: 300,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  searchMessage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: `${theme.space[5]}px`,
    marginRight: `${theme.space[5]}px`,
    marginTop: `${theme.space[5]}px`,
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginLeft: `${theme.space[4]}px`,
      marginRight: `${theme.space[4]}px`,
    },
  },
}))

export default useStyles
