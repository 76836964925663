import { Accordion as MuiAccordion } from '@mui/material'
import { styled } from '@mui/material/styles'

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '& .MuiAccordionDetails-root': {
    padding: `${theme.space[0]} ${theme.space[0]} ${theme.space[5]}px`,
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.space[0],
    padding: `25px ${theme.space[0]}`,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: `${theme.space[0]} ${theme.space[0]} ${theme.space[5]}px`,
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.space[0],
  },
  '&::before': {
    content: 'none',
  },
  '&:first-of-type, &:last-of-type': {
    borderRadius: `${theme.space[3]}px`,
  },
  boxShadow: `0 8px 16px ${theme.palette.shadows.card}`,
  padding: `0 ${theme.space[5]}px`,
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.space[4]}px`,
  },
}))

export default Accordion
