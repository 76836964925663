import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import InputBase from '@mui/material/InputBase'
import Loader from 'components/Loader'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CouponSelector from 'components/PostBuilder/CouponSelector'
import { Plus } from 'components/Icons'
import { useTheme } from '@mui/material'
import { ENV, CATALOG_SEARCH_ROUTE } from 'constants/routes'
import { fetchInfo } from 'helpers/fetchUtil'
import convertImageToBase64 from 'utils/imageUtils'

import useStyles from './styles'

const { search: DATA_SEARCH } = require('./constData.json')

const EntitySelector = ({
  activeCoupons,
  type,
  imageSelected,
  setImageSelected,
  nameSelected,
  setNameSelected,
  setPriceDiscSelected,
  cleanValues,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const urlBase = `${ENV.REACT_APP_API_URL}${CATALOG_SEARCH_ROUTE}`
  const [loading, setLoading] = useState(false)
  const [focusOn, setFocusOn] = useState(false)
  const [searchSelected, setSearchSelected] = useState('')
  const [suggest, setSuggest] = useState(null)
  const handleFocus = () => setFocusOn(true)
  const handleBlur = () => {
    setSuggest('')
    setFocusOn(false)
  }

  const loadImageResource = (img) => {
    if (img) {
      convertImageToBase64(img, (imgResult) => {
        setImageSelected(imgResult)
      })
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    const response = await fetchInfo(`${urlBase}?q=${searchSelected}`)

    if (response) {
      setSuggest(response.data.data.items)
      setLoading(false)
    }
  }

  const handleSearchChange = (e) => {
    const { value } = e.target

    if (value === '') {
      setSuggest('')
      setSearchSelected('')
    } else {
      setSearchSelected(`${value}*`)
    }
  }

  const handleSelected = (name, priceDisc, imgSelected) => {
    setNameSelected(name)
    setPriceDiscSelected(priceDisc)
    loadImageResource(imgSelected)
    setSuggest('')
  }

  const deleteSelected = () => {
    cleanValues()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur()
      handleSearch()
    }
  }

  const MiniLoader = () => loading && <Loader thickness={1} />

  const elementSelected = () => (
    <Stack className={classes.elementSelected} direction="row">
      <Stack direction="row" sx={{ alignItems: 'center', display: 'flex' }}>
        <Avatar
          src={imageSelected}
          sx={{ height: 48, width: 48 }}
          variant="rounded"
        />
        <Typography
          fontWeight={theme.fontWeight.semibold}
          sx={{ ml: 2 }}
          variant="body1"
        >
          {nameSelected}
        </Typography>
      </Stack>
      <IconButton onClick={() => deleteSelected()}>
        <Plus color={theme.palette.text.primary} rotate={45} size={2} />
      </IconButton>
    </Stack>
  )

  const search = () => (
    <Stack
      className={classes.inputWrapper}
      sx={{
        border: focusOn
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.backgrounds[2]}`,
      }}
    >
      <IconButton
        aria-label="search"
        name="search"
        onClick={handleSearch}
        sx={{ p: '0 10px 0' }}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.inputBase}
        defaultValue=""
        InputProps={{
          'aria-label': 'search',
          'data-testid': 'input-base',
        }}
        onBlur={handleBlur}
        onChange={(e) => handleSearchChange(e)}
        onFocus={handleFocus}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={DATA_SEARCH.PLACEHOLDER_SEARCH}
        type={loading ? 'text' : 'search'}
      />

      <Stack>
        <MiniLoader />
      </Stack>
    </Stack>
  )

  return type === 'product' ? (
    <Box className={classes.searchContainer} data-testid="searchItems">
      {suggest && (
        <Box className={classes.suggest}>
          {suggest?.length > 0 ? (
            suggest.map((s) => (
              <Button
                className={classes.button}
                onClick={() => handleSelected(s.product_name, s.price, s.image)}
              >
                <Avatar
                  src={s.image}
                  sx={{ height: 48, width: 48 }}
                  variant="square"
                />
                <Typography
                  fontWeight={theme.fontWeight.semibold}
                  sx={{ ml: 2 }}
                  variant="body1"
                >
                  {s.product_name}
                </Typography>
              </Button>
            ))
          ) : (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '27px',
              }}
            >
              {DATA_SEARCH.NOT_FOUND}
            </Typography>
          )}
        </Box>
      )}
      {nameSelected === '' ? search() : elementSelected()}
    </Box>
  ) : (
    <CouponSelector
      activeCoupons={activeCoupons}
      setNameSelected={setNameSelected}
      setPriceDiscSelected={setPriceDiscSelected}
    />
  )
}

export default EntitySelector

EntitySelector.propTypes = {
  activeCoupons: PropTypes.arrayOf(PropTypes.shape({})),
  cleanValues: PropTypes.func,
  imageSelected: PropTypes.string,
  nameSelected: PropTypes.string,
  setImageSelected: PropTypes.func,
  setNameSelected: PropTypes.func,
  setPriceDiscSelected: PropTypes.func,
  type: PropTypes.string,
}

EntitySelector.defaultProps = {
  activeCoupons: [],
  cleanValues: () => {},
  imageSelected: '',
  nameSelected: '',
  setImageSelected: () => {},
  setNameSelected: () => {},
  setPriceDiscSelected: () => {},
  type: '',
}
