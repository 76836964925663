import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Paper from 'components/Paper'
import { Chevron } from 'components/Icons'

const Widget = ({ children, disabled, navLink, sx }) => {
  const handleClick = (event) => {
    if (disabled) event.preventDefault()
  }

  return (
    <Paper
      sx={{
        ...sx,
        '& > a': {
          textDecoration: 'none',
        },
        opacity: disabled && 0.5,
        p: { sm: '32px 16px 32px 32px', xs: '16px' },
      }}
    >
      <NavLink onClick={handleClick} to={navLink}>
        <Stack
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {children}
          <Chevron />
        </Stack>
      </NavLink>
    </Paper>
  )
}

export default Widget

Widget.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  navLink: PropTypes.string,
  sx: PropTypes.shape({}),
}

Widget.defaultProps = {
  children: '',
  disabled: false,
  navLink: '',
  sx: {},
}
