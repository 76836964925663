import { useEffect, useState } from 'react'
import { fetchInfo } from 'helpers/fetchUtil'

import {
  ENV,
  GET_CONFIG_ROUTE,
  GET_CATALOG_ROUTE,
  GET_TRIBUTARY_INFO_ROUTE,
} from 'constants/routes'

function useStoreConfig(merchantToken) {
  const [storeConfig, setStoreConfig] = useState(null)
  const [error, setError] = useState(false)

  const catalogUrl = `${ENV.REACT_APP_API_URL}${GET_CATALOG_ROUTE}?mt=${merchantToken}`
  const configUrl = `${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}/${merchantToken}`
  const tributaryUrl = `${ENV.REACT_APP_API_URL}${GET_TRIBUTARY_INFO_ROUTE}`

  useEffect(async () => {
    if (merchantToken) {
      try {
        const { data: catalogData } = await fetchInfo(catalogUrl)

        const { data: configData } = await fetchInfo(configUrl)
        const { data: tributaryData } = await fetchInfo(tributaryUrl)

        const storeConfigResponse = configData?.storeConfiguration || configData

        storeConfigResponse.hasCatalog = catalogData?.data.length > 0
        storeConfigResponse.tributary = Object.keys(tributaryData).length > 0

        setStoreConfig(storeConfigResponse)
      } catch (e) {
        setError(e)
      }
    }
  }, [merchantToken])

  return {
    error,
    storeConfig,
  }
}

export default useStoreConfig
