import Cookies from 'js-cookie'
import { ENV } from 'constants/routes'

export const getDomainName = (hostName) =>
  hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )

export const getCookieDomain = () => {
  const hostname = window ? window.location.hostname : ''
  const domain = getDomainName(hostname)

  return domain
}

export const createCookieName = (env, baseName) => {
  if (env === 'prod') return baseName

  return `${ENV.REACT_APP_ENV}_${baseName}`
}

export const TOKEN_NAME = 'access_token'

export const saveToken = (token, name = TOKEN_NAME) => {
  const options = { domain: getCookieDomain(), expires: 60 }
  const cookieName = createCookieName(ENV.REACT_APP_ENV, name)

  Cookies.set(cookieName, token, options)
}

export const getToken = (name = TOKEN_NAME) =>
  Cookies.get(createCookieName(ENV.REACT_APP_ENV, name))

export const reverseString = (str) => {
  if (!str) return ''
  const splitString = str.split('')
  const reverseArray = splitString.reverse()

  return reverseArray.join('')
}

export const getCookie = (name, defaultValue = false) => {
  const value = Cookies.get(createCookieName(ENV.REACT_APP_ENV, name))

  return !value ? defaultValue : JSON.parse(atob(reverseString(value)))
}

export const clearCookie = (name) => {
  const options = { domain: getCookieDomain(), expires: 60 }
  const cookieName = createCookieName(ENV.REACT_APP_ENV, name)

  Cookies.remove(cookieName, options)
}
