import { useState } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import InputChip from 'components/InputChip'
// import CancelIcon from '@mui/icons-material/Cancel'
import Button from 'components/Button'
import TextField from 'components/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ModalAdaptable } from 'components/Modal'
import LoadingButton from 'components/LoadingButton'
import { useGlobalContext } from 'store/global'
import { useProductContext } from 'components/Catalog-v2/store'
import PRODUCT_ACTIONS from 'components/Catalog-v2/store/actions'
import Loader from 'components/Loader'
import { Thick, Plus, ErrorIcon } from 'components/Icons'
import { fetchInfo, postInfo } from 'helpers/fetchUtil'
import { ENV, POST_CATEGORIES_ROUTE } from 'constants/routes'
import RowGrid from '../rowGrid'
import useStyles from './styles'

const { product: PRODUCT, catalog: CATALOG } = require('./constData.json')

const CategoriesAssign = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [open, setOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryName, setCategoryName] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingApply, setLoadingApply] = useState(false)
  const [enableSave, setEnableSave] = useState(false)
  const [fieldError, setFieldError] = useState(false)
  const [focus, setFocus] = useState(false)
  const { productData: product, productDispatch } = useProductContext()
  const classes = useStyles()
  const urlBase = `${ENV.REACT_APP_API_URL}${POST_CATEGORIES_ROUTE}`

  const handleToggle = () => setOpen(!open)

  const handleChangeName = (event) => {
    const { value } = event.target

    if (fieldError && value) setFieldError(false)

    setCategoryName(value)
  }

  const handleShowCategories = async () => {
    setEnableSave(false)
    handleToggle()

    try {
      setLoadingList(true)
      const response = await fetchInfo(urlBase)

      if (response) {
        const { data: responseData } = response

        setCategories(responseData.data)
        setLoadingList(false)
      }

      // eslint-disable-next-line no-console
      console.log('DEBUG:: categories: ', categories)
    } catch (error) {
      setLoadingList(false)
    }
  }

  const handleSaveCategory = async () => {
    if (categoryName) {
      try {
        setLoadingSave(true)
        const response = await postInfo(urlBase, {
          category_name: categoryName,
        })

        if (response) {
          const { data: categoryData } = response

          setCategories([categoryData.data, ...categories])
          setCategoryName('')

          setLoadingSave(false)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR::', { error })
      }

      // eslint-disable-next-line no-console
      console.log('DEBUG::handle Save Category')
    } else {
      setFieldError(true)
    }
  }

  const handleAssignCategories = () => {
    setLoadingApply(true)
    productDispatch({
      productData: product,
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })

    setTimeout(() => {
      setLoadingApply(false)
      handleToggle()
    }, 500)

    // eslint-disable-next-line no-console
    console.log('DEBUG::Assign categories')
  }

  const handleClose = () => {
    setCategoryName(null)
    setOpen(false)
  }

  const isSelected = (cid) => {
    const { categories: itemSelected } = product

    if (itemSelected?.length) {
      return itemSelected.find((item) => item.category_id === cid)
    }

    return null
  }

  const handleSelected = (category) => {
    const checked = isSelected(category.category_id)
    const { categories: itemSelected } = product

    if (checked) {
      product.categories = itemSelected.filter(
        (item) => item.category_id !== category.category_id
      )
    } else if (product.categories.length < PRODUCT.CATEGORY.LIMIT) {
      product.categories.push(category)
    }

    if (product.categories.length) {
      setEnableSave(true)
    } else {
      setEnableSave(false)
    }
    productDispatch({
      productData: product,
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })
    // eslint-disable-next-line no-console
    console.log('DEBUG::handle Selected', category.cid)
  }

  const handleDelete = (cid) => {
    const { categories: itemSelected } = product

    product.categories = itemSelected.filter((item) => item.category_id !== cid)

    productDispatch({
      productData: product,
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })

    // eslint-disable-next-line no-console
    console.log('DEBUG::handle Delete', cid)
  }

  const getClassName = (cid) => {
    if (isSelected(cid)) return clsx(classes.item, classes.selected)

    return classes.item
  }

  const renderCategoryList = () => (
    <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
      {categories.map((category) => (
        <ListItem
          key={category.category_id}
          button
          className={getClassName(category.category_id)}
          onClick={() => handleSelected(category)}
        >
          <ListItemText>{category.category_name}</ListItemText>
          {isSelected(category.category_id) && (
            <ListItemIcon>
              <Thick color={theme.palette.primary.main} size={1.3} />
            </ListItemIcon>
          )}
        </ListItem>
      ))}
    </List>
  )

  const renderLoader = () => (
    <Stack justifyContent="center" sx={{ minHeight: '100px' }}>
      <Loader />
    </Stack>
  )

  return (
    <>
      <RowGrid
        item
        sx={{
          justifyContent: 'space-between',
          mt: `${theme.space[5]}px`,
          pl: { xs: 0 },
          pr: { xs: 0 },
        }}
        xs={12}
      >
        <Typography sx={{ alignItems: 'center', display: 'flex' }} variant="h4">
          {PRODUCT.CATEGORY_ASSIGN}
        </Typography>
        <Button
          className={classes.btnSecondary}
          onClick={handleShowCategories}
          size="small"
          startIcon={<Plus color={theme.palette.primary.main} />}
          type="link"
        >
          {!device.isMobile && PRODUCT.ASSIGN}
        </Button>
      </RowGrid>
      <Stack flexDirection="row" sx={{ mb: `${theme.space[5]}px` }}>
        {product.categories?.map((categ) => (
          <InputChip
            key={categ.category_id}
            // deleteIcon={<CancelIcon className={classes.iconDelete} />}
            label={categ.category_name}
            onDelete={() => handleDelete(categ.category_id)}
            shape="button"
            sx={{
              fontWeight: theme.fontWeight.semibold,
              height: 32,
              maxWidth: 160,
            }}
          />
        ))}
      </Stack>

      {/* ModalVariant */}
      <ModalAdaptable
        acceptText={CATALOG.BTN_ACCEPT_TEXT}
        description={PRODUCT.CATEGORY.DESCRIPTION}
        disabledButtonSave={!enableSave}
        loading={loadingApply}
        onAccept={handleAssignCategories}
        onClose={handleClose}
        open={open}
        title={PRODUCT.CATEGORY.TITLE}
      >
        <Stack flexDirection="row" sx={{ mt: `${theme.space[5]}px` }}>
          <TextField
            error={fieldError}
            fullWidth
            InputLabelProps={{ shrink: focus }}
            InputProps={{
              endAdornment: fieldError && <ErrorIcon />,
            }}
            label={!categoryName && PRODUCT.CATEGORY.NAME}
            name="name"
            onBlur={() => setFocus(false)}
            onChange={handleChangeName}
            onClick={() => setFocus(true)}
            sx={{ mb: `${theme.space[4]}px` }}
            value={categoryName || ''}
            variant="filled"
          />
          <LoadingButton
            className={classes.btnPlus}
            color="orange"
            endIcon={<Plus color={theme.palette.white.main} />}
            loading={loadingSave}
            onClick={handleSaveCategory}
            variant="only-icon"
          />
        </Stack>
        {loadingList ? renderLoader() : renderCategoryList()}
      </ModalAdaptable>
    </>
  )
}

export default CategoriesAssign
