import { TableCell as MuiTableCell } from '@mui/material'
import { styled } from '@mui/material/styles'

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    borderBottom: `0.5px solid ${theme.backgrounds[1]}`,
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: theme.fontWeight.regular,
  lineHeight: 20 / 14,
  textAlign: 'left',
}))

export default TableCell
