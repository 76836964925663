import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Switch from 'components/Switch'
import { useCouponContext } from 'components/Coupon/store'
import { discountRuns } from 'helpers/coupon'
import COUPON_ACTIONS from 'components/Coupon/store/actions'

const {
  coupon: COUPON,
  discount_counts: DISCOUNT,
} = require('components/Coupon/constData.json')

const HeadGrid = () => {
  const { couponData, couponDispatch } = useCouponContext()
  const theme = useTheme()
  const [Inactive, Active, Finish] = DISCOUNT.STATUS.OPTIONS

  const handleChange = (_, newCheck) => {
    couponDispatch({
      couponData: {
        status: newCheck ? Inactive : Active,
      },
      type: COUPON_ACTIONS.UPDATE_COUPON,
    })
  }

  const disabled = couponData?.status === Finish || !discountRuns(couponData)

  const Status = () => (
    <Chip
      label={DISCOUNT.STATUS[couponData.status.toUpperCase()]?.TEXT}
      shape="status"
      status={DISCOUNT.STATUS[couponData.status.toUpperCase()]?.VARIANT}
      sx={{ border: 'none', fontWeight: theme.fontWeight.semibold, ml: 0 }}
    />
  )

  const renderStatus = (status) => (
    <Stack
      justifyContent="space-between"
      sx={{ width: { sm: '75%', xs: '100%' } }}
    >
      {status ? (
        <Status />
      ) : (
        <Typography
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          gutterBottom
          variant="body1"
        >
          {COUPON.DESCRIPTION}
        </Typography>
      )}
    </Stack>
  )

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack sx={{ width: { sm: '65%', xs: '100%' } }}>
        <Typography
          color="black"
          gutterBottom
          sx={{ display: 'flex' }}
          variant="h2"
        >
          {couponData?.status ? couponData.name : COUPON.NEW}
        </Typography>
        {renderStatus(couponData?.status)}
      </Stack>
      {couponData?.status && (
        <Typography variant="body1">
          {DISCOUNT.STATUS[couponData?.status?.toUpperCase()]?.TEXT}
          <Switch
            checked={couponData?.status === DISCOUNT.STATUS.OPTIONS[0]} // Active
            data-testid="store-switch-button"
            disabled={disabled}
            onChange={handleChange}
            sx={{
              ml: {
                sm: `${theme.space[4]}px`,
                xs: `${theme.space[2]}px`,
              },
            }}
          />
        </Typography>
      )}
    </Stack>
  )
}

export default HeadGrid
