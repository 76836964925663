import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'

const Loader = ({ color, thickness }) => (
  <Container
    sx={{
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
    }}
  >
    <CircularProgress color={color} thickness={thickness} />
  </Container>
)

export default Loader

Loader.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.number,
}

Loader.defaultProps = {
  color: 'primary',
  thickness: 3,
}
