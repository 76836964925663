import { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Widget from 'components/Catalog-v2/widget'
import { useGlobalContext } from 'store/global'
import { PROMOTIONS_ROUTE } from 'constants/routes'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import generateKey from 'utils/math'

const {
  sections,
  common: MAIN_PAGE,
} = require('components/Coupon/constData.json')

const Promotions = () => {
  const { device } = useGlobalContext()
  const theme = useTheme()

  useEffect(() => {
    cleverTap.event(EVENTS.PROMOTIONS_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  return (
    <Layout>
      <ContainerCard>
        <Typography color="text.primary" variant="h4">
          {MAIN_PAGE.TITLE}
        </Typography>
        {sections.map((section, idx) => (
          <Widget
            key={generateKey(idx)}
            navLink={`${PROMOTIONS_ROUTE}${section.url}`}
            sx={{ m: `${theme.space[3]}px 0` }}
          >
            <Typography color="black" variant="h2">
              {section.label}
            </Typography>
          </Widget>
        ))}
      </ContainerCard>
    </Layout>
  )
}

export default Promotions
