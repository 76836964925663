import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  empty: {
    '& svg': {
      height: 55,
      width: 55,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 180,
    justifyContent: 'center',
  },
}))

export default useStyles
