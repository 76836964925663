import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import generateKey from 'utils/math'
import RowGrid from 'components/RowGrid'
import PL from './constData.json'
import RenderLabelAndPrice from './renderLabelAndPrice'

const ProductList = ({ products }) => {
  const theme = useTheme()

  const renderVariantAndSku = (variant, pid) => (
    <Stack
      sx={{
        flexDirection: { sm: 'row', xs: 'column' },
        width: '80%',
      }}
    >
      {variant && (
        <Typography
          color="text.primary"
          component="p"
          fontWeight={theme.fontWeight.semibold}
          variant="body2"
        >
          {variant}
        </Typography>
      )}

      <Typography
        color="text.primary"
        component="p"
        fontWeight={theme.fontWeight.semibold}
        sx={{ marginLeft: { sm: variant ? '64px' : '0px' } }}
        variant="body2"
      >
        {PL.SKU_CODE}
        {pid}
      </Typography>
    </Stack>
  )

  return (
    <>
      {products.map((product, i) => (
        <RowGrid
          key={generateKey(i)}
          item
          sx={{
            m: 'auto',
            maxWidth: { xs: 'calc(100% - 48px)' },
            p: { xs: `${theme.space[4]}px 0` },
          }}
          xs={12}
        >
          {product.photoURL && (
            <Grid item sm>
              <Avatar
                alt={product.name}
                src={product.photoURL}
                sx={{
                  borderRadius: `${theme.borderRadius[0]}px`,
                  height: { sm: 80, xs: 60 },
                  marginRight: `${theme.space[4]}px`,
                  width: { sm: 80, xs: 60 },
                }}
              />
            </Grid>
          )}
          <Grid item xs={11}>
            <RenderLabelAndPrice
              name={
                product.quantity === 1
                  ? product.name
                  : `${product.name} - x${product.quantity}`
              }
              price={
                product.quantity === 1
                  ? product.price
                  : product.price * parseFloat(product.quantity, 10)
              }
              textBold
            />
            {renderVariantAndSku(product.variant_name, product.product_id)}
            {product.client_notes && (
              <Typography
                color="text.primary"
                component="p"
                fontWeight={theme.fontWeight.regular}
                variant="body1"
              >
                {product.client_notes}
              </Typography>
            )}
          </Grid>
        </RowGrid>
      ))}
    </>
  )
}

export default ProductList

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      skuId: PropTypes.string,
      variant: PropTypes.string,
    })
  ).isRequired,
}
