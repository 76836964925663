import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import TextField from 'components/TextField'
import { useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useCouponContext } from 'components/Coupon/store'
import { onlyNumber } from 'utils/form'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import useStylesNumberFormat from 'components/Catalog-v2/product/styles'
import useStyles from 'components/Coupon/styles'

const {
  product: NUMBER_FORMAT,
} = require('components/Catalog-v2/product/constData.json')
const { coupon: COUPON } = require('components/Coupon/constData.json')

const DiscountInput = ({ disabled, error, onChange }) => {
  const [focusDiscountType, setFocusDiscountType] = useState(false)
  const theme = useTheme()
  const { couponData, couponDispatch } = useCouponContext()
  const classes = useStyles()
  const styles = useStylesNumberFormat()

  useEffect(() => {
    couponDispatch({
      couponData: {
        discount_type: COUPON.DISCOUNT_TYPE[0],
      },
      type: COUPON_ACTIONS.UPDATE_COUPON,
    })
  }, [])

  const handleChangeToggle = (event, newValue) => {
    const discountType = newValue || couponData?.discount_type

    couponDispatch({
      couponData: {
        discount_type: discountType,
        total_discount: discountType === COUPON.DISCOUNT_TYPE[0] ? 0 : 0.0,
      },
      type: COUPON_ACTIONS.UPDATE_COUPON,
    })
  }

  const isValidPercentage = (value, newValue) => {
    const number = value.concat(newValue)

    if (number.length < 4 && parseFloat(number, 10) <= 100) {
      return true
    }

    return false
  }

  const handleKeyPressCtrl = (event) => {
    if (
      onlyNumber(event.key) ||
      !isValidPercentage(event.target.value, event.key)
    ) {
      event.preventDefault()
    }
  }

  return (
    <>
      <Typography fontWeight={theme.fontWeight.semibold} variant="body2">
        {COUPON.DISCOUNT}
      </Typography>
      <Stack
        className={clsx({
          [classes.wrapDiscountType]: true,
          [classes.error]: error,
        })}
      >
        {couponData?.discount_type !== COUPON.DISCOUNT_TYPE[1] ? (
          <TextField
            autoComplete="off"
            disabled={disabled}
            error={error}
            fullWidth
            helperText={error && COUPON.ERROR}
            label={
              !couponData?.total_discount && !focusDiscountType && COUPON.PRICE
            }
            name="total_discount"
            onBlur={() => setFocusDiscountType(false)}
            onChange={onChange}
            onClick={() => setFocusDiscountType(true)}
            onKeyPress={handleKeyPressCtrl}
            sx={{ height: 40, mb: `${theme.space[4]}px` }}
            value={parseInt(couponData?.total_discount, 10) || ''}
            variant="filled"
          />
        ) : (
          <NumberFormat
            {...NUMBER_FORMAT.PRICE_OPTIONS}
            className={clsx({
              [styles.numberFormat]: true,
              [classes.discountType]: true,
              [classes.numberFormatError]: error,
              [classes.disabled]: disabled,
            })}
            disabled={disabled}
            name="total_discount"
            onChange={onChange}
            onFocus={(elem) => elem.target.select()}
            placeholder={NUMBER_FORMAT.PRICE}
            type="text"
            value={couponData?.total_discount}
          />
        )}

        <ToggleButtonGroup
          color="primary"
          disabled={disabled}
          exclusive
          onChange={handleChangeToggle}
          value={couponData?.discount_type || COUPON.DISCOUNT_TYPE[0]}
        >
          <ToggleButton
            className={clsx({ [classes.toggleButtonError]: error })}
            value={COUPON.DISCOUNT_TYPE[0]}
          >
            {COUPON.DISCOUNT_TYPE[0]}
          </ToggleButton>
          <ToggleButton
            className={clsx({ [classes.toggleButtonError]: error })}
            value={COUPON.DISCOUNT_TYPE[1]}
          >
            {COUPON.DISCOUNT_TYPE[1]}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  )
}

export default DiscountInput

DiscountInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
}

DiscountInput.defaultProps = {
  disabled: false,
  error: false,
  onChange: () => {},
}
