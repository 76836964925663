/**
 * is Extern URL
 *
 * @return {boolean}
 */
const isExternURL = (url) => {
  if (url.search('https://') >= 0 || url.search('www') >= 0) return true

  return false
}

/**
 * random (50 - 50)
 *
 * @return {boolean}
 */
const random = () => Math.random() < 0.5

/**
 * create mail format
 *
 * @param {eCustomer} email customer
 * @param {eMerchant} email merchant
 * @param {datePayment} date success payment
 * @param {timePayment} time success payment
 * @param {amount} price order
 *
 * @return {string}
 */
const getMailTo = (eCustomer, eMerchant, datePayment, timePayment, amount) => {
  const mailto = 'mailto:mystore@payclip.com'
  const subject = 'subject=Mystore%20Reembolso'
  const body = `body=¡Hola!\n
  Para poder llevar a cabo el reembolso a tu cliente necesitamos que nos compartas la siguiente información.\n
  Por favor envía el email sin editar el cuerpo o el asunto.\n
  ----------------------------------------------------------\n
  **IMPORTANTE**
  NO EDITAR LA INFORMACIÓN DE ESTE EMAIL\n
  DATOS DE LA TRANSACCIÓN A REEMBOLSAR:\n
  Email tienda: ${eMerchant}\n
  Monto total: $${amount}\n
  Fecha: ${datePayment}\n
  Hora: ${timePayment}\n
  Email cliente: ${eCustomer}\n
  ----------------------------------------------------------\n
  Una vez que hayamos recibido esta información la evaluaremos y te estaremos notificando por este mismo medio que hemos iniciado el proceso de reembolso Clip.
  Es importante que consideres que el tiempo de reembolso final a tu cliente dependen totalmente de su banco.\n
  Recibe un saludo,\n
  Equipo Clip\n
  `
  const lineBreak = '%0d%0a'
  const returnValue = `${mailto}?${subject}&${body}`

  return returnValue.replace(/\n/g, lineBreak)
}

export { isExternURL, random, getMailTo }
