import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    border: `3px dashed ${theme.backgrounds[1]}`,
    borderRadius: theme.space[2],
    display: 'flex',
    height: 150,
    justifyContent: 'center',
    margin: `${theme.space[5]}px 0 0`,
    maxWidth: 500,
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  editButton: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '50%',
    bottom: '-24px',
    cursor: 'pointer',
    position: 'absolute',
    right: '-24px',
  },
  input: {
    '& input': {
      cursor: 'pointer',
      height: '100%',
    },
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}))

export default useStyles
