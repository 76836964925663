const EVENTS = {
  AYUDA_VIEWED: 'MS_ECOMMERCE_DASHBOARD_AYUDA_VIEWED',
  CATALOGO_VIEWED: 'MS_ECOMMERCE_DASHBOARD_CATALOGO_VIEWED',
  CONFIGURACION_VIEWED: 'MS_ECOMMERCE_DASHBOARD_CONFIGURACION_VIEWED',
  DISENO_VIEWED: 'MS_ECOMMERCE_DASHBOARD_DISENO_VIEWED',
  HOMEMITIENDA_VIEWED: 'MS_ECOMMERCE_DASHBOARD_HOMEMITIENDA_VIEWED',
  MENU_CLICK: 'MS_ECOMMERCE_DASHBOARD_MENU_CLICK',
  ONBOARDING_VIEWED: 'MS_ECOMMERCE_DASHBOARD_ONBOARDING_VIEWED',
  PREGUNTASFRECUENTES_VIEWED:
    'MS_ECOMMERCE_DASHBOARD_PREGUNTASFRECUENTES_VIEWED',
  PROMOTIONS_VIEWED: 'MS_ECOMMERCE_DASHBOARD_PROMOCIONA_VIEWED',
  PUBLISH_VIEWED: 'MS_ECOMMERCE_DASHBOARD_PUBLISH_VIEWED',
  REPORTES_VIEWED: 'MS_ECOMMERCE_DASHBOARD_REPORTES_VIEWED',
  TUTORIALES_VIEWED: 'MS_ECOMMERCE_DASHBOARD_TUTORIALES_VIEWED',
  VENTAS_VIEWED: 'MS_ECOMMERCE_DASHBOARD_VENTAS_VIEWED',
}

export default EVENTS
