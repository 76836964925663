import { lazy, Suspense } from 'react'
import ContainerCard from 'components/ContainerCard'
import Layout from 'components/Layout'

const CatalogAppLazy = lazy(() => import('components/CatalogApp'))

export default () => (
  <Layout>
    <ContainerCard>
      <Suspense fallback={<div />}>
        <CatalogAppLazy />
      </Suspense>
    </ContainerCard>
  </Layout>
)
