import PropTypes from 'prop-types'
import { useState } from 'react'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTheme } from '@mui/material/styles'
import { useGlobalContext } from 'store/global'
import { ChainLink, Email, Facebook, Whatsapp } from 'components/Icons'
import StatusMessages from 'components/StatusMessages'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import data from './data.json'

const SocialMedia = ({
  cleverTapEnable,
  fbShare,
  storeLink,
  title,
  whatsappText,
}) => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [copySuccess, setCopySuccess] = useState(false)
  const [copyError, setCopyError] = useState(false)
  const { message, whatsapp, facebook } = data
  const whatsappShare = whatsappText ? `${whatsapp.url}${whatsappText}` : '#'
  const facebookShare = fbShare ? `${facebook.url}${fbShare}` : '#'

  const handleClipboard = (text, result) => {
    if (cleverTapEnable) {
      cleverTap.event(EVENTS.PUBLISH_VIEWED, {
        Click: PV.publish_copy_url,
        Device: device.type,
      })
    }

    if (result) {
      setCopySuccess(true)
    } else {
      setCopyError(true)
    }
  }

  const handleClose = () => {
    setCopyError(false)
    setCopySuccess(false)
  }

  const handleShareClick = (event) => {
    if (cleverTapEnable) {
      cleverTap.event(EVENTS.PUBLISH_VIEWED, {
        Click: event.currentTarget.id,
        Device: device.type,
      })
    }
  }

  const separator = () => (
    <Typography
      color={theme.backgrounds[2]}
      component="p"
      fontWeight={theme.fontWeight.semibold}
      variant="body1"
    >
      |
    </Typography>
  )

  return (
    <Stack
      sx={{
        '& .MuiSnackbar-root .MuiPaper-root': {
          '& .MuiSnackbarContent-message': {
            '& .MuiBox-root > div': {
              left: -6,
              top: -5,
              width: 'auto',
            },
            '& .MuiBox-root span': {
              left: 24,
              maxWidth: 200,
              position: 'absolute',
              top: -2,
            },
            position: 'absolute',
            top: 0,
          },
          minHeight: 30,
          minWidth: 250,
          overflow: 'hidden',
          position: 'relative',
        },
        mt: `${theme.space[4]}px`,
      }}
    >
      {title && (
        <Typography
          component="p"
          fontWeight={theme.fontWeight.semibold}
          sx={{ mb: `${theme.space[4]}px` }}
          variant="body1"
        >
          {title}
        </Typography>
      )}
      <Stack flexDirection="row">
        <Link
          href={whatsappShare}
          id={PV.publish_share_whatsapp}
          onClick={handleShareClick}
          sx={{ paddingRight: `${theme.space[2]}px` }}
        >
          <Whatsapp color={theme.palette.primary.main} />
        </Link>

        {separator()}

        <Link
          href={facebookShare}
          id={PV.publish_share_facebook}
          onClick={handleShareClick}
          sx={{ padding: `0 ${theme.space[2]}px` }}
        >
          <Facebook color={theme.palette.primary.main} />
        </Link>

        {separator()}

        <Link
          href="mailto:"
          id={PV.publish_share_email}
          onClick={handleShareClick}
          sx={{ padding: `0 ${theme.space[2]}px` }}
        >
          <Email color={theme.palette.primary.main} />
        </Link>

        {storeLink && (
          <>
            {separator()}
            <CopyToClipboard onCopy={handleClipboard} text={storeLink}>
              <IconButton
                sx={{
                  '&:hover': {
                    background: 'none',
                  },
                  paddingTop: '3px',
                }}
              >
                <ChainLink color={theme.palette.primary.main} size={1.3} />
              </IconButton>
            </CopyToClipboard>
          </>
        )}
      </Stack>
      <StatusMessages
        errorFlag={copyError}
        errorMessage={message.error}
        handleClose={handleClose}
        successFlag={copySuccess}
        successMessage={message.success}
      />
    </Stack>
  )
}

export default SocialMedia

SocialMedia.propTypes = {
  cleverTapEnable: PropTypes.bool,
  fbShare: PropTypes.string,
  storeLink: PropTypes.string,
  title: PropTypes.string,
  whatsappText: PropTypes.string,
}

SocialMedia.defaultProps = {
  cleverTapEnable: false,
  fbShare: '',
  storeLink: null,
  title: '',
  whatsappText: '',
}
