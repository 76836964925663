// FAQ Page
const FAQ_TEXT = 'Preguntas frecuentes'

// Store Page
const TEXT_STATISTICS_STORE = 'Estadísticas'
const VISITS_TEXT_STORE = 'Visitas a tu tienda'
const TEXT_LINK_TO_STORE = 'Enlace de tu tienda:'

// Reports Page
const GENERAL = 'General'
const STORE_ACTIVITY = 'Actividad en tu tienda'

// Settings Page
const SUCCESS_MESSAGE = 'Los cambios se guardaron correctamente'
const ERROR_MESSAGE =
  'No pudimos guardar los cambios, inténtalo de nuevo en unos minutos'

// Tutorials Page
const SEE_ADVICE = 'Ver tip'
const TUTORIALS = 'Tutoriales'

// Articles Page
const BACK = 'Regresar'

const COMPLETE = 'Completo'
const PENDING = 'Pendiente'

const MOBILE = 'Mobile'
const DESKTOP = 'Desktop'

// WebView
const WEBVIEW_FLAG_COOKIE = 'webview'

// InfoScreen in Store
const INFO_SCREEN_DESCRIPTION =
  'Aquí podrás ver un pequeño resumen de las estadísticas de tu negocio cuando tengas ventas.'
const INFO_SCREEN_TITLE = 'Mi negocio'
const INFO_SCREEN_TITLE_MSG = 'Aún no tenemos datos'

export {
  BACK,
  COMPLETE,
  DESKTOP,
  ERROR_MESSAGE,
  FAQ_TEXT,
  GENERAL,
  INFO_SCREEN_DESCRIPTION,
  INFO_SCREEN_TITLE,
  INFO_SCREEN_TITLE_MSG,
  MOBILE,
  PENDING,
  SEE_ADVICE,
  SUCCESS_MESSAGE,
  STORE_ACTIVITY,
  TEXT_LINK_TO_STORE,
  TEXT_STATISTICS_STORE,
  TUTORIALS,
  VISITS_TEXT_STORE,
  WEBVIEW_FLAG_COOKIE,
}
