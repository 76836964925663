const getImageBase64 = (content) => content.split('base64,')[1]

const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })

export { getImageBase64, convertToBase64 }
