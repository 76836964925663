import { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from 'components/TextField'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader'
import { useTheme } from '@mui/material'
import { ErrorIcon } from 'components/Icons'
import { useCouponContext } from 'components/Coupon/store'
import { fetchInfo } from 'helpers/fetchUtil'
import { countCodesAllowed, validCharcode } from 'helpers/coupon'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import { ENV, POST_COUPONS_ROUTE } from 'constants/routes'

const { coupon: COUPON } = require('components/Coupon/constData.json')

const CouponCode = ({ disabled, error, errorMessage }) => {
  const [loading, setLoading] = useState(false)
  const [errorExist, setErrorExist] = useState(false)
  const theme = useTheme()
  const { couponData, couponDispatch } = useCouponContext()
  const urlBase = `${ENV.REACT_APP_API_URL}${POST_COUPONS_ROUTE}`

  const handleChange = (event) => {
    const { value } = event.target

    if (countCodesAllowed(value)) {
      couponDispatch({
        couponData: { coupon_code: value },
        type: COUPON_ACTIONS.UPDATE_COUPON,
      })
    }
  }

  const handleKeyPress = (event) => {
    if (!validCharcode(event.key) || !countCodesAllowed(event.target.value)) {
      event.preventDefault()
    }
  }

  const handleExistCode = async (event) => {
    const { value } = event.target

    if (value) {
      try {
        setLoading(true)

        const responseCode = await fetchInfo(`${urlBase}/${value}`)
        const { data: coupon } = responseCode

        // eslint-disable-next-line no-console
        console.log('responseCode: ', coupon)
        if (coupon?.coupon_code) {
          setErrorExist(true)
        }

        setLoading(false)
      } catch (e) {
        setLoading(false)
        setErrorExist(false)
      }
    }
  }

  const getErrorMessage = () => {
    if (errorExist) return COUPON.ERROR_MESSAGE.COUPON_CODE

    if (error && !couponData?.coupon_code) return errorMessage

    return ''
  }

  const MiniLoader = () => loading && <Loader thickness={1} />

  return (
    <>
      <TextField
        disabled={disabled}
        error={error || errorExist}
        fullWidth
        helperText={getErrorMessage()}
        InputProps={{
          endAdornment: ((error || errorExist) && <ErrorIcon />) || (
            <MiniLoader />
          ),
        }}
        label={COUPON.CODE}
        name="coupon_code"
        onBlur={handleExistCode}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        sx={{
          '& .MuiContainer-root': {
            maxWidth: 40,
            pl: 0,
            pr: 0,
          },
        }}
        value={couponData?.coupon_code || ''}
        variant="filled"
      />

      <Typography sx={{ mb: `${theme.space[4]}px` }} variant="body2">
        {!error && !errorExist && !loading && COUPON.MAX_CHARACTER}
      </Typography>
    </>
  )
}

export default CouponCode

CouponCode.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

CouponCode.defaultProps = {
  disabled: false,
  error: false,
  errorMessage: '',
}
