import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material'
import Button from 'components/Button'
import Paper from 'components/Paper'
import NOTES_BOX from './constData.json'

const NotesBox = ({ handleSubmit, value }) => {
  const theme = useTheme()
  const [notes, setNotes] = useState()

  useEffect(() => {
    if (value) {
      setNotes(value)
    }
  }, [])

  return (
    <Paper
      sx={{
        marginLeft: { md: 0, sm: `${theme.space[2]}px`, xs: 0 },
        padding: 0,
      }}
    >
      <Stack alignItems="flex-start" sx={{ padding: `${theme.space[4]}px` }}>
        <Typography color="black" variant="h2">
          {NOTES_BOX.NOTES_LABEL}
        </Typography>
        <TextField
          id="noteText"
          multiline
          onChange={(e) => setNotes(e.target.value)}
          placeholder={NOTES_BOX.PLACEHOLDER_NOTES}
          rows={3}
          sx={{
            '& .MuiInputBase-root': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.backgrounds[2],
              },
              borderRadius: `${theme.borderRadius[0]}px`,
              fontWeight: theme.fontWeight.semibold,
              padding: `${theme.space[2]}px ${theme.space[4]}px`,
            },
            margin: `${theme.space[3]}px 0`,
            width: '100%',
          }}
          value={notes}
        />
        <Button
          onClick={() => handleSubmit(notes)}
          size="full-width"
          sx={{
            alignSelf: { sm: 'flex-end' },
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
            width: { sm: 140 },
          }}
        >
          {NOTES_BOX.SAVE_BUTTON_NOTES}
        </Button>
      </Stack>
    </Paper>
  )
}

export default NotesBox

NotesBox.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

NotesBox.defaultProps = {
  value: '',
}
