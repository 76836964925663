import React, { useContext, useState, useEffect } from 'react'
import { AppBar, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NavLink, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { MenuBurger, DownloadSave, Faq, Logout } from 'components/Icons'
import { PublishModal } from 'components/Modal'
import dashboardSettings from 'constants/dashboardSettings'
import {
  DESIGN_ROUTE,
  FAQ_ROUTE,
  ENV,
  GET_CONFIG_ROUTE,
} from 'constants/routes'
import { useAuthState, useConfigDispatch } from 'store/user'
import { clearCookie, TOKEN_NAME } from 'helpers/tokenHelpers'
import HEADER from 'components/Menu/constData.json'
import Button from 'components/Button'
import { MenuContext } from 'components/Menu/store'
import { updateInfo } from 'helpers/fetchUtil'
import { isCompleteValidation } from 'utils/validation'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import MENU_ACTIONS from 'components/Menu/store/actions'
import UserActions from 'store/user/actions'
import useStyles from './styles'

const Header = () => {
  const navigate = useNavigate()
  const {
    userInfo: userData,
    merchantInfo,
    merchantToken,
    storeConfig,
  } = useAuthState()
  const [openModal, setOpenModal] = useState(false)
  const [published, setPublished] = useState()
  const { menuState, menuDispatch } = useContext(MenuContext)
  const { open } = menuState
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useConfigDispatch()
  const { device } = useGlobalContext()

  useEffect(() => {
    setPublished(storeConfig?.is_published)
  }, [storeConfig])

  if (!userData) {
    return null
  }

  const { first_name: firstName } = userData
  const { publicProfile } = merchantInfo
  const merchantUrl = publicProfile
    ? `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`
    : ''

  const handleToggleModal = () => {
    if (openModal) {
      cleverTap.event(EVENTS.PUBLISH_VIEWED, {
        Click: PV.publish_modal_close,
        Device: device.type,
      })
    }
    setOpenModal(!openModal)
  }
  const handleDrawerOpen = () =>
    menuDispatch({ open: !open, type: MENU_ACTIONS.UPDATE_TOGGLE })

  const handleClickNavLink = (url) => {
    menuDispatch({ selected: url, type: MENU_ACTIONS.UPDATE_SELECTED })
  }

  const handlePublish = () => {
    if (isCompleteValidation(storeConfig)) {
      updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
        is_published: true,
        sharable_catalog: false,
      }).then(() => {
        dispatch({
          payload: {
            ...storeConfig,
            is_published: true,
            sharable_catalog: false,
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        setPublished(true)
        handleToggleModal()
      })
    } else {
      handleToggleModal()
    }
  }

  const handleLogout = () => {
    clearCookie(TOKEN_NAME)
    window.location.href = '/'
  }

  const renderPublishButton = () =>
    dashboardSettings.SHOW_PUBLISH_BUTTON && !published ? (
      <Button
        color="orange"
        onClick={handlePublish}
        size="small"
        startIcon={<DownloadSave color="white" rotate={180} />}
        sx={{ marginRight: { lg: '24px', md: '12px', sm: '6px' } }}
      >
        {HEADER.PUBLIC_BUTTON_TEXT}
      </Button>
    ) : null

  const renderButtonsDesktop = () => (
    <>
      {/* FAQ Button Desktop */}
      <Button
        color="white"
        onClick={() => navigate(FAQ_ROUTE)}
        size="small"
        startIcon={<Faq />}
        sx={{
          display: { md: 'inline-flex', xs: 'none' },
          marginRight: { lg: '24px', md: '12px', sm: '6px' },
        }}
      >
        {HEADER.FAQ_BUTTON_TEXT}
      </Button>
      {/* Logout Button Desktop */}
      <Button
        color="white"
        onClick={handleLogout}
        size="small"
        startIcon={<Logout />}
        sx={{ marginRight: { md: '24px', sm: '6px' } }}
      >
        {HEADER.LOGOUT_BUTTON_TEXT}
      </Button>
    </>
  )

  const renderProfileText = () => (
    <Stack
      alignItems="center"
      className={classes.profileText}
      flexDirection="row"
      sx={{ marginLeft: open ? '250px' : '98px' }}
    >
      <Typography color="black" variant="bod1">
        {HEADER.HELLO_PROFILE_TEXT}
      </Typography>

      <NavLink
        className={classes.profileLink}
        onClick={() => handleClickNavLink(DESIGN_ROUTE)}
        to={DESIGN_ROUTE}
      >
        <i>&nbsp;</i>
        {firstName}
      </NavLink>
    </Stack>
  )

  // Button Hamburger
  const renderMenuButton = () => (
    <IconButton
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerOpen}
      sx={{
        height: 40,
        marginLeft: `${theme.space[4]}px`,
        width: 40,
      }}
    >
      <MenuBurger size={1} />
    </IconButton>
  )

  return (
    <AppBar className={classes.header}>
      <Grid container>
        <Grid
          item
          lg={5}
          md={6}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: { sm: 'column', xs: 'row' },
            justifyContent: 'flex-start',
          }}
          xs={12}
        >
          {device.isMobile && renderMenuButton()}
          {renderProfileText()}
        </Grid>

        <Grid className={classes.last} item lg={7} md={6} sm={5}>
          {renderPublishButton()}
          {renderButtonsDesktop()}
        </Grid>
      </Grid>

      {/* => CONGRATS MODAL */}
      <PublishModal
        merchantData={{
          merchantName: publicProfile?.public_name || '',
          merchantSlug: publicProfile?.alias || '',
          merchantToken,
          merchantUrl,
        }}
        onClose={handleToggleModal}
        open={openModal}
        publishedSuccess={published}
      />
      {/* <= CONGRATS MODAL */}
    </AppBar>
  )
}

export default Header
