/**
 * Check a object is empty
 *
 * @param {object}
 * @return {boolean}
 */
const isEmpty = (object) => !Object.keys(object).length

/**
 * Check a object is Valid
 *
 * @param {object}
 * @return {boolean}
 */
const isCompleteValidation = (sc) => {
  if (!sc) return false
  if (!sc.store_settings?.theme_id) return false

  if (!sc.store_settings?.opening_hours) return false

  if (!sc.store_settings?.delivery_settings) return false

  if (!sc?.contact_information) return false

  if (!sc?.tributary) return false

  if (!sc?.hasCatalog) return false

  return true
}

/**
 * Check a object is partially Valid
 *
 * @param {object}
 * @return {boolean}
 */
const isPartialValidation = (sc) => {
  if (!sc) return false
  if (!sc?.store_settings) return false

  if (!sc.store_settings?.opening_hours) return false
  if (!sc.store_settings?.delivery_settings) return false
  if (!sc.contact_information) return false

  return true
}

// https://es.stackoverflow.com/questions/31713/c%c3%b3mo-validar-un-rfc-de-m%c3%a9xico-y-su-digito-verificador/31714#31714
// example: jumm420313pa9
export const isValidRFC = (value) =>
  value
    ?.toUpperCase()
    .match(
      /^(?:(?:[A-ZÑ&]{3,4})(?:\d{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])(?:[A-Z\d]{2})[A\d])$/
    )

export { isEmpty, isCompleteValidation, isPartialValidation }
