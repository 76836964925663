import { useTheme } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { EmptyRow } from 'components/Icons'

const {
  data: { labels: TABLE },
} = require('../constData.json')

const TableRowEmpty = () => {
  const theme = useTheme()

  return (
    <TableRow>
      <TableCell
        colSpan={6}
        sx={{ height: '60vh', textAlign: 'center', verticalAlign: 'middle' }}
      >
        <EmptyRow size={8} />
        <Typography
          color="divider"
          component="p"
          fontWeight={theme.fontWeight.semibold}
          variant="h3"
        >
          {TABLE.TABLE_MESSAGE_EMPTY}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TableRowEmpty
