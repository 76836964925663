import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'

const LoadingButton = styled(MuiLoadingButton, {
  // Configure which props should be forwarded on DOM
  name: 'MuiLoadingButton',
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === 'primary' && styles.primary,
  ],
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'variant' && prop !== 'sx',
  // We are specifying here how the styleOverrides are being applied based on props
  slot: 'Root',
})(({ theme }) => ({
  // this component has disabled mode
  // 1) props disabled
  // 2) props loading
  '&.Mui-disabled': {
    '&.MuiLoadingButton-loading': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    backgroundColor: theme.backgrounds[1],
    color: theme.palette.divider,
  },
  '&:hover': {
    opacity: 1,
  },

  [theme.breakpoints.down('xl')]: {
    lineHeight: 1,
  },
  borderRadius: theme.borderRadius[0],
  fontSize: 16,
  fontWeight: theme.fontWeight.bold,
  letterSpacing: 0,
  lineHeight: 24 / 16,
  minHeight: 30,
  minWidth: 100,
  padding: theme.space[3],
  textTransform: 'initial',
}))

export default LoadingButton
