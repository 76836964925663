import { useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/Paper'
import ProfileForm from 'components/ProfileForm'
import Button from 'components/Button'
import LoadingButton from 'components/LoadingButton'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { convertToValidURL, validateAlias } from 'utils/form'
import ONBOARDING from 'components/Onboarding/constData.json'
import { ENV } from 'constants/routes'
import { savePublicProfile } from 'helpers/fetchUtil'

const Form = ({ merchantInfo, onCompleted }) => {
  const { device } = useGlobalContext()
  const [link, setLink] = useState('')
  const [publicName, setPublicName] = useState('')
  const [publicDesc, setPublicDesc] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [linkInUseError, setLinkInUseError] = useState(false)
  const [nameInUseError, setNameInUseError] = useState(false)
  const [mandatoryError, setMandatoryError] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [linkModified, setLinkModified] = useState(false)
  const theme = useTheme()

  const isDisabled = () => {
    if (
      link === `${ENV.REACT_APP_STORE_BASE_URL}/` ||
      publicName === '' ||
      publicDesc === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  const onChangeLink = (e) => {
    let alias = e.target.value.split(`${ENV.REACT_APP_STORE_BASE_URL}/`)

    if (alias[1]) {
      alias = convertToValidURL(alias[1])
    } else alias = undefined

    setLink(`${ENV.REACT_APP_STORE_BASE_URL}/${alias || ''}`)
    setLinkInUseError(false)
  }

  const onChangePublicDesc = (e) => setPublicDesc(e.target.value)

  const onChangePublicName = (e) => {
    const { value } = e.target

    setPublicName(value)

    if (!linkModified) {
      const alias = convertToValidURL(value)

      setLink(`${ENV.REACT_APP_STORE_BASE_URL}/${alias || ''}`)
      setLinkInUseError(false)
    }
    setNameInUseError(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const alias = validateAlias(link)

    if (alias === false) {
      setMandatoryError(true)

      return
    }

    cleverTap.event(EVENTS.ONBOARDING_VIEWED, {
      Click: PV.continue,
      Device: device.type,
    })

    try {
      const data = await savePublicProfile(
        alias,
        merchantInfo.id,
        publicName,
        publicDesc
      )

      if (data.messages.code === 200 || (data.items && data.items[0].alias)) {
        onCompleted()
      } else {
        setSubmitError(true)
      }
    } catch (e) {
      setSubmitError(true)
    }
    setLoading(false)
  }

  const handleBlur = () => {
    if (link.length && link !== `${ENV.REACT_APP_STORE_BASE_URL}/`) {
      setLinkModified(true)
    }
  }

  return (
    <Paper sx={{ maxWidth: 696 }}>
      <Typography align="center" variant="h1">
        {ONBOARDING.COMPLETE_DATA_STORE}
      </Typography>
      <ProfileForm
        isOnboarding
        link={link}
        linkInUseError={linkInUseError}
        mandatoryError={mandatoryError}
        nameInUseError={nameInUseError}
        onChangeLink={onChangeLink}
        onChangePublicDesc={onChangePublicDesc}
        onChangePublicName={onChangePublicName}
        onCompleted={() => onCompleted()}
        onLinkBlur={handleBlur}
        publicDesc={publicDesc}
        publicName={publicName}
        validateOnBoarding={isDisabled}
      />
      <Stack direction="row" sx={{ justifyContent: 'space-between', mt: 5 }}>
        <Button
          color="white"
          href={`${ENV.REACT_APP_ME_DASHBOARD_URL}/mystore`}
          sx={{ mr: `${theme.space[2]}px` }}
          target="_blank"
          variant="secondary-form"
        >
          {ONBOARDING.BACK}
        </Button>

        <LoadingButton
          color="orange"
          disabled={disabled}
          loading={loading}
          onClick={handleSubmit}
          size="onboarding"
          variant="primary-form"
        >
          {ONBOARDING.CONTINUE}
        </LoadingButton>
      </Stack>

      {submitError && (
        <p style={{ color: 'red', marginTop: 20, textAlign: 'center' }}>
          Hubo un error, por favor intentalo de nuevo!
        </p>
      )}
    </Paper>
  )
}

Form.propTypes = {
  merchantInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onCompleted: PropTypes.func.isRequired,
}

export default Form
