import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const ModalBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: `${theme.borderRadius[0]}px`,
  left: '50%',
  padding: `${theme.space[5]}px`,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 516,
  [theme.breakpoints.down('sm')]: {
    width: 320,
  },
}))

export default ModalBox
