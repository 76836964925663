// import { useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Plus } from 'components/Icons'
import useStyles from './styles'

const { search: DATA_SEARCH } = require('./constData.json')

const SearchMessage = ({ count, onHandleClear, term }) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Stack className={classes.searchMessage}>
      <Typography
        color="secondary"
        component="p"
        sx={{
          '& > span': {
            color: theme.palette.black.main,
          },
        }}
        variant="body1"
      >
        {`${count} ${DATA_SEARCH.LABEL_SEARCH}`}
        <span>{term}</span>
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onHandleClear}
        sx={{
          backgroundColor: theme.palette.secondary.light,
          borderRadius: { xs: `${theme.borderRadius[0]}px` },
          height: 32,
          width: 32,
        }}
      >
        <Plus rotate={45} size={1.4} />
      </IconButton>
    </Stack>
  )
}

export default SearchMessage

SearchMessage.propTypes = {
  count: PropTypes.number,
  onHandleClear: PropTypes.func,
  term: PropTypes.string,
}

SearchMessage.defaultProps = {
  count: 0,
  onHandleClear: () => {},
  term: '',
}
