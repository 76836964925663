import R from '@mui/material/Radio'
import { styled } from '@mui/material/styles'

const RadioIcon = styled('span')(({ theme }) => ({
  '.Mui-focusVisible &': {
    outline: `2px auto ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
  backgroundColor: theme.palette.white.main,
  borderRadius: '50%',
  boxShadow: `inset 0 0 0 2px ${theme.palette.divider}, inset 0 -2px 0 ${theme.palette.divider}`,
  height: 16,
  'input:hover ~ &': {
    backgroundColor: theme.palette.white.main,
  },
  width: 16,
}))

const CheckedRadioIcon = styled(RadioIcon)(({ theme }) => ({
  '&:before': {
    backgroundImage: `radial-gradient(${theme.palette.white.main}, ${theme.palette.white.main} 28%,transparent 32%)`,
    content: '""',
    display: 'block',
    height: 16,
    width: 16,
  },
  backgroundColor: theme.palette.primary.main,
  boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}, inset 0 -2px 0 ${theme.palette.primary.main}`,
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
}))

// Inspired by blueprintjs
const Radio = (props) => (
  <R
    checkedIcon={<CheckedRadioIcon />}
    color="default"
    icon={<RadioIcon />}
    {...props}
  />
)

export default Radio
