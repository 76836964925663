import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducer'

const SyncContext = createContext(false)

const useSyncContext = () => {
  const context = useContext(SyncContext)

  if (context === undefined) {
    throw new Error(
      'useSyncContext:: useStateContext must be used within a provider'
    )
  }

  return context
}

const initalActions = { delete: [], get: [], post: [], put: [] }

const SyncProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalActions)

  return (
    <SyncContext.Provider value={{ actions: state, syncDispatch: dispatch }}>
      {children}
    </SyncContext.Provider>
  )
}

SyncProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { SyncProvider, useSyncContext }
