import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'

const CustomPopover = (props) => {
  const {
    anchorOrigin,
    anchorEl,
    children,
    onClose,
    open,
    sx,
    transformOrigin,
  } = props

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      id={`popover-${(Math.random() + 1).toString(36).substring(7)}`}
      onClose={onClose}
      open={typeof open === 'boolean' ? open : !!anchorEl}
      sx={sx}
      transformOrigin={transformOrigin}
    >
      {children}
    </Popover>
  )
}

export default CustomPopover

CustomPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sx: PropTypes.shape(),
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
}

CustomPopover.defaultProps = {
  anchorEl: null,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'center',
  },
  onClose: () => {},
  open: null,
  sx: {},
  transformOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  },
}
