import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const ContactToMe = ({ color }) => (
  <SvgIcon sx={{ fontSize: '2.2rem' }} viewBox="0 0 40 28">
    <svg
      fill="none"
      height="28"
      viewBox="0 0 40 28"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1430_51166)">
        <path
          d="M7.75477 26.2385C7.75489 26.2381 7.75501 26.2376 7.75513 26.2372C8.56507 24.1501 7.02695 21.816 4.80116 21.816H4.4669C2.44936 21.816 0.73797 20.0693 0.73797 17.8448V5.38302C0.73797 3.15851 2.44936 1.4118 4.4669 1.4118H26.771C28.8158 1.4118 30.5 3.15516 30.5 5.38302V17.8448C30.5 20.0693 28.7886 21.816 26.771 21.816H14.7073C13.7092 21.816 12.7253 22.3157 12.1309 23.1513L12.126 23.1582L12.1212 23.1652C11.2277 24.484 9.40667 25.5058 7.75477 26.2385ZM7.57004 26.5537L7.57506 26.5483C7.57145 26.5522 7.56843 26.5554 7.57004 26.5537Z"
          fill="white"
          stroke={color}
          strokeWidth="1.47594"
        />
        <rect
          fill="#FDC5AF"
          height="1.58412"
          rx="0.792062"
          width="23.6891"
          x="3.80713"
          y="6.94531"
        />
        <rect
          fill="#FDC5AF"
          height="1.58412"
          rx="0.792062"
          width="23.6891"
          x="3.80713"
          y="10.9775"
        />
        <rect
          fill="#FDC5AF"
          height="1.58412"
          rx="0.792061"
          width="13.5366"
          x="3.80713"
          y="15.0088"
        />
      </g>
      <path
        d="M25.2627 15.7227H37.541C38.896 15.7227 40 16.8965 40 18.3371V22.8795C40 24.3201 38.896 25.494 37.541 25.494H37.357C36.4202 25.494 35.7511 26.5078 36.1191 27.4148C36.1191 27.4326 36.1359 27.4326 36.1359 27.4504C36.2697 27.7705 35.9853 28.1084 35.6842 27.9662C34.7474 27.5571 33.5765 26.9346 32.991 26.0631C32.7401 25.7074 32.3219 25.494 31.9037 25.494H25.2627C23.9078 25.494 22.8037 24.3201 22.8037 22.8795V18.3371C22.8037 16.8965 23.891 15.7227 25.2627 15.7227Z"
        fill="white"
      />
      <path
        d="M33.6036 25.6516C33.9447 26.1594 34.5846 26.5976 35.2802 26.9581C35.2462 25.8202 36.1313 24.756 37.357 24.756H37.541C38.4464 24.756 39.262 23.9558 39.262 22.8795V18.3371C39.262 17.2608 38.4464 16.4606 37.541 16.4606H25.2627C24.3437 16.4606 23.5417 17.2574 23.5417 18.3371V22.8795C23.5417 23.9558 24.3573 24.756 25.2627 24.756H31.9037C32.563 24.756 33.2056 25.087 33.5941 25.6377L33.5989 25.6446L33.6036 25.6516Z"
        fill="#FCF1ED"
        stroke={color}
        strokeWidth="1.47594"
      />
      <ellipse
        cx="31.402"
        cy="20.4757"
        fill="white"
        rx="0.747663"
        ry="0.792061"
        stroke={color}
        strokeWidth="1.47594"
      />
      <ellipse
        cx="35.1402"
        cy="20.4757"
        fill="white"
        rx="0.747662"
        ry="0.792061"
        stroke={color}
        strokeWidth="1.47594"
      />
      <ellipse
        cx="27.6632"
        cy="20.4757"
        fill="white"
        rx="0.747663"
        ry="0.792061"
        stroke={color}
        strokeWidth="1.47594"
      />
      <defs>
        <clipPath id="clip0_1430_51166">
          <rect
            fill="white"
            height="26.5341"
            transform="translate(0 0.673828)"
            width="31.4019"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default ContactToMe

ContactToMe.propTypes = {
  color: PropTypes.string,
}

ContactToMe.defaultProps = {
  color: theme.palette.primary.main,
}
