import { useEffect, useState, useCallback } from 'react'
import { fetchInfo } from 'helpers/fetchUtil'

import { ENV, GET_REPORTS_ROUTE } from 'constants/routes'

export default function useReports(dateFilter) {
  const [report, setReport] = useState(null)
  const [error, setError] = useState(false)

  const fetchReport = useCallback(async () => {
    try {
      const orderApiUrl = `${ENV.REACT_APP_API_URL}${GET_REPORTS_ROUTE}?r=${dateFilter}`
      const { data: result } = await fetchInfo(orderApiUrl)

      setReport(result)
    } catch (err) {
      setError(true)
    }

    return () => {
      setReport(null)
      setError(false)
    }
  }, [dateFilter])

  useEffect(fetchReport, [dateFilter])

  return {
    error,
    report,
  }
}
