import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Switch from 'components/Switch'
import { useAuthState } from 'store/user'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'
import { updateInfo } from 'helpers/fetchUtil'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import SETTINGS from 'components/Settings/constData.json'

const StoreSwitch = ({ onError, onSuccess }) => {
  const { storeConfig } = useAuthState()
  const [activeStore, setActiveStore] = useState(false)
  const theme = useTheme()
  const { device } = useGlobalContext()

  useEffect(() => {
    setActiveStore(storeConfig.is_published && !storeConfig.sharable_catalog)
  }, [storeConfig])

  const handleChange = () => {
    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
      is_published: !activeStore,
      sharable_catalog: activeStore,
    })
      .then(() => {
        if (!activeStore) {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.activar_tienda,
            Device: device.type,
          })
        } else {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.desactivar_tienda,
            Device: device.type,
          })
        }
        onSuccess()
      })
      .catch(() => {
        onError()
      })
    setActiveStore(!activeStore)
  }

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ mb: `${theme.space[5]}px` }}
    >
      <Typography
        color={activeStore ? 'success.main' : 'error.main'}
        component="p"
        data-testid="store-switch-text"
        fontWeight={theme.fontWeight.bold}
        sx={{
          maxWidth: { sm: '65%', xs: '50vw' },
          mt: { sm: `${theme.space[5]}px`, xs: 0 },
        }}
        variant="body1"
      >
        {activeStore ? SETTINGS.ACTIVE_STORE_TEXT : SETTINGS.PAUSE_STORE_TEXT}
      </Typography>
      <Typography
        sx={{
          mr: 0,
          mt: { sm: `${theme.space[5]}px`, xs: 0 },
        }}
        variant="body1"
      >
        {SETTINGS.ACTIVE_STORE}
        <Switch
          checked={activeStore}
          data-testid="store-switch-button"
          onChange={handleChange}
          sx={{
            ml: {
              sm: `${theme.space[4]}px`,
              xs: `${theme.space[2]}px`,
            },
          }}
        />
      </Typography>
    </Stack>
  )
}

StoreSwitch.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default StoreSwitch
