import { useState, useEffect } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Chip from '@mui/material/Chip'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import TextField from 'components/TextField'
import Accordion from 'components/Accordion'
import SubmitButton from 'components/Settings/SubmitButton'
import {
  onlyNumber,
  validateEmail,
  validateSocialNetworkUrl,
  validateWhatsapp,
} from 'utils/form'
import { useAuthState, useConfigDispatch } from 'store/user'
import { updateInfo } from 'helpers/fetchUtil'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'
import { Warning } from 'components/Icons'
import StatusMessages from 'components/StatusMessages'
import UserActions from 'store/user/actions'
import { PENDING } from 'constants/page'
import useStylesChip from 'components/StoreWelcome/styles'
import SETTINGS from 'components/Settings/constData.json'
import useStyles from '../Shipments/styles'

const ContactInfo = () => {
  const [emailError, setEmailError] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [phone, setPhone] = useState('')
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { storeConfig } = useAuthState()
  const { device } = useGlobalContext()
  const dispatch = useConfigDispatch()
  const [socialNetworks, setSocialNetworks] = useState(SETTINGS.SOCIAL_NETWORKS)
  const theme = useTheme()
  const classes = useStyles()
  const chipStyle = useStylesChip()

  const populateSocialLinks = (socialLinks) => {
    const populate = []

    Object.entries(socialLinks).forEach(([key, value]) => {
      populate[key] = value
    })

    setSocialNetworks(
      socialNetworks.map((net) => ({
        ...net,
        value: populate[net.key],
      }))
    )
  }

  useEffect(() => {
    if (storeConfig && storeConfig.contact_information) {
      const ci = storeConfig.contact_information

      if (ci.fullname) setName(ci.fullname)
      if (ci.phone) setPhone(ci.phone)
      if (ci.whatsapp) setWhatsapp(ci.whatsapp)
      if (ci.email) setEmail(ci.email)

      if (ci.social_links) populateSocialLinks(ci.social_links)
    }
  }, [storeConfig, expandedPanel])

  const cleanValues = () => {
    setEmail('')
    setName('')
    setWhatsapp('')
    setPhone('')
  }

  const handleCancel = () => {
    setExpandedPanel((prev) => !prev)
    cleanValues()
    setSubmitError(false)

    if (expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.cancel,
        Device: device.type,
      })
    }
  }

  const emailValidation = (e) => {
    if (validateEmail(e.target.value) && e.target.value !== '') {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }

  const whatsappValidation = (e) => {
    if (validateWhatsapp(e.key)) {
      e.preventDefault()
    }
  }

  const phoneValidation = (e) => {
    if (onlyNumber(e.key)) {
      e.preventDefault()
    }
  }

  const handleChange = (event) => {
    const { name: fieldName, value } = event.target

    setSocialNetworks(
      socialNetworks.map((net) => {
        if (net.key === fieldName) {
          return {
            ...net,
            error: value !== '' && !validateSocialNetworkUrl(value),
            value,
          }
        }

        return net
      })
    )
  }

  const errors = () => {
    if (emailError) return true

    return socialNetworks.filter((net) => net.error === true).length > 0
  }

  const handleSubmit = () => {
    if (errors()) return
    setLoading(true)

    const payload = {
      email,
      fullname: name,
      phone,
      social_links: socialNetworks
        .filter((n) => n.value !== '')
        .reduce(
          (obj, net) => ({
            ...obj,
            [net.key]: net.value,
          }),
          {}
        ),
      whatsapp,
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
      contact_information: payload,
    })
      .then(() => {
        setSubmitSuccess(true)
        dispatch({
          payload: {
            ...storeConfig,
            contact_information: payload,
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
        setSubmitError(true)
      })
      .finally(() => setLoading(false))
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.datos_contacto,
        Device: device.type,
      })
    }
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={handleCancel}
      >
        <Typography align="center" variant="h2">
          {SETTINGS.CONTACT_DETAILS}
        </Typography>
        {!storeConfig?.contact_information && (
          <Chip
            className={clsx(chipStyle.chip, chipStyle.pending)}
            icon={<Warning color={theme.palette.warning.main} />}
            label={PENDING}
            sx={{
              mt: { sm: `${theme.space[1]}px`, xs: `${theme.space[2]}px` },
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontWeight: theme.fontWeight.regular,
            maxWidth: 483,
            width: '100%',
          }}
          variant="body1"
        >
          {SETTINGS.CONTACT_DESCRIPTION}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: `${theme.space[2]}px`,
          }}
        >
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="off"
              data-testid="input-name"
              fullWidth
              label={SETTINGS.NAME}
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
              variant="filled"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="off"
              error={emailError}
              fullWidth
              inputProps={{ 'data-testid': 'input-email' }}
              label={SETTINGS.EMAIL}
              onBlur={emailValidation}
              onChange={(e) => setEmail(e.target.value)}
              value={email || ''}
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: {
              sm: `${theme.space[2]}px`,
              xs: `${theme.space[0]}px`,
            },
          }}
        >
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="off"
              fullWidth
              id="outlined-basic-whatsapp"
              inputProps={{ 'data-testid': 'input-whatsapp' }}
              label={SETTINGS.WHATSAPP}
              onChange={(e) => setWhatsapp(e.target.value)}
              onKeyPress={whatsappValidation}
              value={whatsapp || ''}
              variant="filled"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="off"
              fullWidth
              id="outlined-basic-phone"
              inputProps={{ 'data-testid': 'input-phone' }}
              label={SETTINGS.PHONE}
              onChange={(e) => setPhone(e.target.value)}
              onKeyPress={phoneValidation}
              value={phone || ''}
              variant="filled"
            />
          </Grid>
        </Grid>
        <Typography
          fontWeight={theme.fontWeight.bold}
          sx={{ margin: '46px 0 8px' }}
          variant="h4"
        >
          {SETTINGS.SOCIAL_TITLE}
        </Typography>
        <Typography
          fontWeight={theme.fontWeight.regular}
          sx={{ display: 'block', width: { sm: '75%', xs: '100%' } }}
          variant="body1"
        >
          {SETTINGS.SOCIAL_DESCRIPTION}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: {
              sm: `${theme.space[2]}px`,
              xs: `${theme.space[0]}px`,
            },
          }}
        >
          {socialNetworks.map((net) => (
            <Grid key={net.key} item sm={6} xs={12}>
              <TextField
                autoComplete="off"
                error={net.error}
                fullWidth
                helperText={net.error || SETTINGS.ERROR_URL}
                label={SETTINGS.NETWORKS[net.key]}
                name={net.key}
                onChange={handleChange}
                value={net.value || ''}
                variant="filled"
              />
            </Grid>
          ))}
        </Grid>
        <SubmitButton
          buttonCancelText={SETTINGS.CANCEL_BUTTON}
          buttonSubmitText={SETTINGS.SAVE_BUTTON}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          loading={loading}
        />
        <StatusMessages
          errorFlag={submitError}
          errorMessage={SETTINGS.ERROR_MESSAGE}
          handleClose={handleClose}
          successFlag={submitSuccess}
          successMessage={SETTINGS.SUCCESS_MESSAGE}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default ContactInfo
