import { useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import Paper from 'components/Paper'
import TextField from 'components/TextField'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ErrorIcon, Trash } from 'components/Icons'
import { useGlobalContext } from 'store/global'
import Button from 'components/Button'
import { useProductContext } from 'components/Catalog-v2/store'
import PRODUCT_ACTIONS from 'components/Catalog-v2/store/actions'
import WrapIcon from 'components/WrapIcon'
import Box from '@mui/material/Box'
import UploadPhoto from '../uploadPhoto'
import VariantSection from './variants'
import CategoriesAssign from './categories'
import useStyles from './styles'

const { product: PRODUCT } = require('./constData.json')

const ProductEdit = ({ error, onDelete }) => {
  const [focusName, setFocusName] = useState(false)
  const [focusDesc, setFocusDesc] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { productData, productDispatch } = useProductContext()
  const classes = useStyles()

  const handleChange = (event) => {
    productDispatch({
      productData: {
        [event.target.name]: event.target.value,
      },
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })
  }

  const hasError = (field) => {
    if (!error) return false

    switch (field) {
      case 'product_name':
        return !productData.product_name
      case 'price':
        return !productData.price
      default:
        return false
    }
  }

  const renderDescription = () => (
    <>
      <TextField
        fullWidth
        InputLabelProps={{ shrink: focusDesc || !!productData?.description }}
        inputProps={{
          style: {
            color: theme.palette.black.main,
            fontWeight: theme.fontWeight.semibold,
            height: '57px',
          },
        }}
        label={PRODUCT.DESCRIPTION}
        multiline
        name="description"
        onBlur={() => setFocusDesc(false)}
        onChange={handleChange}
        onClick={() => setFocusDesc(true)}
        value={productData?.description || ''}
        variant="filled"
      />
      <Typography
        align="right"
        color="text.primary"
        component="p"
        fontWeight={theme.fontWeight.regular}
        variant="body1"
      >
        {PRODUCT.OPTIONAL}
      </Typography>
    </>
  )

  return (
    <Paper sx={{ mt: '24px', p: { sm: '24px', xs: '16px' } }}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography color="black" sx={{ display: 'flex' }} variant="h2">
          {productData?.product_id ? PRODUCT.EDIT : PRODUCT.NEW}
        </Typography>

        <Button
          className={classes.btnDelete}
          color="white"
          disabled={!productData?.product_id}
          onClick={onDelete}
          size="large"
          startIcon={
            <Trash
              color={
                productData?.product_id
                  ? theme.palette.text.primary
                  : theme.palette.divider
              }
            />
          }
          type="link"
        >
          {!device.isMobile && PRODUCT.DELETE}
        </Button>
      </Stack>
      {errorMessage !== '' && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.error.light,
            borderRadius: `${theme.borderRadius[0]}px`,
            display: 'flex',
            marginTop: `${theme.space[5]}px`,
            padding: '19px 15px',
          }}
        >
          <WrapIcon sx={{ padding: '0 10px 0 0' }}>
            <ErrorIcon />
          </WrapIcon>
          <Typography color="error.main" variant="body1">
            {errorMessage}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2} sx={{ mt: `${theme.space[2]}px` }}>
        <Grid item xs={3}>
          <UploadPhoto
            error={!!errorMessage}
            handleError={(msg) => setErrorMessage(msg)}
          />
        </Grid>
        <Grid
          item
          sx={{
            '& .Mui-error ': {
              fontWeight: theme.fontWeight.semibold,
            },
          }}
          xs={9}
        >
          <TextField
            error={hasError('product_name')}
            fullWidth
            helperText={hasError('product_name') && PRODUCT.ERROR_NAME}
            InputLabelProps={{
              shrink: focusName || !!productData?.product_name,
            }}
            InputProps={{
              endAdornment: hasError('product_name') && <ErrorIcon />,
            }}
            label={PRODUCT.NAME}
            name="product_name"
            onBlur={() => setFocusName(false)}
            onChange={handleChange}
            onClick={() => setFocusName(true)}
            sx={{ mb: `${theme.space[4]}px` }}
            value={productData?.product_name || ''}
            variant="filled"
          />

          <NumberFormat
            {...PRODUCT.PRICE_OPTIONS}
            className={classes.numberFormat}
            name="price"
            onChange={handleChange}
            onFocus={(elem) => elem.target.select()}
            placeholder={PRODUCT.PRICE}
            type="text"
            value={productData.price}
          />

          {!device.isMobile && renderDescription()}
          {!device.isMobile && (
            <>
              <CategoriesAssign />
              <VariantSection />
            </>
          )}
        </Grid>
        {device.isMobile && (
          <Grid item xs={12}>
            {renderDescription()}
            <CategoriesAssign />
            <VariantSection />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default ProductEdit

ProductEdit.propTypes = {
  error: PropTypes.bool,
  onDelete: PropTypes.func,
}

ProductEdit.defaultProps = {
  error: false,
  onDelete: () => {},
}
