import CHIP from './constData.json'

const filters = {
  reports: [
    {
      id: 1,
      label: CHIP.TODAY_FILTER,
    },
    {
      id: 2,
      label: CHIP.WEEK_FILTER,
    },
    {
      id: 3,
      label: CHIP.MONTH_FILTER,
    },
    {
      id: 4,
      label: CHIP.YEAR_FILTER,
    },
    {
      id: 5,
      label: CHIP.FROM_START_FILTER,
    },
    {
      id: 6,
      label: CHIP.CUSTOM_FILTER,
    },
  ],
  sales: [
    {
      id: 1,
      label: CHIP.TODAY_FILTER,
    },
    {
      id: 2,
      label: CHIP.WEEK_FILTER,
    },
    {
      id: 3,
      label: CHIP.MONTH_FILTER,
    },
    {
      id: 4,
      label: CHIP.FROM_START_FILTER,
    },
  ],
}

export default filters
