import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import theme from 'theme'

const FieldSeparator = ({ character, color }) => (
  <Typography
    color={color}
    component="span"
    fontWeight={() => theme.fontWeight.bold}
    variant="h4"
  >
    {character}
  </Typography>
)

export default FieldSeparator

FieldSeparator.propTypes = {
  character: PropTypes.string.isRequired,
  color: PropTypes.string,
}

FieldSeparator.defaultProps = {
  color: theme.palette.text.primary,
}
