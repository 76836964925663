import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducer'

const ProductContext = createContext(false)

const useProductContext = () => {
  const context = useContext(ProductContext)

  if (context === undefined) {
    throw new Error(
      'useProductContext:: useStateContext must be used within a provider'
    )
  }

  return context
}

const ProductProvider = ({ children, defaultState }) => {
  const [productState, dispatch] = useReducer(reducer, defaultState || {})

  return (
    <ProductContext.Provider
      value={{
        productData: productState,
        productDispatch: dispatch,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

ProductProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { ProductProvider, useProductContext }
