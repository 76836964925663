import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Input from '@mui/material/Input'
import { DownloadSave } from 'components/Icons'
import WrapIcon from 'components/WrapIcon'
import useStyles from './styles'

const { select: DATA_SELECT } = require('./constData.json')

const BackgroundSelector = ({ setBackground }) => {
  const theme = useTheme()
  const classes = useStyles()

  const [uploadedFile, setUploadedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (uploadedFile) {
      const objectUrl = URL.createObjectURL(uploadedFile)

      setPreview(objectUrl)
    }
  }, [uploadedFile])

  const fileInput = useRef(null)

  const changeHandler = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setUploadedFile(undefined)

      return
    }

    setUploadedFile(e.target.files[0])
  }

  const handleImage = (e, sel) => {
    const imgUrl = e.target.attributes.src.nodeValue

    setBackground(imgUrl)
    setSelected(sel)
  }

  return (
    <Stack>
      <Typography className={classes.marginBottom5} color="black" variant="h2">
        {DATA_SELECT.BACK_IMAGE}
      </Typography>
      <Stack direction="row">
        <Input
          className={classes.input}
          inputRef={fileInput}
          name="file"
          onChange={changeHandler}
          type="file"
        />
        <Avatar
          className={clsx({
            [classes.default]: true,
            [classes.selected]: selected === DATA_SELECT.CLASSES.DEFAULT,
          })}
          onClick={(e) => handleImage(e, DATA_SELECT.CLASSES.DEFAULT)}
          src="/images/post-back.svg"
          variant="rounded"
        />
        {uploadedFile && (
          <Avatar
            className={clsx({
              [classes.default]: true,
              [classes.selected]: selected === DATA_SELECT.CLASSES.CUSTOM,
            })}
            onClick={(e) => handleImage(e, DATA_SELECT.CLASSES.CUSTOM)}
            src={preview}
            variant="rounded"
          />
        )}
        <WrapIcon
          className={classes.dashed}
          onClick={() => {
            fileInput.current.click()
          }}
        >
          <DownloadSave color={theme.palette.primary.main} rotate={180} />
        </WrapIcon>
      </Stack>
    </Stack>
  )
}

export default BackgroundSelector

BackgroundSelector.propTypes = {
  setBackground: PropTypes.func,
}

BackgroundSelector.defaultProps = {
  setBackground: () => {},
}
