import { useState } from 'react'
import axios from 'axios'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import LoadingButton from 'components/LoadingButton'
import Link from '@mui/material/Link'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material'
import { Eyes, ErrorIcon } from 'components/Icons'

import {
  INIT_SESSION,
  INIT_SESSION_DESC,
  EMAIL,
  PASSWORD,
  FORGOT_PASS,
  DO_YOU_HAVE_ACC,
  REGISTER,
  ERROR_LOGIN,
} from 'constants/login'

import { ENV, POST_LOGIN, MD_FORGOT_PASS, MD_REGISTER } from 'constants/routes'

import { getRequestOptions } from 'helpers/fetchUtil'
import { saveToken, getToken } from 'helpers/tokenHelpers'

const Login = () => {
  const theme = useTheme()
  const authToken = getToken()

  const [password, setPassword] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [errorLogin, setErrorLogin] = useState(false)
  const [loading, setLoading] = useState(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const handleChangeEmail = (value) => {
    setEmail(value)
    setErrorEmail(false)
    setErrorLogin(false)
  }

  const handleChangePassword = (value) => {
    setPassword(value)
    setErrorPass(false)
    setErrorLogin(false)
  }

  const loginUser = () => {
    const options = getRequestOptions()
    const url = new URL(POST_LOGIN, ENV.REACT_APP_API_URL_2)

    const body = {
      email,
      password,
      source: 'M_DASHBOARD',
      type: 'LOGIN',
    }

    return axios
      .post(url, body, options)
      .then((res) => {
        if (res) {
          setLoading(false)
          saveToken(res.data.access_token)
          window.location.href = '/'
        }
      })
      .catch((error) => {
        if (error) {
          setErrorLogin(true)
          setErrorPass(true)
          setErrorEmail(true)
          setLoading(false)
        }
      })
  }

  const handleLogin = () => {
    if (email !== '' && password !== '') {
      setLoading(true)
      setErrorLogin(false)
      setErrorPass(false)
      setErrorEmail(false)
      loginUser()
    } else {
      setErrorEmail(email === '')
      setErrorPass(password === '')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  if (authToken) {
    window.location.href = '/'
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '50px',
      }}
    >
      <Avatar
        alt="Logo clip"
        src="/images/logo-minegocioclip.svg"
        sx={{
          height: { md: '60px', xs: '40px' },
          width: { md: '300px', xs: '200px' },
        }}
        variant="square"
      />
      <Stack
        spacing={4}
        sx={{
          border: `1px solid ${theme.backgrounds[1]}`,
          borderRadius: '8px',
          boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
          marginTop: '50px',
          padding: '24px',
          width: { md: 'auto', xs: '90%' },
        }}
      >
        <Typography
          color={theme.palette.black}
          fontWeight={theme.fontWeight.bold}
          textAlign="center"
          variant="h1"
        >
          {INIT_SESSION}
        </Typography>
        <Typography
          color={theme.palette.black}
          fontWeight={theme.fontWeight.regular}
          textAlign="center"
          variant="body1"
        >
          {INIT_SESSION_DESC}
        </Typography>
        <Stack spacing={4}>
          <TextField
            error={errorEmail}
            id="email"
            InputProps={{
              endAdornment: errorEmail && <ErrorIcon />,
            }}
            label={EMAIL}
            name="email"
            onChange={(e) => handleChangeEmail(e.target.value)}
            required
            sx={{
              '& .MuiInputLabel-root': {
                fontWeight: theme.fontWeight.semibold,
              },
              '.MuiOutlinedInput-root': {
                fontWeight: theme.fontWeight.semibold,
              },
            }}
            type="text"
            value={email}
          />
          <TextField
            error={errorPass}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  onClick={() => togglePassword()}
                  position="end"
                  sx={{ cursor: 'pointer' }}
                >
                  <Eyes
                    color={
                      errorPass
                        ? theme.palette.error.main
                        : theme.palette.text.main
                    }
                  />
                </InputAdornment>
              ),
            }}
            label={PASSWORD}
            name="password"
            onChange={(e) => handleChangePassword(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
            sx={{
              '& .MuiInputLabel-root': {
                fontWeight: theme.fontWeight.semibold,
              },
              '.MuiOutlinedInput-root': {
                color: theme.palette.black.main,
                fontWeight: theme.fontWeight.semibold,
              },
            }}
            type={passwordShown ? 'text' : 'password'}
            value={password}
            variant="outlined"
          />
          {errorLogin && (
            <Typography
              color={theme.palette.error.main}
              fontWeight={theme.fontWeight.bold}
              sx={{ marginTop: { xs: 0 } }}
              textAlign="center"
              variant="body1"
            >
              {ERROR_LOGIN}
            </Typography>
          )}
          <LoadingButton
            color="orange"
            loading={loading}
            onClick={handleLogin}
            sx={{ fontWeight: theme.fontWeight.bold, height: '48px' }}
            variant="primary-form"
          >
            {INIT_SESSION}
          </LoadingButton>
          <Link
            href={`${ENV.REACT_APP_ME_DASHBOARD_URL}${MD_FORGOT_PASS}`}
            sx={{ textAlign: 'center' }}
          >
            <Typography
              color="primary"
              fontWeight={theme.fontWeight.bold}
              variant="body1"
            >
              {FORGOT_PASS}
            </Typography>
          </Link>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ marginTop: '24px' }}>
        <Typography
          color={theme.palette.black}
          fontWeight={theme.fontWeight.regular}
          variant="body1"
        >
          {DO_YOU_HAVE_ACC}&nbsp;
        </Typography>
        <Link href={`${ENV.REACT_APP_ME_DASHBOARD_URL}${MD_REGISTER}`}>
          <Typography
            color="primary"
            fontWeight={theme.fontWeight.bold}
            variant="body1"
          >
            {REGISTER}
          </Typography>
        </Link>
      </Stack>
    </Box>
  )
}

export default Login
