import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Paper from 'components/Paper'
import fetchData from 'hooks/fetchData'
import { postInfo, updateInfo } from 'helpers/fetchUtil'
import StoreScoreCards from 'components/StoreScoreCards'
import { SalesChart } from 'components/ChartsReports'
import BannerSlider from 'components/Banner'
import { Chat } from 'components/Icons'
import StoreWelcome from 'components/StoreWelcome'
import Loader from 'components/Loader'
import InfoScreen from 'components/InfoScreen'
import EmptyReport from 'components/ChartsReports/EmptyReport'
import { PublishModal, TributaryDataModal } from 'components/Modal'
import { useGlobalContext } from 'store/global'
import AppBarCopyShare from 'components/AppBarCopyShare'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import dashboardSettings from 'constants/dashboardSettings'
import {
  ENV,
  GET_CONFIG_ROUTE,
  GET_REPORTS_ROUTE,
  POST_TRIBUTARY_INFO_ROUTE,
} from 'constants/routes'
import {
  TEXT_STATISTICS_STORE,
  INFO_SCREEN_DESCRIPTION,
  INFO_SCREEN_TITLE,
  INFO_SCREEN_TITLE_MSG,
  TEXT_LINK_TO_STORE,
} from 'constants/page'
import { isCompleteValidation } from 'utils/validation'
import UserActions from 'store/user/actions'
import { REPORTS_YEAR } from 'constants/reportingParams'
import { useGetOrders } from 'hooks/useOrders'
import { useAuthState, useConfigDispatch } from 'store/user'
import { getLastWeekDateInterval } from 'utils/dateTools'
import EMPTY_REPORT from 'constants/reportsPage'
import { STATUS_MAPPING } from 'constants/salesPage'

const Store = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { merchantInfo, merchantToken, storeConfig } = useAuthState()
  const dispatch = useConfigDispatch()
  const { publicProfile } = merchantInfo
  const [openModal, setOpenModal] = useState(false)
  const [openTributaryModal, setOpenTributaryModal] = useState(false)
  const [isPublished, setIsPublished] = useState(null)
  const merchantUrl = publicProfile
    ? `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`
    : ''
  const { data: reportSummary } = fetchData(
    `${ENV.REACT_APP_API_URL}${GET_REPORTS_ROUTE}?r=${REPORTS_YEAR}`
  )

  const dateInterval = getLastWeekDateInterval()
  const { orderList } = useGetOrders(
    dateInterval.dateFrom,
    dateInterval.dateTo,
    STATUS_MAPPING[0]
  )

  useEffect(() => {
    cleverTap.event(EVENTS.HOMEMITIENDA_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  const onHandleTributaryClick = () => setOpenTributaryModal(true)

  const handleTributaryComplete = async (data) => {
    const result = await postInfo(
      `${ENV.REACT_APP_API_URL}${POST_TRIBUTARY_INFO_ROUTE}`,
      data
    )

    if (result?.data.success) {
      dispatch({
        payload: {
          ...storeConfig,
          tributary: true,
        },
        type: UserActions.UPDATE_STORE_CONFIG,
      })
    }

    setOpenTributaryModal(false)
  }

  const handleToggleModal = () => setOpenModal(!openModal)

  const handlePublish = () => {
    if (isCompleteValidation(storeConfig)) {
      updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
        is_published: true,
        sharable_catalog: false,
      }).then(() => {
        dispatch({
          payload: {
            ...storeConfig,
            is_published: true,
            sharable_catalog: false,
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        setIsPublished(true)
        handleToggleModal()
      })
    } else {
      handleToggleModal()
    }
  }

  if (!storeConfig || !reportSummary) {
    return (
      <Layout>
        <ContainerCard>
          <Paper
            sx={{
              height: '100vh',
              pl: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
              pr: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
            }}
          >
            <Loader />
          </Paper>
        </ContainerCard>
      </Layout>
    )
  }

  if (
    (!storeConfig.is_published || !storeConfig.tributary) &&
    (!orderList || orderList.length === 0)
  ) {
    return (
      <Layout>
        <ContainerCard>
          <Paper
            sx={{
              pl: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
              pr: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
            }}
          >
            <StoreWelcome
              onHandlePublish={handlePublish}
              onHandleTributaryClick={onHandleTributaryClick}
              storeConfig={storeConfig}
            />
          </Paper>

          <InfoScreen
            description={INFO_SCREEN_DESCRIPTION}
            icon={Chat}
            title={INFO_SCREEN_TITLE}
            titleMsg={INFO_SCREEN_TITLE_MSG}
          />
          <TributaryDataModal
            handleContinue={handleTributaryComplete}
            onClose={() => setOpenTributaryModal(false)}
            open={openTributaryModal}
          />
          <PublishModal
            merchantData={{
              merchantName: publicProfile?.public_name || '',
              merchantSlug: publicProfile?.alias || '',
              merchantToken,
              merchantUrl,
            }}
            onClose={handleToggleModal}
            open={openModal}
            publishedSuccess={isPublished}
          />
        </ContainerCard>
      </Layout>
    )
  }

  return (
    <Layout>
      <ContainerCard>
        <AppBarCopyShare
          label={TEXT_LINK_TO_STORE}
          merchantName={publicProfile?.public_name || ''}
          shareEnabled
          storeLink={merchantUrl}
        />
        <Box sx={{ marginBottom: `${theme.space[5]}px` }}>
          {dashboardSettings.SHOW_TUTORIAL_BANNER && (
            <Box sx={{ marginBottom: `${theme.space[5]}px` }}>
              <BannerSlider clevertapEventName={EVENTS.HOMEMITIENDA_VIEWED} />
            </Box>
          )}
          <Typography
            color="black"
            sx={{ marginBottom: `${theme.space[5]}px` }}
            variant="h4"
          >
            {TEXT_STATISTICS_STORE}
          </Typography>
          <StoreScoreCards
            data={reportSummary.error ? EMPTY_REPORT : reportSummary}
          />
          {orderList && orderList.length ? (
            <SalesChart
              dateFrom={dateInterval.dateFrom}
              dateTo={dateInterval.dateTo}
              sales={orderList}
            />
          ) : (
            <EmptyReport />
          )}
        </Box>
        {/* => CONGRATS MODAL */}
        <PublishModal
          merchantData={{
            merchantName: publicProfile?.public_name || '',
            merchantSlug: publicProfile?.alias || '',
            merchantUrl,
          }}
          onClose={handleToggleModal}
          open={openModal}
          publishedSuccess={isPublished}
        />
      </ContainerCard>
    </Layout>
  )
}

export default Store
