import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const EmptyRow = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 120 120">
    <svg
      fill="none"
      height="120"
      viewBox="0 0 120 120"
      width="120"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.9208 62.1325C95.9208 83.0438 78.923 100 57.9604 100C36.9557 100 20 83.0438 20 62.1325C20 41.1791 36.9557 24.265 57.9604 24.265"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.47966"
      />
      <mask
        height="27"
        id="mask0_965_1845"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="28"
        x="73"
        y="19"
      >
        <path
          d="M84.6592 21.6981V21.656C85.4605 20.0992 87.4007 19.5522 88.9613 20.3938C89.4675 20.6462 89.9314 21.109 90.1845 21.6139L92.6308 26.0949C92.8839 26.5998 93.3479 27.0626 93.8962 27.3572L98.3671 29.7975C99.8855 30.597 100.434 32.5324 99.6324 34.0892C99.3372 34.5941 98.8732 35.0569 98.3249 35.3514L93.8118 37.7918H93.7697C93.2213 38.0442 92.7574 38.5071 92.5043 39.054L90.0158 43.493H89.9736C89.1301 45.0077 87.1898 45.5546 85.6293 44.7131C85.0809 44.4186 84.617 43.9558 84.3639 43.4509L81.8754 38.9488L81.8332 38.9068C81.538 38.3598 81.074 37.897 80.5679 37.6445L76.0548 35.1621L76.0126 35.12C74.452 34.2785 73.9037 32.3431 74.7051 30.7863C74.9582 30.2393 75.4221 29.7765 75.9704 29.482L80.4413 26.9995V26.9575C80.9475 26.6629 81.4114 26.2001 81.6645 25.6531L84.6592 21.6981Z"
          fill="white"
          stroke="#D1D1D7"
          strokeWidth="1.05386"
        />
      </mask>
      <g mask="url(#mask0_965_1845)">
        <path
          d="M87.19 24.2226V24.1805C87.9914 22.6237 89.9316 22.0768 91.4922 22.9183C91.9983 23.1707 92.4623 23.6335 92.7153 24.1384L95.1617 28.6194C95.4147 29.1243 95.8787 29.5871 96.427 29.8817L100.898 32.322C102.416 33.1215 102.965 35.0569 102.163 36.6137C101.868 37.1186 101.404 37.5814 100.856 37.8759L96.3427 40.3163H96.3005C95.7522 40.5687 95.2882 41.0316 95.0351 41.5785L92.5466 46.0174H92.5044C91.6609 47.5322 89.7207 48.0791 88.1601 47.2376C87.6118 46.9431 87.1478 46.4803 86.8947 45.9754L84.4062 41.4733L84.364 41.4313C84.0688 40.8843 83.6048 40.4215 83.0987 40.169L78.5856 37.6866L78.5434 37.6445C76.9828 36.803 76.4345 34.8676 77.2359 33.3108C77.489 32.7638 77.9529 32.301 78.5013 32.0065L82.9722 29.524V29.482C83.4783 29.1874 83.9423 28.7246 84.1953 28.1776L87.19 24.2226Z"
          fill="#F6F6F7"
        />
      </g>
      <path
        d="M84.6592 21.6981V21.656C85.4605 20.0992 87.4007 19.5522 88.9613 20.3938C89.4675 20.6462 89.9314 21.109 90.1845 21.6139L92.6308 26.0949C92.8839 26.5998 93.3479 27.0626 93.8962 27.3572L98.3671 29.7975C99.8855 30.597 100.434 32.5324 99.6324 34.0892C99.3372 34.5941 98.8732 35.0569 98.3249 35.3514L93.8118 37.7918H93.7697C93.2213 38.0442 92.7574 38.5071 92.5043 39.054L90.0158 43.493H89.9736C89.1301 45.0077 87.1899 45.5546 85.6293 44.7131C85.0809 44.4186 84.617 43.9558 84.3639 43.4509L81.8754 38.9488L81.8332 38.9068C81.538 38.3598 81.074 37.897 80.5679 37.6445L76.0548 35.1621L76.0126 35.12C74.452 34.2785 73.9037 32.3431 74.7051 30.7863C74.9582 30.2393 75.4221 29.7765 75.9704 29.482L80.4413 26.9995V26.9575C80.9475 26.6629 81.4114 26.2001 81.6645 25.6531L84.6592 21.6981Z"
        stroke={color}
        strokeWidth="2.47966"
      />
      <mask
        height="40"
        id="mask1_965_1845"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="36"
        x="40"
        y="42"
      >
        <path
          d="M56.4462 80.1026L43.198 72.9042C41.8483 72.1469 41.0469 70.7584 41.0469 69.2437V54.854H41.0427C41.0427 53.3393 41.8441 51.9088 43.1516 51.1935L55.8051 43.9566V43.9145C57.0705 43.1572 58.6732 43.1572 59.9808 43.9145L72.6342 51.1094L72.5921 51.1051C73.8996 51.8204 74.701 53.2089 74.701 54.7236V69.0712V69.0291C74.701 70.5017 73.8574 71.9323 72.5499 72.6476L60.4911 79.8039C59.1835 80.5191 57.7537 80.8179 56.4462 80.1026Z"
          fill="white"
          stroke="#D1D1D7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.05386"
        />
      </mask>
      <g mask="url(#mask1_965_1845)">
        <path
          d="M58.9769 82.6271L45.7288 75.4287C44.3791 74.6714 43.5777 73.2829 43.5777 71.7682V57.3786H43.5735C43.5735 55.8639 44.3749 54.4333 45.6824 53.718L58.3359 46.4811V46.439C59.6012 45.6817 61.204 45.6817 62.5115 46.439L75.165 53.6339L75.1228 53.6297C76.4303 54.3449 77.2317 55.7334 77.2317 57.2481V71.5957V71.5536C77.2317 73.0263 76.3882 74.4568 75.0806 75.1721L63.0218 82.3284C61.7143 83.0437 60.2845 83.3424 58.9769 82.6271Z"
          fill="#F6F6F7"
        />
      </g>
      <path
        d="M56.4462 80.1026L43.198 72.9042C41.8483 72.1469 41.0469 70.7584 41.0469 69.2437V54.854H41.0427C41.0427 53.3393 41.8441 51.9088 43.1516 51.1935L55.8051 43.9566V43.9145C57.0705 43.1572 58.6732 43.1572 59.9808 43.9145L72.6342 51.1094L72.5921 51.1051C73.8996 51.8204 74.701 53.2089 74.701 54.7236V69.0712V69.0291C74.701 70.5017 73.8574 71.9323 72.5499 72.6476L60.4911 79.8039C59.1835 80.5191 57.7537 80.8179 56.4462 80.1026Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.47966"
      />
      <path
        d="M41.6796 52.8342L57.9604 62.1328"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.47966"
      />
      <path
        d="M74.2412 52.8342L57.9604 62.1328"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.47966"
      />
      <path
        d="M57.9604 62.1325V80.4753"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.47966"
      />
    </svg>
  </SvgIcon>
)

export default EmptyRow

EmptyRow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

EmptyRow.defaultProps = {
  color: theme.backgrounds[2],
  size: 5,
}
