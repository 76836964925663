const MuiTablePagination = () => ({
  styleOverrides: {
    '& .MuiToolbar-root': {
      margin: 0,
      padding: 0,
    },
  },
  variants: [
    {
      props: { nolabel: true },
      style: {
        '& .MuiTablePagination-displayedRows': {
          display: 'none',
        },
        '& .MuiToolbar-root': {
          '& .MuiTablePagination-actions': {
            margin: 0,
          },
          padding: 0,
        },
      },
    },
  ],
})

export default MuiTablePagination
