import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    '& .MuiButton-root .MuiButton-startIcon': {
      [theme.breakpoints.down('lg')]: {
        marginRight: theme.space[1],
      },
    },
    alignItems: 'center',
    backgroundColor: theme.backgrounds[1],
    boxShadow: 'none',
    display: 'flex',
    flexGrow: 0,
    height: 68,
    justifyContent: 'center',
    position: 'initial',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.white.main,
      height: 56,
    },
  },
  last: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  profileLink: {
    color: theme.palette.info.dark,
    display: 'inline-flex',
    fontWeight: theme.fontWeight.semibold,
    letterSpacing: 0,
    margin: 0,
    textDecoration: 'none',
  },
  profileText: {
    flexDirection: 'row',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))

export default useStyles
