import { Accordion as AccordionFAQ } from '@mui/material'
import { styled } from '@mui/material/styles'

const Accordion = styled(AccordionFAQ)(({ theme }) => ({
  '& .MuiAccordionDetails-root': {
    padding: '16px 0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
  },
  '& .MuiAccordionSummary-root': {
    padding: 0,
  },
  '&.Mui-expanded': {
    margin: `${theme.space[0]}`,
  },
  '&::before, &:: after': {
    backgroundColor: 'transparent',
  },
  '&:first-of-type': {
    borderRadius: 0,
  },
  '&:last-of-type': {
    borderRadius: 0,
  },
  borderTop: `1px solid ${theme.backgrounds[1]}`,
  boxShadow: 'initial',
}))

export default Accordion
