import { useEffect, useState } from 'react'
import { fetchInfo } from 'helpers/fetchUtil'

import {
  ENV,
  GET_MERCHANT_INFO_ROUTE,
  PUBLIC_PROFILE_GET,
} from 'constants/routes'

export default function useMerchantInfo(authToken) {
  const [merchantInfo, setMerchantInfo] = useState(null)
  const [error, setError] = useState(false)

  const basicInfoUrl = `${ENV.REACT_APP_API_URL_2}${GET_MERCHANT_INFO_ROUTE}`
  const publicProfileUrl = `${ENV.REACT_APP_API_URL}${PUBLIC_PROFILE_GET}?public_profile_request_type=MERCHANT_PUBLIC_PROFILE_GET_BY_MERCHANT_ID`

  useEffect(async () => {
    let merchantData

    if (authToken) {
      try {
        const { data: baseData } = await fetchInfo(basicInfoUrl)

        merchantData = {
          ...baseData.merchant,
          address: baseData.address,
          publicProfile: null,
        }
      } catch (err) {
        console.log('error querying merchant information', err)
      }

      try {
        const { data: publicProfile } = await fetchInfo(publicProfileUrl)

        merchantData.publicProfile =
          publicProfile.items && publicProfile.items.length > 0
            ? publicProfile.items[0]
            : null
      } catch (err) {
        // merchantData.publicProfile = null
        console.log('error querying merchant public profile', err)
      }

      setMerchantInfo(merchantData)
    }

    return () => {
      setMerchantInfo(null)
      setError(false)
    }
  }, [authToken])

  return {
    error,
    merchantInfo,
  }
}
