import _ from 'lodash'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import TextField from 'components/TextField'
import { ErrorIcon } from 'components/Icons'
import InputChip from 'components/InputChip'
import SubmitButton from 'components/Settings/SubmitButton'
import { fetchInfo } from 'helpers/fetchUtil'
import generateKey from 'utils/math'
import { parsePrice } from 'utils/form'
import { ENV, GET_ZIP_CODES } from 'constants/routes'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import SETTINGS from 'components/Settings/constData.json'
import ModalCustom from '../ModalCustom'
import useStyles from './styles'

const ShipmentModal = ({
  handleModal,
  showModal,
  codesSelected,
  setCodesSelected,
  editObject,
}) => {
  const [mandatoryError, setMandatoryError] = useState(false)
  const [chipList, setChipList] = useState([])
  const [price, setPrice] = useState(0)
  const [zipCodeList, setZipCodeList] = useState([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const [codeSearch, setCodeSearch] = useState('')
  const { device } = useGlobalContext()

  useEffect(() => {
    if (editObject) {
      setChipList(editObject.zipCodes)
      setPrice(editObject.price)
    }
  }, [editObject])

  const theme = useTheme()
  const classes = useStyles()

  const handleChange = (place) => {
    const placeObj = zipCodeList.find((p) => p.colony === place)

    if (!_.find(chipList, { colonyName: place, zipCode: codeSearch })) {
      setChipList([
        ...chipList,
        { colonyName: place, zipCode: codeSearch, ...placeObj },
      ])
    }
    setMandatoryError(false)
  }

  const handleDelete = (item) => {
    setChipList(_.remove(chipList, (elem) => elem !== item))
  }

  const handleCancel = () => {
    handleModal(false)
    setChipList([])
    setPrice(0)
    setMandatoryError(false)
  }

  const handleSubmit = () => {
    if (chipList && !chipList.length) {
      setMandatoryError(true)
    } else {
      const deliveryPrice = [
        { price: parsePrice(price.toString()), zipCodes: chipList },
      ]

      if (!editObject) {
        setCodesSelected(_.union(codesSelected, deliveryPrice))
      } else {
        codesSelected.splice(editObject.pos, 1, deliveryPrice[0])
      }

      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.agregar_codigo_postal,
        Device: device.type,
      })
      handleCancel()
    }
  }

  const handleType = (event) => {
    if (event) {
      const codeValue = event?.target.value

      if (codeValue?.length >= SETTINGS.DIGIT_COUNT) {
        setLoading(true)
        const response = fetchInfo(
          `${ENV.REACT_APP_API_URL_2}${GET_ZIP_CODES}?code=${codeValue}`
        )

        response.then((res) => {
          setZipCodeList(res.data?.mexico_zipcodes)
          setCodeSearch(codeValue)
          setLoading(false)
        })
      }
    }
  }

  return (
    <ModalCustom onClose={() => handleModal(false)} open={showModal}>
      <Typography
        component="h2"
        sx={{
          fontSize: { sm: 24, xs: 18 },
          fontWeight: {
            sm: theme.fontWeight.bold,
            xs: theme.fontWeight.semibold,
          },
          lineHeight: { sm: 36 / 24, xs: 28 / 18 },
        }}
      >
        {SETTINGS.NEW_POSTAL_CODE}
      </Typography>
      <Typography
        sx={{
          fontWeight: theme.fontWeight.regular,
          margin: `${theme.space[4]}px 0`,
          maxWidth: 384,
        }}
        variant="h4"
      >
        {SETTINGS.MODAL_DESCRIPTION}
      </Typography>

      <Stack sx={{ position: 'relative' }}>
        {loading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: 50,
              top: 20,
              zIndex: theme.zIndex.drawer + 1,
            }}
          />
        )}
        <Autocomplete
          blurOnSelect
          filterOptions={(x) => x}
          onBlur={() => setInputValue(null)}
          onChange={(e, obj) => handleChange(obj)}
          onInputChange={handleType}
          options={zipCodeList?.map((option) => option?.colony)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={mandatoryError}
              fullWidth
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontWeight: theme.fontWeight.semibold,
                },
              }}
              InputProps={{
                endAdornment: mandatoryError && <ErrorIcon />,
                ...params.InputProps,
              }}
              label={SETTINGS.PLACEHOLDER}
              variant="filled"
            />
          )}
          value={inputValue}
        />
      </Stack>
      {mandatoryError && (
        <Typography color="error" variant="body1">
          {SETTINGS.ERROR_HINT}
        </Typography>
      )}
      <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
        {chipList &&
          chipList.length > 0 &&
          chipList.map((item, idx) => (
            <InputChip
              key={generateKey(idx)}
              label={item.colonyName}
              onDelete={() => handleDelete(item)}
              shape="button"
            />
          ))}
      </Stack>

      <Stack
        className={classes.wait}
        direction="row"
        sx={{
          alignItems: 'center',
          height: 56,
          justifyContent: 'space-between',
        }}
      >
        <Typography color={theme.palette.text.primary} variant="h4">
          {SETTINGS.SHIPPING_COST}
        </Typography>

        <NumberFormat
          allowNegative
          className={classes.numberFormat}
          decimalScale={2}
          decimalSeparator="."
          defaultValue={0}
          displayType="input"
          fixedDecimalScale
          onChange={(e) => setPrice(e.target.value)}
          onFocus={(e) => e.target.select()}
          prefix="$"
          thousandSeparator=","
          type="text"
          value={price || 0}
        />
      </Stack>
      <SubmitButton
        buttonCancelText={SETTINGS.CANCEL_BUTTON}
        buttonSubmitText={SETTINGS.ADD_BUTTON}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </ModalCustom>
  )
}

export default ShipmentModal

ShipmentModal.propTypes = {
  codesSelected: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string,
      zipCodes: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  editObject: PropTypes.shape({
    pos: PropTypes.number,
    price: PropTypes.string,
    zipCodes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  handleModal: PropTypes.func,
  setCodesSelected: PropTypes.func,
  showModal: PropTypes.bool,
}

ShipmentModal.defaultProps = {
  codesSelected: [],
  editObject: {},
  handleModal: () => {},
  setCodesSelected: () => {},
  showModal: false,
}
