import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  about: {
    alignItems: 'center',
    flexGrow: 1,
    margin: '0 auto',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      marginBottom: `${theme.space[4]}px`,
    },
  },
  lookStore: {
    '& .MuiSvgIcon-root': {
      marginLeft: '6px',
    },
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.space[1],
    textDecoration: 'none',
  },
  profileAvatar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: `${theme.space[4]}px`,
    top: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
}))

export default useStyles
