import PropTypes from 'prop-types'
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { Avatar, Box, Input, Stack, Typography, useTheme } from '@mui/material'
import { FileUpload, EditPen } from 'components/Icons'
import WrapIcon from 'components/WrapIcon'
import { ENV, POST_CONFIG_COVER_ROUTE } from 'constants/routes'
import { getRequestOptions } from 'helpers/fetchUtil'
import useStyles from './styles'
import UPLOAD_FILE from './constData.json'

const fileSize = 1000000

const UploadFile = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const { handleClick, cover, onSuccess } = props

  const [selectedFile, setSelectedFile] = useState(null)
  const [errorFileSize, setErrorFileSize] = useState(false)
  const [preview, setPreview] = useState(null)

  useEffect(() => {
    if (!selectedFile) {
      if (cover && cover.length) {
        setPreview(cover)
      } else {
        setPreview(null)
      }

      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)

    setPreview(objectUrl)
  }, [cover, selectedFile])

  const fileInput = useRef(null)

  const uploadFileToServer = async (fileObj) => {
    const formData = new FormData()

    formData.append('file', fileObj)

    try {
      const options = getRequestOptions()

      options.headers['Content-Type'] = 'multipart/form-data'

      const requestUrl = `${ENV.REACT_APP_API_URL}${POST_CONFIG_COVER_ROUTE}`
      const response = await axios.post(requestUrl, formData, options)

      onSuccess(response.data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('UploadFile::uploadFileToServer:error', e)
    }
  }

  const changeHandler = async (e) => {
    setErrorFileSize(false)
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      setPreview(undefined)

      return
    }

    if (e.target.files[0].size < fileSize) {
      await uploadFileToServer(e.target.files[0])
      setSelectedFile(e.target.files[0])
      handleClick(e.target.files[0], UPLOAD_FILE.COVER)
    } else {
      setErrorFileSize(true)
      setPreview(undefined)
    }
  }

  return (
    <Box sx={{ marginBottom: `${theme.space[5]}px` }}>
      <Box
        className={classes.container}
        sx={{
          '&.MuiBox-root': {
            border: errorFileSize
              ? `1px solid ${theme.palette.error.main}`
              : '',
          },
        }}
      >
        <Input
          className={classes.input}
          inputRef={fileInput}
          name="file"
          onChange={changeHandler}
          type="file"
        />

        {!preview ? (
          <Stack alignItems="center" spacing={1}>
            <WrapIcon sx={{ backgroundColor: theme.backgrounds[0] }}>
              <FileUpload />
            </WrapIcon>
            <Typography variant="body1">
              {UPLOAD_FILE.DROP_HERE}
              <Typography color={theme.palette.primary.main} variant="body1">
                {UPLOAD_FILE.SEARCH}
              </Typography>
            </Typography>
            <Typography
              color={theme.palette.secondary.main}
              fontWeight={theme.fontWeight.regular}
              variant="body1"
            >
              {UPLOAD_FILE.MAX}
            </Typography>
          </Stack>
        ) : (
          <>
            <Avatar
              alt={selectedFile?.name}
              src={preview}
              sx={{ height: '100%', width: '100%' }}
              variant="rounded"
            />
            <WrapIcon
              className={classes.editButton}
              onClick={() => {
                fileInput.current.click()
              }}
            >
              <EditPen color={theme.palette.primary.main} />
            </WrapIcon>
          </>
        )}
      </Box>
      {errorFileSize && (
        <Typography
          color={theme.palette.error.main}
          fontWeight={theme.fontWeight.regular}
          variant="body1"
        >
          {UPLOAD_FILE.SIZE_ERROR}
        </Typography>
      )}
    </Box>
  )
}

export default UploadFile

UploadFile.propTypes = {
  cover: PropTypes.string,
  handleClick: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
}

UploadFile.defaultProps = {
  cover: '',
  handleClick: () => {},
}
