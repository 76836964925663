import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@mui/material/MobileStepper'
import { useGlobalContext } from 'store/global'
import { useTheme } from '@mui/material/styles'
import generateKey from 'utils/math'
import { cleverTap, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import data from './data'
import useStyles from './styles'

const BannerSlider = ({ clevertapEventName }) => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyles()
  const theme = useTheme()
  const { banners } = data
  const { device } = useGlobalContext()
  const handleChangeIndex = (index) => setActiveStep(index)

  const handleRedirect = (url) => {
    if (device.isMobile && url) {
      cleverTap.event(clevertapEventName, {
        Click: PV.tutorial_banner,
        Device: device.type,
      })
      window.open(url, '_blank')
    }
  }

  const handleClick = (url) => {
    cleverTap.event(clevertapEventName, {
      Click: PV.tutorial_banner,
      Device: device.type,
    })
    navigate(url)
  }

  const renderBanner = (banner, i) => (
    <Box key={generateKey(i)} className={classes.banner}>
      <Grid
        alignItems="center"
        container
        onClick={() => handleRedirect(banner.url)}
        spacing={2}
        sx={{ flexDirection: { md: 'row', xs: 'row-reverse' } }}
      >
        <Grid item md={3} sx={{ justifyContent: 'center' }} xs={4}>
          <img alt="alt" src={banner.imgUrl} />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '60%',
            justifyContent: 'space-between',
          }}
          xs={8}
        >
          <Typography
            color="info.main"
            fontWeight={theme.fontWeight.semibold}
            variant="body2"
          >
            {banner.label}
          </Typography>
          <Typography
            color="black.main"
            fontWeight={theme.fontWeight.semibold}
            sx={{
              fontSize: { md: 18, xs: 14 },
              lineHeight: { md: '28px', xs: '20px' },
            }}
            variant="h3"
          >
            {banner.title}
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{
              fontSize: { md: 16, xs: 12 },
              lineHeight: { md: '24px', xs: '18px' },
            }}
            variant="h4"
          >
            {banner.description}
          </Typography>
        </Grid>
        <Grid
          item
          md={3}
          p={2}
          sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: 'center' }}
        >
          {banner.buttonText && (
            <Button
              color="orange"
              onClick={() => handleClick(banner.url)}
              size="small"
              sx={{ minWidth: 140 }}
            >
              {banner.buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <Grid
      data-testid="slideRenderer"
      item
      sx={{ flexBasis: 0, position: 'relative' }}
      xs={12}
    >
      <SwipeableViews
        enableMouseEvents
        index={activeStep}
        onChangeIndex={handleChangeIndex}
      >
        {banners.map((banner, idx) => renderBanner(banner, idx))}
      </SwipeableViews>

      <MobileStepper
        activeStep={activeStep}
        className={classes.bannerDots}
        position="static"
        steps={banners.length}
        variant="dots"
      />
    </Grid>
  )
}

BannerSlider.propTypes = {
  clevertapEventName: PropTypes.string,
}

BannerSlider.defaultProps = {
  clevertapEventName: '',
}
export default BannerSlider
