const POST_BUILDER = 'Post builder'
const NEW_POST = 'Nuevo post'
const SUBTITLE =
  'Una vez creado el post podrás descargar la imagen o directamente compartirla en tus redes sociales.'
const TITLE = 'Título'
const DESCRIPTION = 'Descripción'
const LINK = 'Enlace'
const RADIO_TITLE = '¿Qué estas promocionando?'
const DISCOUNTS = 'descuento'
const PRODUCT = 'product'
const CODE = 'code'
const MAX = 'Max. 80 caracteres.'
const OPTIONAL = 'Opcional'
const DOWNLOAD = 'Descargar'
const SEE_AND_SHARE = 'Ver y compartir imagen'

export {
  POST_BUILDER,
  NEW_POST,
  SUBTITLE,
  TITLE,
  DESCRIPTION,
  LINK,
  RADIO_TITLE,
  DISCOUNTS,
  PRODUCT,
  CODE,
  MAX,
  OPTIONAL,
  DOWNLOAD,
  SEE_AND_SHARE,
}
