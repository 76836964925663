import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  btnDelete: {
    backgroundColor: theme.backgrounds[0],
    color: theme.palette.text.primary,
    paddingLeft: `${theme.space[5]}px`,
    paddingRight: `${theme.space[5]}px`,
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
      height: 32,
      padding: 0,
      width: 32,
    },
  },
  btnPlus: {
    '& .MuiLoadingButton-loadingIndicator': {
      color: theme.palette.white.main,
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.borderRadius[0],
    height: 48,
    marginLeft: `${theme.space[2]}px`,
    marginTop: `${theme.space[1]}px`,
    minHeight: 'initial',
    minWidth: 48,
    padding: 0,
    width: 48,
  },
  btnSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
      height: 32,
      padding: 0,
      width: 32,
    },
  },
  containerList: {
    marginTop: `${theme.space[5]}px`,
    minWidth: 750,
    paddingLeft: `${theme.space[4]}px`,
    paddingRight: `${theme.space[4]}px`,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      paddingLeft: `${theme.space[2]}px`,
      paddingRight: `${theme.space[2]}px`,
    },
  },
  gridItem: {
    alignItems: 'center',
    display: 'flex',
  },
  gridItemLast: {
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  gridLeft: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  gridPagination: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: `${theme.space[5]}px`,
    paddingTop: `${theme.space[5]}px`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  gridRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconDelete: {
    color: 'blue',
  },
  item: {
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
      margin: 0,
    },
    padding: `${theme.space[3]}px ${theme.space[5]}px`,
  },
  numberFormat: {
    '&::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
    '&:focus': {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: theme.borderRadius[0],
    fontFamily: 'Averta',
    fontSize: 14,
    fontWeight: theme.fontWeight.semibold,
    marginBottom: `${theme.space[4]}px`,
    padding: theme.space[4],
    width: '100%',
  },
  rowGrid: {
    '&:hover': {
      backgroundColor: theme.backgrounds[1],
      cursor: 'pointer',
    },
    padding: `${theme.space[4]}px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  selected: {
    '& .MuiListItemIcon-root': {
      minWidth: 'initial',
      paddingTop: '6px',
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      margin: 0,
    },
    backgroundColor: theme.palette.secondary.light,
  },
  thumbnail: {
    borderRadius: `${theme.borderRadius[0]}px`,
    height: 40,
    marginRight: `${theme.space[6]}px`,
    width: 40,
    [theme.breakpoints.down('sm')]: {
      height: 48,
      marginRight: `${theme.space[2]}px`,
      width: 48,
    },
  },
  wrapper: {
    overflowX: 'auto',
    width: '100%',
  },
}))

export default useStyles
