const MuiButton = (theme) => ({
  variants: [
    // my variants
    {
      props: { color: 'orange' },
      style: {
        '&.Mui-disabled': {
          backgroundColor: theme.backgrounds[1],
          color: theme.palette.divider,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.strong,
        },
        [theme.breakpoints.down('xl')]: {
          paddingLeft: theme.space[3],
          paddingRight: theme.space[3],
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
      },
    },
    {
      props: { color: 'white' },
      style: {
        '&.Mui-disabled': {
          color: theme.palette.divider,
        },
        '&:hover': {
          '& .MuiButton-startIcon': {
            color: theme.palette.black.main,
          },
          backgroundColor: theme.backgrounds[1],
          color: theme.palette.black.main,
        },
        backgroundColor: theme.palette.white.main,
        color: theme.palette.text.primary,
        maxWidth: 280,
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: 14,
        fontWeight: theme.fontWeight.bold,
        height: 36,
        lineHeight: 20 / 14,
        maxWidth: 350,
        [theme.breakpoints.down('lg')]: {
          fontSize: 12,
          lineHeight: 18 / 12,
          padding: theme.space[2],
        },
        [theme.breakpoints.between('sm', 'md')]: {
          fontWeight: theme.fontWeight.regular,
        },
      },
    },
    {
      props: { color: 'white', variant: 'outlined' },
      style: {
        border: `1px solid ${theme.palette.text.primary}`,
        maxWidth: 350,
        textTransform: 'uppercase',
      },
    },
    {
      props: { color: 'cyan' },
      style: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.white.main,
        flexGrow: 0,
        letterSpacing: 0,
        textTransform: 'initial',
      },
    },
    {
      props: { color: 'cyan', variant: 'outlined' },
      style: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.info.dark}`,
        color: theme.palette.info.dark,
      },
    },
    {
      props: { size: 'full' },
      style: {
        '.MuiButton-startIcon': {
          marginLeft: 10,
          marginRight: 0,
        },
        div: {
          display: 'flex',
          flexGrow: 0,
          height: '100%',
          justifyContent: 'center',
          width: 130,
        },
        flexGrow: 1,
        height: 100,
        letterSpacing: 0.3,
        lineHeight: 1,
        maxWidth: 280,
        minWidth: 220,
        padding: 0,
        textTransform: 'uppercase',
      },
    },
    {
      props: { type: 'link' },
      style: {
        fontSize: 14,
        fontWeight: theme.fontWeight.semibold,
        lineHeight: 20 / 14,
        minHeight: 'initial',
        minWidth: 'initial',
        padding: theme.space[1],
        textTransform: 'initial',
      },
    },
    {
      props: { size: 'full-width' },
      style: {
        fontSize: 14,
        fontWeight: theme.fontWeight.bold,
        height: 36,
        lineHeight: 20 / 14,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: 16,
        fontWeight: theme.fontWeight.bold,
        height: 48,
        lineHeight: 24 / 16,
      },
    },
    {
      props: { size: 'large', variant: 'secondary-form' }, // SubmitButton
      style: {
        backgroundColor: theme.backgrounds[0],
        color: theme.palette.text.primary,
        fontWeight: theme.fontWeight.bold,
        height: 48,
        width: 236,
        [theme.breakpoints.down('sm')]: {
          height: 36,
          width: 140,
        },
      },
    },
    {
      props: { color: 'white', variant: 'secondary-form' }, // to use onboarding form
      style: {
        backgroundColor: theme.backgrounds[0],
        color: theme.palette.text.primary,
        fontWeight: theme.fontWeight.bold,
        height: 48,
        maxWidth: 308,
        width: '90%',
      },
    },
    {
      props: { shape: 'circle' },
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.borderRadius[3],
        boxShadow: `0px 6px 12px ${theme.palette.shadows.button}`,
        height: 64,
        minHeight: 24,
        minWidth: 24,
        width: 64,
        [theme.breakpoints.down('lg')]: {
          height: 48,
          width: 48,
        },
        [theme.breakpoints.down('sm')]: {
          height: 24,
          width: 24,
        },
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        '&.Mui-disabled': {
          backgroundColor: theme.backgrounds[1],
          color: theme.palette.divider,
        },
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.main,
        },
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    },
  ],
})

export default MuiButton
