// actions
const SYNC_ACTIONS = {
  ACTION_DELETE: 'SAVE_ACTION_DELETE',
  ACTION_GET: 'SAVE_ACTION_GET',
  ACTION_POST: 'SAVE_ACTION_POST',
  ACTION_PUT: 'SAVE_ACTION_PUT',
  RESET: 'RESET_SYNC',
}

export default SYNC_ACTIONS
