import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  customLabel: {
    '&.MuiInputLabel-shrink': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      fontSize: 14,
      top: 9,
    },
    color: theme.palette.text.primary,
    fontWeight: theme.fontWeight.semibold,
    left: 12,
    top: '-4px',
  },
  selectCustom: {
    '&.Mui-focused, & :focus': { backgroundColor: 'transparent' },
    backgroundColor: 'transparent',
  },
  wrapper: {
    '& :before, & :after, & :hover, & :hover:not(.Mui-disabled):before': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: `${theme.space[2]}px`,
    width: '100%',
  },
}))

export default useStyles
