import { useEffect } from 'react'
import Paper from 'components/Paper'
import Typography from '@mui/material/Typography'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Tips from 'components/Tips'
import { TUTORIALS } from 'constants/page'
import { useTheme } from '@mui/material'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'

const Tutorials = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()

  useEffect(() => {
    cleverTap.event(EVENTS.TUTORIALES_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  return (
    <Layout>
      <ContainerCard>
        <Paper sx={{ marginBottom: `${theme.space[5]}px` }}>
          <Typography color="black" variant="h2">
            {TUTORIALS}
          </Typography>
          <Tips />
        </Paper>
      </ContainerCard>
    </Layout>
  )
}

export default Tutorials
