import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { clearCookie, TOKEN_NAME } from 'helpers/tokenHelpers'
import { useTheme } from '@mui/material/styles'
import EllipsisText from 'react-ellipsis-text'
import Drawer from 'components/Drawer'
import { useGlobalContext } from 'store/global'
import {
  DownloadSave,
  MenuArrow,
  LinkExt,
  Faq,
  Logout,
  Logo,
} from 'components/Icons'
import Button from 'components/Button'
import { PublishModal } from 'components/Modal'
import { isExternURL } from 'utils/links'
import { useAuthState, useConfigDispatch } from 'store/user'
import dashboardSettings from 'constants/dashboardSettings'
import { ENV, GET_CONFIG_ROUTE, FAQ_ROUTE } from 'constants/routes'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { isCompleteValidation } from 'utils/validation'
import { updateInfo } from 'helpers/fetchUtil'
import UserActions from 'store/user/actions'
import MENU from './constData.json'
import { MenuContext } from './store'
import MENU_ACTIONS from './store/actions'
import items from './data'
import useStyles from './styles'

const Menu = () => {
  const { menuState, menuDispatch } = useContext(MenuContext)
  const { open, selected } = menuState
  const [openModal, setOpenModal] = useState(false)
  const {
    userInfo: userData,
    merchantInfo,
    merchantToken,
    storeConfig,
  } = useAuthState()
  const classes = useStyles()
  const theme = useTheme()
  const { device } = useGlobalContext()
  const isDesktop = !device.isMobile
  const navigate = useNavigate()
  const dispatch = useConfigDispatch()
  const { pathname } = useLocation()
  const [published, setPublished] = useState()

  useEffect(() => {
    setPublished(storeConfig?.is_published)
  }, [storeConfig])

  useEffect(() => {
    if (!selected) {
      menuDispatch({ selected: pathname, type: MENU_ACTIONS.UPDATE_SELECTED })
    }
    if (isDesktop) {
      menuDispatch({ open: true, type: MENU_ACTIONS.UPDATE_TOGGLE })
    }
  }, [])
  const isSelected = (url) => selected === url

  const handleDrawerOpen = () => {
    menuDispatch({ open: !open, type: MENU_ACTIONS.UPDATE_TOGGLE })
  }

  const handleSelected = (item) => {
    menuDispatch({ selected: item.url, type: MENU_ACTIONS.UPDATE_SELECTED })
    if (!isDesktop)
      menuDispatch({ open: !open, type: MENU_ACTIONS.UPDATE_TOGGLE })
  }
  // version mobile
  const handleToggleModal = () => {
    if (openModal) {
      cleverTap.event(EVENTS.PUBLISH_VIEWED, {
        Click: PV.publish_modal_close,
        Device: device.type,
      })
    }
    setOpenModal(!openModal)
  }

  const handleClickLinkStore = () => {
    cleverTap.event(EVENTS.MENU_CLICK, {
      Click: PV.ver_tienda,
      Device: device.type,
    })
  }

  const handleClickListItem = (item) => {
    handleSelected(item)

    cleverTap.event(EVENTS.MENU_CLICK, {
      Device: device.type,
      Navigation: item.text,
    })
  }

  const handleLogout = () => {
    clearCookie(TOKEN_NAME)
    window.location.href = '/'
  }

  const { publicProfile } = merchantInfo
  const merchantUrl = publicProfile
    ? `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`
    : ''

  const handlePublish = () => {
    if (isCompleteValidation(storeConfig)) {
      updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
        is_published: true,
        sharable_catalog: false,
      }).then(() => {
        dispatch({
          payload: {
            ...storeConfig,
            is_published: true,
            sharable_catalog: false,
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        setPublished(true)
        handleToggleModal()
      })
    } else {
      handleToggleModal()
    }
  }

  const renderAvatar = (image) =>
    image ? (
      <Avatar
        alt={`${userData?.first_name} ${userData?.last_name}`}
        src={image}
        sx={{ height: 64, marginBottom: '5px', width: 64 }}
      />
    ) : (
      <Avatar sx={{ height: 64, marginBottom: '5px', width: 64 }}>
        {`${userData?.first_name.charAt(0)}${userData?.last_name.charAt(0)}`}
      </Avatar>
    )

  const renderProfileAvatar = () => (
    <Stack
      className={classes.profileAvatar}
      flexDirection={open && 'row'}
      sx={{ marginLeft: open ? `${theme.space[4]}px` : 0 }}
    >
      {renderAvatar(publicProfile?.image)}

      <Stack
        flexDirection="column"
        sx={{ marginLeft: `${theme.space[2]}px` && open }}
      >
        {open && publicProfile?.public_name && (
          <Typography color="text.primary" variant="body1">
            <EllipsisText length={22} text={publicProfile.public_name} />
          </Typography>
        )}
        {merchantUrl && (
          <Link
            className={classes.lookStore}
            href={merchantUrl}
            onClick={handleClickLinkStore}
            target="_blank"
            variant="body1"
          >
            {MENU.LOOK_AT_STORE_TEXT}
            {open && <LinkExt size={1.1} />}
          </Link>
        )}
      </Stack>
    </Stack>
  )

  const renderMenuButtonDesktop = () => (
    <IconButton
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerOpen}
      sx={{
        height: 40,
        marginLeft: `${theme.space[open ? 4 : 2]}px`,
        marginTop: `${theme.space[4]}px`,
        width: 40,
      }}
    >
      <MenuArrow rotate={open ? 180 : 0} />
    </IconButton>
  )

  const renderFaqButton = () => (
    <Button
      color="white"
      onClick={() => navigate(FAQ_ROUTE)}
      size="small"
      startIcon={<Faq />}
      type="link"
    >
      {MENU.FAQ_BUTTON_TEXT}
    </Button>
  )

  const renderLogoutButton = () => (
    <Button
      color="white"
      onClick={handleLogout}
      size="small"
      startIcon={<Logout />}
      sx={{ marginRight: 0 }}
    >
      {MENU.LOGOUT_BUTTON_TEXT}
    </Button>
  )

  const renderPublishButton = () =>
    dashboardSettings.SHOW_PUBLISH_BUTTON && !published ? (
      <Button
        color="orange"
        onClick={handlePublish}
        size="small"
        startIcon={<DownloadSave color="white" rotate={180} />}
        sx={{ marginRight: { lg: '24px', md: '12px', sm: '6px' } }}
      >
        {MENU.PUBLIC_BUTTON_TEXT}
      </Button>
    ) : null

  const aboutDashboard = () => (
    <Stack
      className={classes.about}
      sx={{
        '& .MuiSvgIcon-root': {
          marginRight: open && `${theme.space[2]}px`,
        },
        flexDirection: open ? 'row' : 'column',
        justifyContent: !open && 'center',
      }}
    >
      <Logo />
      <Typography fontWeight={theme.fontWeight.semibold} variant="body2">
        {open && `${MENU.APP_NAME} `}
        {MENU.RELEASE_YEAR}
      </Typography>
    </Stack>
  )

  // INTERNAL LINK
  const renderNavLink = (item) => (
    <NavLink key={item.text} end replace={!!item?.replace} to={item.url}>
      <ListItem button onClick={() => handleClickListItem(item)}>
        <ListItemIcon>
          {React.createElement(item.icon, {
            color: isSelected(item.url)
              ? theme.palette.primary.main
              : theme.palette.text.primary,
          })}
        </ListItemIcon>
        {open && <ListItemText inset primary={item.text} />}
      </ListItem>
    </NavLink>
  )

  // EXTERNAL LINK
  const renderListItemLink = (item) => (
    <ListItem
      key={item.text}
      button
      component={Link}
      href={item.url}
      onClick={() => handleClickListItem(item)}
      target="_blank"
    >
      <ListItemIcon>
        {React.createElement(item.icon, {
          color: isSelected(item.url)
            ? theme.palette.primary.main
            : theme.palette.text.primary,
        })}
      </ListItemIcon>
      {open && <ListItemText inset primary={item.text} />}
    </ListItem>
  )

  return (
    <>
      <Drawer
        anchor="left"
        onClose={handleDrawerOpen}
        open={open}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
        variant={isDesktop ? 'permanent' : 'temporary'}
      >
        {isDesktop && renderMenuButtonDesktop()}
        {renderProfileAvatar()}
        <List>
          <Divider sx={{ margin: '12px 10px 16px' }} />
          {items
            .slice(0, -3)
            .map((item) =>
              isExternURL(item.url)
                ? renderListItemLink(item)
                : renderNavLink(item)
            )}
          <Divider sx={{ margin: '8px 10px' }} />
          {items
            .slice(-3)
            .map((item) =>
              isExternURL(item.url)
                ? renderListItemLink(item)
                : renderNavLink(item)
            )}
        </List>

        {!isDesktop && (
          <Stack spacing={2} sx={{ margin: '30px auto 20px', width: '85%' }}>
            {renderFaqButton()}
            {renderLogoutButton()}
            {!userData?.published && renderPublishButton()}
          </Stack>
        )}

        {aboutDashboard()}
      </Drawer>

      {/* => CONGRATS MODAL */}
      <PublishModal
        merchantData={{
          merchantName: publicProfile?.public_name || '',
          merchantSlug: publicProfile?.alias || '',
          merchantToken,
          merchantUrl,
        }}
        onClose={handleToggleModal}
        open={openModal}
        publishedSuccess={published}
      />
      {/* <= CONGRATS MODAL */}
    </>
  )
}

export default Menu
