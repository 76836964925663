import { Box, Typography } from '@mui/material'
import { EmptySales } from 'components/Icons'
import useStyles from './styles'
import CHARTS from '../constData.json'

const EmptyReport = () => {
  const classes = useStyles()

  return (
    <Box className={classes.empty}>
      <EmptySales />
      <Typography
        color={(theme) => theme.palette.text.disabled}
        component="p"
        variant="h3"
      >
        {CHARTS.EMPTY_MESSAGE}
      </Typography>
    </Box>
  )
}

export default EmptyReport
