import Cookies from 'js-cookie'
import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { DESKTOP, MOBILE, WEBVIEW_FLAG_COOKIE } from 'constants/page'

const GlobalContext = createContext(false)

const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (context === undefined) {
    throw new Error(
      'useGlobalContext:: useStateContext must be used within a provider'
    )
  }

  return context
}

const GlobalProvider = ({ children }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const isWebview = Cookies.get(WEBVIEW_FLAG_COOKIE) !== undefined
  const isMobile = width < theme.breakpoints.values.sm

  return (
    <GlobalContext.Provider
      value={{
        device: {
          isMobile,
          type: isMobile ? MOBILE : DESKTOP,
        },
        isWebview,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { GlobalProvider, useGlobalContext }
