import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  boxCanvas: {
    height: 300,
    margin: 'auto',
    position: 'relative',
    width: 300,
  },
  card: {
    background: theme.palette.white.main,
    borderRadius: 12,
    boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 330,
    padding: `${theme.space[5]}px 0`,
    position: 'relative',
    width: 'calc(100% - 20px)',
  },
  percentage: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  storeActivityMetrics: {
    margin: `${theme.space[5]}px 0px`,
    width: 'calc(100% + 20px)',
  },
}))

export default useStyles
