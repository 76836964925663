import { Switch as MuiSwitch } from '@mui/material'
import { styled } from '@mui/material/styles'

const Switch = styled((props) => (
  <MuiSwitch
    disableRipple
    focusVisibleClassName=".Mui-focusVisible"
    {...props}
  />
))(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        border: 0,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      color: theme.palette.white.main,
      transform: 'translateX(16px)',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: `6px solid ${theme.palette.white.main}`,
      color: theme.palette.primary.strong,
    },
    margin: 2,
    padding: 0,
    transitionDuration: '300ms',
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    height: 20,
    width: 20,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.text.disabled,
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  height: 24,
  padding: 0,
  width: 40,
}))

export default Switch
