import _ from 'lodash'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Stack from '@mui/material/Stack'
import TableHead from '@mui/material/TableHead'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import { useGlobalContext } from 'store/global'
import TableCell from './table/tableCell'
import RowGroup from './table/rowGroup'
import TableRowEmpty from './table/tableRowEmpty'

import useStyles from './styles'

const {
  data: { columns, labels: TABLE },
} = require('./constData.json')

const CollapsibleTable = ({ loading, rowData }) => {
  const { device } = useGlobalContext()
  const [contentRows, setContentRows] = useState([])
  const [page, setPage] = useState(1)
  const classes = useStyles()
  const theme = useTheme()
  const rowsPerPage = device.isMobile
    ? TABLE.ROWS_PER_PAGE / 2
    : TABLE.ROWS_PER_PAGE

  const displayRows = () => {
    const start = (page - 1) * rowsPerPage + 1
    const end =
      page * rowsPerPage < rowData.length ? page * rowsPerPage : rowData.length

    return `${start} - ${end} de ${rowData.length}`
  }

  useEffect(() => {
    setContentRows(rowData.slice(0, rowsPerPage))
  }, [rowData])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setContentRows(
      rowData.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage)
    )
  }

  const fieldMap = (sales) =>
    sales.map((sale) => ({
      client: sale.payer.email,
      id: sale.orderId,
      status: sale.order_status,
      time: sale.created_at,
      total: sale?.total_paid_amount.toString(),
    }))

  const contentRowsFilter = contentRows.map((row) => ({
    date_group: row.created_at.slice(0, 10),
    ...row,
  }))

  const salesGroup = _.groupBy(contentRowsFilter, 'date_group')
  const tableBodyContent = []

  _.forIn(salesGroup, (sales, key) => {
    tableBodyContent.push(
      <RowGroup
        key={`${key}-${tableBodyContent.length}`}
        keyRowGroup={key}
        rowGroupData={fieldMap(sales)}
      />
    )
  })

  const pages = Math.ceil(rowData.length / rowsPerPage)

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell
            colSpan={6}
            sx={{
              height: '60vh',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Loader />
          </TableCell>
        </TableRow>
      )
    }
    if (tableBodyContent.length === 0) {
      return <TableRowEmpty />
    }

    return tableBodyContent
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        {tableBodyContent.length ? (
          <TableHead>
            <TableRow sx={{ display: { sm: 'table-row', xs: 'none' } }}>
              {columns.map((head, idx) => (
                <TableCell
                  key={head}
                  sx={{
                    paddingLeft: idx === 0 ? `${theme.space[5]}px` : '10px',
                    paddingRight: idx === columns.length - 1 && '3.5%',
                    paddingTop: '38px',
                    textAlign: idx < columns.length - 1 ? 'left' : 'right',
                  }}
                >
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{renderTableContent()}</TableBody>
        {pages > 1 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  sx={{ justifyContent: { sm: 'space-between', xs: 'center' } }}
                >
                  <Typography
                    color="text.primary"
                    fontWeight="regular"
                    sx={{ display: { sm: 'flex', xs: 'none' } }}
                    variant="body1"
                  >
                    {displayRows()}
                  </Typography>
                  <Pagination
                    boundaryCount={1}
                    count={pages}
                    defaultPage={1}
                    onChange={handleChangePage}
                    page={page}
                    siblingCount={1}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export default CollapsibleTable

CollapsibleTable.propTypes = {
  loading: PropTypes.bool,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      additional_details: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.string,
      payer: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
      }),
      product_ids: PropTypes.arrayOf(PropTypes.number),
      status: PropTypes.string,
      total_paid_amount: PropTypes.number,
      updated_at: PropTypes.string,
    })
  ),
}

CollapsibleTable.defaultProps = {
  loading: false,
  rowData: null,
}
