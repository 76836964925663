import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import NumberFormat from 'react-number-format'
import Paper from 'components/Paper'

const RenderPaperBox = ({
  icon,
  mainTitle,
  leftLabel,
  rightLabel,
  leftNum,
  rightNum,
}) => (
  <Paper sx={{ marginBottom: '24px' }}>
    <Grid
      container
      spacing={3}
      sx={{ marginLeft: { md: '-24px', xs: '-16px' } }}
    >
      <Grid item sx={{ alignItems: 'center', display: 'flex' }} xs={12}>
        {icon}
        <Typography color="black" sx={{ marginLeft: '16px' }} variant="h2">
          {mainTitle}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          color="text.primary"
          fontWeight={(t) => t.fontWeight.semibold}
          variant="h1"
        >
          {leftNum.slice(0, 1) === '$' ? (
            <NumberFormat
              allowEmptyFormatting
              decimalScale="2"
              decimalSeparator="."
              displayType="text"
              fixedDecimalScale
              prefix="$"
              thousandSeparator=","
              thousandsGroupStyle="thousand"
              type="text"
              value={leftNum}
            />
          ) : (
            leftNum
          )}
        </Typography>
        <Typography color="secondary" variant="body2">
          {leftLabel}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          color="text.primary"
          fontWeight={(t) => t.fontWeight.semibold}
          variant="h1"
        >
          {rightNum}
        </Typography>
        <Typography color="secondary" variant="body2">
          {rightLabel}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
)

export default RenderPaperBox

RenderPaperBox.propTypes = {
  icon: PropTypes.element,
  leftLabel: PropTypes.string,
  leftNum: PropTypes.string,
  mainTitle: PropTypes.string,
  rightLabel: PropTypes.string,
  rightNum: PropTypes.string,
}

RenderPaperBox.defaultProps = {
  icon: null,
  leftLabel: '',
  leftNum: '',
  mainTitle: '',
  rightLabel: '',
  rightNum: '',
}
