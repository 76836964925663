import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducer'

const MenuContext = createContext(false)

const MenuProvider = ({ children }) => {
  const [menu, dispatch] = useReducer(reducer, {})

  return (
    <MenuContext.Provider
      value={{
        menuDispatch: dispatch,
        menuState: menu,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

MenuProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { MenuProvider, MenuContext }
