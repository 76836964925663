import PropTypes from 'prop-types'
import { Grid, useTheme } from '@mui/material'
import RenderPaperBox from 'components/RenderPaperBox'
import { Bill, Eyes, Sale } from 'components/Icons'
import WrapIcon from 'components/WrapIcon'

import CHARTS_REPORTS from 'components/ChartsReports/constData.json'

const StoreScoreCards = ({ data }) => {
  const theme = useTheme()
  const {
    total_delivered_orders: totalDeliveredOrders,
    total_pending_orders: totalPendingOrders,
    total_sales_amount: totalSalesAmount,
    total_sales_count: totalSalesCount,
    total_visit_count: totalVisitCount,
  } = data

  const conversion = (totalSalesCount / totalVisitCount) * 100

  return (
    <Grid columns={12} container spacing={2}>
      <Grid item lg={4} md={6} xs={12}>
        <RenderPaperBox
          icon={
            <WrapIcon sx={{ backgroundColor: theme.palette.secondary.light }}>
              <Bill color={theme.palette.primary.main} />
            </WrapIcon>
          }
          leftLabel={CHARTS_REPORTS.INCOME_AMOUNT_STORE}
          leftNum={`$${totalSalesAmount.toString()}`}
          mainTitle={CHARTS_REPORTS.INCOME_TEXT_STORE}
          rightLabel={CHARTS_REPORTS.INCOME_SALES_STORE}
          rightNum={totalSalesCount.toString()}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <RenderPaperBox
          icon={
            <WrapIcon sx={{ backgroundColor: theme.backgrounds[4] }}>
              <Sale />
            </WrapIcon>
          }
          leftLabel={CHARTS_REPORTS.SALES_PENDINGS_STORE}
          leftNum={totalPendingOrders.toString()}
          mainTitle={CHARTS_REPORTS.SALES_TEXT_STORE}
          rightLabel={CHARTS_REPORTS.SALES_DELIVERED_STORE}
          rightNum={totalDeliveredOrders.toString()}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <RenderPaperBox
          icon={
            <WrapIcon sx={{ backgroundColor: theme.backgrounds[5] }}>
              <Eyes color={theme.palette.primary.light} />
            </WrapIcon>
          }
          leftLabel={CHARTS_REPORTS.VISITS_VISIT_STORE}
          leftNum={totalVisitCount.toString()}
          mainTitle={CHARTS_REPORTS.VISITS_TEXT_STORE}
          rightLabel={CHARTS_REPORTS.VISITS_CONVERTION_STORE}
          rightNum={`${conversion ? conversion.toFixed(0).toString() : '-'}%`}
        />
      </Grid>
    </Grid>
  )
}

StoreScoreCards.propTypes = {
  data: PropTypes.shape({
    total_delivered_orders: PropTypes.number.isRequired,
    total_pending_orders: PropTypes.number.isRequired,
    total_sales_amount: PropTypes.number.isRequired,
    total_sales_count: PropTypes.number.isRequired,
    total_visit_count: PropTypes.number.isRequired,
  }).isRequired,
}

export default StoreScoreCards
