import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useTheme } from '@mui/material/styles'
import { Plus } from 'components/Icons'
import ModalBox from '../ModalBox'

const ModalCustom = ({ open, children, onClose, sx }) => {
  const theme = useTheme()

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      closeAfterTransition
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiBox-root': {
          borderBottomLeftRadius: { sm: theme.borderRadius[0], xs: 0 },
          borderBottomRightRadius: { sm: theme.borderRadius[0], xs: 0 },
          bottom: { sm: 'unset', xs: 'initial' },
          position: { sm: 'absolute', xs: 'fixed' },
          top: { sm: '50%', xs: 'initial' },
          width: { sm: 516, xs: '99%' },
          ...sx,
        },
        '&.MuiModal-root': {
          alignItems: { sm: 'initial', xs: 'center' },
          display: { sm: 'block', xs: 'flex' },
          top: { sm: 0, xs: 'initial' },
        },
      }}
    >
      <Fade in={open}>
        <ModalBox>
          <IconButton
            onClick={onClose}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: `${theme.space[2]}px`,
              top: { sm: 20, xs: 18 },
              transform: 'rotate(45deg)',
            }}
          >
            <Plus color={theme.palette.text.primary} />
          </IconButton>
          {children}
        </ModalBox>
      </Fade>
    </Modal>
  )
}

export default ModalCustom

ModalCustom.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
}

ModalCustom.defaultProps = {
  onClose: () => {},
  sx: {},
}
