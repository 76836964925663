import moment from 'moment'
import _ from 'lodash'

const formatDateInterval = (dateFrom, dateTo) => {
  const dateFromArray = dateFrom.split('-')
  const dateToArray = dateTo.split('-')
  const dataAux = {}
  const labels = []

  const initialDate = new Date(
    dateFromArray[2],
    dateFromArray[0] - 1,
    dateFromArray[1]
  )
  const finalDate = new Date(dateToArray[2], dateToArray[0] - 1, dateToArray[1])

  const diff = moment(finalDate).diff(initialDate, 'days')

  if (diff > 7) {
    let i = parseInt(initialDate.getDate(), 10)

    while (i <= parseInt(finalDate.getDate(), 10)) {
      const monthNumber = initialDate.getMonth() + 1
      const dayValue = i < 10 ? `0${i}` : i
      const dayMonth = monthNumber < 10 ? `0${monthNumber}` : monthNumber
      const dateIntervalValue = `${dayValue}-${dayMonth}-${initialDate.getFullYear()}`

      labels.push(dateIntervalValue)
      dataAux[dateIntervalValue] = 0
      i += 1
    }
  } else {
    let j = 7

    while (j >= 0) {
      const dateIntervalValue = moment(finalDate)
        .subtract(j, 'days')
        .format('DD-MM-YYYY')

      labels.push(dateIntervalValue)
      dataAux[dateIntervalValue] = 0
      j -= 1
    }
  }

  return {
    dataAux,
    labels,
  }
}

const formatChartData = (orderData, dateFrom, dateTo) => {
  const data = []
  const { labels, dataAux } = formatDateInterval(dateFrom, dateTo)

  _.each(orderData, (order) => {
    if (order.total_paid_amount) {
      const createdAt = order.created_at
      const dateStr = moment(createdAt).format('DD-MM-YYYY')

      dataAux[dateStr] += parseFloat(order.total_paid_amount)
    }
  })

  Object.keys(dataAux).forEach((dateKey) => {
    data.push(dataAux[dateKey])
  })

  /* DEBUG - DO NOT REMOVE FOR NOW  
  console.log('SalesChart::dataAux', dataAux)
  console.log('SalesChart::orderData', orderData)
  console.log('SalesChart::chartData', data)
  console.log('SalesChart::labels', labels)
  */

  return {
    chartData: data,
    labels,
  }
}

export default formatChartData
