import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import NumberFormat from 'react-number-format'

const RenderLabelAndPrice = ({ textBold, priceBold, name, price, variant }) => {
  const theme = useTheme()

  return (
    <Typography
      color={textBold ? 'black' : 'text.primary'}
      component="p"
      fontWeight={textBold ? theme.fontWeight.bold : theme.fontWeight.semibold}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      variant={variant}
    >
      <span>{name}</span>

      <NumberFormat
        allowEmptyFormatting
        decimalScale="2"
        decimalSeparator="."
        displayType="text"
        fixedDecimalScale
        prefix="$"
        style={{
          color: priceBold
            ? theme.palette.black.main
            : theme.palette.text.primary,
          fontWeight: priceBold ? '700' : '600',
        }}
        thousandSeparator=","
        thousandsGroupStyle="thousand"
        type="text"
        value={price}
      />
    </Typography>
  )
}

export default RenderLabelAndPrice

RenderLabelAndPrice.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  priceBold: PropTypes.bool,
  textBold: PropTypes.bool,
  variant: PropTypes.string,
}

RenderLabelAndPrice.defaultProps = {
  priceBold: false,
  textBold: false,
  variant: 'body1',
}
