import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import NumberFormat from 'react-number-format'
import { EmptyReport } from 'components/ChartsReports'
import { Chip } from 'components/Chip'
import useStyles from './styles'

import MSP from './constData.json'

const MostSelledProducts = ({ productsList }) => {
  const classes = useStyles()
  const theme = useTheme()

  const Item = (item, i, length) => {
    const {
      photoURL: imageUrl,
      name,
      total_amount_paid: totalPaid,
      total_product_sales: unitsSold,
    } = item
    const index = i + 1

    return (
      <Box key={`${name}-${index}`}>
        <Box className={classes.item}>
          <Stack direction="row" spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              <Chip
                label={index}
                shape="circle"
                size="large"
                sx={{ marginRight: `${theme.space[2]}px` }}
              />
              <Avatar
                alt={name}
                src={imageUrl}
                sx={{ height: 60, width: 60 }}
                variant="rounded"
              />
            </Stack>
            <Stack sx={{ justifyContent: 'space-between', padding: '5px 0' }}>
              <Typography
                color="black.main"
                sx={{ fontWeight: theme.fontWeight.regular }}
                variant="body1"
              >
                {name}
              </Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography
                  color="black.main"
                  sx={{ fontWeight: theme.fontWeight.bold }}
                  variant="body1"
                >
                  {MSP.SELLS}
                </Typography>
                <Typography
                  color="black.main"
                  sx={{ fontWeight: theme.fontWeight.regular }}
                  variant="body1"
                >
                  {unitsSold}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ justifyContent: 'space-between', padding: '5px 0' }}>
            <Typography
              color="secondary.main"
              sx={{ fontWeight: theme.fontWeight.regular }}
              variant="body1"
            >
              {MSP.RAISED}
            </Typography>
            <Typography
              align="right"
              color="black.main"
              sx={{ fontWeight: theme.fontWeight.bold }}
              variant="body1"
            >
              <NumberFormat
                allowEmptyFormatting
                decimalScale="2"
                decimalSeparator="."
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator=","
                thousandsGroupStyle="thousand"
                type="text"
                value={totalPaid}
              />
            </Typography>
          </Stack>
        </Box>
        {index === length ? '' : <Divider light />}
      </Box>
    )
  }

  return (
    <Box className={classes.mostSelledProducts}>
      <Typography color="black.main" fontWeight="bold" variant="h2">
        {MSP.MOST_SELLED_PROD_TITLE}
      </Typography>
      {productsList && productsList.length
        ? productsList.map((item, i, arr) => Item(item, i, arr.length))
        : EmptyReport()}
    </Box>
  )
}

export default MostSelledProducts

MostSelledProducts.propTypes = {
  productsList: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      total_amount_paid: PropTypes.number,
      total_product_sales: PropTypes.number,
    })
  ),
}

MostSelledProducts.defaultProps = {
  productsList: [
    {
      imageUrl: '',
      name: '',
      total_amount_paid: '',
      total_product_sales: '',
    },
  ],
}
