import { useState, useEffect, useCallback, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Layout from 'components/Layout'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import TextField from 'components/TextField'
import ContainerCard from 'components/ContainerCard'
import Paper from 'components/Paper'
import EntitySelector from 'components/PostBuilder/EntitySelector'
import BackgroundSelector from 'components/PostBuilder/BackgroundSelector'
import WrapIcon from 'components/WrapIcon'
import { toPng } from 'html-to-image'
import { useGlobalContext } from 'store/global'

import { Discounts, DownloadSave, Chevron } from 'components/Icons'
import { useAuthState } from 'store/user'

import { RenderNav } from 'components/Catalog-v2'
import {
  POST_BUILDER,
  NEW_POST,
  SUBTITLE,
  TITLE,
  DESCRIPTION,
  RADIO_TITLE,
  DISCOUNTS,
  CODE,
  PRODUCT,
  MAX,
  OPTIONAL,
  DOWNLOAD,
  SEE_AND_SHARE,
} from 'constants/posts'
import { useTheme } from '@mui/material'
import {
  ENV,
  PROMOTIONS_ROUTE,
  GET_ACTIVE_COUPONS_ROUTE,
} from 'constants/routes'

import { fetchInfo } from 'helpers/fetchUtil'
import convertImageToBase64 from 'utils/imageUtils'

const PostBuilder = () => {
  const theme = useTheme()
  const refImgToDownload = useRef()
  const { merchantToken, merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo
  const { device } = useGlobalContext()

  const couponsEndpoint = `${ENV.REACT_APP_API_URL}${GET_ACTIVE_COUPONS_ROUTE}/${merchantToken}`

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [processingDownload, setProcessingDownload] = useState(false)
  const [avatarBase64Img, setAvatarBase64Img] = useState(false)
  const [type, setType] = useState('product')
  const [activeCoupons, setActiveCoupons] = useState('')
  const [imageSelected, setImageSelected] = useState('')
  const [nameSelected, setNameSelected] = useState('')
  const [priceDescSelected, setPriceDiscSelected] = useState('')
  const [background, setBackground] = useState('/images/post-back.svg')
  const [error, setError] = useState(false)
  const [showPostMobile, setShowPostMobile] = useState(false)

  useEffect(async () => {
    const response = await fetchInfo(couponsEndpoint)

    setActiveCoupons(response.data)
  }, [])

  const loadImageResource = useCallback(() => {
    if (publicProfile?.image) {
      convertImageToBase64(publicProfile?.image, (imgResult) => {
        setAvatarBase64Img(imgResult)
      })
    }
  }, [])

  useEffect(() => {
    if (publicProfile) {
      loadImageResource()
    }
  }, [])

  const cleanValues = () => {
    setNameSelected('')
    setPriceDiscSelected('')
    setImageSelected('')
  }

  const handleChange = (e) => {
    cleanValues()
    setType(e.target.value)
  }

  const handleDownload = async () => {
    if (title === '') {
      setError(true)

      return
    }

    setProcessingDownload(true)
    const baseNode = refImgToDownload.current

    if (!baseNode) return

    toPng(baseNode, {
      backgroundColor: theme.palette.common.white,
      cacheBust: false,
    })
      .then((dataUrl) => {
        const linkToDownload = document.createElement('a')

        linkToDownload.download = `post-${nameSelected}.png`
        linkToDownload.href = dataUrl
        linkToDownload.click()
        setProcessingDownload(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Image download failed', err)
      })
  }

  const header = () => (
    <>
      <Typography color="black" variant="h2">
        {NEW_POST}
      </Typography>
      <Typography
        color="text.primary"
        fontWeight={theme.fontWeight.regular}
        sx={{
          maxWidth: 590,
          width: '100%',
        }}
        variant="h4"
      >
        {SUBTITLE}
      </Typography>
    </>
  )

  const productsPost = () => (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: `${theme.borderRadius[0]}px`,
        boxShadow: `0 8px 16px ${theme.palette.shadows.card}`,
        p: 2,
      }}
    >
      <Avatar
        src={imageSelected}
        sx={{ height: 140, width: 140 }}
        variant="rounded"
      />
      <Stack>
        <Typography fontWeight={theme.fontWeight.semibold} variant="h3">
          {nameSelected}
        </Typography>
        <Typography
          color="text.primary"
          fontWeight={theme.fontWeight.semibold}
          variant="h3"
        >
          ${priceDescSelected}
        </Typography>
      </Stack>
    </Box>
  )

  const discountsPost = () => (
    <Box sx={{ position: 'relative' }}>
      <Avatar
        src="/images/back-discounts.svg"
        sx={{
          height: 180,
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 350,
        }}
        variant="square"
      />
      <Stack
        sx={{
          display: 'flex',
          height: 130,
          justifyContent: 'space-between',
          position: 'relative',
          textAlign: 'center',
          zIndex: 1,
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'center', display: 'flex' }}>
          <Discounts size={2} />
          <Typography fontWeight={theme.fontWeight.bold} variant="h4">
            {nameSelected}
          </Typography>
        </Stack>
        <Typography fontWeight={theme.fontWeight.bold} variant="hxxxl">
          {priceDescSelected}
        </Typography>
        <Typography
          color="text.primary"
          fontWeight={theme.fontWeight.semibold}
          variant="h2"
        >
          {DISCOUNTS}
        </Typography>
      </Stack>
    </Box>
  )

  const renderPostBody = () => (
    <Box
      sx={{
        maxHeight: 400,
        maxWidth: 400,
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        ref={refImgToDownload}
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            left: 20,
            position: 'absolute',
            top: 20,
            width: '90%',
            zIndex: 1,
          }}
        >
          {avatarBase64Img && (
            <Avatar src={avatarBase64Img} sx={{ height: 64, width: 64 }} />
          )}
          <Avatar
            alt="logo clip"
            src="/images/logo-clip.svg"
            sx={{ height: 56, width: 56 }}
            variant="square"
          />
        </Stack>
        <Avatar
          src={background}
          sx={{
            height: {
              sm: 400,
              xs: '90vw',
            },
            maxHeight: 400,
            maxWidth: 400,
            width: '100%',
          }}
          variant="rounded"
        />
        {nameSelected && (
          <Box
            sx={{
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {type === PRODUCT ? productsPost() : discountsPost()}
          </Box>
        )}
        <Stack
          sx={{
            backgroundColor: 'rgba(24, 26, 54, 0.75)',
            borderBottomLeftRadius: `${theme.borderRadius[0]}px`,
            borderBottomRightRadius: `${theme.borderRadius[0]}px`,
            bottom: 0,
            height: 73,
            maxWidth: 400,
            p: 1,
            position: 'absolute',
            width: '100%',
          }}
        >
          <Typography color="white.main" variant="h4">
            {title}
          </Typography>
          <Typography color="white.main" variant="body2">
            {description}
          </Typography>
        </Stack>
      </Box>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          mt: { sm: `${theme.space[4]}px`, xs: `${theme.space[5]}px` },
        }}
      >
        <WrapIcon
          data-testid="btn-download"
          onClick={handleDownload}
          sx={{
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            height: 36,
            width: '100%',
          }}
        >
          <DownloadSave color={theme.palette.white.main} />
          <Typography color="white.main">
            {processingDownload ? 'Descargando...' : DOWNLOAD}
          </Typography>
        </WrapIcon>
      </Stack>
    </Box>
  )

  const body = () => (
    <Grid container sx={{ mt: '46px' }}>
      <Grid item md={4.5} sm={12} xs={12}>
        <TextField
          error={error}
          fullWidth
          label={TITLE}
          onChange={(e) => setTitle(e.target.value)}
          onFocus={() => setError(false)}
          sx={{ mb: `${theme.space[5]}px` }}
          value={title}
          variant="filled"
        />
        <TextField
          fullWidth
          inputProps={{
            maxLength: 80,
            style: {
              height: '57px',
            },
          }}
          label={DESCRIPTION}
          multiline
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          variant="filled"
        />
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: `${theme.space[5]}px`,
          }}
        >
          <Typography variant="body2">{MAX}</Typography>
          <Typography variant="body2">{OPTIONAL}</Typography>
        </Stack>
        <Divider
          sx={{ borderColor: theme.backgrounds[2], mb: `${theme.space[5]}px` }}
        />
        <Typography
          color="black"
          sx={{ mb: `${theme.space[5]}px` }}
          variant="h2"
        >
          {RADIO_TITLE}
        </Typography>
        <RadioGroup
          defaultValue="product"
          name="radios"
          row
          sx={{ mb: `${theme.space[5]}px` }}
        >
          <FormControlLabel
            control={<Radio />}
            label="Producto"
            onChange={(e) => handleChange(e)}
            sx={{ mr: `${theme.space[6]}px` }}
            value={PRODUCT}
          />
          <FormControlLabel
            control={<Radio />}
            label="Código de descuento"
            onChange={(e) => handleChange(e)}
            value={CODE}
          />
        </RadioGroup>
        <EntitySelector
          activeCoupons={activeCoupons}
          cleanValues={cleanValues}
          imageSelected={imageSelected}
          nameSelected={nameSelected}
          setImageSelected={setImageSelected}
          setNameSelected={setNameSelected}
          setPriceDiscSelected={setPriceDiscSelected}
          type={type}
        />
        <Divider
          sx={{
            borderColor: theme.backgrounds[2],
            mb: `${theme.space[5]}px`,
            mt: `${theme.space[5]}px`,
          }}
        />
        <BackgroundSelector setBackground={setBackground} />
      </Grid>
      <Grid item md={3} sm={1} xs={0}>
        <Divider
          orientation="vertical"
          sx={{
            borderColor: theme.backgrounds[2],
            height: '65%',
            margin: 'auto',
            transform: 'translate(50%, 45%)',
            width: 5,
          }}
        />
      </Grid>
      {!device.isMobile && (
        <Grid
          item
          md={4.5}
          sm={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            p: 4,
          }}
          xs={12}
        >
          {renderPostBody()}
        </Grid>
      )}
    </Grid>
  )

  return (
    <Layout>
      <ContainerCard>
        <RenderNav
          back={{ label: POST_BUILDER, to: PROMOTIONS_ROUTE }}
          current={{
            label: POST_BUILDER,
          }}
        />
        <Paper
          sx={{
            minHeight: '100vh',
            mt: '34px',
            p: { sm: '24px', xs: '16px' },
          }}
        >
          {header()}
          {body()}
        </Paper>
        {device.isMobile && (
          <>
            {showPostMobile && (
              <Box
                onClick={() => setShowPostMobile(!showPostMobile)}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  bottom: 0,
                  left: 0,
                  position: 'fixed',
                  right: 0,
                  top: 0,
                }}
              />
            )}
            <Paper
              sx={{
                bottom: 0,
                boxShadow: `0px -8px 16px ${theme.palette.shadows.card}`,
                left: 0,
                pl: { xs: `${theme.space[4]}px` },
                position: 'fixed',
                pr: { xs: `${theme.space[4]}px` },
                zIndex: 1,
              }}
            >
              <Stack
                direction="row"
                onClick={() => setShowPostMobile(!showPostMobile)}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4">{SEE_AND_SHARE}</Typography>
                <WrapIcon
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    height: 24,
                    width: 24,
                  }}
                >
                  <Chevron
                    color={theme.palette.primary.main}
                    rotate={showPostMobile ? 90 : 270}
                  />
                </WrapIcon>
              </Stack>
              {showPostMobile && (
                <Box sx={{ pb: 3, pt: 3 }}> {renderPostBody()}</Box>
              )}
            </Paper>
          </>
        )}
      </ContainerCard>
    </Layout>
  )
}

export default PostBuilder
