import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { Arrow, Chevron } from 'components/Icons'

const RenderNav = ({ back, current }) => {
  const theme = useTheme()

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{
        '& > * ': {
          color: theme.palette.text.primary,
          display: 'flex',
          fontWeight: theme.fontWeight.semibold,
          textDecoration: 'none',
        },
      }}
    >
      <NavLink to={back.to}>
        <Arrow size={1.4} />
        <i>&nbsp;&nbsp;</i>
        {back.label}
      </NavLink>
      <Chevron />
      <NavLink style={{ color: theme.palette.black.main }} to={current.to}>
        {current.label}
      </NavLink>
    </Stack>
  )
}

export default RenderNav

RenderNav.propTypes = {
  back: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  current: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
}
