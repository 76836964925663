import { useEffect, useState, useRef, useCallback } from 'react'
import Paper from 'components/Paper'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import PaymentMethods from 'components/PaymentMethods'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import QRCode from 'react-qr-code'
import EllipsisText from 'react-ellipsis-text'
import Button from 'components/Button'
import { toPng } from 'html-to-image'
import { useAuthState } from 'store/user'
import { RenderNav } from 'components/Catalog-v2'
import convertImageToBase64 from 'utils/imageUtils'
import {
  QR_CODE,
  PROMOTE,
  QR_DESCRIPTION,
  SELECT_TITLE,
  SELECT_CONTENT,
  MY_CODE,
  QR_COPY_FIRST,
  QR_COPY_SECOND,
  STORE,
  MENU,
  DOWNLOAD,
} from 'constants/promotions'
import { ENV, PROMOTIONS_QR_ROUTE, PROMOTIONS_ROUTE } from 'constants/routes'
import { useTheme } from '@mui/material'
import { MiNegocioClip, DownloadSave } from 'components/Icons'

const QrCode = () => {
  const theme = useTheme()
  const refQR = useRef()
  const [item, setItem] = useState('')
  const [link, setLink] = useState('')
  const [processingDownload, setProcessingDownload] = useState(false)
  const [base64Img, setBase64Img] = useState(false)

  const { userInfo: userData, merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo

  const loadImageResource = useCallback(() => {
    if (publicProfile?.image) {
      convertImageToBase64(publicProfile?.image, (imgResult) => {
        setBase64Img(imgResult)
      })
    }
  }, [])

  useEffect(() => {
    if (publicProfile) {
      if (publicProfile.alias) {
        const merchantUrl = `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`

        setLink(merchantUrl)
      }

      loadImageResource()
    }
  }, [])

  const renderAvatar = (image) =>
    image && (
      <Avatar
        alt={`${userData?.first_name} ${userData?.last_name}`}
        src={image}
        sx={{ height: 48, mr: '5px', width: 48 }}
      />
    )

  const headerQR = () => (
    <Stack direction="row" sx={{ alignItems: 'center', m: '0 0 32px' }}>
      {renderAvatar(base64Img)}

      <Stack sx={{ textAlign: 'left' }}>
        <Typography
          color="black"
          fontWeight={theme.fontWeight.bold}
          variant="h2"
        >
          <EllipsisText
            length={27}
            text={publicProfile.public_name}
            tooltip={publicProfile.public_name}
          />
        </Typography>
      </Stack>
    </Stack>
  )

  const QR = () => (
    <QRCode
      size={256}
      style={{
        display: 'block',
        height: 'auto',
        maxWidth: '194px',
        width: '194px',
      }}
      value={link}
      viewBox="0 0 256 256"
    />
  )

  const underlineQR = () => (
    <Stack
      sx={{
        mb: 3,
        mt: 3,
        textAlign: 'left',
        width: '194px',
      }}
    >
      <Typography
        color="black"
        fontWeight={theme.fontWeight.bold}
        sx={{ width: '80%' }}
        variant="hxxl"
      >
        {QR_COPY_FIRST}{' '}
        <Typography color="primary.main" variant="hxxl">
          {item === SELECT_CONTENT[1] ? MENU : STORE}
        </Typography>{' '}
        {QR_COPY_SECOND}
      </Typography>
    </Stack>
  )

  const footerQR = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ alignItems: 'center', height: 20, justifyContent: 'center' }}
    >
      <MiNegocioClip size={6} />
      <Divider flexItem orientation="vertical" sx={{ height: 16 }} />
      <PaymentMethods />
    </Stack>
  )

  const handleDownload = useCallback(() => {
    setProcessingDownload(true)
    const baseNode = refQR.current

    if (!baseNode) return

    toPng(baseNode, {
      backgroundColor: theme.palette.common.white,
      cacheBust: false,
    })
      .then((dataUrl) => {
        const linkToDownload = document.createElement('a')

        linkToDownload.download = `qr-${link}.png`
        linkToDownload.href = dataUrl
        linkToDownload.click()
        setProcessingDownload(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Image download failed', err)
      })
  }, [refQR, link, theme])

  return (
    <Layout>
      <ContainerCard
        sx={{
          display: 'flex',
          flexDirection: { sm: 'row', xs: 'column' },
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper
              sx={{
                marginBottom: `${theme.space[5]}px`,
                p: { xs: 3 },
              }}
            >
              <RenderNav
                back={{ label: PROMOTE, to: PROMOTIONS_ROUTE }}
                current={{
                  label: QR_CODE,
                  to: PROMOTIONS_QR_ROUTE,
                }}
              />
              <Typography color="black" sx={{ mt: '34px' }} variant="h2">
                {QR_CODE}
              </Typography>

              <Typography
                color="text.primary"
                fontWeight={theme.fontWeight.regular}
                sx={{
                  display: 'block',
                  mt: 2,
                  width: { sm: 416, xs: '100%' },
                }}
                variant="body1"
              >
                {QR_DESCRIPTION}
              </Typography>
              <Typography
                color="black"
                fontWeight={theme.fontWeight.semibold}
                sx={{ mt: 3 }}
                variant="h3"
              >
                {SELECT_TITLE}
              </Typography>
              <Select
                displayEmpty
                onChange={(e) => setItem(e.target.value)}
                sx={{
                  '&.MuiOutlinedInput-root.MuiInputBase-root': {
                    borderRadius: `${theme.borderRadius[0]}px`,
                    fontWeight: theme.fontWeight.semibold,
                  },
                  maxWidth: 324,
                  mt: 2,
                  width: '100%',
                }}
                value={item}
              >
                {SELECT_CONTENT.map((it, idx) => (
                  <MenuItem key={`s-${it}`} selected={idx === it} value={it}>
                    {it}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={4}
            md={5}
            sm={6}
            sx={{ maxWidth: { sm: '100%' } }}
            xl={3}
            xs={12}
          >
            <Paper
              ref={refQR}
              sx={{
                marginBottom: `${theme.space[5]}px`,
                p: { xs: 3 },
              }}
            >
              {!processingDownload && (
                <Typography
                  color="black"
                  fontWeight={theme.fontWeight.semibold}
                  sx={{ mb: 3 }}
                  variant="h4"
                >
                  {MY_CODE}
                </Typography>
              )}
              <Box
                sx={{
                  border: `1.5px solid ${theme.palette.primary.main}`,
                  borderRadius: `${theme.borderRadius[0]}px`,
                  boxShadow: '8px 8px 0px 0px rgb(252 76 2)',
                  p: 3,
                }}
              >
                <Stack sx={{ alignItems: 'center' }}>
                  {headerQR()}
                  {QR()}
                  {underlineQR()}
                </Stack>
                {footerQR()}
              </Box>
              {!processingDownload && (
                <Button
                  color="orange"
                  onClick={handleDownload}
                  size="big"
                  startIcon={<DownloadSave color="white" />}
                  sx={{
                    maxWidth: { md: 323, xs: 'initial' },
                    mt: 5,
                    width: '100%',
                  }}
                >
                  {DOWNLOAD}
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </ContainerCard>
    </Layout>
  )
}

export default QrCode
