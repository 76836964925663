import moment from 'moment'

const { coupon: COUPON } = require('components/Coupon/constData.json')

const today = new Date()

const dateExpired = (date) => moment(date).diff(today, 'days') < 0

const discountRuns = (coupon) => {
  if (
    moment() >= moment(coupon?.start_date, 'YYYY-MM-DD') &&
    !dateExpired(coupon?.end_date)
  ) {
    return true
  }

  return false
}

const countCodesAllowed = (value) => value.length <= COUPON.MAX

const validCharcode = (value) => /^[a-zA-Z0-9-]$/.test(value)

export { dateExpired, discountRuns, countCodesAllowed, validCharcode }
