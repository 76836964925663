import { Paper as MuiPaper } from '@mui/material'
import { styled } from '@mui/material/styles'

const Paper = styled(MuiPaper)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.borderRadius[0],
  boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
  padding: theme.space[5],
  width: '100%',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
  },
}))

export default Paper
