import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { Chevron } from 'components/Icons'
import { isPartialValidation } from 'utils/validation'
import { STORE_ROUTE } from 'constants/routes'
import ListItemLink from './listItemLink'
import store from './data'
import useStyles from './styles'

const StoreWelcome = ({
  onHandlePublish,
  onHandleTributaryClick,
  storeConfig,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()

  const renderSections = () => (
    <List>
      <ListItemLink
        complete={storeConfig?.hasCatalog}
        icon={Chevron}
        item={store.sections.find((s) => s.storeConfig === 'products')}
      />
      <ListItemLink
        complete={!!storeConfig?.store_settings?.theme_id}
        handleClick={() =>
          navigate(store.sections[1].urlPath, { state: STORE_ROUTE })
        }
        icon={Chevron}
        item={store.sections.find((s) => s.storeConfig === 'theme_id')}
      />

      <ListItemLink
        complete={isPartialValidation(storeConfig)}
        icon={Chevron}
        item={store.sections.find((s) => s.storeConfig === 'settings')}
      />

      <ListItemLink
        complete={storeConfig?.tributary}
        handleClick={onHandleTributaryClick}
        icon={Chevron}
        item={store.sections.find((s) => s.storeConfig === 'tributary')}
      />

      <ListItemLink
        complete={storeConfig?.is_published}
        handleClick={onHandlePublish}
        icon={Chevron}
        item={store.sections.find((s) => s.storeConfig === 'is_published')}
      />
    </List>
  )

  return (
    <Stack>
      <Typography
        fontWeight={theme.fontWeight.bold}
        sx={{ mb: `${theme.space[5]}px` }}
        variant="h2"
      >
        {store.title}
      </Typography>
      <Typography className={classes.description} variant="h4">
        {store.description}
      </Typography>

      {renderSections()}
    </Stack>
  )
}

export default StoreWelcome

StoreWelcome.propTypes = {
  onHandlePublish: PropTypes.func.isRequired,
  onHandleTributaryClick: PropTypes.func.isRequired,
  storeConfig: PropTypes.shape({
    contact_information: PropTypes.shape({}),
    hasCatalog: PropTypes.bool,
    is_published: PropTypes.bool,
    store_settings: PropTypes.shape({
      delivery_settings: PropTypes.shape({}),
      opening_hours: PropTypes.arrayOf(PropTypes.shape({})),
      theme_id: PropTypes.string,
    }),
    tributary: PropTypes.bool,
  }).isRequired,
}
