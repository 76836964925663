import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  cellDate: {
    alignItems: 'center',
    backgroundColor: theme.backgrounds[0],
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
    paddingLeft: `${theme.space[5]}px`,
    paddingRight: `${theme.space[5]}px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.space[4]}px`,
      paddingRight: `${theme.space[4]}px`,
    },
  },
  tableContainer: {
    '& .MuiTable-root': {
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: 1024,
      },
    },
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.space[4],
    },
  },
}))

export default useStyles
