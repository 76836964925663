import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dashed: {
    border: `3px dashed ${theme.backgrounds[1]}`,
    height: 64,
    width: 64,
  },
  default: {
    cursor: 'pointer',
    height: 64,
    marginRight: `${theme.space[4]}px`,
    width: 64,
  },
  input: {
    '& input': {
      cursor: 'pointer',
      height: '100%',
    },
    height: 0,
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: 0,
  },
  marginBottom5: {
    marginBottom: `${theme.space[5]}px`,
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: `0 8px 16px ${theme.palette.shadows.card}`,
  },
}))

export default useStyles
