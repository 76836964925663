import PropTypes from 'prop-types'
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import { useTheme } from '@mui/material'
import { FileUpload, Plus } from 'components/Icons'
import Button from 'components/Button'
import { ENV, POST_CATALOG_IMAGES_ROUTE } from 'constants/routes'
import { getRequestOptions } from 'helpers/fetchUtil'
import { useGlobalContext } from 'store/global'
import { useProductContext } from 'components/Catalog-v2/store'
import { getImageBase64, convertToBase64 } from 'helpers/product'
import PRODUCT_ACTIONS from 'components/Catalog-v2/store/actions'
import useStyles from './styles'

const {
  product: { UPLOAD_PHOTO },
} = require('../product/constData.json')

const fileSizeSupport = 1000000

const UploadPhoto = ({ error, handleError }) => {
  const [previewPhoto, setPreviewPhoto] = useState(null)
  const styles = useStyles()
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { productData, productDispatch } = useProductContext()
  const extensions = ['jpg', 'jpeg', 'png']

  useEffect(() => {
    if (productData?.image) {
      setPreviewPhoto(productData.image)
    }
  }, [productData])

  const fileImageInput = useRef(null)

  const uploadFileToServer = async (fileObj) => {
    const imgBase64 = await convertToBase64(fileObj)

    try {
      const options = getRequestOptions()

      options.headers['Content-Type'] = 'text/plain'

      const requestUrl = `${ENV.REACT_APP_API_URL}${POST_CATALOG_IMAGES_ROUTE}`

      const { data: axiosResponse } = await axios.post(
        requestUrl,
        getImageBase64(imgBase64),
        options
      )

      productDispatch({
        productData: {
          image: axiosResponse.data.url,
        },
        type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('UploadPhoto::uploadFileToServer:error', e)
    }
  }

  const validateFile = (file) => {
    const validSize = file.size < fileSizeSupport
    const ext = file.name.split('.')
    const validFormat = ext[ext.length - 1].toLowerCase()

    if (!extensions.includes(validFormat)) {
      handleError(UPLOAD_PHOTO.FORMAT_ERROR)
    } else if (!validSize) {
      handleError(UPLOAD_PHOTO.SIZE_ERROR)
    }

    return validSize && extensions.includes(validFormat)
  }

  const handleChange = async (event) => {
    const { files } = event.target

    handleError('')
    if (!files || files.length === 0) {
      setPreviewPhoto(undefined)
    }

    if (validateFile(files[0])) {
      await uploadFileToServer(files[0])
    } else {
      setPreviewPhoto(undefined)
    }
  }

  return (
    <Box sx={{ marginBottom: `${theme.space[5]}px` }}>
      <Box
        className={styles.container}
        sx={{
          '&.MuiBox-root': {
            border: error && `1px solid ${theme.palette.error.main}`,
          },
          overflow: previewPhoto && 'hidden',
        }}
      >
        <Input
          className={styles.input}
          inputRef={fileImageInput}
          name="file"
          onChange={handleChange}
          type="file"
        />

        {!previewPhoto ? (
          <>
            <FileUpload size={device.isMobile ? 2 : 4} />
            <Button
              className={styles.btnPlus}
              color="orange"
              onClick={() => fileImageInput.current.click()}
              shape="circle"
            >
              <Plus
                color={theme.palette.white.main}
                size={!device.isMobile ? 2 : 1.2}
              />
            </Button>
          </>
        ) : (
          <>
            <Avatar
              alt={productData?.product_name}
              onClick={() => {
                fileImageInput.current.click()
              }}
              src={previewPhoto}
              sx={{ cursor: 'pointer', height: '100%', width: '100%' }}
              variant="rounded"
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default UploadPhoto

UploadPhoto.propTypes = {
  error: PropTypes.bool,
  handleError: PropTypes.func,
}

UploadPhoto.defaultProps = {
  error: false,
  handleError: () => {},
}
