import { TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& input': {
    color: theme.palette.black.main,
    fontSize: 14,
    fontWeight: theme.fontWeight.semibold,
    lineHeight: '20px',
  },
  '& label, & input::placeholder': {
    color: theme.palette.text.primary,
    fontWeight: theme.fontWeight.semibold,
  },
  '.MuiFilledInput-root': {
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: `${theme.space[2]}px`,
  },
  '.MuiFilledInput-root.Mui-error': {
    border: `1px solid ${theme.palette.error.main}`,
  },
  '.MuiFilledInput-root.Mui-focused': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '.MuiFilledInput-root.Mui-focused fieldset': {
    border: `1px solid ${theme.backgrounds[2]}`,
  },
  '.MuiFilledInput-root.Mui-focused, .MuiFilledInput-root:hover': {
    backgroundColor: theme.palette.white.main,
  },
  '.MuiFilledInput-root.Mui-focused.Mui-disabled, .MuiFilledInput-root.Mui-disabled':
    {
      backgroundColor: theme.backgrounds[0],
    },
  '.MuiFilledInput-root:before, .MuiFilledInput-root:after, .MuiFilledInput-root.Mui-focused:before, .MuiFilledInput-root:hover:not(.Mui-disabled):before, .MuiFilledInput-root.Mui-disabled:before':
    {
      borderBottom: 'none',
    },
  '.MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
}))

export default TextField
