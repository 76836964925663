import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  numberFormat: {
    '&:focus': {
      outline: 0,
    },
    border: 'none',
    fontFamily: 'Averta',
    fontSize: 16,
    fontWeight: theme.fontWeight.semibold,
    textAlign: 'right',
    width: '70%',
  },
  wait: {
    alignItems: 'center',
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: theme.borderRadius[0],
    display: 'flex',
    height: 56,
    margin: `${theme.space[4]}px 0`,
    padding: '8px 16px',
  },
}))

export default useStyles
