import {
  STORE_ROUTE,
  SALES_ROUTE,
  REPORTS_ROUTE,
  PROMOTIONS_ROUTE,
  DESIGN_ROUTE,
  SETTINGS_ROUTE,
  // TUTORIALS_ROUTE,
  HELP_ROUTE,
  CATALOG,
} from 'constants/routes'
import {
  Bill,
  Chart,
  EditPen,
  ShoppingBag,
  ShopStore,
  Settings,
  // Faq,
  Headset,
  Discounts,
} from '../Icons'

const menuItems = [
  {
    icon: ShopStore,
    text: 'Resumen',
    url: STORE_ROUTE,
  },
  {
    icon: Bill,
    text: 'Ventas',
    url: SALES_ROUTE,
  },
  {
    icon: ShoppingBag,
    replace: true,
    text: 'Catálogo',
    url: CATALOG,
  },
  {
    icon: Chart,
    text: 'Reportes',
    url: REPORTS_ROUTE,
  },
  {
    icon: Discounts,
    text: 'Promociona',
    url: PROMOTIONS_ROUTE,
  },
  {
    icon: EditPen,
    text: 'Diseño',
    url: DESIGN_ROUTE,
  },
  {
    icon: Settings,
    text: 'Configuración',
    url: SETTINGS_ROUTE,
  },
  /*
  {
    icon: Faq,
    text: 'Tutoriales',
    url: TUTORIALS_ROUTE,
  },
  */
  {
    icon: Headset,
    text: 'Ayuda',
    url: HELP_ROUTE,
  },
]

export default menuItems
