import React, { createContext, useContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import useMerchantInfo from 'hooks/useMerchantInfo'
import useStoreConfig from 'hooks/useStoreConfig'
import useUserInfo from 'hooks/useUserInfo'
import { getToken, clearCookie, TOKEN_NAME } from 'helpers/tokenHelpers'
import { LOGIN, ONBOARDING_ROUTE } from 'constants/routes'
import reducer from './reducer'
import UserActions from './actions'

const DispatchContext = createContext()
const StateContext = createContext()

const useAuthState = () => {
  const context = useContext(StateContext)

  if (context === undefined) {
    throw new Error(
      'useAuthState:: useStateContext must be used within a provider'
    )
  }

  return context
}

const useConfigDispatch = () => {
  const context = useContext(DispatchContext)

  if (context === undefined) {
    throw new Error(
      'useSearchDispatch:: useDispatchContext must be used within a provider'
    )
  }

  return context
}

const AuthProvider = ({ children, defaultState }) => {
  const authToken = getToken()

  const { userInfo, error: userInfoError } =
    defaultState || useUserInfo(authToken)
  const { merchantInfo } = defaultState || useMerchantInfo(authToken)
  const merchantToken = merchantInfo?.proxy_id?.proxy_merchant_token
  const { storeConfig } = defaultState || useStoreConfig(merchantToken)
  const [state, dispatch] = useReducer(reducer, null)

  useEffect(() => {
    if (!state && storeConfig && !storeConfig.error) {
      dispatch({
        payload: storeConfig,
        type: UserActions.UPDATE_STORE_CONFIG,
      })
    }
  }, [storeConfig])

  if (authToken) {
    if (userInfoError) {
      clearCookie(TOKEN_NAME)
    }
  } else if (window.location.pathname !== LOGIN) {
    window.location.href = LOGIN
  }

  if (!userInfo || !merchantInfo || !storeConfig) {
    return <Loader />
  }

  if (
    storeConfig &&
    storeConfig.error &&
    storeConfig.message === 'item not found' &&
    window.location.pathname !== ONBOARDING_ROUTE
  ) {
    window.location.href = ONBOARDING_ROUTE

    return null
  }

  return (
    <StateContext.Provider
      value={{
        merchantInfo,
        merchantToken,
        storeConfig: defaultState || state,
        userInfo,
      }}
    >
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

AuthProvider.defaultProps = {
  defaultState: null,
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  defaultState: PropTypes.shape({
    merchantInfo: PropTypes.shape(),
    merchantToken: PropTypes.string,
    storeConfig: PropTypes.shape(),
    userInfo: PropTypes.shape(),
  }),
}

export { AuthProvider, useAuthState, useConfigDispatch }
