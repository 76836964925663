import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    '& .MuiCardActionArea-root': {
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'transparent',
      },
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& a': {
      textDecoration: 'none',
    },
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: theme.borderRadius[0],
    height: 164,
    marginRight: 0,
    padding: `${theme.space[1]}px`,
    width: '40vw',
    [theme.breakpoints.down('lg')]: {
      width: '36vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '48%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.space[6]}px`,
      width: '45%',
    },
  },
  description: {
    fontWeight: theme.fontWeight.regular,
    marginBottom: `${theme.space[5]}px`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
}))

export default useStyles
