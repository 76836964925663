import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Settings = ({ color }) => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M19.8182 13.7971L19.8671 13.7848C20.5351 13.6178 21.0037 13.0176 21.0037 12.329V11.6706C21.0037 10.982 20.5351 10.3818 19.8671 10.2147L19.8181 10.2025C19.3088 10.0749 18.8901 9.71353 18.6893 9.22842C18.4885 8.7433 18.5294 8.19175 18.7996 7.74155L18.8202 7.70722C19.1789 7.11577 19.0878 6.35583 18.5993 5.86603L18.1337 5.40042C17.6468 4.91351 16.891 4.82046 16.3005 5.17471L16.2572 5.20072C15.807 5.4708 15.2555 5.51161 14.7705 5.31071C14.2855 5.10982 13.9244 4.69099 13.7971 4.18171L13.7848 4.13282C13.6178 3.46481 13.0176 2.99622 12.329 2.99622H11.6705C10.982 2.99622 10.3817 3.46483 10.2147 4.13284L10.2024 4.18186C10.0748 4.69115 9.71352 5.10987 9.22841 5.31066C8.74329 5.51144 8.19173 5.47055 7.74153 5.2004L7.70718 5.17981C7.11574 4.82101 6.35579 4.91217 5.866 5.40068L5.4004 5.86628C4.91347 6.3531 4.82038 7.10884 5.1746 7.69927L5.20022 7.74199C5.47037 8.19219 5.51128 8.74375 5.3105 9.22887C5.10972 9.71399 4.691 10.0753 4.18171 10.2029L4.13285 10.2152C3.46486 10.3822 2.99622 10.9824 2.99622 11.6709V12.3295C2.99622 13.0179 3.4646 13.6181 4.13248 13.7851L4.18102 13.7972C4.69038 13.9245 5.10931 14.2856 5.31037 14.7706C5.51143 15.2556 5.47085 15.8072 5.20096 16.2576L5.17502 16.3008C4.82078 16.8912 4.91382 17.647 5.40068 18.1339L5.86633 18.5995C6.35304 19.0864 7.10868 19.1795 7.69903 18.8254L7.74194 18.7996C8.19214 18.5295 8.7437 18.4885 9.22883 18.6893C9.71395 18.8901 10.0753 19.3088 10.2029 19.8181L10.2151 19.867C10.3821 20.535 10.9823 21.0037 11.6709 21.0037H12.3294C13.0179 21.0037 13.618 20.5353 13.785 19.8674L13.7972 19.8189C13.9245 19.3095 14.2856 18.8906 14.7706 18.6895C15.2556 18.4884 15.8072 18.5291 16.2575 18.799L16.3007 18.8249C16.8912 19.1791 17.6469 19.0861 18.1338 18.5992L18.5995 18.1336C19.0864 17.6469 19.1795 16.8912 18.8253 16.3009L18.7996 16.258C18.5295 15.8078 18.4886 15.2562 18.6894 14.7711C18.8902 14.286 19.3089 13.9247 19.8182 13.7971Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle
        cx="11.9998"
        cy="12.0003"
        r="2.07779"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Settings

Settings.propTypes = {
  color: PropTypes.string,
}

Settings.defaultProps = {
  color: theme.palette.text.primary,
}
