import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import LinkWithChain from 'components/LinkWithChain'
import SocialMedia from 'components/SocialMedia'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import recordAppsFlyerEvent from 'helpers/appsflyer'
import APPSFLYER_EVENTS from 'constants/appsflyer'

const { success: PM_SUCCESS } = require('./constData.json')

const Success = ({
  merchantName,
  merchantSlug,
  merchantUrl,
  merchantToken,
}) => {
  const { device } = useGlobalContext()
  const theme = useTheme()

  useEffect(() => {
    cleverTap.event(EVENTS.PUBLISH_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
    recordAppsFlyerEvent(APPSFLYER_EVENTS.published, {
      merchant_id: merchantToken,
    })
  }, [])

  return (
    <>
      <Typography component="p" sx={{ mt: `${theme.space[5]}px` }} variant="h3">
        {PM_SUCCESS.DESCRIPTION}
      </Typography>
      <Divider
        light
        sx={{
          borderColor: theme.backgrounds[1],
          mb: `${theme.space[4]}px`,
          mt: `${theme.space[5]}px`,
        }}
      />
      <LinkWithChain
        href={merchantUrl}
        label={PM_SUCCESS.LABEL_LINK}
        text={`${PM_SUCCESS.MY_STORE_NAME}/${merchantSlug}`}
      />

      <SocialMedia
        cleverTapEnable
        fbShare={merchantUrl}
        storeLink={merchantUrl}
        title={PM_SUCCESS.LABEL_SHARE}
        whatsappText={merchantName}
      />
      <Divider
        sx={{
          borderColor: theme.backgrounds[1],
          mt: `${theme.space[4]}px`,
        }}
        variant="middle"
      />
    </>
  )
}

export default Success

Success.propTypes = {
  merchantName: PropTypes.string.isRequired,
  merchantSlug: PropTypes.string.isRequired,
  merchantUrl: PropTypes.string.isRequired,
}
