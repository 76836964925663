import _ from 'lodash'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import Chip from '@mui/material/Chip'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import TextField from 'components/TextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Switch from 'components/Switch'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { onlyNumber } from 'utils/form'
import { Plus, Trash, EditPen, Warning } from 'components/Icons'
import { useTheme } from '@mui/material/styles'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import dashboardSettings from 'constants/dashboardSettings'
import { ENV, GET_CONFIG_ROUTE, HELP_ROUTE } from 'constants/routes'
import { PENDING } from 'constants/page'
import generateKey from 'utils/math'
import { updateInfo } from 'helpers/fetchUtil'
import { useAuthState, useConfigDispatch } from 'store/user'
import UserActions from 'store/user/actions'
import useStylesChip from 'components/StoreWelcome/styles'
import StatusMessages from 'components/StatusMessages'
import SubmitButton from 'components/Settings/SubmitButton'
import SETTINGS from 'components/Settings/constData.json'

import { ShipmentModal } from 'components/Modal'

import useStyles from './styles'

const Shipments = () => {
  const theme = useTheme()
  const classes = useStyles()
  const chipStyle = useStylesChip()
  const { device } = useGlobalContext()

  const { storeConfig } = useAuthState()

  const [pickUp, setPickUp] = useState(false)
  const [nearByDelivery, setNearByDelivery] = useState(false)
  const [countryDelivery, setCountryDelivery] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editObject, setEditObject] = useState(null)
  const [codesSelected, setCodesSelected] = useState([])
  const [waitTime, setWaitTime] = useState(null)
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [waitTimeUnit, setWaitTimeUnit] = useState(SETTINGS.MIN)
  const [deliveryTimeUnit, setDeliveryTimeUnit] = useState(SETTINGS.HRS)
  const [loading, setLoading] = useState(false)
  const dispatch = useConfigDispatch()

  useEffect(() => {
    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.delivery_settings &&
      storeConfig.store_settings.delivery_settings.pick_up
    ) {
      setPickUp(storeConfig.store_settings.delivery_settings.pick_up)
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.delivery_settings &&
      storeConfig.store_settings.delivery_settings.near_by_delivery
    ) {
      setNearByDelivery(
        storeConfig.store_settings.delivery_settings.near_by_delivery
      )
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.delivery_settings &&
      storeConfig.store_settings.delivery_settings.full_delivery
    ) {
      setCountryDelivery(
        storeConfig.store_settings.delivery_settings.full_delivery
      )
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.max_near_delivery_time
    ) {
      setDeliveryTime(storeConfig.store_settings.max_near_delivery_time.value)
      setDeliveryTimeUnit(
        storeConfig.store_settings.max_near_delivery_time.unit
      )
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.min_preparation_time
    ) {
      setWaitTime(storeConfig.store_settings.min_preparation_time.value)
      setWaitTimeUnit(storeConfig.store_settings.min_preparation_time.unit)
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.delivery_pricing
    ) {
      setCodesSelected(
        storeConfig.store_settings.delivery_pricing.map((item) => ({
          price: `${item.price.toString()}`,
          zipCodes: item.zipCodes,
        }))
      )
    }
  }, [storeConfig, expandedPanel])

  const switchChange = (e) => {
    switch (e.target.name) {
      case 'store':
        setPickUp(!pickUp)
        setWaitTime(0)
        setWaitTimeUnit(SETTINGS.MIN)

        if (!pickUp) {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.activar_retiro_tienda,
            Device: device.type,
          })
        } else {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.desactivar_retiro_tienda,
            Device: device.type,
          })
        }
        break
      case 'shipment':
        setNearByDelivery(!nearByDelivery)
        setDeliveryTime(0)
        setDeliveryTimeUnit(SETTINGS.MIN)

        if (!nearByDelivery) {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.activar_envios_cercanos,
            Device: device.type,
          })
        } else {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.desactivar_envios_cercanos,
            Device: device.type,
          })
        }
        break
      case 'country':
        setCountryDelivery(!countryDelivery)

        if (!countryDelivery) {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.activar_envios_pais,
            Device: device.type,
          })
        } else {
          cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
            Click: PV.desactivar_envios_pais,
            Device: device.type,
          })
        }
        break
      default:
        break
    }
  }

  const handleSubmit = () => {
    setLoading(true)

    const payload = {
      delivery_settings: {
        full_delivery: countryDelivery,
        near_by_delivery: nearByDelivery,
        pick_up: pickUp,
      },
    }

    if (nearByDelivery) {
      payload.max_near_delivery_time = {
        unit: deliveryTimeUnit,
        value: parseInt(deliveryTime, 10),
      }
      payload.delivery_pricing = []
    }

    if (pickUp) {
      payload.min_preparation_time = {
        unit: waitTimeUnit,
        value: parseInt(waitTime, 10),
      }
    }

    if (codesSelected.length > 0) {
      payload.delivery_pricing = codesSelected.map((item) => ({
        price: parseFloat(item.price),
        zipCodes: item.zipCodes,
      }))
    }

    const storeSettings = {
      store_settings: {
        ...storeConfig.store_settings,
        ...payload,
      },
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, storeSettings)
      .then(() => {
        setSubmitSuccess(true)
        dispatch({
          payload: {
            ...storeConfig,
            store_settings: { ...storeSettings.store_settings },
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
        setLoading(false)
      })
      .catch(() => {
        setSubmitError(true)
        setLoading(false)
      })
  }

  const handleModal = (val) => {
    if (!val) {
      setEditObject(null)
    }
    setShowModal(val)
  }

  const handleEdit = (ele, pos) => {
    setEditObject({ pos, price: ele.price, zipCodes: ele.zipCodes })
    setShowModal(!showModal)
  }

  const handleDelete = (index) => {
    const arrayCleaned = _.filter(codesSelected, (item, i) => i !== index)

    setCodesSelected(arrayCleaned)
  }

  const handleCancel = () => {
    setExpandedPanel((prev) => !prev)
    setSubmitError(false)

    if (expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.cancel,
        Device: device.type,
      })
    }
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.envios,
        Device: device.type,
      })
    }
  }

  const handleBlurDeliveryTime = () => {
    cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
      Click: PV.tiempo_maximo_envio,
      Device: device.type,
    })
  }

  const handleBlurWaitTime = () => {
    cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
      Click: PV.tiempo_preparacion,
      Device: device.type,
    })
  }

  const handleChangeTime = (e) => {
    if (e.target.value.length >= 3) {
      e.preventDefault()
    }
    if (onlyNumber(e.key)) {
      e.preventDefault()
    }
  }

  const isShipmentsComplete = (s) => {
    if (s.store_settings && s.store_settings?.delivery_settings) return true

    return false
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  const AddButton = () => (
    <Button
      onClick={() => handleModal(true)}
      size="small"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        mb: { sm: `${theme.space[4]}px`, xs: `${theme.space[2]}px` },
        mt: `${theme.space[4]}px`,
        width: { sm: 'auto', xs: '100%' },
      }}
    >
      <Plus size={1.4} />
      {SETTINGS.BUTTON_ADD_POSTAL_CODE}
    </Button>
  )

  const SwitchEl = (flag, name, label) => (
    <FormControlLabel
      control={
        <Switch
          checked={flag}
          inputProps={{ 'data-testid': label }}
          name={name}
          onChange={(e) => switchChange(e)}
          sx={{
            margin: `${theme.space[2]}px ${theme.space[3]}px`,
          }}
        />
      }
      label={label}
      sx={{ display: 'block' }}
    />
  )

  const RenderButtons = (elem, pos) => (
    <>
      <IconButton
        onClick={() => handleDelete(pos)}
        sx={{ height: 40, width: 40 }}
      >
        <Trash color={theme.palette.text.primary} />
      </IconButton>

      <Divider
        light
        orientation="vertical"
        sx={{ borderColor: theme.backgrounds[1], height: 20 }}
      />
      <IconButton
        onClick={() => handleEdit(elem, pos)}
        sx={{ height: 40, width: 40 }}
      >
        <EditPen />
      </IconButton>
    </>
  )

  const RenderPrice = (price) => (
    <Typography fontWeight={theme.fontWeight.bold} variant="body1">
      <NumberFormat
        allowEmptyFormatting
        decimalScale="2"
        decimalSeparator="."
        displayType="text"
        fixedDecimalScale
        prefix="$"
        thousandSeparator=","
        thousandsGroupStyle="thousand"
        type="text"
        value={price}
      />
    </Typography>
  )

  const TableDesktop = () =>
    codesSelected.map((el, j) => (
      <Grid
        key={generateKey(j)}
        container
        sx={{
          borderBottom: `1px solid ${theme.backgrounds[1]}`,
          padding: '15px',
        }}
      >
        <Grid item sm={5}>
          {el.zipCodes.map((obj, i) => (
            <Typography
              key={generateKey(j + i)}
              component="p"
              fontWeight={theme.fontWeight.regular}
              variant="body1"
            >
              <b>{obj.zipCode}</b>
              {`- ${obj.colonyName}`}
            </Typography>
          ))}
        </Grid>
        <Grid item sm={6} sx={{ alignItems: 'center', display: 'flex' }}>
          {RenderPrice(el.price)}
        </Grid>
        <Grid
          item
          sm={1}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {RenderButtons(el, j)}
        </Grid>
      </Grid>
    ))

  const TableMobile = () =>
    codesSelected.map((el, j) => (
      <Grid
        key={generateKey(j)}
        container
        sx={{
          borderBottom: `1px solid ${theme.backgrounds[1]}`,
          padding: '8px 0',
        }}
      >
        <Grid item xs={9}>
          <Stack>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              variant="body1"
            >
              {SETTINGS.POSTAL_CODE}
            </Typography>
            {el.zipCodes.map((obj, i) => (
              <Typography
                key={generateKey(j + i)}
                fontWeight={theme.fontWeight.regular}
                variant="body1"
              >
                <b>{obj.zipCode}</b>
                {`- ${obj.colonyName}`}
              </Typography>
            ))}

            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ pt: `${theme.space[1]}px` }}
              variant="body1"
            >
              {SETTINGS.SHIPPING_COST}
            </Typography>

            {RenderPrice(el.price)}
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          xs={3}
        >
          {RenderButtons(el, j)}
        </Grid>
      </Grid>
    ))

  const deliveryTimeUnitIcons = () => {
    const handleSetUnit = (unit) => {
      setDeliveryTimeUnit(unit)
      setDeliveryTime(0)
    }

    return (
      <Stack direction="row">
        <Button
          className={`${classes.timeUnitButton} ${
            deliveryTimeUnit === SETTINGS.MIN
              ? classes.timeUnitButtonSelected
              : classes.timeUnitButtonUnselected
          }`}
          onClick={() => handleSetUnit(SETTINGS.MIN)}
        >
          <Typography variant="h4">{SETTINGS.MIN}</Typography>
        </Button>
        <Button
          className={`${classes.timeUnitButton} ${
            deliveryTimeUnit !== SETTINGS.MIN
              ? classes.timeUnitButtonSelected
              : classes.timeUnitButtonUnselected
          }`}
          onClick={() => handleSetUnit(SETTINGS.HRS)}
        >
          <Typography variant="h4">{SETTINGS.HRS}</Typography>
        </Button>
      </Stack>
    )
  }

  const waitTimeUnitIcons = () => {
    const handleSetUnit = (unit) => {
      setWaitTimeUnit(unit)
      setWaitTime(0)
    }

    return (
      <Stack direction="row">
        <Button
          className={`${classes.timeUnitButton} ${
            waitTimeUnit === SETTINGS.MIN
              ? classes.timeUnitButtonSelected
              : classes.timeUnitButtonUnselected
          }`}
          onClick={() => handleSetUnit(SETTINGS.MIN)}
        >
          <Typography variant="h4">{SETTINGS.MIN}</Typography>
        </Button>
        <Button
          className={`${classes.timeUnitButton} ${
            waitTimeUnit !== SETTINGS.MIN
              ? classes.timeUnitButtonSelected
              : classes.timeUnitButtonUnselected
          }`}
          onClick={() => handleSetUnit(SETTINGS.HRS)}
        >
          <Typography variant="h4">{SETTINGS.HRS}</Typography>
        </Button>
      </Stack>
    )
  }

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => handleCancel()}
      >
        <Typography align="center" variant="h2">
          {SETTINGS.SHIPMENTS}
        </Typography>
        {storeConfig && !isShipmentsComplete(storeConfig) && (
          <Chip
            className={clsx(chipStyle.chip, chipStyle.pending)}
            icon={<Warning color={theme.palette.warning.main} />}
            label={PENDING}
            sx={{
              mt: { sm: `${theme.space[1]}px`, xs: `${theme.space[2]}px` },
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ marginBottom: '40px' }}>
          <Typography fontWeight={theme.fontWeight.regular} variant="h4">
            {SETTINGS.SHIPMENTS_DETAIL}
          </Typography>
          <Link
            color={theme.palette.primary.main}
            href={HELP_ROUTE}
            rel="noopener"
            target="_blank"
          >
            {SETTINGS.MORE_INFORMATION}
          </Link>
        </Box>
        {SwitchEl(pickUp, 'store', SETTINGS.PICK_UP_IN_STORE)}
        {pickUp && (
          <Stack sx={{ paddingBottom: `${theme.space[4]}px` }}>
            <Typography
              fontWeight={theme.fontWeight.regular}
              sx={{ margin: '16px 0 24px 0' }}
              variant="body1"
            >
              {SETTINGS.PICK_UP_IN_STORE_HINT}
            </Typography>
            <TextField
              fullWidth
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontWeight: theme.fontWeight.semibold,
                },
              }}
              inputProps={{
                'data-testid': 'wait-time',
                endAdornment: waitTimeUnitIcons(),
              }}
              label={SETTINGS.WAIT_TEXT}
              onBlur={handleBlurWaitTime}
              onChange={(e) => setWaitTime(e.target.value)}
              onKeyPress={handleChangeTime}
              sx={{ maxWidth: 394, paddingRight: '2px' }}
              value={waitTime}
              variant="filled"
            />
          </Stack>
        )}
        {dashboardSettings.SHOW_LOCAL_SHIPPING_TOGGLE && (
          <Stack className={classes.shipmentContainer} direction="row">
            {SwitchEl(nearByDelivery, 'shipment', SETTINGS.CLOSE_SHIPMENTS)}
            {nearByDelivery && (
              <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                {AddButton()}
              </Box>
            )}
          </Stack>
        )}
        {nearByDelivery && (
          <Box>
            <TextField
              fullWidth
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontWeight: theme.fontWeight.semibold,
                },
              }}
              InputProps={{
                'data-testid': 'delivery-time',
                endAdornment: deliveryTimeUnitIcons(),
              }}
              label={SETTINGS.DELIVERY_TIME}
              onBlur={handleBlurDeliveryTime}
              onChange={(e) => setDeliveryTime(e.target.value)}
              onKeyPress={handleChangeTime}
              sx={{ maxWidth: 394, paddingRight: '2px' }}
              value={deliveryTime}
              variant="filled"
            />
            <Grid
              container
              sx={{
                alignItems: 'center',
                borderBottom: `1px solid ${theme.backgrounds[1]}`,
                display: {
                  sm: 'flex',
                  xs: 'none',
                },
                height: 50,
                marginTop: `${theme.space[5]}px`,
              }}
            >
              <Grid item sm={5} xs={10}>
                <Typography
                  color="text.primary"
                  fontWeight={theme.fontWeight.regular}
                  sx={{ paddingLeft: '15px' }}
                  variant="body1"
                >
                  {SETTINGS.POSTAL_CODE}
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <Typography
                  color="text.primary"
                  fontWeight={theme.fontWeight.regular}
                  variant="body1"
                >
                  {SETTINGS.SHIPPING_COST}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                &nbsp;
              </Grid>
            </Grid>
            {!codesSelected.length && (
              <Box
                sx={{
                  clear: 'both',
                  padding: {
                    sm: '15px 0',
                    xs: 0,
                  },
                  textAlign: 'left',
                }}
              >
                <Typography
                  color={theme.palette.text.disabled}
                  fontWeight={theme.fontWeight.regular}
                  variant="body1"
                >
                  {SETTINGS.EMPTY_POSTAL_CODE}
                </Typography>
              </Box>
            )}
            {device.isMobile ? (
              <Box
                sx={{
                  borderTop: `1px solid ${theme.backgrounds[1]}`,
                  mt: `${theme.space[5]}px`,
                }}
              >
                <TableMobile />
              </Box>
            ) : (
              <TableDesktop />
            )}
            <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
              {AddButton()}
            </Box>
          </Box>
        )}
        <Stack className={classes.shipmentContainer} direction="row">
          {SwitchEl(countryDelivery, 'country', SETTINGS.COUNTRY_DELIVERY)}
        </Stack>
        {countryDelivery && (
          <Stack sx={{ paddingBottom: `${theme.space[4]}px` }}>
            <Typography
              fontWeight={theme.fontWeight.regular}
              sx={{ margin: '16px 0 24px 0' }}
              variant="body1"
            >
              {SETTINGS.COUNTRY_DELIVERY_DESC}
            </Typography>
          </Stack>
        )}

        <SubmitButton
          buttonCancelText={SETTINGS.CANCEL_BUTTON}
          buttonSubmitText={SETTINGS.SAVE_BUTTON}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          loading={loading}
        />
        <StatusMessages
          errorFlag={submitError}
          errorMessage={SETTINGS.ERROR_MESSAGE}
          handleClose={() => handleClose()}
          successFlag={submitSuccess}
          successMessage={SETTINGS.SUCCESS_MESSAGE}
        />
      </AccordionDetails>
      <ShipmentModal
        codesSelected={codesSelected}
        editObject={editObject}
        handleModal={handleModal}
        setCodesSelected={setCodesSelected}
        showModal={showModal}
      />
    </Accordion>
  )
}

export default Shipments
