import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import MuiButton from './muiButton'
import MuiLoadingButton from './muiLoadingButton'
import MuiCssBaseline from './muiCssBaseline'
import MuiTypography from './muiTypography'
import MuiTablePagination from './muiTablePagination'
import MuiChip from './muiChip'
import MuiAlert from './muiAlert'
import { MuiFormLabel, MuiFormControlLabel } from './muiForms'

const theme = createTheme({
  backgrounds: [
    '#FAFBFB',
    '#F6F6F7',
    '#E8E9EB',
    '#FDF8F6',
    '#EDF8F9',
    '#EFF3FD',
    'rgba(196, 196, 196, 0.5)',
  ],
  borderRadius: [8, 12, 24, 50],
  components: {
    // Name of the component
    MuiButton: {},
    MuiChip: {},
    MuiCssBaseline: MuiCssBaseline(),
    MuiLoadingButton: {},
    MuiTypography: {},
    name: 'Merchant Dashboard',
  },
  fontWeight: {
    bold: 700,
    regular: 400,
    semibold: 600,
  },
  palette: {
    black: {
      main: '#181A36',
    },
    divider: '#D1D1D7',
    error: {
      light: '#FDE6E6',
      main: '#D32121',
    },
    info: {
      dark: '#00A0AD',
      light: '#E4F4F6',
      main: '#017B89',
    },
    primary: {
      dark: '#006470',
      light: '#346CE4',
      main: '#FC4C02',
      strong: '#F53B02',
    },
    secondary: {
      light: '#FCF1ED',
      main: '#8A8B99',
    },
    shadows: {
      btnSwitch: 'rgba(24, 26, 54, 0.12)',
      button: 'rgba(252, 76, 2, 0.2)',
      card: 'rgba(24, 26, 54, 0.04)',
      overlay: 'rgba(24, 26, 54, 0.4)',
    },
    success: {
      light: '#DEF5EB',
      main: '#177850',
    },
    text: {
      disabled: '#BABBC3',
      light: '#2C2E47',
      primary: '#5E5F73',
      secondary: '#8A8B99',
    },
    warning: {
      dark: '#FFB023',
      light: '#FDF6EA',
      main: '#A06000',
    },
    white: {
      main: '#FFF',
    },
  },
  space: [0, 4, 8, 12, 16, 24, 32],
  typography: {
    body1: {
      fontSize: 14,
      lineHeight: 20 / 14,
    },
    body2: {
      fontSize: 12,
      lineHeight: 18 / 12,
    },
    fontFamily: 'Averta',
    h1: {
      fontSize: 24,
      lineHeight: 36 / 24,
    },
    h2: {
      fontSize: 20,
      lineHeight: 30 / 20,
    },
    h3: {
      fontSize: 18,
      lineHeight: 28 / 18,
    },
    h4: {
      fontSize: 16,
      lineHeight: 24 / 16,
    },
    hxl: {
      fontSize: 28,
      lineHeight: 40 / 28,
    },
    hxxl: {
      fontSize: 32,
      lineHeight: 48 / 32,
    },
  },
})

theme.components.MuiButton = MuiButton(theme)
theme.components.MuiLoadingButton = MuiLoadingButton(theme)
theme.components.MuiTypography = MuiTypography(theme)
theme.components.MuiTablePagination = MuiTablePagination(theme)
theme.components.MuiChip = MuiChip(theme)
theme.components.MuiAlert = MuiAlert(theme)
theme.components.MuiFormLabel = MuiFormLabel(theme)
theme.components.MuiFormControlLabel = MuiFormControlLabel(theme)

export default responsiveFontSizes(theme)
