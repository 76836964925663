import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import TextField from 'components/TextField'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { ErrorIcon } from 'components/Icons'
import Loader from 'components/Loader'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import useStylesNumberFormat from 'components/Catalog-v2/product/styles'
import {
  DiscountInput,
  WrapDateRange,
  WrapCheckbox,
  HeadGrid,
  TotalCodes,
  CouponCode,
} from 'components/Coupon'
import useStyles from 'components/Coupon/styles'

const {
  product: NUMBER_FORMAT,
} = require('components/Catalog-v2/product/constData.json')
const {
  coupon: COUPON,
  discount_counts: DISCOUNT,
} = require('components/Coupon/constData.json')

const CouponEdit = ({ error, loading, mode }) => {
  const theme = useTheme()
  const { couponData, couponDispatch } = useCouponContext()
  const classes = useStyles()
  const styles = useStylesNumberFormat()

  useEffect(() => {
    if (mode === COUPON.MODE.CREATE) {
      couponDispatch({
        couponData: {
          discount_type: COUPON.DISCOUNT_TYPE[0],
          minimun_purchase: NUMBER_FORMAT.PRICE,
        },
        type: COUPON_ACTIONS.UPDATE_COUPON,
      })
    }
  }, [])

  const handleChange = (event) => {
    couponDispatch({
      couponData: {
        [event.target.name]: event.target.value
          .replace('$', '')
          .replace(',', ''),
      },
      type: COUPON_ACTIONS.UPDATE_COUPON,
    })
  }

  const validateDiscount = () => {
    if (!couponData.total_discount) {
      return false
    }

    if (
      couponData.discount_type === COUPON.DISCOUNT_TYPE[0] &&
      couponData.total_discount === COUPON.PRICE
    ) {
      return false
    }

    if (
      couponData.discount_type === COUPON.DISCOUNT_TYPE[1] &&
      couponData.total_discount === NUMBER_FORMAT.PRICE
    ) {
      return false
    }

    return true
  }

  const validateTotalCodes = () => {
    const { redeemed } = couponData

    if (!couponData.total_to_be_used) {
      return false
    }

    if (
      redeemed !== 'undefined' &&
      redeemed >= parseInt(couponData.total_to_be_used, 10)
    ) {
      return false
    }

    return true
  }

  const validateMinPurchase = () => {
    let { minimun_purchase: minPurchase, total_discount: totalDiscount } =
      couponData

    const { discount_type: discountType } = couponData

    minPurchase =
      typeof minPurchase === 'string'
        ? minPurchase?.replace(',', '')
        : minPurchase

    totalDiscount =
      typeof totalDiscount === 'string'
        ? totalDiscount?.replace(',', '')
        : totalDiscount

    if (
      discountType === COUPON.DISCOUNT_TYPE[1] &&
      parseFloat(minPurchase) < parseFloat(totalDiscount)
    ) {
      return false
    }

    return true
  }

  const getErrorMessage = (field) => {
    switch (field) {
      case 'coupon_code':
        return COUPON.COUPON_CODE
      case 'total_to_be_used':
        return !couponData.total_to_be_used
          ? COUPON.ERROR
          : `${COUPON.ERROR_MESSAGE.TOTAL_CODES}(${couponData.redeemed})`
      default:
        return false
    }
  }

  const hasError = (field) => {
    if (!error) return false

    switch (field) {
      case 'name':
        return !couponData.name
      case 'coupon_code':
        return !couponData.coupon_code
      case 'total_to_be_used':
        return !validateTotalCodes()
      case 'total_discount':
        return !validateDiscount()
      case 'date_range':
        return !couponData.start_date || !couponData.end_date
      case 'minimun_purchase':
        return !validateMinPurchase()
      default:
        return false
    }
  }

  const renderLoader = () => (
    <Stack
      sx={{
        left: { sm: '100%', xs: '50%' },
        ml: '-44px',
        position: 'absolute',
        top: '50%',
      }}
    >
      <Loader />
    </Stack>
  )

  // nombre de la promoción
  const couponName = () => (
    <TextField
      disabled={mode === COUPON.MODE.EDIT}
      error={hasError('name')}
      fullWidth
      helperText={hasError('name') && COUPON.ERROR}
      InputProps={{
        endAdornment: hasError('name') && <ErrorIcon />,
      }}
      label={COUPON.NAME}
      name="name"
      onChange={handleChange}
      sx={{ mb: `${theme.space[5]}px` }}
      value={couponData?.name || ''}
      variant="filled"
    />
  )

  // minimo de compra
  const minPurcharse = () => (
    <>
      <Typography
        fontWeight={theme.fontWeight.semibold}
        sx={{ mb: `${theme.space[4]}px` }}
        variant="body2"
      >
        {COUPON.MIN_PURCHASE}
      </Typography>
      <NumberFormat
        {...NUMBER_FORMAT.PRICE_OPTIONS}
        className={clsx({
          [styles.numberFormat]: true,
          [classes.minPurcharse]: true,
          [classes.numberFormatError]: hasError('minimun_purchase'),
          [classes.disabled]: mode === COUPON.MODE.EDIT,
        })}
        disabled={mode === COUPON.MODE.EDIT}
        name="minimun_purchase"
        onChange={handleChange}
        onFocus={(elem) => elem.target.select()}
        placeholder={NUMBER_FORMAT.PRICE}
        type="text"
        value={couponData.minimun_purchase}
      />
      <Typography
        align="right"
        className={!validateMinPurchase() && classes.error}
        sx={{ mb: `${theme.space[4]}px` }}
        variant="body2"
      >
        {!validateMinPurchase()
          ? COUPON.ERROR_MESSAGE.MIN_PURCHASE
          : COUPON.OPTIONAL}
      </Typography>
    </>
  )

  return (
    <>
      <HeadGrid />

      <Grid
        container
        sx={{
          '& .MuiFormHelperText-root': { ml: 0 },
          mt: `${theme.space[6]}px`,
        }}
      >
        <Grid
          item
          sm={6}
          sx={{
            borderRight: { sm: `1px solid ${theme.backgrounds[1]}`, xs: 0 },
            paddingRight: { sm: `${4 * theme.space[4]}px`, xs: 0 },
            position: loading && 'relative',
          }}
          xs={12}
        >
          {loading && renderLoader()}
          {couponName()}

          <CouponCode
            disabled={mode === COUPON.MODE.EDIT}
            error={hasError('coupon_code')}
            errorMessage={getErrorMessage('coupon_code')}
          />
          <WrapDateRange
            error={hasError('date_range')}
            isEdit={mode === COUPON.MODE.EDIT}
          />
          <TotalCodes
            disabled={couponData?.status === DISCOUNT.STATUS.OPTIONS[2]} // Finish
            error={hasError('total_to_be_used')}
            errorMessage={getErrorMessage('total_to_be_used')}
          />

          <DiscountInput
            disabled={mode === COUPON.MODE.EDIT}
            error={hasError('total_discount')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{ paddingLeft: { sm: `${4 * theme.space[4]}px`, xs: 0 } }}
          xs={12}
        >
          <Typography
            color="black"
            sx={{
              mb: `${theme.space[5]}px`,
              mt: { sm: 0, xs: `${theme.space[6]}px` },
            }}
            variant="h4"
          >
            {COUPON.ADDITIONAL_INFO}
          </Typography>
          {minPurcharse()}
          <WrapCheckbox />
        </Grid>
      </Grid>
    </>
  )
}

export default CouponEdit

CouponEdit.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf([COUPON.MODE.EDIT, COUPON.MODE.CREATE]),
}

CouponEdit.defaultProps = {
  error: false,
  loading: false,
  mode: COUPON.MODE.CREATE,
}