import React from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { ENV } from 'constants/routes'
import Paper from 'components/Paper'
import { useAuthState } from 'store/user'
import SocialMedia from 'components/SocialMedia'

const InfoScreen = ({ icon, description, socialMedia, title, titleMsg }) => {
  const theme = useTheme()
  const { userInfo: userData, merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo

  const merchantUrl =
    userData && socialMedia
      ? `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`
      : null

  return (
    <Paper
      sx={{
        mt: `${theme.space[5]}px`,
        pl: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
        pr: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
      }}
    >
      <Typography
        fontWeight={theme.fontWeight.bold}
        sx={{ mb: `${theme.space[5]}px` }}
        variant="h2"
      >
        {title}
      </Typography>
      <Stack
        alignItems="center"
        sx={{
          padding: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
        }}
      >
        {React.createElement(icon)}
        <Typography fontWeight={theme.fontWeight.semibold} variant="h3">
          {titleMsg}
        </Typography>
        <Typography
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          variant="body1"
        >
          {description}
        </Typography>

        {socialMedia && (
          <SocialMedia
            fbShare={merchantUrl}
            storeLink={merchantUrl}
            whatsappText={merchantInfo?.name}
          />
        )}
      </Stack>
    </Paper>
  )
}

export default InfoScreen

InfoScreen.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.func.isRequired,
  socialMedia: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleMsg: PropTypes.string.isRequired,
}

InfoScreen.defaultProps = {
  description: '',
  socialMedia: false,
}
