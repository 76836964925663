import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  salesChart: {
    '& canvas': {
      height: '100% !important',
      maxHeight: 380,
      width: '100% !important',
    },
    '& h3': {
      color: theme.palette.black.main,
      fontSize: 20,
      lineHeight: '30px',
    },
    background: theme.palette.white.main,
    borderRadius: 12,
    boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
    margin: '24px 0px',
    minHeight: 234,
    padding: 24,
  },
}))

export default useStyles
