import AccordionFAQ from 'components/AccordionFAQ'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { useAuthState } from 'store/user'
import { useTheme } from '@mui/material'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { ENV, TERMS_AND_CONDITIONS } from 'constants/routes'

const FrequentlyAskedQuestions = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { userInfo: userData, merchantInfo } = useAuthState()

  if (!userData || !merchantInfo) return null

  const { publicProfile } = merchantInfo

  const merchantUrl = publicProfile
    ? `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`
    : ''

  const handleChange = (event) => {
    if (event) {
      cleverTap.event(EVENTS.PREGUNTASFRECUENTES_VIEWED, {
        Click: PV.expandir_pregunta,
        Device: device.type,
        Question: event.target.innerText,
      })
    }
  }

  return (
    <Box sx={{ padding: '16px 0' }}>
      <Box sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}` }}>
        <Typography color="primary" sx={{ margin: '16px 0' }} variant="h4">
          Montar mi tienda
        </Typography>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo crear Mi Tienda Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              ¡Crear tu tienda en línea es muy simple! Si ya vendes con Clip en
              tu tienda solo debes ingresar a tu&nbsp;
              <Link href={`${ENV.REACT_APP_ME_DASHBOARD_URL}`} target="_blank">
                panel de control
              </Link>
              &nbsp;y seleccionar la opción “Tienda en línea”. Una vez ahí te
              guiaremos paso a paso. Si todavía no tienes una cuenta en Clip
              deberás crear una. No necesitas tener conocimientos de diseño o
              programación, ¡crea tu tienda y comienza a vender en línea hoy!
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo publicar productos o servicios en mi tienda Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Para que tus productos o servicios aparezcan en tu tienda en línea
              deberás cargarlos en tu Catálogo Clip. Todos los productos o
              servicios que publiques en tu catálogo se mantendrán sincronizados
              con tu tienda en línea. Puedes ver el paso a paso explicado en
              estos videos:
            </Typography>
            <Grid container pb={2} pt={2} spacing={2}>
              <Grid item sm={6} xs={12}>
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                  height={device.isMobile ? '200px' : '400px'}
                  src="https://www.youtube.com/embed/PzW8-GSVenM"
                  title="YouTube video player"
                  width="100%"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                  height={device.isMobile ? '200px' : '400px'}
                  src="https://www.youtube.com/embed/hdyQGwanJSA"
                  title="YouTube video player"
                  width="100%"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Qué productos o servicios puedo vender en mi tienda en línea?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              No importa cual sea tu negocio, si tienes o no una tienda física,
              o si eres un pequeño comercio, ¡con Mi Tienda Clip puedes vender
              en línea! Puedes publicar cualquier producto o servicio, siempre y
              cuando esté dentro de la ley, y cumpla con los&nbsp;
              <Link href={`${TERMS_AND_CONDITIONS}`} target="_blank">
                términos y condiciones
              </Link>
              &nbsp;de Mi Tienda Clip.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cuántos productos puedo publicar?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Puedes publicar hasta 1000 productos. Aún así te recomendamos
              cargar los productos que mayor rotación tengan para que al cliente
              le sea más sencillo encontrarlos.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo subir la imagen de un producto?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Dentro de Catálogo Clip ve a la imágen actual (o al espacio
              reservado para ella) y te aparecerán dos opciones: la primera,
              para tomarla con tu cámara; la segunda, para escogerla desde tu
              galería.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo modificar un producto?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Hazlo desde tu Catálogo Clip. Ve a la sección “Catálogo”,
              posteriormente, al listado de productos, selecciona el que quieras
              modificar y actualizar su información.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo crear una categoría?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Dentro de la app de Clip ve a “Ajustes”, después a Catálogo, da
              clic en la sección “Categorías” y selecciona el signo de + en la
              parte superior. Listo, ya puedes crear una categoría.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Qué enlace tendrá mi tienda?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              El enlace de todas las tiendas inicia con{' '}
              <Link href={merchantUrl} target="_blank">
                www.mitiendaclip.mx/
              </Link>{' '}
              y le sigue el nombre de cada una con el que fueron registradas. En
              caso de querer modificarlo, debes ingresar al panel de control,
              seleccionar “Configuración”, luego “Información de tu tienda” y,
              por último, modificar el campo enlace para ponerle el nombre de tu
              negocio.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo administrar Mi Tienda Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Toda la administración de la tienda la puedes hacer desde tu panel
              de control. Administrar tus ventas, ver los reportes de tu tienda,
              modificar o agregar información o configuración de la misma,
              incluso acceder a la sección de tutoriales y ayuda.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Puedo administrar mi tienda desde el celular?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Mi Tienda Clip está desarrollada para que puedas usarla 100% desde
              tu celular.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Puedo diseñar mi propia tienda?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Si, puedes elegir entre los diseños y colores que ofrecemos, el
              que mejor se adapte a tu negocio.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo van a encontrar mi tienda?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Para que esto suceda, es importante que compartas el enlace de tu
              tienda en redes sociales y con todos tus contactos invitándolos a
              visitarla.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo compartir el enlace de mi tienda?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Puedes compartirla ingresando a tu tienda desde el perfil de tu
              negocio o simplemente copiando y pegando la liga que figura en tu
              navegador.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Puedo editar o borrar una publicación o producto?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Sí, puedes hacerlo. No te preocupes, guardaremos todos los
              registros de ventas que hayan tenido.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Puedo ocultar o dar de baja mi tienda en cualquier momento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Sí, puedes hacerlo en cualquier momento. Solo debes cambiar el
              estado en el extremo superior derecho dentro del panel de control
              en “Pausar tienda”.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo recuperar mi contraseña?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              La puedes restablecer con estos sencillos pasos:
              <Stack pl={2}>
                <ol>
                  <li>
                    Ingresa a nuestra página{' '}
                    <Link
                      href="https://compra.clip.mx/MI-CUENTA"
                      target="_blank"
                    >
                      https://compra.clip.mx/MI-CUENTA
                    </Link>
                  </li>
                  <li>Selecciona la opción “¿Olvidaste tu contraseña?”.</li>
                  <li>
                    Escribe tu correo electrónico y selecciona “Recuperar”.
                  </li>
                  <li>
                    Recibirás por correo electrónico un enlace para crear tu
                    nueva contraseña.
                  </li>
                </ol>
              </Stack>
              *Recuerda que debe tener, por lo menos, 8 caracteres entre números
              y letras, una de ellas debe ser mayúscula.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo denunciar a un comprador?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              En caso de que necesites denunciar a un comprador por mal uso de
              la plataforma te pedimos que te comuniques con nuestro centro de
              atención a clientes, Customer Happiness, con los datos del
              comprador.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}` }}>
        <Typography color="primary" sx={{ margin: '16px 0' }} variant="h4">
          Costos asociados
        </Typography>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Tiene algún costo el uso de la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              No, el uso de la plataforma no tiene ningún costo adicional, solo
              se te cobrará la misma comisión que hoy pagas por la venta a
              través de los lectores Clip.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}` }}>
        <Typography color="primary" sx={{ margin: '16px 0' }} variant="h4">
          Ventas
        </Typography>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿El cliente recibe alguna confirmación cuando hace una compra?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Sí, el cliente recibe un correo electrónico con el comprobante de
              compra, el cual tiene el detalle de esta, así como los datos de
              contacto del negocio. También puede descargar el comprobante en su
              dispositivo una vez que finaliza la compra.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Dónde puedo consultar las ventas realizadas?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Dentro de la sección ventas de tu panel de control. Ahí podrás
              filtrar por fecha o estado (pendiente o entregada) y podrás
              acceder a cada operación para ver el detalle de la misma,
              incluyendo los productos vendidos, información del cliente, etc.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿En cuánto tiempo se depositan las ganancias de una venta con Mi
              Tienda Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Las condiciones son las mismas que una venta realizada a través de
              los lectores Clip.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿En dónde se depositan las ganancias de mis ventas con Mi Tienda
              Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              En la cuenta de banco que hayas registrado en tu cuenta Clip.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Tuviste algún problema con los depósitos de las ganancias de tus
              ventas con Mi Tienda Clip?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              Por favor contacta a nuestro centro de atención a clientes,
              Customer Happiness, haremos todo lo posible para ayudarte.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}` }}>
        <Typography color="primary" sx={{ margin: '16px 0' }} variant="h4">
          Contacto
        </Typography>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo me contacto con ustedes?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              A través de cualquiera de nuestros canales de atención al cliente,
              también desde la sección de ayuda de Mi Tienda en línea, o a
              través de nuestras redes sociales.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}` }}>
        <Typography color="primary" sx={{ margin: '16px 0' }} variant="h4">
          Perfil
        </Typography>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Cómo cambiar el nombre de mi negocio?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              <Stack pl={2}>
                <ol>
                  <li>Entra a la app de Clip.</li>
                  <li>Ve a “Ajustes”.</li>
                  <li>
                    Da clic en la parte superior derecha donde está el círculo
                    naranja con tu nombre.
                  </li>
                  <li>Selecciona la opción “Información de negocio”.</li>
                  <li>Elige “editar” y cambia el nombre de tu negocio.</li>
                  <li>Guarda los cambios.</li>
                </ol>
              </Stack>
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
        <AccordionFAQ onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography align="left" variant="h4">
              ¿Puedo cambiar el correo electrónico con el que me registré?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              El correo electrónico es el único dato de tu cuenta Clip que no
              puedes modificar o cambiar. Esto es para evitar que se dupliquen
              las cuentas.
            </Typography>
          </AccordionDetails>
        </AccordionFAQ>
      </Box>
    </Box>
  )
}

export default FrequentlyAskedQuestions
