import {
  CANCELED_PAYMENT,
  DELIVERED_PAYMENT,
  PENDING_ORDER,
} from 'constants/salesPage'

/**
 * get status label
 *
 * @return {string}
 */
const getStatusLabel = (status) => {
  const label = {
    canceled_payment: CANCELED_PAYMENT,
    delivery_delivered: DELIVERED_PAYMENT,
    payment_success: PENDING_ORDER,
  }

  return label[status] || ''
}

export default getStatusLabel
