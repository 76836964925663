import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import useStyles from './styles'

const { select: DATA_SELECT } = require('./constData.json')

const CouponSelector = ({
  activeCoupons,
  setNameSelected,
  setPriceDiscSelected,
}) => {
  const classes = useStyles()

  const handleclick = (name, discount, discType) => {
    const d = discType === '%' ? discount + discType : discType + discount

    setNameSelected(name)
    setPriceDiscSelected(d)
  }

  return (
    <FormControl className={classes.wrapper} variant="standard">
      <InputLabel className={classes.customLabel}>
        {DATA_SELECT.CHOOSE_A_CODE}
      </InputLabel>
      <Select
        className={classes.CouponSelector}
        inputProps={{ 'data-testid': 'activeCode' }}
        renderValue={(v) => `${v}`}
        variant="filled"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {activeCoupons.map((c) => (
          <MenuItem
            onClick={() =>
              handleclick(c.name, c.total_discount, c.discount_type)
            }
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            value={c.name}
          >
            <Stack>
              <Typography>{c.name}</Typography>
              <Typography>{c.coupon_code}</Typography>
            </Stack>
            <Typography>
              {c.discount_type === '%'
                ? c.total_discount + c.discount_type
                : c.discount_type + c.total_discount}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CouponSelector

CouponSelector.propTypes = {
  activeCoupons: PropTypes.arrayOf,
  setNameSelected: PropTypes.func,
  setPriceDiscSelected: PropTypes.func,
}

CouponSelector.defaultProps = {
  activeCoupons: [],
  setNameSelected: () => {},
  setPriceDiscSelected: () => {},
}
