import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import ModalCustom from '../ModalCustom'
import Warning from './warning'
import Success from './success'

const { success: PM_SUCCESS, warning: PM_WARNING } = require('./constData.json')

const PublishModal = ({ merchantData, onClose, open, publishedSuccess }) => (
  <ModalCustom onClose={onClose} open={open}>
    <Typography
      component="h2"
      sx={{
        display: 'flex',
        fontSize: { sm: 24, xs: 18 },
        justifyContent: 'space-between',
        lineHeight: { sm: 36 / 24, xs: 28 / 18 },
      }}
      variant="h1"
    >
      {publishedSuccess ? PM_SUCCESS.TITLE : PM_WARNING.TITLE}
    </Typography>

    {publishedSuccess ? (
      <Success
        merchantName={merchantData?.merchantName}
        merchantSlug={merchantData?.merchantSlug}
        merchantToken={merchantData?.merchantToken}
        merchantUrl={merchantData?.merchantUrl}
      />
    ) : (
      <Warning />
    )}
  </ModalCustom>
)

export default PublishModal

PublishModal.propTypes = {
  merchantData: PropTypes.shape({
    merchantName: PropTypes.string,
    merchantSlug: PropTypes.string,
    merchantToken: PropTypes.string,
    merchantUrl: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  publishedSuccess: PropTypes.bool,
}

PublishModal.defaultProps = {
  publishedSuccess: false,
}
