import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthState, useConfigDispatch } from 'store/user'
import { postInfo } from 'helpers/fetchUtil'
import Stack from '@mui/material/Stack'
import ContainerCard from 'components/ContainerCard'
import { Form, Loading, Success } from 'components/Onboarding'
import { ENV, GET_CONFIG_ROUTE, TERMS_AND_CONDITIONS } from 'constants/routes'
import ONBOARDING from 'components/Onboarding/constData.json'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Layout from 'components/Layout'
import UserActions from 'store/user/actions'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import recordAppsFlyerEvent from 'helpers/appsflyer'
import APPSFLYER_EVENTS from 'constants/appsflyer'

const Onboarding = () => {
  const theme = useTheme()
  const { merchantInfo, merchantToken, storeConfig } = useAuthState()
  const [loading, setLoading] = useState(false)
  const [setupCompleted, setSetupCompleted] = useState(false)
  const dispatch = useConfigDispatch()
  const { device } = useGlobalContext()

  const createStoreConfig = async () => {
    recordAppsFlyerEvent(APPSFLYER_EVENTS.onboarding.completed, {
      merchant_id: merchantToken,
    })
    try {
      setLoading(true)
      if (merchantToken) {
        await postInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
          is_published: false,
        })
        setTimeout(() => {
          setSetupCompleted(true)
          setLoading(false)
          dispatch({
            payload: {
              is_published: false,
            },
            type: UserActions.UPDATE_STORE_CONFIG,
          })
        }, 1500)
      } else {
        setSetupCompleted(true)
        setLoading(false)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('createStoreConfig::error', e)
    }
  }

  useEffect(async () => {
    if (merchantInfo && merchantInfo?.publicProfile?.alias) {
      setLoading(true)
      await createStoreConfig()
      setTimeout(() => {
        setSetupCompleted(true)
        setLoading(false)
      }, 150000)
    }
    cleverTap.event(EVENTS.ONBOARDING_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
    recordAppsFlyerEvent(APPSFLYER_EVENTS.onboarding.view, {
      merchant_id: merchantToken,
    })
  }, [merchantInfo])

  useEffect(async () => {
    cleverTap.event(EVENTS.ONBOARDING_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  if (
    storeConfig &&
    !storeConfig.error &&
    !storeConfig.message &&
    storeConfig.is_published !== undefined
  ) {
    window.location.href = '/'

    return null
  }

  return (
    <Layout wrapper>
      <ContainerCard>
        {!merchantInfo?.publicProfile?.alias && !loading && !setupCompleted && (
          <Form
            merchantInfo={merchantInfo}
            onCompleted={() => createStoreConfig()}
          />
        )}
        {loading && <Loading />}
        {setupCompleted && <Success />}
        <Stack direction="row" sx={{ mt: 3 }}>
          <Typography
            align="center"
            fontWeight={theme.fontWeight.regular}
            sx={{
              '& > a': {
                color: theme.palette.primary.main,
                textDecoration: 'none',
              },
            }}
            variant="body1"
          >
            {ONBOARDING.TERMS_AND_CONDITIONS_TEXT}&nbsp;
            <NavLink name="btn-tac" target="_blank" to={TERMS_AND_CONDITIONS}>
              {ONBOARDING.TERMS_AND_CONDITIONS}
            </NavLink>
          </Typography>
        </Stack>
      </ContainerCard>
    </Layout>
  )
}

export default Onboarding
