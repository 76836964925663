import { useEffect, useState, useCallback } from 'react'
import { fetchInfo } from 'helpers/fetchUtil'

import { ENV, GET_ORDERS_ROUTE, GET_ORDER_DETAIL_ROUTE } from 'constants/routes'

export function getOrderById(orderId) {
  const [order, setOrder] = useState(null)
  const [error, setError] = useState(false)
  const orderApiUrl = `${ENV.REACT_APP_API_URL}${GET_ORDER_DETAIL_ROUTE.replace(
    ':id',
    orderId
  )}`

  useEffect(async () => {
    try {
      const { data: result } = await fetchInfo(orderApiUrl)

      setOrder(result.ecommerceOrder)
    } catch (err) {
      setError(true)
    }

    return () => {
      setOrder(null)
      setError(false)
    }
  }, [])

  return {
    error,
    order,
  }
}

export function useGetOrders(dateFrom, dateTo, orderStatus, searchParams) {
  const [orderList, setOrderList] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  let orderApiUrl = `${ENV.REACT_APP_API_URL}${GET_ORDERS_ROUTE}?`

  if (dateFrom) {
    orderApiUrl += `&date_from=${dateFrom}`
  }

  if (dateTo) {
    orderApiUrl += `&date_to=${dateTo}`
  }

  if (orderStatus) {
    orderApiUrl += `&status=${orderStatus}`
  }

  if (searchParams && searchParams.email) {
    orderApiUrl += `&email=${searchParams.term}`
  }

  if (searchParams && searchParams.orderId) {
    orderApiUrl = `${ENV.REACT_APP_API_URL}${GET_ORDER_DETAIL_ROUTE.replace(
      ':id',
      searchParams.term
    )}`
  }

  const fetchOrders = useCallback(async () => {
    try {
      setOrderList([])
      setLoading(true)
      const { data: results } = await fetchInfo(orderApiUrl)

      if (results.ecommerceOrders) {
        setOrderList(results.ecommerceOrders)
      }
      if (results.ecommerceOrder) {
        setOrderList([
          {
            orderId: searchParams.term,
            ...results.ecommerceOrder,
          },
        ])
      }

      setLoading(false)
    } catch (err) {
      setOrderList([])
      setError(true)
      setLoading(false)
    }

    return () => {
      setOrderList([])
      setError(false)
      setLoading(false)
    }
  }, [orderStatus, dateFrom, dateTo, searchParams])

  useEffect(fetchOrders, [orderStatus, dateFrom, dateTo, searchParams])

  return {
    error,
    loading,
    orderList,
  }
}
