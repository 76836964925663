import { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from 'components/Button'
import { useTheme } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Plus } from 'components/Icons'
import CustomPopover from 'components/Popover'

const ProductCategoryMobile = ({ btnTxt, btnURL }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        color="orange"
        onClick={handleClick}
        shape="circle"
        sx={{
          bottom: 16,
          height: { xs: 48 },
          position: 'fixed',
          right: 16,
          width: { xs: 48 },
        }}
      >
        <Plus
          color={theme.palette.white.main}
          rotate={anchorEl ? 45 : 0}
          size={2}
        />
      </Button>

      <CustomPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={onClosePopover}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: `${theme.borderRadius[0]}px`,
          },
          '& .MuiPopover-paper > div': {
            '& div': {
              pl: `${theme.space[2]}px`,
            },
            mt: `${theme.space[1]}px`,
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <NavLink style={{ textDecoration: 'none' }} to={btnURL.category}>
          <ListItem
            sx={{ borderBottom: `1px solid ${theme.backgrounds[1]}`, pr: 6 }}
          >
            <ListItemIcon sx={{ minWidth: 20, pr: 1 }}>
              <Plus color={theme.palette.text.primary} size={1.3} />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { color: theme.palette.text.primary },
              }}
              variant="h4"
            >
              {btnTxt.category}
            </ListItemText>
          </ListItem>
        </NavLink>

        <NavLink style={{ textDecoration: 'none' }} to={btnURL.product}>
          <ListItem sx={{ pr: 6 }}>
            <ListItemIcon sx={{ minWidth: 20, pr: 1 }}>
              <Plus color={theme.palette.text.primary} size={1.3} />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { color: theme.palette.text.primary },
              }}
              variant="h4"
            >
              {btnTxt.product}
            </ListItemText>
          </ListItem>
        </NavLink>
      </CustomPopover>
    </Box>
  )
}

export default ProductCategoryMobile

ProductCategoryMobile.propTypes = {
  btnTxt: PropTypes.shape({
    category: PropTypes.string,
    product: PropTypes.string,
  }),
  btnURL: PropTypes.shape({
    category: PropTypes.string,
    product: PropTypes.string,
  }),
}

ProductCategoryMobile.defaultProps = {
  btnTxt: {
    category: '',
    product: '',
  },
  btnURL: { category: '', product: '' },
}
