import PRODUCT_ACTIONS from './actions'

export const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_ACTIONS.UPDATE_PRODUCT:
      return {
        ...state,
        ...action.productData,
      }
    case PRODUCT_ACTIONS.CLEAR_PRODUCT:
      return initialState
    default:
      return state
  }
}

export default reducer
