const data = {
  tips: [
    {
      description:
        'Te presentamos la herramienta de Clip para crear tu tienda en línea de forma rápida, fácil, con la mejor seguridad y completamente gratis.',
      file: 'tip-1.json',
      imgUrl: 'https://img.youtube.com/vi/Og40WYW0aGA/0.jpg',
      subtitle: 'Tip 1',
      title: 'Conoce Mi Negocio Clip',
    },
    {
      description:
        'Mi Negocio Clip es la opción que brindamos para armar tu tienda en línea, aquí podrás encontrar los pasos para crear tu negocio en internet y comenzar a vender en línea.',
      file: 'tip-2.json',
      imgUrl: 'https://img.youtube.com/vi/yrBalI822Ms/0.jpg',
      subtitle: 'Tip 2',
      title: '¿Cómo comenzar a vender en línea con Mi Negocio Clip?',
    },
  ],
}

export default data
