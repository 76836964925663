import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
}))

export default useStyles
