import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import ContainerCard from 'components/ContainerCard'
import Paper from 'components/Paper'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Layout from 'components/Layout'

const TaC = () => {
  const theme = useTheme()

  return (
    <Layout
      sx={{
        '& > .MuiBox-root': {
          pt: { md: 0, xs: `${theme.space[5]}px` },
        },
      }}
      wrapper
    >
      <ContainerCard>
        <Paper
          sx={{
            maxWidth: 1024,
            mb: 5,
            mt: { md: 20, xs: 5 },
            p: { sm: '30px 7vw', xs: '0 20px' },
            width: { sm: '80%', xs: '100%' },
          }}
        >
          <Typography align="center" color="primary" sx={{ m: 3 }} variant="h2">
            TÉRMINOS Y CONDICIONES MI TIENDA CLIP
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            Por este medio manifiestas que, al realizar la carga de información
            de tus productos o realizar una transacción a través de Mi Tienda
            Clip, aceptas los presentes Términos y Condiciones. El Cliente en
            este acto acepta y se obliga a cumplir con la normativa, regulación
            y lineamientos internos de Clip que resulten aplicables al Servicio
            (según se define más adelante).
          </Typography>

          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            El presente Servicio es prestado por Payclip, S. de R.L. de C.V. (en
            lo sucesivo “Clip”) con el fin de que el Cliente esté sujeto a los
            presentes Términos y Condiciones y pueda crear su propia tienda en
            línea para llevar a cabo la venta de productos a través de internet.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            El Cliente es responsable de leer con detenimiento los presentes
            Términos y Condiciones, los cuales pueden estar sujetos a futuras
            modificaciones, por lo que será obligación de éste consultar
            periódicamente la versión vigente, la cual se encontrará disponible
            en la página{' '}
            <Link
              href="https://mitienda.clip.mx/terminos-y-condiciones"
              sx={{ textDecoration: 'none' }}
            >
              <b>https://mitienda.clip.mx/terminos-y-condiciones</b>
            </Link>
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            En caso de que el Cliente, en cualquier momento, se encuentre
            inconforme de manera total o parcial con los presentes Términos y
            Condiciones, así como con sus modificaciones, deberá de abstenerse
            de manera inmediata de hacer uso del Servicio establecido en los
            presentes Términos y Condiciones y deberá cumplir todas aquellas
            obligaciones que se encuentren pendientes derivadas del uso del
            Servicio.
          </Typography>
          <Typography fontWeight={theme.fontWeight.semibold} variant="h3">
            1. DEFINICIONES.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Actividades Ilícitas:</b> cualquier acto, operación o transacción
            realizada en contravención con lo establecido en la legislación
            mexicana derivando en la comisión de un delito, sanción
            administrativa o bien en las ganancias derivadas de la comisión de
            algún delito y que no pueda acreditarse su legítima procedencia.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Aviso de Privacidad:</b> documento a disposición del titular de
            los datos personales de forma física, electrónica o en cualquier
            formato generado por el responsable, con el objeto de informarle los
            propósitos del tratamiento de los mismos a partir del momento en el
            cual se recaben sus datos personales, para la realización de algún
            trámite o servicio ante Clip, mismo que se encuentra disponible en
            <Link href="clip.mx/privacidad" sx={{ textDecoration: 'none' }}>
              <b>&nbsp;clip.mx/privacidad</b>
            </Link>
            .
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Catálogo Clip:</b> es una de las funcionalidades que se encuentra
            disponible en la Plataforma Clip que permite al Cliente registrar
            todos los productos que tiene disponibles para su venta.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Cliente:</b> persona física o moral registrada con una cuenta
            creada ante Clip misma que suscribe los presentes Términos y
            Condiciones.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Clip:</b> Payclip, S. de R.L. de C.V.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Datos Personales:</b> cualquier información concerniente a una
            persona física identificada o identificable.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Plataforma Clip:</b> es el sistema desarrollado por Clip que
            permite a los Clientes, de manera virtual, la ejecución de ambientes
            digitales para acceder al Servicio.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Servicio:</b> es la opción disponible en la Plataforma Clip a
            través de la cual el Cliente genera un subdominio y personaliza su
            propia tienda en línea con el fin de llevar a cabo la venta de sus
            productos a través de internet.
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="h4"
          >
            <b>Usuario:</b> es la persona que a través del subdominio generado
            por el Cliente, hace uso del Servicio.
          </Typography>

          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              2. OBJETO. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              A través del presente Servicio, el Cliente deberá proporcionar el
              nombre comercial bajo el cual desea que se identifique su tienda
              en línea, creando así de forma automática un subdominio del sitio{' '}
              <b>mitiendaclip.mx</b>, de igual manera, podrá personalizar su
              tienda en línea incluyendo imágenes, logotipos y/o una descripción
              breve de los productos que se encuentran disponibles para venta,
              así como su información de contacto. La información sobre los
              productos que se encuentren disponibles para venta deberá ser
              compartida por el Cliente desde el Catálogo Clip.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              3. CONTRAPRESTACIÓN. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Clip podrá cobrar una comisión al Cliente por el uso del presente
              Servicio, misma que le será notificada al momento de la
              contratación del Servicio.
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Clip podrá modificar de tiempo en tiempo la comisión
              correspondiente del presente Servicio, notificando en todo momento
              al Cliente de dicha modificación, por los medios que Clip
              considere necesarios.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              4. FUNCIONALIDAD. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente será el único responsable sobre el dispositivo
              utilizado para descargar las aplicaciones IOS, Android y/o el
              sitio web designado para el Servicio, por lo que libera a Clip de
              cualquier responsabilidad y/o reclamación en caso de estos no
              fueran compatibles o no permitieran visualizar las funcionalidades
              del Servicio.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              5. ENTREGA DE LOS PRODUCTOS.
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente, como parte de las opciones de personalización del
              Servicio, podrá incluir las opciones de “entrega a domicilio” y/o
              “recolección” dentro de su tienda en línea, lo anterior a efecto
              de que el Usuario pueda recibir en su domicilio o recolectar en un
              domicilio determinado por el Cliente, los productos que haya
              adquirido.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente será el único responsable del envío y/o de la opción de
              recolección que haya pactado con el Usuario, por lo que en este
              acto el Cliente libera a Clip de cualquier responsabilidad que
              pudiera generarse sobre este supuesto.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              6. RESPONSABILIDAD.
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              A través de los presentes Términos y Condiciones, el Cliente
              manifiesta ser el único responsable por el uso del Servicio, así
              como de todas las operaciones y transacciones de pago que se
              realicen a través del mismo.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente podrá realizar reembolsos al Usuario, considerando que
              los mismos no podrán llevarse a cabo en efectivo, ni podrá aceptar
              incentivos en efectivo ni contraprestación alguna con el fin de
              facilitar el mismo. Para que los reembolsos puedan realizarse
              deberán cumplir con lo siguiente: (i) corresponder a una operación
              previamente concluida, en el entendido de que se efectuarán
              utilizando la misma tarjeta que la usada en la operación de pago;
              (ii) el importe del reembolso no podrá superar el importe de la
              correspondiente operación de pago, (iii) el Cliente deberá contar
              con saldo disponible en la cuenta aperturada con Clip y (iv)
              deberá ser realizada dentro de los 7 (siete) días naturales
              siguientes a que el Usuario haya realizado la compra.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              En caso de no cumplir con las condiciones antes señaladas Clip no
              podrá cancelar la transacción, ni realizar el reembolso
              correspondiente.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente no podrá hacer uso del Servicio con el fin de realizar
              el ofrecimiento y/o la venta de Actividades Ilícitas o que se
              encuentren dentro de los giros prohibidos identificados por Clip,
              lo anterior en el entendido que, el Cliente se obliga en este acto
              a cumplir con la normativa, regulación y lineamientos internos de
              uso del Servicio que Clip emita para tal efecto.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Clip se encontrará facultado en todo momento a solicitar
              modificaciones sobre la información que el Cliente incluya en el
              subdominio de su tienda en línea, así como a dar de baja el
              Servicio si a través de éste se realizan o se promueve la
              realización de Actividades Ilícitas o transacciones que se
              encuentren dentro de las Actividades Ilícitas y/o los giros
              prohibidos identificados por Clip, sin que exista responsabilidad
              alguna para este último.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              En caso de que el Cliente reciba Datos Personales del Usuario a
              través del presente Servicio, tendrá la obligación de utilizar y
              resguardar dichos datos conforme a sus propios lineamientos de
              privacidad de datos, por lo que el Cliente en este acto libera a
              Clip de cualquier responsabilidad por el mal uso que llegara a
              hacer de dicha información.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente en este acto manifiesta ser el único contacto directo
              con el Usuario en caso de que exista alguna consulta, garantía
              sobre los productos, solicitud de devolución de los productos,
              cancelaciones de compras, quejas o reclamaciones sobre los
              productos, liberando a Clip de toda responsabilidad sobre
              cualquier proceso ante cualquier autoridad o instancia que pudiere
              resultar aplicable. Por lo anterior, el Cliente se compromete a
              mantener su información de contacto actualizada y disponible en
              todo momento para el Usuario.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              7. SERVICIOS DE TERCEROS. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente acepta y reconoce que Clip no tendrá responsabilidad
              alguna sobre el cumplimiento de los servicios prestados por
              terceros, tales como internet y/o telefonía móvil.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              8. DATOS PERSONALES. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente autoriza a Clip a tratar los Datos Personales que en el
              desarrollo y ejecución de los presentes Términos y Condiciones
              sean recabados, con la finalidad de cumplir con los mismos y en
              general, todo lo necesario para el desarrollo del presente
              Servicio y subsecuentes actos. Los Datos Personales que sean
              transmitidos a Clip no podrán ser utilizados para fines distintos
              a los previstos en su Aviso de Privacidad consultable en:
              <Link href="clip.mx/privacidad" sx={{ textDecoration: 'none' }}>
                <b>&nbsp;Aviso de Privacidad | Clip - clip.mx</b>
              </Link>
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Se le informa al Cliente que, como titular de los Datos
              Personales, le asisten los siguientes derechos:
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              A. Derecho de Acceso: Conocer con cuáles de sus Datos Personales
              cuenta Clip y cuáles son las finalidades de su tratamiento.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              B. Derecho de Rectificación: Solicitar la corrección de sus Datos
              Personales en caso de que carezcan de actualización, sean
              inexactos, erróneos o estén incompletos.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              C. Derecho de Cancelación: Solicitud de eliminación de todo
              registro o copia de sus Datos Personales, ya sea física o
              electrónica.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              D. Derecho de Oposición: Solicitar el cese en el uso de sus Datos
              Personales.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              9. CONFIDENCIALIDAD. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente se obliga a mantener la confidencialidad de la
              información que reciba por parte de Clip por cualquier medio, ya
              sea de manera verbal, escrita, gráfica o electrónica y, que tenga
              dicho carácter conforme a las disposiciones legales aplicables en
              los Estados Unidos Mexicanos y a no compartirlo con cualquier
              persona o entidad, excepto con Clip, sus afiliadas, cesionarios,
              y/o partes relacionadas conforme a lo establecido en el Aviso de
              Privacidad de Clip.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              10. PROPIEDAD INTELECTUAL. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente se obliga a utilizar las marcas y/o nombres comerciales
              de Clip, siempre y cuando cumpla con los requerimientos y
              características previamente autorizados por este último, en el
              entendido de que dicho uso de marca y/o nombres comerciales no
              represente por ningún motivo una cesión de derechos sobre las
              marcas, patentes, nombres comerciales y/o derechos de propiedad
              industrial e intelectual.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Al ser los signos distintivos propiedad en todo momento de Clip,
              el Cliente se obliga a sacar en paz y a salvo a Clip, de cualquier
              reclamación de carácter civil, penal, administrativo, o cualquier
              otra índole, debido al uso de sus signos distintivos para el
              presente Servicio.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente, en caso de cargar imágenes a través del subdominio,
              declara que dichas imágenes son de su propiedad o cuenta con la
              autorización expresa para su utilización, por lo que el uso de las
              mismas no constituye la comisión de delitos en materia de derechos
              de autor, por lo que autoriza a Clip la utilización de las
              imágenes de conformidad con lo dispuesto en los presentes Términos
              y Condiciones, el Aviso de Privacidad, así como con los
              lineamientos internos y externos aplicables de Clip y, así mismo
              deslinda de cualquier tipo de responsabilidad a Clip, derivada de
              reclamaciones o acciones legales por algún incumplimiento sobre
              este supuesto.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              11. MISCELÁNEOS. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Cualquier tipo de Acto Ilícito o de intento de este, al igual que
              cualquier incumplimiento a los presentes Términos y Condiciones,
              dará lugar a que el Cliente sea dado de baja y se concluya la
              prestación del Servicio. Clip podrá verificar el Acto Ilícito, el
              intento de Acto Ilícito, o el incumplimiento a los presentes
              Términos y Condiciones por cualquier medio que considere
              pertinente.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Clip a efecto de cumplir con la normativa, regulación y
              lineamientos internos relativos a temas de Prevención de Lavado de
              Dinero, podrá:
              <ul style={{ margin: '20px 0 20px 50px' }}>
                <li>
                  Solicitar de forma periódica al Cliente información y/o
                  documentación que le permita llevar a cabo su identificación,
                  así como el monitoreo de las operaciones que lleve a cabo a
                  través del Servicio.
                </li>
                <li>
                  Suspender o cancelar el Servicio en caso de que la información
                  y/o documentación que el Cliente haya proporcionado sea falsa
                  o inexacta, así como en aquellos casos en los que el Cliente
                  no proporcione o actualice la información y/o documentación
                  solicitada.
                </li>
              </ul>
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              El Cliente libera de toda responsabilidad a Clip, a sus filiales,
              subsidiarias, directores, accionistas, gerentes, empleados y todos
              aquellos asociados de Clip, en relación a, o de cualquier manera
              relacionada directa o indirectamente con los presentes Términos y
              Condiciones y/o el Servicio.
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Clip podrá realizar de tiempo en tiempo cualquier tipo de evento,
              promoción y/o publicidad (en lo sucesivo “Publicidades”) en
              relación al presente Servicio, en cuyo caso, las Publicidades se
              regirán por los términos y condiciones especiales que sean
              aplicables.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              fontWeight={theme.fontWeight.semibold}
              variant="h3"
            >
              12. JURISDICCIÓN Y LEGISLACIÓN APLICABLE. &nbsp;
            </Typography>
            <Typography
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              Los presentes Términos y Condiciones se regirán en todos sus
              puntos por las leyes vigentes en materia federal aplicables en la
              República Mexicana. Cualquier controversia derivada de los
              presentes Términos y Condiciones, su existencia, validez,
              interpretación, alcance o cumplimiento, será sometida a la
              legislación federal aplicable a los Tribunales Federales
              competentes en la Ciudad de México, renunciando el Cliente en
              consecuencia a cualquier fuero que en razón de su domicilio
              presente o futuro pudiera corresponderle.
            </Typography>
            <Typography
              align="center"
              color="text.primary"
              component="span"
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2 }}
              variant="h4"
            >
              ¿Necesitas ayuda? <br />
              El servicio de atención a clientes Customer Happiness® te brinda
              atención <br />
              personalizada los 7 días de la semana, las 24 horas del día.
              <br />
              Llámanos o escríbenos por Whatsapp al{' '}
              <Link href="https://wa.me/5215563932323" target="_blank">
                55 6393-2323
              </Link>
              . También nos puedes mandar un email a{' '}
              <Link href="mailto:help@clip.mx" target="_blank">
                <b>help@clip.mx</b>
              </Link>{' '}
              o escribirnos por webchat en{' '}
              <Link href="www.clip.mx" target="_blank">
                <b>www.clip.mx</b>
              </Link>
              .
              <br />
              Consulta{' '}
              <Link href="www.clip.mx/ayuda">
                <b>www.clip.mx/ayuda</b>
              </Link>{' '}
              para más información.
            </Typography>
          </Stack>
        </Paper>
      </ContainerCard>
    </Layout>
  )
}

export default TaC
