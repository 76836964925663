import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useTheme } from '@mui/material/styles'
import { Calendar } from 'react-date-range'
import { addDays } from 'date-fns'
import * as locales from 'react-date-range/dist/locale'
import ModalBox from 'components/Modal/ModalBox'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import useStyles from './styles'

const ModalDatePicker = ({ date, minDate, onChange, onClose, open }) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className={classes.datePicker}
      closeAfterTransition
      onClose={onClose}
      open={open}
    >
      <Fade in={open}>
        <ModalBox>
          <Calendar
            color={theme.palette.primary.main}
            date={addDays(date, 1)}
            locale={locales.es}
            minDate={addDays(minDate, 1)}
            onChange={onChange}
          />
        </ModalBox>
      </Fade>
    </Modal>
  )
}

export default ModalDatePicker

ModalDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

ModalDatePicker.defaultProps = {
  minDate: null,
}
