import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuthState } from 'store/user'
import Accordion from 'components/Accordion'
import SubmitButton from 'components/Settings/SubmitButton'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { ENV } from 'constants/routes'
import SETTINGS from 'components/Settings/constData.json'
import { savePublicProfile } from 'helpers/fetchUtil'
import { validateAlias } from 'utils/form'
import ProfileForm from 'components/ProfileForm'
import StatusMessages from 'components/StatusMessages'

const BusinessInfo = () => {
  const [link, setLink] = useState('')
  const [publicName, setPublicName] = useState('')
  const [publicDesc, setPublicDesc] = useState('')
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const { device } = useGlobalContext()
  const [mandatoryError, setMandatoryError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo

  useEffect(async () => {
    if (publicProfile) {
      if (publicProfile.alias) {
        const merchantUrl = `${ENV.REACT_APP_STORE_BASE_URL}/${publicProfile.alias}`

        setLink(merchantUrl)
      }

      if (publicProfile.public_description) {
        setPublicDesc(publicProfile.public_description)
      }

      if (publicProfile) {
        setPublicName(publicProfile.public_name)
      }
    }
  }, [expandedPanel])

  const handleCancel = () => {
    setExpandedPanel((prev) => !prev)
    setSubmitError(false)

    if (expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.cancel,
        Device: device.type,
      })
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const alias = validateAlias(link)

    if (alias === false) {
      setMandatoryError(true)

      return
    }

    try {
      const data = await savePublicProfile(
        alias,
        merchantInfo.id,
        publicName,
        publicDesc
      )

      if (data.messages.code === 200 || (data.items && data.items[0].alias)) {
        setSubmitSuccess(true)
        setLoading(false)
        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
      } else {
        setSubmitError(true)
        setLoading(false)
      }
    } catch (e) {
      setSubmitError(true)
      setLoading(false)
    }
  }

  const handleChangeLink = (e) => {
    const alias = e.target.value
      .split(`${ENV.REACT_APP_STORE_BASE_URL}/`)[1]
      .replace(' ', '')

    setLink(`${ENV.REACT_APP_STORE_BASE_URL}/${alias || ''}`)
    setMandatoryError(false)
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.informacion,
        Device: device.type,
      })
    }
  }

  const onChangePublicName = (e) => {
    setPublicName(e.target.value)
  }

  const onChangePublicDesc = (e) => {
    setPublicDesc(e.target.value)
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleCancel}>
        <Typography align="center" variant="h2">
          {SETTINGS.STORE_INFO}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ProfileForm
          link={link}
          mandatoryError={mandatoryError}
          onChangeLink={handleChangeLink}
          onChangePublicDesc={onChangePublicDesc}
          onChangePublicName={onChangePublicName}
          publicDesc={publicDesc}
          publicName={publicName}
        />
        <SubmitButton
          buttonCancelText={SETTINGS.CANCEL_BUTTON}
          buttonSubmitText={SETTINGS.SAVE_BUTTON}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          loading={loading}
        />
        <StatusMessages
          errorFlag={submitError}
          errorMessage={SETTINGS.ERROR_MESSAGE}
          handleClose={handleClose}
          successFlag={submitSuccess}
          successMessage={SETTINGS.SUCCESS_MESSAGE}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default BusinessInfo
