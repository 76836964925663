import { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import Paper from 'components/Paper'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import filterData from 'components/Chip/data'
import fetchData from 'hooks/fetchData'
import { RenderChips } from 'components/Chip'
import { SalesChart, StoreActivityMetrics } from 'components/ChartsReports'
import EmptyReport from 'components/ChartsReports/EmptyReport'
import MostSelledProducts from 'components/MostSelledProducts'
import BannerSlider from 'components/Banner'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { GENERAL, STORE_ACTIVITY } from 'constants/page'
import Loader from 'components/Loader'
import dashboardSettings from 'constants/dashboardSettings'
import { ENV, GET_PRODUCT_RANKING_ROUTE } from 'constants/routes'
import { REPORTING_API_MAP } from 'constants/reportingParams'
import StoreScoreCards from 'components/StoreScoreCards'
import { useGetOrders } from 'hooks/useOrders'
import useReports from 'hooks/useReports'
import { getDateIntervalHelper } from 'utils/dateTools'
import EMPTY_REPORT from 'constants/reportsPage'
import { STATUS_MAPPING } from 'constants/salesPage'

const Reports = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [dateFilter, setDateFilter] = useState(2)

  const { report: reportData } = useReports(REPORTING_API_MAP[dateFilter])

  const { data: pRanking } = fetchData(
    `${ENV.REACT_APP_API_URL}${GET_PRODUCT_RANKING_ROUTE}`
  )

  const { dateFrom, dateTo } = getDateIntervalHelper(dateFilter)

  const { orderList } = useGetOrders(dateFrom, dateTo, STATUS_MAPPING[0])

  const onSelected = (filter) => {
    setDateFilter(filter.id)
    cleverTap.event(EVENTS.REPORTES_VIEWED, {
      Click: `${PV.date_filter}: ${filter.label}`,
      Device: device.type,
    })
  }

  useEffect(() => {
    cleverTap.event(EVENTS.REPORTES_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  const renderChart = () => {
    if (dateFilter === 1 || dateFilter === 4) return null

    if (orderList && orderList.length) {
      return (
        <SalesChart dateFrom={dateFrom} dateTo={dateTo} sales={orderList} />
      )
    }

    return <EmptyReport />
  }

  const renderContent = () => {
    if (!reportData) {
      return (
        <Paper
          sx={{
            height: '100vh',
            pl: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
            pr: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
          }}
        >
          <Loader />
        </Paper>
      )
    }

    return (
      <>
        {/* Filters */}
        <RenderChips
          filters={filterData.sales}
          onSelected={onSelected}
          scrollContainer
          selected={dateFilter}
        />
        <Typography
          color="black.main"
          fontWeight="bold"
          sx={{ margin: `${theme.space[5]}px 0` }}
          variant="h2"
        >
          {GENERAL}
        </Typography>

        <StoreScoreCards data={reportData.error ? EMPTY_REPORT : reportData} />

        {renderChart()}

        {pRanking && pRanking.product_ranking && (
          <MostSelledProducts productsList={pRanking.product_ranking} />
        )}
        <Typography color="black.main" fontWeight="bold" variant="h2">
          {STORE_ACTIVITY}
        </Typography>
        <StoreActivityMetrics
          storeActivity={reportData.error ? EMPTY_REPORT : reportData}
        />
      </>
    )
  }

  return (
    <Layout>
      <ContainerCard>
        <Box sx={{ marginBottom: `${theme.space[5]}px` }}>
          {dashboardSettings.SHOW_TUTORIAL_BANNER && (
            <Box sx={{ marginBottom: '24px', padding: '3px' }}>
              <BannerSlider clevertapEventName={EVENTS.REPORTES_VIEWED} />
            </Box>
          )}
          {renderContent()}
        </Box>
      </ContainerCard>
    </Layout>
  )
}

export default Reports
