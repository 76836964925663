import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { SaveButton } from 'components/Catalog-v2'
import ModalCustom from '../ModalCustom'

const ModalAdaptable = ({
  children,
  title,
  description,
  disabledButtonSave,
  cancelText,
  acceptText,
  loading,
  onAccept,
  onClose,
  open,
}) => {
  const theme = useTheme()

  return (
    <ModalCustom onClose={onClose} open={open}>
      <Typography
        color="black"
        fontWeight={theme.fontWeight.semibold}
        sx={{ mb: `${theme.space[2]}px` }}
        variant="h3"
      >
        {title}
      </Typography>

      <Typography
        component="p"
        fontWeight={theme.fontWeight.regular}
        variant="body1"
      >
        {description}
      </Typography>
      {children}
      <SaveButton
        cancelText={cancelText}
        disabled={disabledButtonSave}
        fullWidth
        handleCancel={onClose}
        handleSave={onAccept}
        loading={loading}
        saveText={acceptText}
      />
    </ModalCustom>
  )
}

export default ModalAdaptable

ModalAdaptable.propTypes = {
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.node,
  disabledButtonSave: PropTypes.bool,
  loading: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

ModalAdaptable.defaultProps = {
  acceptText: '',
  cancelText: '',
  children: '',
  description: '',
  disabledButtonSave: false,
  loading: false,
}
