import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useTheme } from '@mui/material/styles'
import { DateRange } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import ModalBox from 'components/Modal/ModalBox'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

const CalendarRange = ({ minDate, onChange, onClose, open, rangeState }) => {
  const theme = useTheme()

  const onRangeFocusChange = (dateRange) => {
    if (dateRange[0] === 0 && dateRange[0] === dateRange[1]) {
      onClose()
    }
  }

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      closeAfterTransition
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiBox-root': {
          borderBottomLeftRadius: theme.borderRadius[0],
          borderBottomRightRadius: theme.borderRadius[0],
          bottom: { sm: 'unset', xs: 'initial' },
          position: { sm: 'absolute', xs: 'fixed' },
          top: { sm: '50%', xs: 'initial' },
          width: { sm: 380, xs: '99%' },
        },
        '& .rdrDateDisplayWrapper': {
          display: 'none',
        },
        '& .rdrDayToday .rdrDayNumber > span::after': {
          backgroundColor: theme.palette.primary.main,
        },
        '& .rdrMonthAndYearWrapper': {
          '& .rdrNextPrevButton': {
            backgroundColor: theme.palette.secondary.light,
          },
          '& .rdrNextPrevButton i': {
            borderLeftColor: theme.palette.primary.main,
            borderRightColor: 'transparent',
          },
          '& .rdrPprevButton i': {
            borderLeftColor: 'transparent',
            borderRightColor: theme.palette.primary.main,
          },
        },
        '&.MuiModal-root': {
          alignItems: { sm: 'initial', xs: 'center' },
          display: { sm: 'block', xs: 'flex' },
          top: { sm: 0, xs: 'initial' },
        },
      }}
    >
      <Fade in={open}>
        <ModalBox>
          <DateRange
            editableDateInputs={false}
            locale={locales.es}
            minDate={minDate}
            moveRangeOnFirstSelection
            onChange={onChange}
            onRangeFocusChange={onRangeFocusChange}
            rangeColors={[theme.palette.primary.main]}
            ranges={rangeState}
          />
        </ModalBox>
      </Fade>
    </Modal>
  )
}

export default CalendarRange

CalendarRange.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rangeState: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
    })
  ).isRequired,
}

CalendarRange.defaultProps = {
  minDate: null,
}
