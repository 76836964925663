import { useState } from 'react'
import PropTypes from 'prop-types'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import { useTheme } from '@mui/material/styles'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import CollapsibleTable from 'components/CollapsibleTable'

import Tabs from './tabs'
import TabPanel from './tabPanel'

import TAB_MANAGER from './constData.json'

const a11yProps = (index) => ({
  'aria-controls': `sales-tabpanel-${index}`,
  id: `sales-tab-${index}`,
})

const SalesTabManager = ({ dataSet, loading, onChange }) => {
  const [value, setValue] = useState(0)
  const theme = useTheme()
  const { device } = useGlobalContext()

  const handleChange = (event, newValue) => {
    const tabsLabel = ['todas', 'pendientes', 'cerradas']

    setValue(newValue)
    onChange(newValue)

    cleverTap.event(EVENTS.VENTAS_VIEWED, {
      Click: PV[tabsLabel[newValue]],
      Device: device.type,
    })
  }

  const renderBadge = (count, position) => {
    if (loading || value !== position) return ''

    return (
      <Badge
        badgeContent={count}
        color="primary"
        max={99}
        showZero
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor:
              value === position
                ? theme.palette.primary.main
                : theme.palette.text.disabled,
          },
        }}
      />
    )
  }

  if (!dataSet) return null

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          aria-label={TAB_MANAGER.SALES_MADE}
          onChange={handleChange}
          scrollButtons={false}
          value={value}
          variant="scrollable"
        >
          <Tab
            icon={value === 0 ? renderBadge(dataSet.length, 0) : ''}
            label={TAB_MANAGER.ALL_SALES}
            {...a11yProps(0)}
          />
          <Tab
            icon={value === 1 ? renderBadge(dataSet.length, 1) : ''}
            label={TAB_MANAGER.PENDING_SALES}
            {...a11yProps(1)}
          />
          <Tab
            icon={value === 2 ? renderBadge(dataSet.length, 2) : ''}
            label={TAB_MANAGER.DELIVERED_SALES}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel index={0} value={value}>
        <CollapsibleTable loading={loading} rowData={dataSet} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CollapsibleTable loading={loading} rowData={dataSet} />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <CollapsibleTable loading={loading} rowData={dataSet} />
      </TabPanel>
    </Box>
  )
}

export default SalesTabManager

SalesTabManager.propTypes = {
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      additional_details: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.string,
      payer: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
      }),
      product_ids: PropTypes.arrayOf(PropTypes.number),
      status: PropTypes.string,
      total_paid_amount: PropTypes.number,
      updated_at: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

SalesTabManager.defaultProps = {
  dataSet: null,
  loading: false,
}
