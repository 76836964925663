const PROPERTIES_VALUES = {
  activar_envios_cercanos: 'Activar envíos cercanos',
  activar_envios_pais: 'Activar envíos a todo el país',
  activar_retiro_tienda: 'Activar retiro en tienda',
  activar_tienda: 'Activar tienda',
  agregar_codigo_postal: 'Agregar código postal',
  agregar_horarios: 'Agregar horarios',
  ayuda: 'Ayuda',
  back_to_md: 'Back to MD',
  cancel: 'Cancel',
  catalogo: 'Catálogo',
  cerradas: 'Cerradas',
  cerrar_sesion: 'Cerrar sesión',
  click_email: 'Click en email',
  click_telefono: 'Click en teléfono',
  click_whatsapp: 'Click en whatsapp',
  color_selection: 'Color selection',
  completar_datos_contacto: 'Completar datos de contacto',
  configuracion: 'Configuración',
  continue: 'Continue',
  cover_upload: 'Cover upload',
  date_filter: 'Date filter',
  datos_contacto: 'Datos de contacto',
  desactivar_envios_cercanos: 'Desactivar envíos cercanos',
  desactivar_envios_pais: 'Desactivar envíos a todo el país',
  desactivar_retiro_tienda: 'Desactivar retiro en tienda',
  desactivar_tienda: 'Desactivar tienda',
  design_selection: 'Design selection',
  desktop: 'Desktop',
  detalle_venta: 'Detalle de venta',
  diseno: 'Diseño',
  editar_slug: 'Editar slug',
  envios: 'Envíos',
  expandir_pregunta: 'Expandir pregunta',
  go_back: 'Go Back',
  go_to_catalog: 'Go to catalog',
  guardar: 'Guardar',
  informacion: 'Información',
  mi_tienda: 'Mi tienda',
  mobile: 'Mobile',
  mostrar_direccion: 'Mostrar dirección',
  notificaciones: 'Notificaciones',
  ocultar_direccion: 'Ocultar dirección',
  pendientes: 'Pendientes',
  preguntas_frecuentes: 'Preguntas frecuentes',
  preview_store: 'Preview store',
  publish_copy_url: 'copy-url',
  publish_modal_close: 'Close',
  publish_share_email: 'share-email',
  publish_share_facebook: 'share-facebook',
  publish_share_whatsapp: 'share-whatsapp',
  reportes: 'Reportes',
  resenas: 'Reseñas',
  save: 'Save',
  search: 'Search',
  tiempo_maximo_envio: 'Tiempo de máximo envío',
  tiempo_preparacion: 'Tiempo de preparación',
  todas: 'Todas',
  tutorial_banner: 'Tutorial banner',
  tutoriales: 'Tutoriales',
  tyc: 'Terms and conditions',
  ubicacion_horarios: 'Ubicación y horarios',
  ventas: 'Ventas',
  ver_tienda: 'Ver tienda',
  ver_tutorial: 'Ver tutorial',
  viewed: 'Viewed',
  wizardCatalog: 'Wizard-Catalog',
  wizardConfiguration: 'Wizard-configuration',
  wizardDesign: 'Wizard-Design',
  wizardPublish: 'Wizard-publish',
  wizardTributary: 'Wizard-tributary',
}

export default PROPERTIES_VALUES
