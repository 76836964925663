const CUSTOMIZE_YOUR_STORE = 'Personalización'
const DEFAULT_COLOR = 'vermilion'
const DEFAULT_TEMPLATE = 'retail'
const PREVIEW_STORE = 'Previsualizar'
const COVER_IMAGE = 'Imagen de portada'
const CHOOSE_DESIGN_STORE = 'Elige el diseño de tu tienda'
const COLORS = 'Colores'
const CANCEL = 'Cancelar'
const SAVE = 'Guardar'

export {
  CANCEL,
  CHOOSE_DESIGN_STORE,
  COLORS,
  COVER_IMAGE,
  CUSTOMIZE_YOUR_STORE,
  DEFAULT_COLOR,
  DEFAULT_TEMPLATE,
  PREVIEW_STORE,
  SAVE,
}
