import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import TextArea from 'components/TextArea'
// import { ADDRESS, ALT_LOCATION } from 'constants/settingsPage'
import SETTINGS from 'components/Settings/constData.json'
import ChangeDirectionStoreText from './changeDirectionStoreText'

const DirectionAndLocation = ({ address, imgUrl, isDesktop }) => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        flexDirection: { sm: 'row', xs: 'column-reverse' },
        mb: `${theme.space[2]}px`,
      }}
    >
      <TextArea
        defaultValue={address}
        id="standard-multiline"
        InputProps={{ disableUnderline: true, disabled: true }}
        label={SETTINGS.ADDRESS}
        multiline
        rows={3}
        sx={{
          mr: `${theme.space[5]}px`,
          mt: { sm: 0, xs: `${theme.space[4]}px` },
        }}
        variant="standard"
      />
      {!isDesktop && <ChangeDirectionStoreText />}
      <Box
        sx={{
          height: 112,
          mb: { sm: 0, xs: `${theme.space[4]}px` },
          width: { sm: 248, xs: '100%' },
        }}
      >
        <img
          alt={SETTINGS.ALT_LOCATION}
          height="112"
          src={imgUrl}
          style={{ borderRadius: theme.borderRadius[0] }}
          width="100%"
        />
      </Box>
    </Stack>
  )
}

export default DirectionAndLocation

DirectionAndLocation.propTypes = {
  address: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool,
}

DirectionAndLocation.defaultProps = {
  isDesktop: true,
}
