import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import MENU from 'components/Menu/constData.json'

const openedMixin = (theme) => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: MENU.MENU_MAX_WIDTH,
})

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: MENU.MENU_MIN_WIDTH,
})
const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '& .MuiDrawer-paper': {
    alignItems: open ? 'flex-start' : 'center',
    backgroundColor: theme.backgrounds[1],
    borderRight: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    position: 'fixed',
    width: open ? MENU.MENU_MAX_WIDTH : MENU.MENU_MIN_WIDTH,
  },
  '& .MuiList-root': {
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      justifyContent: 'center',
    },
    '& a': {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
      textDecoration: 'none',
    },
    '& a.active': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      display: 'flex',
    },
    width: '100%',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 24,
    width: 'auto',
  },
  '& .MuiListItemText-root': {
    paddingLeft: theme.space[4],
  },
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: open ? MENU.MENU_MAX_WIDTH : MENU.MENU_MIN_WIDTH,
}))

export default Drawer
