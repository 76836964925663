import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Button from 'components/Button'
import { Plus } from 'components/Icons'
import { useTheme } from '@mui/material'

const ProductCategoryDesktop = ({ btnTxt, btnURL }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Stack
      flexDirection="row"
      sx={{
        justifyContent: { md: 'flex-end', sm: 'space-between' },
        width: { md: 'auto', xs: '100%' },
      }}
    >
      <Button
        color="secondary"
        onClick={() => navigate(btnURL.category)}
        size="large"
        startIcon={<Plus color={theme.palette.primary.main} />}
        sx={{ mr: { sm: `${theme.space[5]}px`, xs: 0 } }}
      >
        {btnTxt.category}
      </Button>
      <Button
        color="orange"
        onClick={() => navigate(btnURL.product)}
        size="large"
        startIcon={<Plus color={theme.palette.white.main} />}
      >
        {btnTxt.product}
      </Button>
    </Stack>
  )
}

export default ProductCategoryDesktop

ProductCategoryDesktop.propTypes = {
  btnTxt: PropTypes.shape({
    category: PropTypes.string,
    product: PropTypes.string,
  }),
  btnURL: PropTypes.shape({
    category: PropTypes.string,
    product: PropTypes.string,
  }),
}

ProductCategoryDesktop.defaultProps = {
  btnTxt: {
    category: '',
    product: '',
  },
  btnURL: { category: '', product: '' },
}
