import _ from 'lodash'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'
import { getDateTitle } from 'utils/dateTools'
import NumberFormat from 'react-number-format'
import TableCell from './tableCell'
import Row from './row'
import useStyles from '../styles'

const {
  data: { labels: TABLE },
} = require('../constData.json')

const RowGroup = ({ keyRowGroup, rowGroupData }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellDate} colSpan={6}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography fontWeight="bold" variant="body2">
              {getDateTitle(keyRowGroup)}
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              {TABLE.SELLED}
              <NumberFormat
                allowEmptyFormatting
                decimalScale="2"
                decimalSeparator="."
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator=","
                thousandsGroupStyle="thousand"
                type="text"
                value={_.sumBy(rowGroupData, (sale) => Number(sale.total))}
              />
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>

      {rowGroupData.map((row) => (
        <Row key={row.id} row={row} />
      ))}
    </>
  )
}

RowGroup.propTypes = {
  keyRowGroup: PropTypes.string.isRequired,
  rowGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      client: PropTypes.string,
      id: PropTypes.string.isRequired,
      product_ids: PropTypes.arrayOf(PropTypes.number),
      products: PropTypes.string,
      status: PropTypes.string,
      time: PropTypes.string,
      total: PropTypes.string,
    })
  ).isRequired,
}

export default RowGroup
