import _ from 'lodash'
import PropTypes from 'prop-types'
import { Box, Typography, useTheme } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import formatChartData from './utils'
import CHARTS from '../constData.json'

import useStyles from './styles'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const SalesChart = ({ sales, dateFrom, dateTo }) => {
  const classes = useStyles()
  const theme = useTheme()

  const { labels, chartData } = formatChartData(sales, dateFrom, dateTo)

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.palette.black.main,
        bodyAlign: 'center',
        bodyFont: {
          size: 24,
          weight: 'bold',
        },
        callbacks: {
          label: (tooltipItem) =>
            tooltipItem.parsed.y.toLocaleString('en-US', {
              currency: 'USD',
              style: 'currency',
            }),
        },
        caretPadding: 2,
        displayColors: false,
        padding: {
          bottom: 5,
          left: 15,
          right: 15,
          top: 5,
        },
        titleAlign: 'center',
        titleFont: {
          size: 14,
          weight: 'normal',
        },
        yAlign: 'bottom',
      },
    },
    responsive: true,
  }

  const getBackgroundColors = (orderData) => {
    const backgrounds = []
    const orange = theme.palette.primary.main
    const grey = theme.backgrounds[2]
    const max = Math.max(...Array.from(orderData, (o) => o))

    _.each(orderData, (orderAmount) => {
      if (orderAmount === max) {
        backgrounds.push(orange)
      } else {
        backgrounds.push(grey)
      }
    })

    return backgrounds
  }

  const renderChart = () => {
    const data = {
      datasets: [
        {
          backgroundColor: getBackgroundColors(chartData),
          data: chartData,
          hoverBackgroundColor: getBackgroundColors(chartData),
        },
      ],
      labels,
    }

    return (
      <Box className={classes.sales}>
        <Bar data={data} options={options} />
      </Box>
    )
  }

  return (
    <Box className={classes.salesChart}>
      <Typography component="h3">{CHARTS.INCOME_SALES_STORE}</Typography>
      {renderChart()}
    </Box>
  )
}

export default SalesChart

SalesChart.propTypes = {
  sales: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      date: PropTypes.shape,
    })
  ),
}

SalesChart.defaultProps = {
  sales: [
    {
      count: '',
      date: '',
    },
  ],
}
