import { styled } from '@mui/material/styles'
import { Tabs as MuiTabs } from '@mui/material'

const Tabs = styled(MuiTabs)(({ theme }) => ({
  // Badge //
  '& .MuiBadge-badge': {
    borderRadius: theme.borderRadius[1],
    height: 24,
    minWidth: 24,
    right: -20,
  },
  '& .MuiButtonBase-root.MuiTab-root': {
    '& .MuiTab-iconWrapper': {
      marginBottom: 0,
    },
    '&#sales-tab-0': {
      paddingLeft: theme.space[2],
    },
    '&.Mui-selected': {
      color: theme.palette.black.main,
    },
    flexDirection: 'row-reverse',
    fontSize: 16,
    fontWeight: theme.fontWeight.semibold,
    justifyContent: 'center',
    lineHeight: 24 / 16,
    minHeight: 45,
    minWidth: '33.33%',
    textTransform: 'Capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 20 / 12,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
  borderBottom: `1px solid ${theme.backgrounds[1]}`,
  marginTop: `${theme.space[5]}px`,
}))

export default Tabs
