const INIT_SESSION = 'Iniciar Sesión'
const INIT_SESSION_DESC =
  'Inicia sesión con tu cuenta Clip para comenzar a vender en línea.'
const EMAIL = 'Correo electrónico'
const PASSWORD = 'Contraseña'
const FORGOT_PASS = '¿Olvidaste tu contraseña?'
const DO_YOU_HAVE_ACC = '¿No tienes cuenta?'
const REGISTER = 'Registrate aquí'
const ERROR_LOGIN = 'Usuario o contraseña inválidos'

export {
  INIT_SESSION,
  INIT_SESSION_DESC,
  EMAIL,
  PASSWORD,
  FORGOT_PASS,
  DO_YOU_HAVE_ACC,
  REGISTER,
  ERROR_LOGIN,
}
