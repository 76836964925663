import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { Chevron, Warning } from 'components/Icons'
import Loader from 'components/Loader'
import { useAuthState } from 'store/user'
import { MenuContext } from 'components/Menu/store'
import MENU_ACTIONS from 'components/Menu/store/actions'
import fetchData from 'hooks/fetchData'
import { isPartialValidation } from 'utils/validation'
import { ENV, GET_CATALOG_ROUTE, ONBOARDING_ROUTE } from 'constants/routes'

import store from 'components/StoreWelcome/data'

const { warning: PM_WARNING } = require('./constData.json')

const WarningModal = () => {
  const theme = useTheme()
  const { merchantToken, storeConfig } = useAuthState()
  const { menuDispatch } = useContext(MenuContext)
  const { sections: SECTIONS } = store
  const [loading, setLoading] = useState(true)

  const { data: catalogSummary } = fetchData(
    `${ENV.REACT_APP_API_URL}${GET_CATALOG_ROUTE}?mt=${merchantToken}`
  )

  useEffect(() => {
    if (storeConfig && catalogSummary) setLoading(false)
  }, [storeConfig, catalogSummary])
  if (loading)
    return (
      <Stack justifyContent="center" sx={{ minHeight: '40vh' }}>
        <Loader />
      </Stack>
    )

  const handleClickNavLink = (url) => {
    menuDispatch({ selected: url, type: MENU_ACTIONS.UPDATE_SELECTED })
  }

  const renderRow = (text, url) => (
    <Typography
      component="p"
      sx={{
        '& a': {
          alignItems: 'center',
          color: theme.palette.black.main,
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          pb: `${theme.space[5]}px`,
          pl: 0,
          pr: `${theme.space[1]}px`,
          pt: `${theme.space[5]}px`,
          textDecoration: 'none',
        },
      }}
      variant="h4"
    >
      <NavLink onClick={() => handleClickNavLink(url)} to={url}>
        {text}
        <Chevron />
      </NavLink>
    </Typography>
  )

  if (!catalogSummary || !storeConfig) return null

  return (
    <>
      <Typography
        component="p"
        sx={{
          alignItems: 'center',
          backgroundColor: theme.palette.warning.light,
          borderRadius: `${theme.borderRadius[0]}px`,
          color: theme.palette.warning.main,
          display: 'flex',
          fontWeight: theme.fontWeight.semibold,
          mt: `${theme.space[4]}px`,
          padding: `${theme.space[4]}px`,
        }}
        variant="body2"
      >
        <Warning color={theme.palette.warning.main} />
        <i>&nbsp;</i>
        <i>&nbsp;</i>
        {PM_WARNING.MESSAGE}
      </Typography>

      {storeConfig.tributary === false ? (
        <>
          {renderRow(SECTIONS[3].text, ONBOARDING_ROUTE)}
          <Divider light sx={{ borderColor: theme.backgrounds[1] }} />
        </>
      ) : null}

      {catalogSummary.data.length === 0 && (
        <>
          {renderRow(SECTIONS[0].text, SECTIONS[0].urlPath)}
          <Divider light sx={{ borderColor: theme.backgrounds[1] }} />
        </>
      )}

      {!isPartialValidation(storeConfig) ? (
        <>
          {renderRow(SECTIONS[2].text, SECTIONS[2].urlPath)}
          <Divider light sx={{ borderColor: theme.backgrounds[1] }} />
        </>
      ) : null}

      {!storeConfig.store_settings?.theme_id
        ? renderRow(SECTIONS[1].text, SECTIONS[1].urlPath)
        : null}

      <Divider sx={{ borderColor: theme.backgrounds[1] }} variant="middle" />
    </>
  )
}

export default WarningModal
