import { Chip as MuiChip } from '@mui/material'
import { styled } from '@mui/material/styles'

const Chip = styled(MuiChip, {
  // Configure which props should be forwarded on DOM
  name: 'MuiChip',
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === 'primary' && styles.primary,
    props.color === 'secondary' && styles.secondary,
  ],
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'variant' && prop !== 'sx',
  // We are specifying here how the styleOverrides are being applied based on props
  slot: 'Root',
})(({ theme }) => ({
  '& .MuiChip-label': {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  '&:hover': {
    backgroundColor: theme.backgrounds[1],
  },
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.backgrounds[2]}`,
  borderRadius: theme.borderRadius[3],
  color: theme.palette.black.main,
  display: 'flex',
  fontWeight: theme.fontWeight.semibold,

  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    lineHeight: 18 / 12,
  },
}))

export default Chip
