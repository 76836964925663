import PropTypes from 'prop-types'
import { Stack, useTheme } from '@mui/material'
import ScrollContainer from 'react-indiana-drag-scroll'
import Chip from './chip'

const RenderFilters = ({ filters, onSelected, scrollContainer, selected }) => {
  const theme = useTheme()

  const renderChips = () =>
    filters.map((filter) => (
      <Chip
        key={filter.id}
        disabled={!selected}
        label={filter.label}
        onClick={() => onSelected(filter)}
        shape="button"
        sx={{
          backgroundColor:
            selected === filter.id && theme.palette.secondary.light,
          borderColor: selected === filter.id && theme.palette.secondary.light,
          color: selected === filter.id && theme.palette.primary.main,
        }}
      />
    ))

  return scrollContainer ? (
    <ScrollContainer style={{ display: 'flex' }}>
      {renderChips()}
    </ScrollContainer>
  ) : (
    <Stack flexDirection="row">{renderChips()}</Stack>
  )
}

export default RenderFilters

RenderFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  onSelected: PropTypes.func,
  scrollContainer: PropTypes.bool,
  selected: PropTypes.number,
}

RenderFilters.defaultProps = {
  onSelected: () => {},
  scrollContainer: false,
  selected: 1,
}
