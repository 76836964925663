import PropTypes from 'prop-types'
import TextField from 'components/TextField'
import { useTheme } from '@mui/material'
import { ErrorIcon } from 'components/Icons'
import { onlyNumber } from 'utils/form'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'

const { coupon: COUPON } = require('components/Coupon/constData.json')

const TotalCodes = ({ disabled, error, errorMessage }) => {
  const theme = useTheme()
  const { couponData, couponDispatch } = useCouponContext()

  const handleChange = (event) => {
    if (event.target.value > 0 || event.target.value === '')
      couponDispatch({
        couponData: { total_to_be_used: event.target.value },
        type: COUPON_ACTIONS.UPDATE_COUPON,
      })
  }

  const handleKeyPress = (event) => {
    if (onlyNumber(event.key)) {
      event.preventDefault()
    }
  }

  return (
    <TextField
      data-testid="coupons-limit-input"
      disabled={disabled}
      error={error}
      fullWidth
      helperText={error && errorMessage}
      InputProps={{
        endAdornment: error && <ErrorIcon />,
      }}
      label={COUPON.LIMIT_CODES}
      name="total_to_be_used"
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      sx={{ mb: `${theme.space[4]}px` }}
      value={couponData?.total_to_be_used || ''}
      variant="filled"
    />
  )
}

export default TotalCodes

TotalCodes.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

TotalCodes.defaultProps = {
  disabled: false,
  error: false,
  errorMessage: '',
}