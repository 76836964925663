import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Paper from 'components/Paper'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Modal from '@mui/material/Modal'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import WrapIcon from 'components/WrapIcon'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Button from 'components/Button'
import generateKey from 'utils/math'
import { Arrow, Chevron, Play } from 'components/Icons'
import { TUTORIALS_ROUTE } from 'constants/routes'
import { TUTORIALS, BACK } from 'constants/page'
import { useTheme } from '@mui/material'

const Articles = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { file, title } = useParams()
  const [post, setPost] = useState({})
  const [open, setOpen] = useState(false)

  const YOUTUBE = 'youtube.com'

  const handleOpen = (fileName) => {
    fetch(`/articles/${fileName}.json`)
      .then((res) => res.json())
      .then((data) => {
        setPost(data)
      })
      .catch((err) =>
        // eslint-disable-next-line no-console
        console.log(err)
      )
  }

  const redirect = (link) => {
    navigate(link)
  }

  useEffect(() => {
    handleOpen(file)
  }, [])

  const { header, sections } = post

  const getUrlImage = (url) =>
    url || `//img.${YOUTUBE}/vi/${header?.video}/0.jpg`

  const Player = (id) => (
    <iframe
      height="100%"
      src={`//${YOUTUBE}/embed/${id}`}
      style={{ border: 'none' }}
      title={id}
      width="100%"
    />
  )

  return (
    <Layout>
      <ContainerCard>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=""
          sx={{ margin: '10px 0 30px' }}
        >
          <WrapIcon
            onClick={() => redirect(TUTORIALS_ROUTE)}
            sx={{ cursor: 'pointer', padding: 0 }}
          >
            <Arrow size={1} />
          </WrapIcon>

          <Typography
            color={theme.palette.text.primary}
            component="h4"
            onClick={() => redirect(TUTORIALS_ROUTE)}
            sx={{ cursor: 'pointer' }}
            variant="body1"
          >
            {TUTORIALS}
          </Typography>
          <WrapIcon sx={{ padding: 0 }}>
            <Chevron />
          </WrapIcon>
          <Typography>{title}</Typography>
        </Breadcrumbs>

        <Paper sx={{ mb: 5 }}>
          {header && (
            <Grid container sx={{ justifyContent: 'space-between', mb: 6 }}>
              <Grid
                item
                md={6}
                sx={{ alignItems: 'center', display: 'flex', pr: '54px' }}
                xs={12}
              >
                <Typography
                  color="text.primary"
                  fontWeight={theme.fontWeight.bold}
                  sx={{ alignItems: 'center', display: 'flex' }}
                  variant="hxxl"
                >
                  {header.title || ''}
                </Typography>
              </Grid>

              <Grid item md={6} sx={{ position: 'relative' }} xs={12}>
                <Avatar
                  alt="alt"
                  src={getUrlImage(post?.header?.img)}
                  sx={{ height: '100%', width: '100%' }}
                  variant="rounded"
                />
                {header?.video && (
                  <IconButton
                    name="btn-video"
                    onClick={() => setOpen(true)}
                    sx={{
                      cursor: 'pointer',
                      left: '50%',
                      p: 0,
                      position: 'absolute',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Play size={4} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
          {sections?.map((item, i) => (
            <Stack key={`key-${generateKey(i)}`} sx={{ mb: 6 }}>
              {item.title !== '' && (
                <Typography
                  fontWeight={theme.fontWeight.semibold}
                  sx={{ marginBottom: `${theme.space[2]}px` }}
                  variant="h4"
                >
                  {item.title}
                </Typography>
              )}
              {Array.isArray(item.description) ? (
                <Box sx={{ pl: 3 }}>
                  <ul>
                    {item.description.map(
                      (it, id) =>
                        it !== '' && (
                          <li key={`key-${generateKey(id)}`}>{it}</li>
                        )
                    )}
                  </ul>
                </Box>
              ) : (
                item.description !== '' && (
                  <Typography
                    fontWeight={theme.fontWeight.regular}
                    variant="body1"
                  >
                    {item.description}
                  </Typography>
                )
              )}
            </Stack>
          ))}

          <Button
            onClick={() => redirect(TUTORIALS_ROUTE)}
            startIcon={<Arrow />}
            sx={{
              backgroundColor: theme.backgrounds[0],
              color: theme.palette.text.primary,
              margin: '12px 0',
              padding: '12px 16px',
            }}
          >
            {BACK}
          </Button>
        </Paper>

        {header?.video && (
          <Modal onClose={() => setOpen(false)} open={open}>
            <Box
              sx={{
                height: { md: '60%', xs: '30%' },
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: { md: '60%', xs: '95%' },
              }}
            >
              {Player(header?.video)}
            </Box>
          </Modal>
        )}
      </ContainerCard>
    </Layout>
  )
}

export default Articles
