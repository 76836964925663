import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import { SaveButton } from 'components/Catalog-v2'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import { ErrorIcon } from 'components/Icons'
import { isValidRFC } from 'utils/validation'
import ModalCustom from '../ModalCustom'

const { data } = require('./constData.json')

const pFisicaValue = 'Fisica'
const pMoralValue = 'Moral'

const TributaryDataModal = ({ onClose, open, handleContinue }) => {
  const theme = useTheme()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { rfc: '', rfcCheck: '', type: data.pFisica },
    onSubmit: (values) => {
      setIsSubmitting(true)
      handleContinue({
        mystore_fiscal_type: values.type,
        mystore_rfc: values.rfc,
      })
    },
    validationSchema: yup.object().shape({
      rfc: yup
        .string()
        .test('is-empty-rfc', data.rfcEmpty, (value) => {
          if (formik.values.type === pMoralValue && !value) {
            return false
          }

          return true
        })
        .test('is-valid-rfc', data.rfcError, (value) => {
          if (formik.values.type === pFisicaValue && !value) {
            return true
          }

          return isValidRFC(value)
        }),
      rfcCheck: yup.string().test('is-checked', data.rfcCheckError, (value) => {
        if (formik.values?.rfc?.length && !value) {
          return false
        }

        return true
      }),
    }),
  })

  const handleChange = (e) => {
    formik.handleChange(e)
  }

  return (
    <ModalCustom onClose={onClose} open={open} sx={{ width: { sm: 573 } }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <Stack>
            <Typography
              component="h2"
              sx={{
                display: 'flex',
                fontSize: { sm: 24, xs: 18 },
                justifyContent: 'space-between',
                lineHeight: { sm: 36 / 24, xs: 28 / 18 },
              }}
              variant="h1"
            >
              {data.title}
            </Typography>
            <Typography
              color="text.primary"
              fontWeight="normal"
              sx={{
                fontSize: 14,
                lineHeight: '20px',
              }}
              variant="h4"
            >
              {data.description}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {data.pTitle}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <Stack direction="row">
                  <FormControlLabel
                    control={
                      <Radio
                        name="type"
                        onChange={handleChange}
                        required
                        value={pFisicaValue}
                      />
                    }
                    label={data.pFisica}
                    value={pFisicaValue}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        name="type"
                        onChange={handleChange}
                        required
                        value={pMoralValue}
                      />
                    }
                    label={data.pMoral}
                    value={pMoralValue}
                  />
                </Stack>
              </RadioGroup>
              <Divider
                sx={{
                  borderColor: theme.backgrounds[1],
                  mt: 1,
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{data.rfcTitle}</FormLabel>
              <TextField
                error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                helperText={formik.touched.rfc && formik.errors.rfc}
                id="rfc"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik.touched?.rfc && formik.errors?.rfc ? (
                        <ErrorIcon />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                label={data.rfcLabel}
                name="rfc"
                onChange={handleChange}
                sx={{ mt: 1 }}
                value={formik.values?.rfc}
                variant="outlined"
              />
            </FormControl>

            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    error={formik.touched.rfcCheck && formik.errors.rfcCheck}
                    id="rfcCheck"
                    onChange={handleChange}
                    value={formik.values?.rfcCheck}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {data.checkTxt}
                  </Typography>
                }
              />
              {formik.touched.rfcCheck && formik.errors.rfcCheck ? (
                <FormHelperText error>{formik.errors.rfcCheck}</FormHelperText>
              ) : null}
            </FormControl>

            <Alert severity="info">
              <Link
                href={data.disclosureLink}
                sx={{
                  color: 'inherit !important',
                  textDecoration: 'none',
                }}
                target="_blank"
              >
                {data.disclosure}
              </Link>
            </Alert>
          </Stack>
          <SaveButton
            cancelText={data.cancelBtn}
            disabled={isSubmitting}
            fullWidth
            handleCancel={onClose}
            isSubmit
            loading={isSubmitting}
            saveText={data.saveBtn}
          />
        </Stack>
      </form>
    </ModalCustom>
  )
}

export default TributaryDataModal

TributaryDataModal.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
