import { NavLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Card from 'components/ContainerCard'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import WrapIcon from 'components/WrapIcon'
import { Faq } from 'components/Icons'
import generateKey from 'utils/math'
import HELP_CARD from './constData.json'
import useStyles from './styles'

const HelpCard = () => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Stack>
      <Typography
        fontWeight={theme.fontWeight.bold}
        sx={{ mb: `${theme.space[5]}px` }}
        variant="h2"
      >
        {HELP_CARD.title}
      </Typography>
      <Typography className={classes.description} variant="h4">
        {HELP_CARD.description}
      </Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: 0,
        }}
      >
        {HELP_CARD.cards.map((card, idx) => (
          <Card key={generateKey(idx)} className={classes.card}>
            <NavLink to={card.url}>
              <CardActionArea>
                <WrapIcon
                  sx={{
                    backgroundColor: theme.backgrounds[3],
                    height: 84,
                    width: '100%',
                  }}
                >
                  <Faq color={theme.palette.primary.main} size={2.25} />
                </WrapIcon>
                <CardContent sx={{ padding: `${theme.space[5]}px 0` }}>
                  <Typography align="center" variant="h4">
                    {card.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}

export default HelpCard
