import { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import Layout from 'components/Layout'
import Paper from 'components/Paper'
import ContainerCard from 'components/ContainerCard'
import HelpCard from 'components/HelpCard'
import HelpMedia from 'components/HelpMedia'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'

const Help = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()

  useEffect(() => {
    cleverTap.event(EVENTS.AYUDA_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  return (
    <Layout>
      <ContainerCard>
        <Paper
          sx={{
            padding: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
          }}
        >
          <HelpCard />
          <Divider
            light
            sx={{
              backgroundColor: theme.backgrounds[1],
              m: { sm: `${theme.space[5]}px 0`, xs: '0 0' },
            }}
          />
          <HelpMedia clevertapEventName={EVENTS.AYUDA_VIEWED} />
        </Paper>
      </ContainerCard>
    </Layout>
  )
}

export default Help
