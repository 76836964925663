import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ListItem from 'components/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import RowGrid from 'components/RowGrid'
import CustomPopover from 'components/Popover'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import { Chip } from 'components/Chip'
import StatusMessages from 'components/StatusMessages'
import { Divider, useTheme } from '@mui/material'
import { Copy, Dots } from 'components/Icons'
import { useGlobalContext } from 'store/global'
import useStyles from 'components/Coupon/styles'
import { ModalAdaptable } from 'components/Modal'
import NumberFormat from 'react-number-format'

import {
  ENV,
  PROMOTIONS_COUPON_ROUTE,
  POST_COUPONS_ROUTE,
} from 'constants/routes'
import { updateInfo } from 'helpers/fetchUtil'
import { dateExpired, discountRuns } from 'helpers/coupon'

const {
  discount_counts: DATA,
  coupon: COUPON,
} = require('components/Coupon/constData.json')

const {
  product: PRODUCT,
} = require('components/Catalog-v2/product/constData.json')

const urlBase = `${ENV.REACT_APP_API_URL}${POST_COUPONS_ROUTE}`

const CouponList = ({ coupons, handleChangeStatus }) => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [curCoupon, setCurCoupon] = useState(null)
  const [flag, setFlag] = useState(null)
  const rowsPerPage = 5
  const [page, setPage] = useState(1)
  const { device } = useGlobalContext()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleClickNavigate = (cid) => {
    navigate(PROMOTIONS_COUPON_ROUTE, { state: { coupon_code: cid } })
  }

  const displayRows = () => {
    const start = (page - 1) * rowsPerPage + 1
    const end =
      page * rowsPerPage < coupons.length ? page * rowsPerPage : coupons.length

    return `${start} ${DATA.PAGINATION.RANGE_SEP} ${end} ${DATA.PAGINATION.OF} ${coupons.length}`
  }

  const pages = Math.ceil(coupons.length / rowsPerPage)

  const handlePopupClick = (event, coupon) => {
    setCurCoupon(coupon)
    setAnchorEl(event.currentTarget)
  }

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  const getDiscount = (type, total) => {
    const discount =
      type === COUPON.DISCOUNT_TYPE[0] ? (
        total + type
      ) : (
        <NumberFormat
          {...PRODUCT.PRICE_OPTIONS}
          displayType="text"
          value={total}
        />
      )

    return discount
  }

  const handleStatus = async (action) => {
    let status = ''
    const [Active, Inactive, Finished] = DATA.STATUS.OPTIONS

    if (action === DATA.STATUS_MODAL.PAUSE) {
      status = Inactive
      setCurCoupon({ ...curCoupon, status: Inactive })
    } else if (action === DATA.STATUS_MODAL.ACTIVATE) {
      status = Active
      setCurCoupon({ ...curCoupon, status: Active })
    } else if (action === DATA.STATUS_MODAL.FINISHED) {
      status = Finished
      setCurCoupon({ ...curCoupon, status: Finished })
    }
    setLoading({ [status]: true })
    handleChangeStatus(curCoupon, status)

    try {
      const response = await updateInfo(`${urlBase}/update`, {
        coupon_code: curCoupon.coupon_code,
        status,
      })

      if (response) {
        setLoading(null)
        setAnchorEl(null)

        // eslint-disable-next-line no-console
        console.log(response)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      setLoading(null)
    }
  }

  const getDescriptionFinish = () => (
    <>
      <span>{COUPON.CONFIRM.FINISH.DESCRIPTION[0]}</span>
      <b>{` "${curCoupon?.coupon_code}" `}</b>
      <span>{COUPON.CONFIRM.FINISH.DESCRIPTION[1]}</span>
    </>
  )

  const handleAccept = () => {
    handleStatus(DATA.STATUS_MODAL.FINISHED)
    setOpenConfirm(!openConfirm)
  }

  const handleClipboard = (text, result) =>
    result ? setFlag({ success: true }) : setFlag({ error: true })

  const renderModalStatus = () => (
    <CustomPopover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      onClose={onClosePopover}
      sx={{
        boxShadow: '1px 1px 1px 2px rgb(24 26 54 / 1%)',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <ListItem onClick={() => handleClickNavigate(curCoupon?.coupon_code)}>
        <ListItemText variant="h4">{DATA.STATUS_MODAL.EDIT}</ListItemText>
      </ListItem>
      {discountRuns(curCoupon) && (
        <ListItem onClick={(e) => handleStatus(e.target.innerText)}>
          <ListItemText variant="h4">
            {curCoupon?.status === DATA.STATUS.OPTIONS[0]
              ? DATA.STATUS_MODAL.PAUSE
              : DATA.STATUS_MODAL.ACTIVATE}
          </ListItemText>
          {(loading?.Active || loading?.Inactive) && (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <Loader thickness={2} />
            </Box>
          )}
        </ListItem>
      )}
      {discountRuns(curCoupon) && (
        <ListItem onClick={() => setOpenConfirm(!openConfirm)}>
          <ListItemText variant="h4">{DATA.STATUS_MODAL.FINISHED}</ListItemText>
          {loading?.Finished && (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <Loader thickness={2} />
            </Box>
          )}
        </ListItem>
      )}
    </CustomPopover>
  )

  const renderText = (text, isBold, variant) => (
    <Typography
      color={isBold ? 'black.main' : 'text.primary'}
      fontWeight={!isBold && theme.fontWeight.regular}
      sx={{ display: 'inline-flex' }}
      variant={variant}
    >
      {text}
    </Typography>
  )

  const header = () => (
    <RowGrid
      container
      sx={{ border: 'none', minWidth: { sm: '750px', xs: 'auto' } }}
    >
      <Grid item sm={3.5}>
        {renderText(DATA.GRID_COLUMNS.NAME, false, 'body1')}
      </Grid>
      <Grid item sm={2}>
        {renderText(DATA.GRID_COLUMNS.USED, false, 'body1')}
      </Grid>
      <Grid item sm={2}>
        {renderText(DATA.GRID_COLUMNS.VALIDITY, false, 'body1')}
      </Grid>
      <Grid item sm={2.5}>
        {renderText(DATA.GRID_COLUMNS.DISCOUNT, false, 'body1')}
      </Grid>
      <Grid item sm={2}>
        {renderText(DATA.GRID_COLUMNS.STATUS, false, 'body1')}
      </Grid>
    </RowGrid>
  )

  const renderStatus = (coupon) => {
    const stat = dateExpired(coupon?.end_date)
      ? DATA.STATUS.OPTIONS[2].toUpperCase()
      : coupon?.status?.toUpperCase()

    return (
      <Chip
        className={classes.chip}
        label={DATA.STATUS[stat].TEXT}
        shape="status"
        status={DATA.STATUS[stat].VARIANT}
      />
    )
  }

  const renderCouponData = (coupon) => (
    <>
      {!device.isMobile && (
        <Grid item sm={0.5}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      )}
      <Grid item sm={2} xs={3}>
        {coupon.redeemed}/{coupon.total_to_be_used}
      </Grid>
      {device.isMobile && (
        <Grid item sm={0.5}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      )}
      <Grid
        item
        sm={2}
        sx={{ textAlign: { sm: 'initial', xs: 'center' } }}
        xs={5}
      >
        {coupon?.start_date?.slice(-2)}/{coupon?.start_date?.slice(5, 7)}{' '}
        {DATA.PREPOSITIONS[0]} {coupon?.end_date?.slice(-2)}/
        {coupon?.end_date?.slice(5, 7)}
      </Grid>
      {device.isMobile && (
        <Grid item sm={0.5}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      )}
      <Grid
        item
        sm={2}
        sx={{ textAlign: { sm: 'initial', xs: 'right' } }}
        xs={3}
      >
        {getDiscount(coupon.discount_type, coupon.total_discount)}
      </Grid>
      {!device.isMobile && (
        <Grid item sm={0.5}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      )}
    </>
  )

  const renderCouponName = (coupon) => (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: {
            alignItems: 'center',
            sm: 'flex-start',
            xs: 'space-between',
          },
        }}
      >
        <Stack>
          <Typography fontWeight={theme.fontWeight.bold} variant="body1">
            {coupon.name}
          </Typography>
          <Stack direction="row">
            <Typography
              color="text.primary"
              fontWeight={theme.fontWeight.semibold}
              variant="body1"
            >
              {coupon.coupon_code}
            </Typography>
            <CopyToClipboard onCopy={handleClipboard} text={coupon.coupon_code}>
              <IconButton sx={{ ml: '6px', p: 0 }}>
                <Copy color={theme.palette.primary.main} size={1} />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        </Stack>
        {device.isMobile && renderStatus(coupon)}
      </Stack>
      {device.isMobile && (
        <Grid container sx={{ mt: { sm: 0, xs: 2 } }}>
          {renderCouponData(coupon)}
        </Grid>
      )}
    </>
  )

  const renderChangeStatusButton = (coupon) => (
    <>
      <IconButton
        disabled={
          dateExpired(coupon?.end_date) ||
          coupon.status === DATA.STATUS.OPTIONS[2]
        }
        onClick={(e) => handlePopupClick(e, coupon)}
      >
        <Dots />
      </IconButton>
    </>
  )

  const renderCouponsList = (coupon, i) => {
    const index = i + 1
    const pagination =
      page === 1
        ? index < page + rowsPerPage
        : index > rowsPerPage * (page - 1) && index <= page * rowsPerPage

    return (
      pagination && (
        <RowGrid
          key={`${coupon.name}-${coupon.coupon_code}`}
          className={classes.container}
          item
          xs={12}
        >
          <Grid item sm={3} xs={11}>
            {renderCouponName(coupon)}
          </Grid>
          {!device.isMobile && (
            <>
              {renderCouponData(coupon)}
              <Grid item sm={2}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {renderStatus(coupon)}

                  {renderChangeStatusButton(coupon)}
                </Stack>
              </Grid>
            </>
          )}
          {device.isMobile && (
            <Grid item xs={1}>
              {renderChangeStatusButton(coupon)}
            </Grid>
          )}
        </RowGrid>
      )
    )
  }

  return (
    <>
      <Box className={classes.wrapperGrid}>
        {!device.isMobile && header()}
        <Grid container sx={{ minWidth: { sm: '750px', xs: 'auto' } }}>
          {coupons.map((item, index) => renderCouponsList(item, index))}
          {renderModalStatus()}
        </Grid>
      </Box>

      {coupons.length > 0 && (
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: { sm: 'flex-end', xs: 'center' },
          }}
        >
          {!device.isMobile && displayRows()}
          <Pagination
            boundaryCount={1}
            count={pages}
            defaultPage={1}
            onChange={handleChangePage}
            page={page}
            siblingCount={1}
          />
        </Stack>
      )}

      {/* ModalConfirm Finish */}
      <ModalAdaptable
        acceptText={COUPON.CONFIRM.FINISH.BTN_ACCEPT_TEXT}
        cancelText={COUPON.CONFIRM.BTN_CANCEL_TEXT}
        description={getDescriptionFinish()}
        onAccept={handleAccept}
        onClose={() => setOpenConfirm(!openConfirm)}
        open={openConfirm}
        title={COUPON.CONFIRM.FINISH.TITLE}
      />

      <StatusMessages
        errorFlag={flag?.error || false}
        errorMessage={COUPON.MESSAGE.COPY.ERROR}
        handleClose={() => setFlag(null)}
        successFlag={flag?.success || false}
        successMessage={COUPON.MESSAGE.COPY.SUCCESS}
      />
    </>
  )
}

export default CouponList

CouponList.propTypes = {
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      coupon_code: PropTypes.string,
      discount_type: PropTypes.string,
      end_date: PropTypes.string,
      name: PropTypes.string,
      redeemed: PropTypes.number,
      start_date: PropTypes.string,
      status: PropTypes.string,
      total_discount: PropTypes.number,
      total_to_be_used: PropTypes.number,
    })
  ).isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
}
