import SYNC_ACTIONS from './actions'

const initialState = {
  delete: [],
  get: [],
  post: [],
  put: [],
}
const reducer = (state, action) => {
  switch (action.type) {
    case SYNC_ACTIONS.ACTION_DELETE:
      return {
        ...state,
        delete: [...state.delete, action.delete],
      }
    case SYNC_ACTIONS.ACTION_GET:
      return {
        ...state,
        get: [...state.get, action.get],
      }
    case SYNC_ACTIONS.ACTION_POST:
      return {
        ...state,
        post: [...state.post, action.post],
      }
    case SYNC_ACTIONS.ACTION_PUT:
      return {
        ...state,
        put: [...state.put, action.put],
      }
    case SYNC_ACTIONS.RESET:
      return initialState
    default:
      return state
  }
}

export default reducer
