import { styled } from '@mui/material/styles'
import { Pagination as MuiPagination } from '@mui/material'

const Pagination = styled(MuiPagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: theme.fontWeight.semibold,
    lineHeight: 20 / 16,
    margin: 0,
    padding: 0,
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
  },
  '& .MuiPaginationItem-root:hover': {
    backgroundColor: theme.backgrounds[1],
  },
}))

export default Pagination
