import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    aria-labelledby={`sales-tab-${index}`}
    hidden={value !== index}
    id={`sales-tabpanel-${index}`}
    role="tabpanel"
    {...other}
    sx={{ overflowX: { md: 'initial', sm: 'scroll' } }}
  >
    {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
  </Box>
)

export default TabPanel

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

TabPanel.defaultProps = {
  children: '',
}
