import { useState } from 'react'
import NumberFormat from 'react-number-format'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Button from 'components/Button'
import { useGlobalContext } from 'store/global'
import { useSyncContext } from 'store/sync'
import SYNC_ACTIONS from 'store/sync/actions'
import { useProductContext } from 'components/Catalog-v2/store'
import PRODUCT_ACTIONS from 'components/Catalog-v2/store/actions'
import { EditPen, Plus, Trash } from 'components/Icons'
import TextField from 'components/TextField'
import { ModalAdaptable } from 'components/Modal'
import generateKey from 'utils/math'
import { onlyNumber, parsePrice } from 'utils/form'
import { ENV, POST_PRODUCTS_ROUTE } from 'constants/routes'
import RowGrid from '../rowGrid'
import useStyles from './styles'

const {
  list: LIST_PRODUCT,
  product: PRODUCT,
  catalog: CATALOG,
  additional_info: STOCK,
} = require('./constData.json')

const VariantSection = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { productData: product, productDispatch } = useProductContext()
  const { actions, syncDispatch } = useSyncContext()
  const [variantName, setVariantName] = useState(null)
  const [variantPrice, setVariantPrice] = useState(null)
  const [variantStock, setVariantStock] = useState(null)
  const [variantStockAlert, setVariantStockAlert] = useState(null)
  const [variantSku, setVariantSku] = useState('')
  const [variantIndex, setVariantIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const handleToggle = () => setOpen(!open)
  const urlBase = `${ENV.REACT_APP_API_URL}${POST_PRODUCTS_ROUTE}`

  const resetValues = () => {
    setVariantName(null)
    setVariantPrice(null)
    setVariantStock(null)
    setVariantStockAlert(null)
    setVariantSku(null)
  }

  const handleClose = () => {
    resetValues()

    setOpen(false)
    setErrorName(false)
  }

  const handleKeyPress = (event) => {
    if (onlyNumber(event.key)) {
      event.preventDefault()
    }
  }

  const updateAttributes = () => {
    if (!product.attributes.length) {
      product.attributes = [
        {
          name: PRODUCT.ATTRIBUTES_NAME,
          values: [variantName],
        },
      ]
    } else if (!product.attributes[0].values.includes(variantName)) {
      product.attributes[0].values.push(variantName)
    }
  }

  const saveVariant = () => {
    const pid = product?.product_id
    const stock = parseInt(variantStock, 10)
    const stockAlert = parseInt(variantStockAlert, 10)

    if (variantIndex >= 0) {
      product.variants[variantIndex] = {
        ...product.variants[variantIndex],
        price: parsePrice(variantPrice.toString()),
        sku: variantSku || null,
        stock: stock >= 0 ? stock : null,
        stock_alert: stockAlert >= 0 ? stockAlert : null,
        variant_name: variantName,
      }
      const lastValue = product.variants[variantIndex].options[0].value

      product.variants[variantIndex].options[0].value = variantName
      product.attributes[0].values[variantIndex] = variantName
      const vid = product.variants[variantIndex]?.variant_id

      if (pid) {
        if (vid) {
          product.variants[variantIndex].options[0].value = variantName
          syncDispatch({
            put: {
              payload: product.variants[variantIndex],
              url: `${urlBase}/${pid}/variants/${vid}`,
            },
            type: SYNC_ACTIONS.ACTION_PUT,
          })
        } else {
          const posInPost = actions.post.findIndex(
            (p) => p.payload.variant_name === lastValue
          )

          actions.post[posInPost].payload = product.variants[variantIndex]
        }
      }
      setVariantIndex(-1)
    } else {
      const variant = {
        is_available: true,
        options: [{ name: PRODUCT.ATTRIBUTES_NAME, value: variantName }],
        price: parsePrice(variantPrice.toString()),
        sku: variantSku || null,
        stock: stock >= 0 ? stock : null,
        stock_alert: stockAlert >= 0 ? stockAlert : null,
        variant_name: variantName,
      }

      if (pid) {
        syncDispatch({
          post: { payload: variant, url: `${urlBase}/${pid}/variants` },
          type: SYNC_ACTIONS.ACTION_POST,
        })
      }

      updateAttributes()

      product.variants.push(variant)
    }
    productDispatch({
      productData: product,
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })
  }

  const isVariantExist = (name) => {
    const pos = product.variants.findIndex((v) => v.variant_name === name)

    if ((pos >= 0 && variantIndex < 0) || (pos >= 0 && pos !== variantIndex)) {
      return true
    }

    return false
  }

  const apply = () => {
    if (!isVariantExist(variantName)) {
      setLoading(true)
      saveVariant()

      resetValues()

      setTimeout(() => {
        setLoading(false)
        handleToggle()
      }, 500)
    } else {
      setErrorName(true)
    }
  }

  const deleteVariant = (idx) => {
    const pid = product?.product_id
    const vid = product?.variants[idx]?.variant_id

    if (pid && vid) {
      syncDispatch({
        delete: { url: `${urlBase}/${pid}/variants/${vid}` },
        type: SYNC_ACTIONS.ACTION_DELETE,
      })
    }
    const values = product.attributes[0].values.filter((_, i) => i !== idx)

    product.attributes[0].values = values
    // fix the attributes must contain between 1 and 10 values
    if (values.length === 0) {
      product.attributes = []
    }

    product.variants = product.variants.filter((_, index) => index !== idx)

    productDispatch({
      productData: product,
      type: PRODUCT_ACTIONS.UPDATE_PRODUCT,
    })
  }

  const editVariant = (idx) => {
    const variant = product.variants[idx]

    setVariantIndex(idx)

    setVariantName(variant?.variant_name)
    setVariantPrice(variant?.price)
    setVariantStock(variant?.stock)
    setVariantStockAlert(variant?.stock_alert)
    setVariantSku(variant?.sku)

    handleToggle()
  }

  const changeVariantName = (event) => {
    const { value } = event.target

    setVariantName(value)
    if (value.length) {
      setErrorName(false)
    }
  }

  const disabled = product?.variants?.length >= PRODUCT.VARIANT.LIMIT

  const OptionalText = () => (
    <Typography
      align="right"
      color="text.primary"
      component="p"
      fontWeight={theme.fontWeight.regular}
      sx={{ mb: `${theme.space[4]}px` }}
      variant="body1"
    >
      {PRODUCT.OPTIONAL}
    </Typography>
  )

  return (
    <>
      <RowGrid
        sx={{
          '& .Mui-disabled': {
            color: theme.palette.divider,
          },
          justifyContent: 'space-between',
          pl: { xs: 0 },
          pr: { xs: 0 },
        }}
      >
        <Typography sx={{ alignItems: 'center', display: 'flex' }} variant="h4">
          {PRODUCT.VARIANTS}
        </Typography>
        <Button
          className={classes.btnSecondary}
          disabled={disabled}
          onClick={handleToggle}
          size="small"
          startIcon={
            <Plus
              color={
                disabled ? theme.palette.divider : theme.palette.primary.main
              }
            />
          }
          type="link"
        >
          {!device.isMobile && PRODUCT.ASSIGN}
        </Button>
      </RowGrid>
      {product?.variants?.map((variant, index) => (
        <RowGrid
          key={generateKey(index)}
          container
          sx={{
            borderTopWidth: { xs: index === 0 ? 0 : 1 },
            padding: `${theme.space[4]}px 0`,
          }}
        >
          <Grid className={classes.gridLeft} item xs={7}>
            <Stack>
              <Typography variant="body1">{variant.variant_name}</Typography>
              <Typography fontWeight={theme.fontWeight.regular} variant="body1">
                {parseInt(variant.stock, 10) >= 0
                  ? `${variant.stock || 0} ${LIST_PRODUCT.ITEMS}`
                  : LIST_PRODUCT.NONE}
              </Typography>
            </Stack>
            <Typography color="text.primary" variant="body1">
              <NumberFormat
                {...PRODUCT.PRICE_OPTIONS}
                displayType="text"
                type="text"
                value={variant.price}
              />
            </Typography>
          </Grid>
          <Grid className={classes.gridRight} item xs={5}>
            <IconButton onClick={() => deleteVariant(index)}>
              <Trash color={theme.palette.text.primary} size={1.4} />
            </IconButton>
            <IconButton onClick={() => editVariant(index)}>
              <EditPen size={1.4} />
            </IconButton>
          </Grid>
        </RowGrid>
      ))}
      {/* ModalVariant */}
      <ModalAdaptable
        acceptText={CATALOG.BTN_ACCEPT_TEXT}
        cancelText={CATALOG.BTN_CANCEL_TEXT}
        description={PRODUCT.VARIANT.DESCRIPTION}
        disabledButtonSave={!variantName || !variantPrice}
        loading={loading}
        onAccept={apply}
        onClose={handleClose}
        open={open}
        title={PRODUCT.VARIANT.TITLE}
      >
        <Stack sx={{ mt: `${theme.space[5]}px` }}>
          <TextField
            error={errorName}
            fullWidth
            helperText={errorName && PRODUCT.VARIANT.ERROR_NAME}
            label={!variantName && PRODUCT.VARIANT.NAME}
            name="name"
            onChange={changeVariantName}
            sx={{ mb: `${theme.space[4]}px` }}
            value={variantName || ''}
            variant="filled"
          />
          <NumberFormat
            {...PRODUCT.PRICE_OPTIONS}
            className={classes.numberFormat}
            name="price"
            onChange={(input) => setVariantPrice(input.target.value)}
            onFocus={(elem) => elem.target.select()}
            placeholder={PRODUCT.PRICE}
            type="text"
            value={variantPrice || ''}
          />
          <TextField
            fullWidth
            label={STOCK.INVENTORY}
            name="stock"
            onChange={(stock) => setVariantStock(stock.target.value)}
            onKeyPress={handleKeyPress}
            value={variantStock || ''}
            variant="filled"
          />
          <OptionalText />
          <TextField
            fullWidth
            label={STOCK.MINIMUN}
            name="stock_alert"
            onChange={(alert) => setVariantStockAlert(alert.target.value)}
            onKeyPress={handleKeyPress}
            value={variantStockAlert || ''}
            variant="filled"
          />
          <OptionalText />
          <TextField
            fullWidth
            label={STOCK.BAR_CODE}
            name="sku"
            onChange={(sku) => setVariantSku(sku.target.value)}
            onKeyPress={handleKeyPress}
            sx={{}}
            value={variantSku || ''}
            variant="filled"
          />
          <OptionalText />
        </Stack>
      </ModalAdaptable>
    </>
  )
}

export default VariantSection
