import _ from 'lodash'
import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Paper from 'components/Paper'
import { RenderChips } from 'components/Chip'
import { Search, SearchMessage } from 'components/Search'
import filterData from 'components/Chip/data'
import { ModalCustom, ModalReport } from 'components/Modal'
import { Dots, Logout, Chevron } from 'components/Icons'
import Button from 'components/Button'
import { useGetOrders } from 'hooks/useOrders'
import { useGlobalContext } from 'store/global'
import { useAuthState } from 'store/user'
import { getDateIntervalHelper } from 'utils/dateTools'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import {
  SALES_MADE,
  STATUS_MAPPING,
  DOWNLOAD_REPORT,
  TITLE_MODAL_BUTTON,
  BUTTON_HIDDEN,
} from 'constants/salesPage'
import SalesTabManager from 'components/SalesTabManager'
import CollapsibleTable from 'components/CollapsibleTable'

const Sales = () => {
  const { device } = useGlobalContext()
  const { merchantInfo } = useAuthState()
  const [statusFilter, setStatusFilter] = useState(0)
  const [dateFilter, setDateFilter] = useState(4)
  const [searchParams, setSearchParams] = useState(null)
  const theme = useTheme()
  const { dateFrom, dateTo } = getDateIntervalHelper(dateFilter)
  const { orderList, loading } = useGetOrders(
    dateFrom,
    dateTo,
    STATUS_MAPPING[statusFilter],
    searchParams
  )

  const [openModal, setOpenModal] = useState(false)

  const [openModalButton, setOpenModalButton] = useState(false)

  const isButtonHide = () => {
    if (BUTTON_HIDDEN === 'hidden') return true

    return false
  }
  const handleToggleModal = () => {
    if (device.isMobile) {
      setOpenModalButton(false)
    }
    setOpenModal(!openModal)
  }

  const onSelected = (filter) => {
    setDateFilter(filter.id)
    cleverTap.event(EVENTS.VENTAS_VIEWED, {
      Click: `${PV.date_filter}: ${filter.label}`,
      Device: device.type,
    })
  }

  const onSearch = (searchValue) => {
    cleverTap.event(EVENTS.VENTAS_VIEWED, {
      Click: PV.search,
      Device: device.type,
    })
    const isEmail = searchValue?.indexOf('@') > 0

    setSearchParams({
      email: isEmail,
      orderId: !isEmail,
      term: searchValue,
    })
  }

  useEffect(() => {
    cleverTap.event(EVENTS.VENTAS_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
  }, [])

  const renderButtonReport = () => {
    if (device.isMobile) {
      return (
        <IconButton onClick={() => setOpenModalButton(true)}>
          <Dots />
        </IconButton>
      )
    }

    return (
      <Button
        color="white"
        onClick={handleToggleModal}
        size="small"
        startIcon={<Logout rotate={90} />}
        sx={{ backgroundColor: theme.backgrounds[0], display: 'inline-flex' }}
      >
        {DOWNLOAD_REPORT}
      </Button>
    )
  }

  const RenderModalButton = () => (
    <ModalCustom
      onClose={() => setOpenModalButton(false)}
      open={openModalButton}
    >
      <Typography
        color="black"
        fontWeight={theme.fontWeight.semibold}
        variant="h3"
      >
        {TITLE_MODAL_BUTTON}
      </Typography>

      <Button
        onClick={handleToggleModal}
        sx={{
          '& > .MuiSvgIcon-root': {
            marginRight: `-${theme.space[2]}px`,
          },
          display: 'flex',
          height: 40,
          justifyContent: 'space-between',
          marginTop: `${theme.space[5]}px`,
          padding: 0,
          width: '100%',
        }}
        type="link"
      >
        <Typography
          color="black"
          sx={{
            '& .MuiSvgIcon-root': {
              marginRight: `${theme.space[3]}px`,
            },
            alignItems: 'center',
            display: 'flex',
          }}
          variant="body1"
        >
          <Logout rotate={90} size={1} />
          {DOWNLOAD_REPORT}
        </Typography>
        <Chevron />
      </Button>
    </ModalCustom>
  )

  return (
    <Layout>
      <ContainerCard>
        <Paper sx={{ padding: { xs: '8px 0 0 0' } }}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              marginLeft: { sm: '24px', xs: '16px' },
              marginRight: { sm: '24px', xs: '16px' },
              marginTop: { sm: '12px', xs: '4px' },
            }}
          >
            <Typography color="black" variant="h2">
              {SALES_MADE}
            </Typography>
            {!isButtonHide() && renderButtonReport()}
          </Stack>
          {/* AppBar */}
          <Stack
            flexDirection={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent="space-between"
            sx={{
              alignItems: { sm: 'center', xs: 'initial' },
              marginTop: '20px',
              paddingLeft: { md: '24px', xs: '8px' },
              paddingRight: { md: '24px', xs: 0 },
            }}
          >
            {/* Filters */}
            <RenderChips
              clevertapEventName=""
              filters={filterData.sales}
              onSelected={onSelected}
              scrollContainer
              selected={searchParams ? 0 : dateFilter}
            />

            {/* Search Content */}
            <Search onSearch={onSearch} />
          </Stack>

          {searchParams ? (
            <>
              <SearchMessage
                count={orderList.length}
                onHandleClear={() => setSearchParams(null)}
                term={searchParams?.term}
              />
              <CollapsibleTable
                loading={loading}
                rowData={_.orderBy(orderList, ['created_at'], ['desc'])}
              />
            </>
          ) : (
            // Tabs & DataGrid
            <SalesTabManager
              dataSet={_.orderBy(orderList, ['created_at'], ['desc'])}
              loading={loading}
              onChange={(tabValue) => setStatusFilter(tabValue)}
            />
          )}
        </Paper>
        {/* Download Report */}
        <ModalReport
          onClose={handleToggleModal}
          open={openModal}
          publicProfile={merchantInfo.publicProfile || ''}
        />

        <RenderModalButton />
      </ContainerCard>
    </Layout>
  )
}

export default Sales
