/* eslint-disable */
import { useEffect, useState } from 'react'
import axios from 'axios'
import { getRequestOptions } from 'helpers/fetchUtil'

export default function fetchData(url) {
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const options = getRequestOptions()

  useEffect(async () => {
    try {
      const response = await axios.get(url, options)
      setData(response.data)
    } catch (e) {
      setError(true)
    }
  }, [])

  return {
    data,
    error,
  }
}
