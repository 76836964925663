import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const WrapIcon = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.borderRadius[0],
  display: 'flex',
  justifyContent: 'center',
  padding: 10,
}))

export default WrapIcon
