const data = {
  COLOR_LABEL: 'color',
  colors: [
    { color: '#FC4C02', id: 'vermilion' },
    { color: '#00A0AD', id: 'bondiblue' },
    { color: '#2257C8', id: 'mariner' },
    { color: '#177850', id: 'jewel' },
    { color: '#181A36', id: 'mirage' },
  ],
}

export default data
