import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import Radio from 'components/Radio'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import Accordion from 'components/Accordion'
import Switch from 'components/Switch'
import SubmitButton from 'components/Settings/SubmitButton'
import { useAuthState, useConfigDispatch } from 'store/user'
import { updateInfo } from 'helpers/fetchUtil'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'
import StatusMessages from 'components/StatusMessages'
import UserActions from 'store/user/actions'
import SETTINGS from 'components/Settings/constData.json'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Stack,
} from '@mui/material'
import useStyles from '../Shipments/styles'

const Notifications = () => {
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [abandonedCart, setAbandonedCart] = useState(false)
  const [timeToSend, setTimeToSend] = useState(
    SETTINGS.NOTIFICATIONS.abandoned_cart.default
  )
  const [loading, setLoading] = useState(false)
  const { storeConfig } = useAuthState()
  const { device } = useGlobalContext()
  const dispatch = useConfigDispatch()
  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    if (storeConfig && storeConfig?.store_settings?.abandoned_cart) {
      const ac = storeConfig.store_settings.abandoned_cart

      if (ac.active) {
        setAbandonedCart(true)
      }

      if (ac.time_to_reminder) {
        setTimeToSend(ac.time_to_reminder)
      }
    }
  }, [storeConfig, expandedPanel])

  const handleCancel = () => {
    setExpandedPanel((prev) => !prev)
    setSubmitError(false)

    if (expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.cancel,
        Device: device.type,
      })
    }
  }

  const handleChangeSwitch = () => {
    setAbandonedCart(!abandonedCart)
  }

  const handleSubmit = () => {
    setLoading(true)

    const payload = {
      abandoned_cart: {
        active: abandonedCart,
        time_to_reminder: parseInt(timeToSend, 10),
      },
    }

    const storeSettings = {
      store_settings: {
        ...storeConfig.store_settings,
        ...payload,
      },
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, storeSettings)
      .then(() => {
        setSubmitSuccess(true)
        dispatch({
          payload: {
            ...storeConfig,
            store_settings: { ...storeSettings.store_settings },
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })

        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
        setSubmitError(true)
      })
      .finally(() => setLoading(false))
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.notificaciones,
        Device: device.type,
      })
    }
  }

  const handleRadioChange = (event) => {
    setTimeToSend(event.target.value)
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={handleCancel}
      >
        <Typography align="center" variant="h2">
          {SETTINGS.NOTIFICATIONS_TITLE}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontWeight: theme.fontWeight.bold,
            pb: 3,
            width: '100%',
          }}
          variant="body1"
        >
          {SETTINGS.ABANDONED_CART_TITLE}
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography
            sx={{
              color: theme.palette.text.light,
              fontWeight: theme.fontWeight.regular,
              maxWidth: 483,
              width: '100%',
            }}
            variant="body1"
          >
            {SETTINGS.ABANDONED_CART_SUBTITLE}
          </Typography>
          <Switch
            checked={abandonedCart}
            data-testid="switch-abandoned-cart"
            inputProps={{ 'data-testid': 'switchStore' }}
            onChange={handleChangeSwitch}
            sx={{ mr: `${theme.space[4]}px` }}
          />
        </Stack>
        <Accordion
          expanded={abandonedCart}
          sx={{ boxShadow: 'none', padding: 0 }}
        >
          <AccordionSummary sx={{ display: 'none' }} />
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: `${theme.space[2]}px`,
              }}
            >
              <Grid item xs={12}>
                <FormLabel id="demo-radio-buttons-group-label">
                  {SETTINGS.ABANDONED_CART_VALUES_TITLES}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleRadioChange}
                    value={timeToSend}
                  >
                    {SETTINGS.NOTIFICATIONS.abandoned_cart.values.map(
                      (value) => (
                        <FormControlLabel
                          key={value.value}
                          control={<Radio />}
                          label={value.label}
                          value={value.value}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <SubmitButton
          buttonCancelText={SETTINGS.CANCEL_BUTTON}
          buttonSubmitText={SETTINGS.SAVE_BUTTON}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          loading={loading}
        />
        <StatusMessages
          errorFlag={submitError}
          errorMessage={SETTINGS.ERROR_MESSAGE}
          handleClose={handleClose}
          successFlag={submitSuccess}
          successMessage={SETTINGS.SUCCESS_MESSAGE}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Notifications
