import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    border: 'none',
    fontWeight: theme.fontWeight.semibold,
    marginLeft: 0,
  },
  container: {
    alignItems: 'center',
    border: `1px solid ${theme.backgrounds[1]}`,
    borderRadius: `${theme.borderRadius[1]}px`,
    height: '80px',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 90,
    },
  },
  disabled: {
    backgroundColor: theme.backgrounds[0],
    color: theme.palette.text.disabled,
  },
  discountType: {
    height: 54,
    marginBottom: 2,
  },
  divider: {
    borderLeftWidth: '1px',
    borderRight: 'none',
    height: '35px',
    marginTop: '2px',
    opacity: 0.4,
    [theme.breakpoints.down('sm')]: {
      height: '15px',
    },
  },
  error: {
    '& .MuiFormControl-root.MuiTextField-root input': {
      color: theme.palette.error.main,
    },
    '& .MuiToggleButton-root': {
      '&.Mui-selected': {
        color: theme.palette.error.main,
      },
    },
    color: theme.palette.error.main,
  },
  formRangeDate: {
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-root::after': {
      content: 'none',
    },
    '& .MuiInputBase-root::before': {
      content: 'none',
    },
    alignItems: 'center',
    borderRadius: `${theme.borderRadius[0]}px`,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: `${theme.space[5]}px`,
    padding: `${theme.space[3]}px`,
  },
  inputRangeDate: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 20,
      paddingLeft: 0,
      paddingTop: `${theme.space[1]}px`,
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 1,
    },
    '& .MuiInputLabel-root': {
      paddingLeft: `${theme.space[3]}px`,
    },
  },
  marginBottomNone: {
    marginBottom: 0,
  },
  minPurcharse: {
    marginBottom: `${theme.space[1]}px`,
  },
  numberFormatError: {
    '&::placeholder': {
      color: theme.palette.error.main,
    },
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  rangeDateBlack: {
    '& .MuiInputBase-input': {
      color: theme.palette.black.main,
    },
  },
  toggleButtonError: {
    '&.MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.error.main,
    },
  },
  wrapDiscountType: {
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      border: 0,
    },
    '& .MuiFormControl-root.MuiTextField-root input': {
      padding: `${theme.space[4]}px`,
    },
    '& .MuiToggleButton-root': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.black.main,
      },
      '&.Mui-selected.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
      borderRadius: '6px !important',
      borderWidth: 10,
      boxShadow: `0px 6px 12px ${theme.palette.shadows.btnSwitch}`,
      color: theme.palette.text.disabled,
      fontSize: 16,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 24,
      width: 40,
    },
    '& .MuiToggleButtonGroup-root': {
      '& > .Mui-disabled': {
        color: theme.palette.text.disabled,
      },
      borderRadius: '6px',
      height: 42,
      justifyContent: 'space-between',
      position: 'absolute',
      right: 8,
      top: 6,
      width: 84,
    },
    display: 'flex',
    flexDirection: 'row',
    marginBottom: `${theme.space[3]}px`,
    position: 'relative',
  },
  wrapperGrid: {
    overflowX: 'auto',
    width: '100%',
  },
}))

export default useStyles