import { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import StatusMessages from 'components/StatusMessages'
import SocialMedia from 'components/SocialMedia'
import { Copy, ShareArrow } from 'components/Icons'
import data from 'components/SocialMedia/data.json'
import CustomPopover from 'components/Popover'

const AppBarCopyShare = ({
  copyEnabled,
  merchantName,
  shareEnabled,
  label,
  storeLink,
}) => {
  const theme = useTheme()
  const [copySuccess, setCopySuccess] = useState(false)
  const [copyError, setCopyError] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClipboard = (text, result) => {
    if (result) {
      setCopySuccess(true)
    } else {
      setCopyError(true)
    }
  }

  const handleClose = () => {
    setCopyError(false)
    setCopySuccess(false)
  }

  const handleShare = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const separator = () => (
    <Typography color="text.primary" component="div" variant="body1">
      |
    </Typography>
  )

  const showSocialMedia = () => (
    <CustomPopover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => setAnchorEl(null)}
      sx={{
        '& .MuiPopover-paper > div': {
          '& div': {
            pl: `${theme.space[2]}px`,
          },
          mt: `${theme.space[1]}px`,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <SocialMedia fbShare={storeLink} whatsappText={merchantName} />
    </CustomPopover>
  )

  return (
    <Stack
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: { sm: `${theme.borderRadius[0]}px` },
        flexDirection: 'row',
        justifyContent: 'space-between',
        mb: { xs: `${theme.space[5]}px` },
        minHeight: { sm: 56 },
        ml: { sm: 0, xs: `-${theme.space[4]}px` },
        mr: { sm: 0, xs: `-${theme.space[4]}px` },
        mt: { sm: 0, xs: `-${theme.space[5]}px` },
        padding: { md: `0 ${theme.space[4]}px`, xs: `${theme.space[4]}px` },
      }}
    >
      <Stack
        sx={{
          flexDirection: { md: 'row', xs: 'column' },
          justifyContent: { xs: 'center' },
        }}
      >
        <Typography color="text.primary" variant="body1">
          {label}
        </Typography>
        <Typography sx={{ display: { md: 'inline-flex', xs: 'none' } }}>
          &nbsp;
        </Typography>
        <Typography
          color="primary"
          sx={{
            maxWidth: { md: 'initial', xs: '57vw' },
            overflowWrap: 'break-word',
          }}
          variant="body1"
        >
          {storeLink}
        </Typography>
      </Stack>
      <Stack alignItems="center" flexDirection="row">
        {copyEnabled && (
          <CopyToClipboard onCopy={handleClipboard} text={storeLink}>
            <IconButton>
              <Copy color={theme.palette.primary.main} />
            </IconButton>
          </CopyToClipboard>
        )}
        {copyEnabled && shareEnabled && separator()}
        {shareEnabled && (
          <>
            <IconButton
              onClick={handleShare}
              sx={{ mr: `-${theme.space[2]}px` }}
            >
              <ShareArrow color={theme.palette.primary.main} />
            </IconButton>
            {showSocialMedia()}
          </>
        )}
      </Stack>
      <StatusMessages
        errorFlag={copyError}
        errorMessage={data.message.error}
        handleClose={handleClose}
        successFlag={copySuccess}
        successMessage={data.message.success}
      />
    </Stack>
  )
}

export default AppBarCopyShare

AppBarCopyShare.propTypes = {
  copyEnabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  merchantName: PropTypes.string,
  shareEnabled: PropTypes.bool,
  storeLink: PropTypes.string.isRequired,
}

AppBarCopyShare.defaultProps = {
  copyEnabled: true,
  merchantName: '',
  shareEnabled: false,
}
