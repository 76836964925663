import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Chip } from 'components/Chip'
import { useTheme } from '@mui/material/styles'
import { Thick } from 'components/Icons'
import WrapIcon from 'components/WrapIcon'

import data from './data'

const ColorPicker = (props) => {
  const theme = useTheme()
  const { handleClick, color } = props
  const selectColor = (e) => {
    const id = e.currentTarget.attributes.dataindex.value

    handleClick(id, data.COLOR_LABEL)
  }

  return (
    <Box sx={{ margin: `${theme.space[5]}px 0` }}>
      <Stack direction="row">
        {data.colors.map((el) => (
          <Box key={el.id} sx={{ position: 'relative' }}>
            <Chip
              dataindex={el.id}
              onClick={(e) => selectColor(e)}
              sx={{
                '& .MuiChip-label': {
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 0,
                },
                backgroundColor: el.color,
                cursor: 'pointer',
                height: 40,
                marginRight: `${theme.space[4]}px`,
                padding: 0,
                width: 40,
              }}
            />
            {color === el.id && (
              <WrapIcon sx={{ left: 1, position: 'absolute', top: 3 }}>
                <Thick />
              </WrapIcon>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

export default ColorPicker

ColorPicker.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}

ColorPicker.defaultProps = {
  color: 'vermillion',
}
