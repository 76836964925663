import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'
import { useGlobalContext } from 'store/global'
import { MenuContext } from 'components/Menu/store'
import MENU_ACTIONS from 'components/Menu/store/actions'
import { Thick, Warning } from 'components/Icons'
import { cleverTap, EVENTS } from 'helpers/clevertap'
import { isExternURL } from 'utils/links'
import { COMPLETE, PENDING } from 'constants/page'
import useStyles from './styles'

const ListItemLink = ({ complete, icon, item, handleClick }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { device } = useGlobalContext()
  const { menuDispatch } = useContext(MenuContext)

  const handleClickNavLink = (elem) => {
    // fix selected menu update.
    menuDispatch({ selected: elem.url, type: MENU_ACTIONS.UPDATE_SELECTED })

    cleverTap.event(EVENTS.HOMEMITIENDA_VIEWED, {
      Click: elem.cleverTapClick,
      Device: device.type,
    })
  }

  const Icon = () => (
    <ListItemIcon sx={{ minWidth: 20 }}>
      {React.createElement(icon)}
    </ListItemIcon>
  )

  if (complete)
    return (
      <ListItem className={classes.listItem} component={Link}>
        <ListItemText variant="h4">{item.text}</ListItemText>
        <Chip
          className={clsx(classes.chip, classes.complete)}
          icon={<Thick color={theme.palette.success.main} />}
          label={COMPLETE}
        />
      </ListItem>
    )

  if (isExternURL(item.urlPath))
    return (
      <ListItem
        className={classes.listItem}
        component={Link}
        href={item.urlPath}
        target="_blank"
      >
        <ListItemButton>
          <ListItemText variant="h4">{item.text}</ListItemText>
          {!device.isMobile && (
            <Chip
              className={clsx(classes.chip, classes.pending)}
              icon={<Warning color={theme.palette.warning.main} />}
              label={PENDING}
            />
          )}
          <Icon />
        </ListItemButton>
        {device.isMobile && (
          <Chip
            className={clsx(classes.chip, classes.pending)}
            icon={<Warning color={theme.palette.warning.main} />}
            label={PENDING}
          />
        )}
      </ListItem>
    )

  return (
    <ListItem className={classes.listItem} onClick={handleClick}>
      <NavLink onClick={() => handleClickNavLink(item)} to={item.urlPath}>
        <ListItemText variant="h4">{item.text}</ListItemText>
        {!device.isMobile && (
          <Chip
            className={clsx(classes.chip, classes.pending)}
            icon={<Warning color={theme.palette.warning.main} />}
            label={PENDING}
          />
        )}
        <Icon />
      </NavLink>
      {device.isMobile && (
        <Chip
          className={clsx(classes.chip, classes.pending)}
          icon={<Warning color={theme.palette.warning.main} />}
          label={PENDING}
        />
      )}
    </ListItem>
  )
}

export default ListItemLink

ListItemLink.propTypes = {
  complete: PropTypes.bool,
  handleClick: PropTypes.func,
  icon: PropTypes.func.isRequired,
  item: PropTypes.shape({
    text: PropTypes.string,
    urlPath: PropTypes.string,
  }).isRequired,
}

ListItemLink.defaultProps = {
  complete: true,
  handleClick: () => {},
}
