import { useEffect, useState } from 'react'
import { fetchInfo } from 'helpers/fetchUtil'

import { ENV, GET_USER_INFO_ROUTE } from 'constants/routes'

export default function useUserInfo(authToken) {
  const [userInfo, setUserInfo] = useState(null)
  const [error, setError] = useState(false)

  const url = `${ENV.REACT_APP_API_URL_2}${GET_USER_INFO_ROUTE}`

  useEffect(() => {
    if (authToken) {
      fetchInfo(url)
        .then((response) => {
          setUserInfo(response.data)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('err getting merchant info data: ', err.response)
          setError(true)
        })
    }
  }, [authToken])

  return {
    error,
    userInfo,
  }
}
