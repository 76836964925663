import COUPON_ACTIONS from './actions'

export const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUPON_ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        ...action.couponData,
      }
    case COUPON_ACTIONS.CLEAR_COUPON:
      return initialState
    default:
      return state
  }
}

export default reducer
