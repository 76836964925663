import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiBox-root': {
      borderBottomLeftRadius: theme.borderRadius[0],
      borderBottomRightRadius: theme.borderRadius[0],
      bottom: 'unset',
      position: 'absolute',
      top: '50%',
      width: 380,
      [theme.breakpoints.down('sm')]: {
        bottom: 'initial',
        position: 'fixed',
        top: 'initial',
        width: '99%',
      },
    },
    '& .rdrDateDisplayWrapper': {
      display: 'none',
    },
    '& .rdrDayToday .rdrDayNumber > span::after': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .rdrMonthAndYearWrapper': {
      '& .rdrNextPrevButton': {
        backgroundColor: theme.palette.secondary.light,
      },
      '& .rdrNextPrevButton i': {
        borderLeftColor: theme.palette.primary.main,
        borderRightColor: 'transparent',
      },
      '& .rdrPprevButton i': {
        borderLeftColor: 'transparent',
        borderRightColor: theme.palette.primary.main,
      },
    },
    '&.MuiModal-root': {
      alignItems: 'initial',
      display: 'block',
      top: 0,
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        display: 'flex',
        top: 'initial',
      },
    },
  },
}))

export default useStyles
