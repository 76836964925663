import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from 'components/TextField'
import { ErrorIcon } from 'components/Icons'
import { useTheme } from '@mui/material/styles'
import { useAuthState } from 'store/user'
import { ENV } from 'constants/routes'
import SETTINGS from 'components/Settings/constData.json'
import ONBOARDING from 'components/Onboarding/constData.json'

const ProfileForm = ({
  isOnboarding,
  link,
  onChangeLink,
  publicDesc,
  onChangePublicDesc,
  publicName,
  onChangePublicName,
  mandatoryError,
  validateOnBoarding,
  linkInUseError,
  nameInUseError,
  onLinkBlur,
}) => {
  const theme = useTheme()

  const { merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: isOnboarding ? 'column-reverse' : 'row',
          marginTop: `${theme.space[2]}px`,
        }}
      >
        <Grid item sm={isOnboarding ? 12 : 6} xs={12}>
          <TextField
            autoComplete="off"
            disabled={isOnboarding ? false : !!publicProfile?.alias}
            error={mandatoryError || linkInUseError}
            fullWidth
            InputProps={{
              endAdornment: (mandatoryError || linkInUseError) && <ErrorIcon />,
            }}
            label={SETTINGS.LINK}
            onBlur={onLinkBlur}
            onChange={onChangeLink}
            onKeyUp={validateOnBoarding}
            value={link || `${ENV.REACT_APP_STORE_BASE_URL}/`}
            variant="filled"
          />
          {mandatoryError && (
            <Typography color="error" variant="body1">
              {SETTINGS.ERROR_LINK}
            </Typography>
          )}
          {linkInUseError && (
            <Typography color="error" variant="body1">
              {ONBOARDING.LINK_ERROR_MESSAGE}
            </Typography>
          )}
        </Grid>
        <Grid item sm={isOnboarding ? 12 : 6} xs={12}>
          <TextField
            error={nameInUseError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: nameInUseError && <ErrorIcon />,
            }}
            label={SETTINGS.STORE_NAME}
            onChange={onChangePublicName}
            onKeyUp={validateOnBoarding}
            value={publicName}
            variant="filled"
          />
          {nameInUseError && (
            <Typography color="error" variant="body1">
              {ONBOARDING.NAME_ERROR_MESSAGE}
            </Typography>
          )}
        </Grid>
      </Grid>
      {!isOnboarding && (
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: `${theme.space[2]}px`,
          }}
        >
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!isOnboarding}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={SETTINGS.INDUSTRY}
              value={merchantInfo?.industry_name || ''}
              variant="filled"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: `${theme.space[2]}px`,
        }}
      >
        <Grid item sm={isOnboarding ? 12 : 6} xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                height: '57px',
              },
            }}
            label={SETTINGS.DESCRIPTION}
            multiline
            onChange={onChangePublicDesc}
            onKeyUp={validateOnBoarding}
            value={publicDesc}
            variant="filled"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ProfileForm

ProfileForm.propTypes = {
  isOnboarding: PropTypes.bool,
  link: PropTypes.string,
  linkInUseError: PropTypes.bool,
  mandatoryError: PropTypes.bool,
  nameInUseError: PropTypes.bool,
  onChangeLink: PropTypes.func,
  onChangePublicDesc: PropTypes.func,
  onChangePublicName: PropTypes.func,
  onLinkBlur: PropTypes.func,
  publicDesc: PropTypes.string,
  publicName: PropTypes.string,
  validateOnBoarding: PropTypes.func,
}

ProfileForm.defaultProps = {
  isOnboarding: false,
  link: '',
  linkInUseError: false,
  mandatoryError: false,
  nameInUseError: false,
  onChangeLink: () => {},
  onChangePublicDesc: () => {},
  onChangePublicName: () => {},
  onLinkBlur: () => {},
  publicDesc: '',
  publicName: '',
  validateOnBoarding: () => {},
}
