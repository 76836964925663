import { useEffect, useState } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import { addDays } from 'date-fns'
import { Calendar, Chevron, ErrorIcon } from 'components/Icons'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import CalendarRange from 'components/Modal/ModalReport/calendarRange'
import { DatePicker } from 'components/Coupon'
import LABEL_DATE from 'components/Modal/ModalReport/constData.json'
import useStyles from 'components/Coupon/styles'

const {
  coupon: COUPON,
  discount_counts: DISCOUNT,
} = require('components/Coupon/constData.json')

const WrapDateRange = ({ error, isEdit }) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const [rangeValue, setRangeValue] = useState(null)
  const [showInputLabel, setShowInputLabel] = useState(false)
  const [stateDate, setStateDate] = useState([
    {
      endDate: new Date(),
      key: 'selection',
      startDate: new Date(),
    },
  ])
  const theme = useTheme()
  const { couponData, couponDispatch } = useCouponContext()
  const classes = useStyles()

  useEffect(() => {
    if (couponData?.coupon_code && couponData.status) {
      setRangeValue({
        endDate: moment(couponData?.end_date).format(LABEL_DATE.DATE_FORMAT),
        startDate: moment(couponData?.start_date).format(
          LABEL_DATE.DATE_FORMAT
        ),
      })
    }
  }, [couponData])

  const handleToggleModal = () => {
    if (couponData?.status !== DISCOUNT.STATUS.OPTIONS[2]) {
      setOpenCalendar(!openCalendar)
      setShowInputLabel(true)
    }
  }

  const handleChangeCalendar = ({ selection }) => {
    setStateDate([selection])
    const { startDate, endDate } = selection

    if (startDate && endDate) {
      setRangeValue({
        endDate: moment(endDate).format(LABEL_DATE.DATE_FORMAT),
        startDate: isEdit
          ? moment(startDate).format(LABEL_DATE.DATE_FORMAT)
          : moment(couponData.start_date).format(LABEL_DATE.DATE_FORMAT),
      })

      couponDispatch({
        couponData: {
          end_date: moment(endDate).format('YYYY-MM-DD'),
          start_date: moment(startDate).format('YYYY-MM-DD'),
        },
        type: COUPON_ACTIONS.UPDATE_COUPON,
      })
    }
  }

  const getRangeValue = () => {
    if (!rangeValue) return ''

    const { endDate, startDate } = rangeValue

    return endDate && startDate
      ? `${startDate} ${LABEL_DATE.TO} ${endDate}`
      : ''
  }

  const handleSelectedOneDate = (date) => {
    if (couponData?.status !== DISCOUNT.STATUS.OPTIONS[2]) {
      couponDispatch({
        couponData: {
          end_date: moment(date).format('YYYY-MM-DD'),
        },
        type: COUPON_ACTIONS.UPDATE_COUPON,
      })
      setOpenCalendar(!openCalendar)
      setShowInputLabel(true)
    }
  }

  const getMinDate = () => {
    if (moment() > moment(couponData.start_date, 'YYYY-MM-DD')) {
      return new Date()
    }

    return addDays(new Date(couponData.start_date), 1)
  }

  const disabled = couponData?.status === DISCOUNT.STATUS.OPTIONS[2] // Finish

  return (
    <>
      <FormControl
        className={clsx({
          [classes.formRangeDate]: true,
          [classes.marginBottomNone]: isEdit,
        })}
        fullWidth
        sx={{
          borderColor: error // errorDate
            ? theme.palette.error.main
            : theme.backgrounds[2],
        }}
        variant="standard"
      >
        {showInputLabel && (
          <InputLabel
            shrink
            sx={{
              color: theme.palette.text.primary,
              fontWeight: theme.fontWeight.semibold,
              left: 16,
              top: 10,
            }}
          >
            {COUPON.CALENDAR_PLACEHOLDER}
          </InputLabel>
        )}

        <Input
          autoComplete="off"
          className={clsx({
            [classes.inputRangeDate]: true,
            [classes.rangeDateBlack]: showInputLabel,
          })}
          disabled={disabled}
          endAdornment={
            <InputAdornment position="end">
              {error ? <ErrorIcon /> : <Chevron />}
            </InputAdornment>
          }
          id="date-ranges"
          onClick={handleToggleModal}
          placeholder={COUPON.CALENDAR_PLACEHOLDER}
          startAdornment={
            !error && (
              <InputAdornment position="start">
                <Calendar size={1.2} />
              </InputAdornment>
            )
          }
          sx={{
            '& .MuiInputBase-input': {
              color: error
                ? theme.palette.error.main
                : theme.palette.text.primary,
            },
          }}
          value={getRangeValue()}
          variant="standard"
        />
      </FormControl>

      {isEdit ? (
        <>
          <DatePicker
            date={new Date(couponData?.end_date)}
            minDate={getMinDate()}
            onChange={handleSelectedOneDate}
            onClose={handleToggleModal}
            open={openCalendar}
          />
          <Typography sx={{ mb: `${theme.space[5]}px` }} variant="body2">
            {COUPON.START_END_EDIT}
          </Typography>
        </>
      ) : (
        <CalendarRange
          minDate={new Date()}
          onChange={handleChangeCalendar}
          onClose={handleToggleModal}
          open={openCalendar}
          rangeState={stateDate}
        />
      )}
    </>
  )
}

export default WrapDateRange

WrapDateRange.propTypes = {
  error: PropTypes.bool,
  isEdit: PropTypes.bool,
}

WrapDateRange.defaultProps = {
  error: false,
  isEdit: false,
}
