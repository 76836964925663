const REPORTS_YEAR = 'ALL'
const REPORTS_TODAY = 'DAY'
const REPORTS_WEEK = 'WEEK'
const REPORTS_MONTH = 'MONTH'

const REPORTING_API_MAP = {
  1: REPORTS_TODAY,
  2: REPORTS_WEEK,
  3: REPORTS_MONTH,
  4: REPORTS_YEAR,
}

export {
  REPORTING_API_MAP,
  REPORTS_MONTH,
  REPORTS_TODAY,
  REPORTS_WEEK,
  REPORTS_YEAR,
}
