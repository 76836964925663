import { useEffect, useState } from 'react'
import Geocode from 'react-geocode'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from 'components/Accordion'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { ModalCustom } from 'components/Modal'
import Switch from 'components/Switch'
import Button from 'components/Button'
import SubmitButton from 'components/Settings/SubmitButton'
import { useAuthState, useConfigDispatch } from 'store/user'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'
import { updateInfo } from 'helpers/fetchUtil'
import generateKey from 'utils/math'
import SETTINGS from 'components/Settings/constData.json'
import UserActions from 'store/user/actions'
import { useGlobalContext } from 'store/global'
import { PENDING } from 'constants/page'
import useStylesChip from 'components/StoreWelcome/styles'
import StatusMessages from 'components/StatusMessages'
import { EditPen, Plus, Trash, Warning } from 'components/Icons'
import useStyles from '../Shipments/styles'
import DirectionAndLocation from './directionAndLocation'
import OfficeHours from './officeHours'

const {
  modal: {
    days: { week: weekDays },
  },
} = require('./data.json')

const Location = () => {
  const { merchantInfo, storeConfig } = useAuthState()
  const [openModal, setOpenModal] = useState(false)
  const [showLocation, setShowLocation] = useState(true)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [populate, setPopulate] = useState(null)
  const [location, setLocation] = useState('')
  const classes = useStyles()
  const chipStyle = useStylesChip()

  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const dispatch = useConfigDispatch()

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN)

  // set response language. Defaults to english.
  Geocode.setLanguage('en')
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion('es')

  const theme = useTheme()
  const { device } = useGlobalContext()

  useEffect(() => {
    if (
      expandedPanel &&
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.display_address !== null
    ) {
      setShowLocation(
        storeConfig?.store_settings.display_address || showLocation
      )
    }
    if (
      expandedPanel &&
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.opening_hours
    ) {
      setList(
        storeConfig.store_settings.opening_hours.map((item) => ({
          daySince: item.day_from,
          dayUntil: item.day_to,
          intervals: item.intervals,
        }))
      )
    }
  }, [storeConfig, expandedPanel])

  const {
    address: {
      address1: address,
      colony,
      municipality,
      state,
      postal_code: cp,
    },
  } = merchantInfo

  const addressFullString = `${address} + ${colony}, ${municipality}, ${state}`

  useEffect(() => {
    // Get latitude & longitude from address.
    Geocode.fromAddress(addressFullString).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location

        setLocation(`${lat},${lng}`)
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    )
  })

  const handleToggleModal = () => setOpenModal(!openModal)
  const handleChangeSwitch = () => {
    setShowLocation(!showLocation)

    if (!showLocation) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.mostrar_direccion,
        Device: device.type,
      })
    } else {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.ocultar_direccion,
        Device: device.type,
      })
    }
  }

  const loadOfficeHours = ({ officeHour }) => {
    if (
      list.find(
        (elem) =>
          elem.daySince === officeHour.daySince &&
          elem.dayUntil === officeHour.dayUntil
      )
    ) {
      const newAux = list.map((elem) => {
        if (
          elem.daySince === officeHour.daySince &&
          elem.dayUntil === officeHour.dayUntil
        )
          return { ...elem, intervals: officeHour.intervals }

        return elem
      })

      setList(newAux)
    } else {
      setList((oldList) => [...oldList, officeHour])
    }

    setPopulate(null)
    handleToggleModal()
  }

  const handleCancel = () => {
    setList([])

    setShowLocation(false)
    setExpandedPanel(!expandedPanel)

    if (expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.cancel,
        Device: device.type,
      })
    }
  }

  const saveLocationAndHours = () => {
    setLoading(true)
    const payload = {
      display_address: showLocation,
    }

    const openingHours = list.map((item) => ({
      day_from: item.daySince,
      day_to: item.dayUntil,
      intervals: item.intervals,
    }))

    payload.opening_hours = openingHours

    const storeSettings = {
      store_settings: {
        ...storeConfig.store_settings,
        ...payload,
      },
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, storeSettings)
      .then(() => {
        setSubmitSuccess(true)
        setLoading(false)
        dispatch({
          payload: {
            ...storeConfig,
            store_settings: { ...storeSettings.store_settings },
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })
        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
      })
      .catch(() => {
        setSubmitError(true)
        setLoading(false)
      })
  }

  const handleEdit = (elem) => {
    setPopulate(elem)
    handleToggleModal()
  }
  const handleDelete = (elem) => {
    const newAux = list.filter(
      (item) =>
        item.daySince !== elem.daySince && item.dayUntil !== elem.dayUntil
    )

    setList(newAux)
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.ubicacion_horarios,
        Device: device.type,
      })
    }
  }

  const isLocationComplete = (s) => {
    if (!s.store_settings) return false

    if (s.store_settings?.opening_hours) return true

    return false
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  const options = 'zoom=14&size=300x150&maptype=roadmap&markers=color:red%7C'
  const imgUrl = `${ENV.REACT_APP_GOOGLE_MAPS_BASE_URL}?center=${addressFullString}&${options}${location}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN}`

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={handleCancel}
      >
        <Typography align="center" variant="h2">
          {SETTINGS.LOCATION_SCHEDULE}
        </Typography>
        {storeConfig && !isLocationComplete(storeConfig) && (
          <Chip
            className={clsx(chipStyle.chip, chipStyle.pending)}
            icon={<Warning color={theme.palette.warning.main} />}
            label={PENDING}
            sx={{
              mt: { sm: `${theme.space[1]}px`, xs: `${theme.space[2]}px` },
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <DirectionAndLocation
          address={`${address} - ${colony}. ${municipality} ${state}. ${cp}`}
          imgUrl={imgUrl}
          isDesktop={!device.isMobile}
        />

        {/* isDesktop && <ChangeDirectionStoreText /> */}
        <Typography
          component="p"
          sx={{ mt: `${theme.space[5]}px` }}
          variant="body1"
        >
          <Switch
            checked={showLocation}
            inputProps={{ 'data-testid': 'switchStore' }}
            onChange={handleChangeSwitch}
            sx={{ mr: `${theme.space[4]}px` }}
          />
          {SETTINGS.SHOW_LOCATION_STORE}
        </Typography>
        {/* component */}
        <Stack
          sx={{
            flexDirection: { sm: 'row', xs: 'column' },
            justifyContent: { sm: 'space-between', xs: 'flex-start' },
            mb: 2,
          }}
        >
          <Typography sx={{ mt: `${theme.space[5]}px` }} variant="h4">
            {SETTINGS.HOURS_STORE}
          </Typography>
          <Button
            onClick={handleToggleModal}
            size="small"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              mb: { sm: `${theme.space[4]}px`, xs: `${theme.space[2]}px` },
              mt: `${theme.space[4]}px`,
              width: { sm: 'auto', xs: '100%' },
            }}
          >
            <Plus size={1.4} /> {SETTINGS.ADD_DAY_HOURS}
          </Button>
        </Stack>
        {list.length === 0 && (
          <Typography color="text.disabled" variant="body1">
            {SETTINGS.HOURS_STORE_EMPTY}
          </Typography>
        )}
        {list.map((item, idx) => (
          <Grid
            key={`key-${generateKey(idx)}`}
            container
            sx={{
              borderTop: `1px solid ${theme.backgrounds[1]}`,
              padding: `${theme.space[4]}px`,
            }}
          >
            <Grid
              item
              sx={{
                alignItems: { sm: 'center', xs: 'flex-start' },
                display: 'flex',
                flexDirection: { sm: 'row', xs: 'column' },
                justifyContent: 'space-between',
              }}
              xs={7}
            >
              <Typography variant="body1">
                {item.daySince === item.dayUntil
                  ? weekDays[item.daySince]
                  : `${weekDays[item.daySince]} ${SETTINGS.TO} ${
                      weekDays[item.dayUntil]
                    }`}
              </Typography>

              <Stack>
                {item.intervals.map((int) => (
                  <Typography
                    key={int.opening}
                    fontWeight={theme.fontWeight.regular}
                    variant="body1"
                  >
                    {`${int.opening} ${SETTINGS.TO} ${int.closing} ${SETTINGS.HOUR}`}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={5}
            >
              <IconButton component="span" onClick={() => handleDelete(item)}>
                <Trash color={theme.palette.text.primary} size={1.4} />
              </IconButton>
              <IconButton component="span" onClick={() => handleEdit(item)}>
                <EditPen size={1.4} />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <SubmitButton
          buttonCancelText={SETTINGS.CANCEL_BUTTON}
          buttonSubmitText={SETTINGS.SAVE_BUTTON}
          handleCancel={handleCancel}
          handleSubmit={saveLocationAndHours}
          loading={loading}
          submitError={submitError}
          submitSuccess={submitSuccess}
        />
        <StatusMessages
          errorFlag={submitError}
          errorMessage={SETTINGS.ERROR_MESSAGE}
          handleClose={() => handleClose()}
          successFlag={submitSuccess}
          successMessage={SETTINGS.SUCCESS_MESSAGE}
        />
      </AccordionDetails>

      {/* MODAL */}
      <ModalCustom onClose={handleToggleModal} open={openModal}>
        <OfficeHours
          officeHour={populate}
          onCancel={handleToggleModal}
          onSave={loadOfficeHours}
        />
      </ModalCustom>
    </Accordion>
  )
}

export default Location
