import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducer'

const CouponContext = createContext(false)

const useCouponContext = () => {
  const context = useContext(CouponContext)

  if (context === undefined) {
    throw new Error(
      'useCouponContext:: useStateContext must be used within a provider'
    )
  }

  return context
}

const CouponProvider = ({ children, defaultState }) => {
  const [couponState, dispatch] = useReducer(reducer, defaultState || {})

  return (
    <CouponContext.Provider
      value={{
        couponData: couponState,
        couponDispatch: dispatch,
      }}
    >
      {children}
    </CouponContext.Provider>
  )
}

CouponProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { CouponProvider, useCouponContext }
