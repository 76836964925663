import { CATALOG, DESIGN_ROUTE, SETTINGS_ROUTE } from 'constants/routes'
import { PROPERTIES_VALUES as PV } from 'helpers/clevertap'

const store = {
  description:
    'Puedes configurar tu catálogo digital completando los siguientes pasos:',
  sections: [
    {
      cleverTapClick: PV.wizardCatalog,
      storeConfig: 'products',
      text: 'Carga tus productos',
      urlPath: CATALOG,
    },
    {
      cleverTapClick: PV.wizardDesign,
      storeConfig: 'theme_id',
      text: 'Elige tu diseño',
      urlPath: DESIGN_ROUTE,
    },
    {
      cleverTapClick: PV.wizardConfiguration,
      storeConfig: 'settings',
      text: 'Completa la configuración (horarios, contacto y métodos de envío)',
      urlPath: SETTINGS_ROUTE,
    },
    {
      cleverTapClick: PV.wizardTributary,
      storeConfig: 'tributary',
      text: 'Completa tus datos fiscales',
      urlPath: '',
    },
    {
      cleverTapClick: PV.wizardPublish,
      storeConfig: 'is_published',
      text: 'Publica tu negocio',
      urlPath: '',
    },
  ],
  title: '¡Bienvenido al panel de Mi Negocio Clip!',
}

export default store
