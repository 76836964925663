import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'

const {
  coupon: COUPON,
  discount_counts: DISCOUNT,
} = require('components/Coupon/constData.json')

const WrapCheckbox = () => {
  const { couponData, couponDispatch } = useCouponContext()

  const handleCheck = (event) => {
    couponDispatch({
      couponData: {
        [event.target.id]: event.target.checked,
      },
      type: COUPON_ACTIONS.UPDATE_COUPON,
    })
  }

  const disabled = couponData?.status === DISCOUNT.STATUS.OPTIONS[2]

  return (
    <Stack>
      <FormControlLabel
        control={
          <Checkbox
            checked={couponData?.one_per_client || false}
            disabled={disabled}
            id="one_per_client"
            onChange={handleCheck}
          />
        }
        label={COUPON.ONE_PER_CLIENT}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={couponData?.is_visible || false}
            disabled={disabled}
            id="is_visible"
            onChange={handleCheck}
          />
        }
        label={COUPON.BANNER_VISIBLE}
      />
    </Stack>
  )
}

export default WrapCheckbox
