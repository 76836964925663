import PropTypes from 'prop-types'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import useStyles from './styles'

import CHARTS from '../constData.json'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  borderWidth: 0,
  cutout: 120,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  radius: 100,
}

const StoreActivityMetrics = ({ storeActivity }) => {
  const classes = useStyles()
  const theme = useTheme()

  const {
    total_sales_count: totalSalesCount,
    total_visit_count: totalVisitCount,
    total_visit_count_desktop: totalVisitCountDesktop,
    total_visit_count_mobile: totalVisitCountMobile,
  } = storeActivity

  const conversion = (totalSalesCount / totalVisitCount) * 100
  const desktopVisits = (totalVisitCountDesktop / totalVisitCount) * 100
  const mobileVisits = (totalVisitCountMobile / totalVisitCount) * 100

  const Card = (percentage, amount, title, footerTitle) => {
    const data = {
      datasets: [
        {
          backgroundColor: [theme.palette.primary.strong, theme.backgrounds[2]],
          data: [percentage, 100 - percentage],
          hoverBackgroundColor: [
            theme.palette.primary.strong,
            theme.backgrounds[2],
          ],
          hoverOffset: 0,
        },
      ],
    }

    return (
      <Box className={classes.card}>
        <Typography
          color="black.main"
          fontWeight="bold"
          sx={{ paddingLeft: `${theme.space[5]}px` }}
          variant="h2"
        >
          {title}
        </Typography>
        <Box className={classes.boxCanvas}>
          <Doughnut data={data} options={options} />
          <Typography
            className={classes.percentage}
            component="span"
            fontWeight="bold"
            variant="hxl"
          >
            {percentage}%
          </Typography>
        </Box>

        <Typography align="center" fontWeight="regular" variant="h4">
          {footerTitle} {amount}
        </Typography>
      </Box>
    )
  }

  return (
    <Grid className={classes.storeActivityMetrics} container>
      <Grid item lg={4} md={6} sx={{ marginBottom: 5 }} xs={12}>
        {Card(
          mobileVisits > 0 ? mobileVisits.toFixed(0) : 0,
          totalVisitCountMobile,
          CHARTS.MOBILE,
          CHARTS.AMOUNT
        )}
      </Grid>
      <Grid item lg={4} md={6} sx={{ marginBottom: 5 }} xs={12}>
        {Card(
          desktopVisits > 0 ? desktopVisits.toFixed(0) : 0,
          totalVisitCountDesktop,
          CHARTS.DESKTOP,
          CHARTS.AMOUNT
        )}
      </Grid>
      <Grid item lg={4} md={6} sx={{ marginBottom: 5 }} xs={12}>
        {Card(
          conversion > 0 ? conversion.toFixed(0) : 0,
          totalSalesCount,
          CHARTS.CONVERSION,
          CHARTS.CAR_SESSION
        )}
      </Grid>
    </Grid>
  )
}

export default StoreActivityMetrics

StoreActivityMetrics.propTypes = {
  storeActivity: PropTypes.shape({
    total_sales_count: PropTypes.number,
    total_visit_count: PropTypes.number,
    total_visit_count_desktop: PropTypes.number,
    total_visit_count_mobile: PropTypes.number,
  }),
}

StoreActivityMetrics.defaultProps = {
  storeActivity: {},
}
