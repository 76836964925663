const MuiAlert = (theme) => ({
  variants: [
    {
      props: { severity: 'info' },
      style: {
        '& path': {
          fill: theme.palette.primary.dark,
        },
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.dark,
        fontWeight: theme.fontWeight.bold,
      },
    },
  ],
})

export default MuiAlert
