const COUPON_TEXT = 'Descuento'
const SALES_MADE = 'Ventas Realizadas'
const PENDING_ORDER = 'Pendiente'
const DELIVERED_PAYMENT = 'Entregado'
const CANCELED_PAYMENT = 'Cancelado'
const PENDING_ORDER_STATUS = 'payment_success'
const DELIVERED_STATUS = 'delivery_delivered'
const CANCELED_STATUS = 'canceled_success'
const PRODUCTS_LABEL = 'Productos'
const SKU_CODE = 'Código: '
const MARK_AS_DELIVERED = 'Marcar como entregada'
const TITLE_SALE_DETAILS = 'Detalles de la venta'
const NUMBER_LABEL = 'N°'
const SALE_LABEL = 'venta '
const SALE_TEXT_NAV = 'Ventas'
const CANCEL_ORDER = 'Cancelar venta'
const MESSAGE_MODAL =
  '¿Estas seguro? Al cancelar la venta devolveremos el dinero al cliente. Normalmente tarda xxhs'
const BUTTON_NO_MODAL = 'No, volver'
const BUTTON_YES_MODAL = 'Si, cancelar'
const SUBTOTAL_AMOUNT_TEXT = 'Sub total'
const TOTAL_AMOUNT = 'Total'
const DELIVERY_COST_TEXT = 'Costo de envío'
const DOWNLOAD_REPORT = 'Descargar Reporte'
const TITLE_MODAL_BUTTON = 'Opciones'
const BUTTON_HIDDEN = 'hidden'
const MESSAGE = {
  DELIVERED: {
    ERROR:
      '¡Ups! No pudimos marcar la venta como entregada, inténtalo de nuevo en unos minutos.',
    SUCCESS: 'Se marco la venta como entregada.',
  },
  NOTES: {
    ERROR:
      '¡Ups! No pudimos guardar la nota, inténtalo de nuevo en unos minutos.',
    SUCCESS: 'La nota se guardado correctamente',
  },
}

const STATUS_MAPPING = {
  0: `${PENDING_ORDER_STATUS},${DELIVERED_STATUS}`,
  1: PENDING_ORDER_STATUS,
  2: DELIVERED_STATUS,
}

export {
  BUTTON_NO_MODAL,
  BUTTON_YES_MODAL,
  BUTTON_HIDDEN,
  CANCEL_ORDER,
  CANCELED_PAYMENT,
  CANCELED_STATUS,
  COUPON_TEXT,
  DELIVERY_COST_TEXT,
  DELIVERED_PAYMENT,
  DELIVERED_STATUS,
  DOWNLOAD_REPORT,
  MESSAGE,
  MARK_AS_DELIVERED,
  MESSAGE_MODAL,
  NUMBER_LABEL,
  PENDING_ORDER,
  PENDING_ORDER_STATUS,
  PRODUCTS_LABEL,
  SALE_LABEL,
  SALE_TEXT_NAV,
  SALES_MADE,
  SKU_CODE,
  SUBTOTAL_AMOUNT_TEXT,
  STATUS_MAPPING,
  TITLE_SALE_DETAILS,
  TOTAL_AMOUNT,
  TITLE_MODAL_BUTTON,
}
