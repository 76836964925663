import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'

const ContainerCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.backgrounds[0],
  borderRadius: theme.borderRadius[2],
  boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
  marginBottom: theme.space[5],
  marginRight: theme.space[5],
  padding: theme.space[5],
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    marginBottom: 96,
    marginRight: 0,
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4],
  },
}))

export default ContainerCard
