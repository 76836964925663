import Paper from 'components/Paper'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import ONBOARDING from 'components/Onboarding/constData.json'

const Loading = () => (
  <Paper sx={{ height: 290, maxWidth: 696 }}>
    <Typography align="center" variant="h1">
      {ONBOARDING.LOADING_MESSAGE}
    </Typography>
    <Avatar
      src="/images/cart.svg"
      sx={{ height: 120, margin: '64px auto', width: 110 }}
    />
  </Paper>
)

export default Loading
