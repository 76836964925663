import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  btnPlus: {
    bottom: -3,
    position: 'absolute',
    right: -3,
  },
  container: {
    alignItems: 'center',
    border: `2.6px solid ${theme.backgrounds[2]}`,
    borderRadius: theme.borderRadius[2],
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
    width: 200,
    [theme.breakpoints.down('lg')]: {
      borderRadius: theme.borderRadius[1],
      height: 100,
      width: 100,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.borderRadius[1],
      height: 64,
      width: 64,
    },
  },
  editButton: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '50%',
    bottom: '-24px',
    cursor: 'pointer',
    position: 'absolute',
    right: '-24px',
  },
  input: {
    '& input': {
      cursor: 'pointer',
      height: '100%',
    },
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}))

export default useStyles
