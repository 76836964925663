import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { ChainLink } from '../Icons'

const LinkWithChain = ({ label, href, text }) => {
  const theme = useTheme()

  return (
    <Stack>
      {label && (
        <Typography
          color="text.primary"
          component="span"
          fontWeight={theme.fontWeight.regular}
          variant="body1"
        >
          {label}
        </Typography>
      )}
      <Link
        href={href}
        sx={{
          alignItems: 'flex-end',
          display: 'inline-flex',
          fontWeight: theme.fontWeight.bold,
          textDecoration: 'none',
        }}
        variant="h3"
      >
        {text}
        <i>&nbsp;</i>
        <ChainLink color={theme.palette.primary.main} size={1.3} />
      </Link>
    </Stack>
  )
}

export default LinkWithChain

LinkWithChain.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
}

LinkWithChain.defaultProps = {
  label: '',
}
