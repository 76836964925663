const MuiTypography = (theme) => ({
  defaultProps: {
    variantMapping: {
      body1: 'span',
      body2: 'p',
      h1: 'h2',
      h2: 'h2',
      h3: 'h2',
      h4: 'h4',
      h5: 'h5',
      h6: 'h5',
      subtitle1: 'h6',
      subtitle2: 'h6',
    },
  },
  styleOverrides: {
    body1: {
      fontWeight: theme.fontWeight.semibold,
    },
    body2: {
      color: theme.palette.text.primary,
    },
    h1: {
      fontWeight: theme.fontWeight.bold,
    },
    h2: {
      fontWeight: theme.fontWeight.bold,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        lineHeight: 28 / 18,
      },
    },
    h4: {
      fontWeight: theme.fontWeight.semibold,
    },
    root: {
      color: theme.palette.black.main,
      letterSpacing: '0px',
    },
  },
})

export default MuiTypography
