import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Header from 'components/Header'
import Menu from 'components/Menu'
import useStyles from './styles'

const Layout = ({ children, wrapper }) => {
  const classes = useStyles()

  const RenderLogo = () => (
    <Box className={classes.logoContainer}>
      <img alt="logo clip" src="/images/logo-clip.svg" />
    </Box>
  )

  return (
    <section className={classes.layout}>
      {!wrapper && <Header />}
      {wrapper ? (
        <Box className={classes.boxContainer}>
          <RenderLogo />
          {children}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu />
          {children}
        </Box>
      )}
    </section>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node,
  wrapper: PropTypes.bool,
}

Layout.defaultProps = {
  children: '',
  wrapper: false,
}
