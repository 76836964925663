import { createStyles, makeStyles } from '@mui/styles'
import 'fonts/styles.css'

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '#root': {
        height: '100%',
        width: '100%',
      },
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      body: {
        fontFamily: 'Averta',
        height: '100%',
        width: '100%',
      },
      html: {
        '-moz-osx-font-smoothing': 'grayscale',
        '-webkit-font-smoothing': 'antialiased',
        height: '100%',
        width: '100%',
      },
    },
  })
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export default GlobalStyles
