const QR_CODE = 'Código QR'
const DISCOUNT_CODES = 'Códigos de descuento'
const PROMOTE = 'Promociona'
const QR_DESCRIPTION =
  'Descarga el código QR de tu catálogo digital,  puedes imprimirlo y colocarlo donde más prefieras para que tus clientes puedan escanearlo y acceder desde su telefono movil.'
const SELECT_TITLE = 'Selecciona tu rubro'
const SELECT_CONTENT = ['Comercios', 'Restaurantes y bares', 'Servicios']
const MY_CODE = 'Mi código'
const QR_COPY_FIRST = 'Checa mi'
const QR_COPY_SECOND = 'en línea.'
const STORE = 'tienda'
const MENU = 'menú'
const DOWNLOAD = 'Descargar'

export {
  QR_CODE,
  DISCOUNT_CODES,
  PROMOTE,
  QR_DESCRIPTION,
  SELECT_TITLE,
  SELECT_CONTENT,
  MY_CODE,
  QR_COPY_FIRST,
  QR_COPY_SECOND,
  STORE,
  MENU,
  DOWNLOAD,
}
