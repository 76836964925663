import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Pagination from 'components/Pagination'

import { useTheme } from '@mui/material'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { TUTORIALS_ARTICLES_ROUTE } from 'constants/routes'
import TIPS from './constData.json'

import data from './data'
import useStyles from './styles'

const Tips = () => {
  const navigate = useNavigate()
  const { tips } = data
  const rowsPerPage = 5
  const [page, setPage] = useState(1)
  const { device } = useGlobalContext()
  const theme = useTheme()
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const redirect = (file, title) => {
    cleverTap.event(EVENTS.TUTORIALES_VIEWED, {
      Click: PV.ver_tutorial,
      Device: device.type,
      Tutorial: title,
    })

    navigate(
      `${TUTORIALS_ARTICLES_ROUTE.replace(':title', title).replace(
        ':file',
        file.replace('.json', '')
      )}`
    )
  }

  const displayRows = () => {
    const start = (page - 1) * rowsPerPage + 1
    const end =
      page * rowsPerPage < tips.length ? page * rowsPerPage : tips.length

    return `${start} - ${end} de ${tips.length}`
  }

  const pages = Math.ceil(tips.length / rowsPerPage)

  const renderTip = (item, i) => {
    const index = i + 1
    const pagination =
      page === 1
        ? index < page + rowsPerPage
        : index > rowsPerPage * (page - 1) && index <= page * rowsPerPage

    return (
      <Box key={item.subtitle}>
        {pagination && (
          <Box className={classes.tip}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item md={3} xs={12}>
                <Avatar
                  alt="alt"
                  src={item.imgUrl}
                  sx={{ height: '100%', width: '100%' }}
                  variant="rounded"
                />
              </Grid>
              <Grid
                item
                md={7}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: {
                    md: '60%',
                    sm: 'auto',
                  },
                  justifyContent: 'space-between',
                  ml: {
                    md: 'auto',
                    xs: `${theme.space[4]}px`,
                  },
                }}
                xs={12}
              >
                <Stack>
                  <Typography color="secondary" variant="body1">
                    {item.subtitle}
                  </Typography>
                  <Typography fontWeight={theme.fontWeight.bold} variant="h3">
                    {item.title}
                  </Typography>
                </Stack>
                <Typography
                  color="black"
                  fontWeight={theme.fontWeight.regular}
                  variant="body1"
                >
                  {item.description}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                sx={{
                  '.MuiButton-root.MuiButton-textPrimary.MuiButtonBase-root': {
                    maxWidth: '140px',
                    width: '100%',
                  },
                  pb: { xs: 3 },
                }}
                xs={12}
              >
                {item.file && (
                  <Button
                    color="orange"
                    onClick={() => redirect(item.file, item.subtitle)}
                    size="small"
                    sx={{
                      marginLeft: {
                        sm: 'auto',
                        xs: '16px',
                      },
                    }}
                  >
                    {TIPS.SEE_ADVICE}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box>
      {tips.map((item, index) => renderTip(item, index))}
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        {displayRows()}
        <Pagination
          boundaryCount={1}
          count={pages}
          defaultPage={1}
          onChange={handleChangePage}
          page={page}
          siblingCount={1}
        />
      </Stack>
    </Box>
  )
}

export default Tips
