import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  shapeButton: {
    '&&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

export default useStyles
