import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/Paper'
import { Email, DeliveryTruck, Phone, ShopStore, User } from 'components/Icons'
import useStyles from './styles'

import CLIENT_BOX from './constData.json'

const ClientBox = ({ payer }) => {
  const theme = useTheme()
  const classes = useStyles()

  const renderLabel = (icon, label) => (
    <Typography
      color="black"
      fontWeight={theme.fontWeight.semibold}
      sx={{
        '& .MuiSvgIcon-root': {
          marginLeft: '-2px',
          marginRight: `${theme.space[2]}px`,
        },
        alignItems: 'center',
        display: 'flex',
        marginBottom: `${theme.space[1]}px`,
        marginTop: `${theme.space[3]}px`,
      }}
      variant="body2"
    >
      {React.createElement(icon)}
      {label}
    </Typography>
  )

  const renderTypographyData = (content) => (
    <Typography
      gutterBottom
      sx={{ fontSize: { xs: 14 }, lineHeight: { xs: 20 / 14 } }}
      variant="body1"
    >
      {content}
    </Typography>
  )

  const renderTypographyLabel = (label) => (
    <Typography
      color="secondary.main"
      fontWeight={theme.fontWeight.regular}
      sx={{
        fontSize: { xs: 14 },
        lineHeight: { xs: 20 / 14 },
        mt: `${theme.space[1]}px`,
      }}
      variant="body1"
    >
      {label}
    </Typography>
  )

  const renderDeliveryAddress = () => (
    <>
      {renderLabel(DeliveryTruck, CLIENT_BOX.ADDRESS)}
      <Divider sx={{ pb: `${theme.space[3]}px` }} />
      {renderTypographyLabel(CLIENT_BOX.STREET_NUMBER)}
      {renderTypographyData(payer.address.street)}

      {renderTypographyLabel(CLIENT_BOX.APARTMENT)}
      {renderTypographyData(payer.address.streetApt)}

      {renderTypographyLabel(CLIENT_BOX.COLONY_ZIPCODE)}
      {renderTypographyData(payer.address.department)}

      {renderTypographyLabel(CLIENT_BOX.STATE)}
      {renderTypographyData(payer.address.state)}

      {renderTypographyLabel(CLIENT_BOX.DELIVERY_INSTRUCTIONS)}
      {renderTypographyData(payer.order_additional_notes || '')}
    </>
  )

  return (
    <Paper
      sx={{
        marginBottom: `${theme.space[5]}px`,
        marginRight: { md: 0, sm: `${theme.space[2]}px`, xs: 0 },
        padding: 0,
      }}
    >
      <Stack alignItems="flex-start" sx={{ padding: `${theme.space[4]}px` }}>
        <Typography color="black" variant="h2">
          {CLIENT_BOX.TITLE}
        </Typography>

        {renderLabel(User, CLIENT_BOX.CLIENT_NAME)}
        {payer.name || '-'}

        {renderLabel(Email, CLIENT_BOX.EMAIL)}
        <Link className={classes.link} href={`mailto:${payer.email || ''}`}>
          {payer.email || '-'}
        </Link>

        {renderLabel(Phone, CLIENT_BOX.PHONE)}
        <Link className={classes.link} href={`tel:${payer.phone || ''}`}>
          {payer.phone || '-'}
        </Link>

        <Divider
          light
          sx={{
            padding: `${theme.space[2]}px 0 ${theme.space[3]}px`,
            width: '100%',
          }}
        />

        {payer.address
          ? renderDeliveryAddress()
          : renderLabel(ShopStore, CLIENT_BOX.WITHDRAWAL)}
      </Stack>
    </Paper>
  )
}

export default ClientBox

ClientBox.propTypes = {
  payer: PropTypes.shape({
    address: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      colonyZipcode: PropTypes.string,
      deliveryInstructions: PropTypes.string,
      state: PropTypes.string,
      streetNumber: PropTypes.string,
    }),
    email: PropTypes.string,
    name: PropTypes.string,
    order_additional_notes: PropTypes.string,
    phone: PropTypes.string,
    pickUp: PropTypes.bool,
  }).isRequired,
}
