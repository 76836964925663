import PropTypes from 'prop-types'
import Message from 'components/Message'
import { ErrorIcon, CheckMark } from 'components/Icons'
import { useTheme } from '@mui/material/styles'

const StatusMessages = ({
  errorFlag,
  errorMessage,
  handleClose,
  successFlag,
  successMessage,
}) => {
  const theme = useTheme()

  return (
    <>
      <Message
        backgroundColor={theme.palette.success.light}
        flag={successFlag}
        fontColor={theme.palette.success.main}
        handleClose={() => handleClose()}
        icon={<CheckMark />}
        message={successMessage}
      />
      <Message
        backgroundColor={theme.palette.error.light}
        flag={errorFlag}
        fontColor={theme.palette.error.main}
        handleClose={() => handleClose()}
        icon={<ErrorIcon />}
        message={errorMessage}
      />
    </>
  )
}

export default StatusMessages

StatusMessages.propTypes = {
  errorFlag: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  successFlag: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
}
