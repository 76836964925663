import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Stack, useTheme } from '@mui/material'
import ScrollContainer from 'react-indiana-drag-scroll'
import Filter from './chip'
import useStyles from './styles'

// filters with trigger click to first element
const Filters = ({
  items,
  onSelectedItem,
  scrollContainer,
  selected,
  triggerClick,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const firstRef = useRef(null)

  useEffect(() => {
    if (triggerClick) {
      firstRef.current?.click()
    }
  })

  const renderChips = () =>
    items.map((filter, idx) =>
      idx === 0 ? (
        <Filter
          key={filter.id}
          ref={firstRef}
          className={classes.shapeButton}
          label={filter.label}
          onClick={() => onSelectedItem(filter)}
          shape="button"
          sx={{
            backgroundColor:
              selected === filter.id && theme.palette.secondary.light,
            borderColor:
              selected === filter.id && theme.palette.secondary.light,
            color: selected === filter.id && theme.palette.primary.main,
          }}
        />
      ) : (
        <Filter
          key={filter.id}
          className={classes.shapeButton}
          label={filter.label}
          onClick={() => onSelectedItem(filter)}
          shape="button"
          sx={{
            backgroundColor:
              selected === filter.id && theme.palette.secondary.light,
            borderColor:
              selected === filter.id && theme.palette.secondary.light,
            color: selected === filter.id && theme.palette.primary.main,
          }}
        />
      )
    )

  return scrollContainer ? (
    <ScrollContainer style={{ display: 'flex' }}>
      {renderChips()}
    </ScrollContainer>
  ) : (
    <Stack flexDirection="row">{renderChips()}</Stack>
  )
}

export default Filters

Filters.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  onSelectedItem: PropTypes.func,
  scrollContainer: PropTypes.bool,
  selected: PropTypes.number,
  triggerClick: PropTypes.bool,
}

Filters.defaultProps = {
  onSelectedItem: () => {},
  scrollContainer: false,
  selected: 1,
  triggerClick: false,
}
