import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.space[4]}px 0`,
  },
  mostSelledProducts: {
    background: theme.palette.white.main,
    borderRadius: theme.borderRadius[1],
    boxShadow: `0px 8px 16px ${theme.palette.shadows.card}`,
    margin: `${theme.space[5]}px 0px`,
    minHeight: 234,
    padding: theme.space[5],
  },
}))

export default useStyles
