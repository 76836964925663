import actions from './actions'

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_STORE_CONFIG: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default reducer
