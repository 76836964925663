import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

const RowGrid = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.backgrounds[1]}`,
  display: 'flex',
  padding: `${theme.space[5]}px`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.space[4]}px`,
  },
}))

export default RowGrid
