import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    '& .MuiChip-label': {
      display: 'flex',
      fontSize: 12,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 18 / 12,
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      height: 16,
      marginTop: '5px',
      width: 16,
    },

    height: 24,
    marginLeft: 0,
    paddingLeft: 0,
    paddingTop: 0,
    width: 92,
  },
  complete: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    marginRight: `${theme.space[6]}px`,
  },
  description: {
    fontWeight: theme.fontWeight.regular,
    marginBottom: `${theme.space[5]}px`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
  listItem: {
    '& .MuiListItemButton-root': {
      display: 'flex',
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
    },
    '& > .MuiListItemText-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.space[2]}px`,
      },
    },
    '& a': {
      display: 'flex',
      textDecoration: 'none',
      width: '100%',
    },
    borderBottom: `1px solid ${theme.backgrounds[1]}`,
    borderBottomWidth: 'thin',
    fontSize: 16,
    lineHeight: 24 / 16,
    margin: `${theme.space[5]}px 0`,
    padding: `${theme.space[4]}px 0`,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
  },
  pending: {
    '& .MuiSvgIcon-root': {
      marginRight: `${theme.space[1]}px`,
      marginTop: 0,
    },
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    marginRight: `${theme.space[2]}px`,
  },
}))

export default useStyles
