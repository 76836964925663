import { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Loader from 'components/Loader'
import { Calendar, Chevron, ErrorIcon, Email } from 'components/Icons'
import Button from 'components/Button'
import ModalCustom from '../ModalCustom'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import CalendarRange from './calendarRange'
import TEXT from './constData.json'

const ModalReport = ({ publicProfile, onClose, open }) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const [rangeValue, setRangeValue] = useState(null)
  const [errorDate, setErrorDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [timeout, updateTimeout] = useState(-1)
  const [stateDate, setStateDate] = useState([
    {
      endDate: new Date(),
      key: 'selection',
      startDate: new Date(),
    },
  ])

  const theme = useTheme()

  const handleToggleModal = () => setOpenCalendar(!openCalendar)

  const handleClose = () => {
    setStateDate([
      {
        endDate: new Date(),
        key: 'selection',
        startDate: new Date(),
      },
    ])
    setRangeValue(null)
    updateTimeout(-1)
    setErrorDate(null)
    onClose()
  }

  const handleChange = ({ selection }) => {
    setStateDate([selection])
    const { startDate, endDate } = selection

    if (startDate && endDate) {
      setRangeValue({
        endDate: moment(endDate).format(TEXT.DATE_FORMAT),
        startDate: moment(startDate).format(TEXT.DATE_FORMAT),
      })
      setErrorDate(false)
    }
  }

  const getRangeValue = () => {
    if (!rangeValue) return ''

    const { endDate, startDate } = rangeValue

    return endDate && startDate ? `${startDate} ${TEXT.TO} ${endDate}` : ''
  }

  const handleDownload = () => {
    if (!rangeValue) {
      setErrorDate(true)
    } else {
      setLoading(true)
      // simulation process
      setTimeout(() => {
        updateTimeout(Math.random() < 0.5 ? 1 : 0)
        setLoading(false)
      }, 5000)
    }
  }

  const handleCancel = () => {
    setStateDate([
      {
        endDate: new Date(),
        key: 'selection',
        startDate: new Date(),
      },
    ])
    setRangeValue(null)
    setLoading(false)
    setErrorDate(false)
    updateTimeout(-1)
  }

  const renderTimeout = () => {
    const message = TEXT.VERY_LONG_REPORT.split('.')

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ pt: `${theme.space[5]}px` }}
      >
        <Email color={theme.palette.info.dark} />

        <Typography
          align="center"
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          sx={{ mt: `${theme.space[4]}px` }}
          variant="body1"
        >
          {message[0]}.
        </Typography>

        <Typography
          align="center"
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          sx={{ mb: `${theme.space[4]}px` }}
          variant="body1"
        >
          {message[1]} <br />
          <b>({publicProfile.email})</b>
        </Typography>

        <Button
          color="orange"
          onClick={handleClose}
          size="large"
          sx={{
            mt: `${theme.space[6]}px`,
            width: '100%',
          }}
        >
          {TEXT.BUTTON_FINISH}
        </Button>
      </Stack>
    )
  }

  const renderLoading = () => (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ pt: `${theme.space[5]}px` }}
    >
      <Loader />

      <Typography
        align="center"
        color="text.primary"
        component="p"
        fontWeight={theme.fontWeight.regular}
        sx={{ m: `${theme.space[4]}px`, width: '70%' }}
        variant="body1"
      >
        {TEXT.LOADING_MESSAGE}
      </Typography>

      <Button
        color="white"
        onClick={handleCancel}
        size="large"
        sx={{
          backgroundColor: theme.backgrounds[0],
          maxWidth: '100%',
          mt: `${theme.space[6]}px`,
          width: '100%',
        }}
      >
        {TEXT.BUTTON_CANCEL}
      </Button>
    </Stack>
  )

  const renderMessageError = () => (
    <Typography
      color="error"
      component="p"
      fontWeight={theme.fontWeight.semibold}
      sx={{ m: `${theme.space[1]}px ${theme.space[0]}px` }}
      variant="body2"
    >
      {TEXT.ERROR_MESSAGE}
    </Typography>
  )

  const renderInputDate = () => (
    <Stack>
      <Typography
        color="text.primary"
        component="p"
        fontWeight={theme.fontWeight.regular}
        sx={{ mb: `${theme.space[6]}px` }}
        variant="body1"
      >
        {TEXT.DESCRIPTION}
      </Typography>
      <Typography
        color="black"
        component="p"
        sx={{ mb: `${theme.space[2]}px`, mt: `${theme.space[2]}px` }}
        variant="body1"
      >
        {TEXT.LABEL_DATE}
      </Typography>

      <FormControl
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            width: '100%',
          },
          '& .MuiInputBase-root::after': {
            content: 'none',
          },
          '& .MuiInputBase-root::before': {
            content: 'none',
          },
          alignItems: 'center',
          borderColor: errorDate
            ? theme.palette.error.main
            : theme.backgrounds[2],
          borderRadius: `${theme.borderRadius[0]}px`,
          borderStyle: 'solid',
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'row',
          padding: `${theme.space[3]}px`,
        }}
        variant="standard"
      >
        <Input
          endAdornment={
            <InputAdornment position="end">
              {errorDate ? <ErrorIcon /> : <Chevron />}
            </InputAdornment>
          }
          id="date-ranges"
          label={TEXT.PLACEHOLDER}
          onClick={handleToggleModal}
          placeholder={TEXT.PLACEHOLDER}
          startAdornment={
            !errorDate && (
              <InputAdornment position="start">
                <Calendar size={1.2} />
              </InputAdornment>
            )
          }
          sx={{
            '& .MuiInputBase-input': {
              color: errorDate
                ? theme.palette.error.main
                : theme.palette.text.primary,
              fontSize: 14,
              fontWeight: theme.fontWeight.semibold,
              lineHeight: 20,
              paddingLeft: 0,
              paddingTop: `${theme.space[1]}px`,
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: 1,
            },
            '& .MuiInputLabel-root': {
              paddingLeft: `${theme.space[3]}px`,
            },
          }}
          value={getRangeValue()}
          variant="standard"
        />
      </FormControl>

      {!rangeValue && renderMessageError()}
      <Button
        color="orange"
        onClick={handleDownload}
        size="large"
        sx={{ mt: `${theme.space[6]}px`, width: '100%' }}
      >
        {TEXT.BUTTON_DOWNLOAD}
      </Button>
    </Stack>
  )

  const runRenders = () => {
    if (loading) {
      return renderLoading()
    }

    switch (timeout) {
      case 0:
        return handleClose()
      case 1:
        return renderTimeout()
      default:
        return renderInputDate()
    }
  }

  return (
    <ModalCustom onClose={handleClose} open={open}>
      <Typography
        color="black"
        fontWeight={theme.fontWeight.semibold}
        sx={{ mb: `${theme.space[2]}px` }}
        variant="h3"
      >
        {TEXT.TITLE}
      </Typography>

      {runRenders()}

      <CalendarRange
        onChange={handleChange}
        onClose={handleToggleModal}
        open={openCalendar}
        rangeState={stateDate}
      />
    </ModalCustom>
  )
}

export default ModalReport

ModalReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  publicProfile: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
}
