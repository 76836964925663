const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_API_URL_2: process.env.REACT_APP_API_URL_2,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_GOOGLE_MAPS_BASE_URL: process.env.REACT_APP_GOOGLE_MAPS_BASE_URL,
  REACT_APP_ME_DASHBOARD_URL: process.env.REACT_APP_ME_DASHBOARD_URL,
  REACT_APP_STORE_BASE_URL: process.env.REACT_APP_STORE_BASE_URL,
}

/* API ROUTING */
const GET_AUTH_MOCK_ROUTE = '/api/merchant/info'
const GET_CATALOG_ROUTE = '/ecommerce/catalog/all'
const GET_CATALOG_CATEGORIES_ROUTE = '/ecommerce/catalog/categories'
const GET_REPORTS_ROUTE = '/ecommerce/reporting/summary'
const GET_PRODUCT_RANKING_ROUTE = '/ecommerce/reporting/product_ranking'
const GET_CONFIG_ROUTE = '/ecommerce/config'
const GET_ORDERS_ROUTE = '/ecommerce/orders'
const GET_ORDER_DETAIL_ROUTE = '/ecommerce/orders/:id'
const POST_CONFIG_COVER_ROUTE = '/ecommerce/config/cover'
const GET_MERCHANT_INFO_ROUTE = '/api/merchant/info'
const GET_USER_INFO_ROUTE = '/api/merchant/user/info'
const GET_MERCHANT_DESCRIPTION_ROUTE = '/catalog/online/config'
const GET_ZIP_CODES = '/api/md/zipcodes'
const POST_LOGIN = '/api/login'
const GET_COUPONS_ROUTE = '/discount/coupons/all'
const GET_ACTIVE_COUPONS_ROUTE = '/discount/coupons/active'
const GET_TRIBUTARY_INFO_ROUTE = '/ecommerce/merchant/tributary'
const POST_TRIBUTARY_INFO_ROUTE = '/ecommerce/merchant/tributary'
// CATALOG
const POST_PRODUCTS_ROUTE = '/catalog/products'
const POST_CATALOG_IMAGES_ROUTE = '/catalog/images'
const POST_CATEGORIES_ROUTE = '/catalog/categories'
const POST_VARIANTS_ROUTE = '/catalog/variants'
// COUPONS
const POST_COUPONS_ROUTE = '/discount/coupons'
// -> MERCHANT DASHBOARD ROUTING
const MD_ACCOUNT_INFO = '/account_information/merchant'
const MD_LOGIN = '/users/sign_in?path='
const MD_ECOMMERCE = '/mystore'
const MD_CATALOG = '/catalog'
const MD_FORGOT_PASS = '/users/password/new'
const MD_REGISTER = '/onboard/register'
// <- MERCHANT DASHBOARD ROUTING
/* APP ROUTING */
const STORE_ROUTE = '/'
const SALES_ROUTE = '/ventas'
const REPORTS_ROUTE = '/reportes'
const QR_ROUTE = '/qr'
const DESIGN_ROUTE = '/perfil'
const ONBOARDING_ROUTE = '/onboarding'
const NOT_FOUND_ROUTE = '*'
const SETTINGS_ROUTE = '/ajustes'
const TUTORIALS_ROUTE = '/tutoriales'
const HELP_ROUTE = '/ayuda'
const CATALOG = '/catalog'
const GET_PURCHASE_PRODUCTS = '/getPurchaseProducts/id/'
const SALE_ROUTE_DETAILS = '/ventas/venta/:id'
const FAQ_ROUTE = '/faq'
const TUTORIALS_ARTICLES_ROUTE = '/tutoriales/articles/:title/:file'
const PUBLIC_PROFILE_GET = '/public-profile/get-profile'
const PUBLIC_PROFILE_POST = '/public-profile/create-profile'
const TERMS_AND_CONDITIONS = '/terminos-y-condiciones'
const LOGIN = '/login'
const CATALOG_PRODUCT_ROUTE = '/catalogo/producto'
const CATALOG_CATEGORY_ROUTE = '/catalogo/categoria'
const CATALOG_ADDITIONAL_INFO = '/catalogo/producto/informacion-adicional'
const CATALOG_CATEG_ADD_PRODUCT = '/catalogo/categoria/agregar-productos'
const PROMOTIONS_ROUTE = '/promociona'
const PROMOTIONS_QR_ROUTE = '/promociona/qr'
const PROMOTIONS_COUPONS_ROUTE = '/promociona/descuentos'
const PROMOTIONS_COUPON_ROUTE = '/promociona/descuento'
const PROMOTIONS_POST_BUILDER = '/promociona/post-builder'
const CATALOG_SEARCH_ROUTE = '/catalog/search'

export {
  ENV,
  CATALOG_SEARCH_ROUTE,
  GET_ACTIVE_COUPONS_ROUTE,
  GET_AUTH_MOCK_ROUTE,
  GET_REPORTS_ROUTE,
  GET_CATALOG_ROUTE,
  GET_CATALOG_CATEGORIES_ROUTE,
  GET_PRODUCT_RANKING_ROUTE,
  GET_ORDERS_ROUTE,
  GET_ORDER_DETAIL_ROUTE,
  GET_USER_INFO_ROUTE,
  GET_MERCHANT_DESCRIPTION_ROUTE,
  GET_ZIP_CODES,
  GET_CONFIG_ROUTE,
  POST_LOGIN,
  POST_CONFIG_COVER_ROUTE,
  GET_TRIBUTARY_INFO_ROUTE,
  PROMOTIONS_POST_BUILDER,
  POST_TRIBUTARY_INFO_ROUTE,
  POST_PRODUCTS_ROUTE,
  POST_CATALOG_IMAGES_ROUTE,
  POST_CATEGORIES_ROUTE,
  POST_VARIANTS_ROUTE,
  POST_COUPONS_ROUTE,
  STORE_ROUTE,
  SALES_ROUTE,
  SALE_ROUTE_DETAILS,
  REPORTS_ROUTE,
  QR_ROUTE,
  DESIGN_ROUTE,
  ONBOARDING_ROUTE,
  NOT_FOUND_ROUTE,
  HELP_ROUTE,
  CATALOG,
  SETTINGS_ROUTE,
  TUTORIALS_ROUTE,
  GET_PURCHASE_PRODUCTS,
  GET_MERCHANT_INFO_ROUTE,
  PUBLIC_PROFILE_GET,
  PUBLIC_PROFILE_POST,
  LOGIN,
  GET_COUPONS_ROUTE,
  // -> MERCHANT DASHBOARD ROUTING
  MD_ACCOUNT_INFO,
  MD_LOGIN,
  MD_ECOMMERCE,
  MD_CATALOG,
  MD_FORGOT_PASS,
  MD_REGISTER,
  // <- MERCHANT DASHBOARD ROUTING
  FAQ_ROUTE,
  TERMS_AND_CONDITIONS,
  TUTORIALS_ARTICLES_ROUTE,
  CATALOG_PRODUCT_ROUTE,
  CATALOG_CATEGORY_ROUTE,
  CATALOG_ADDITIONAL_INFO,
  CATALOG_CATEG_ADD_PRODUCT,
  PROMOTIONS_ROUTE,
  PROMOTIONS_QR_ROUTE,
  PROMOTIONS_COUPONS_ROUTE,
  PROMOTIONS_COUPON_ROUTE,
}
