import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Button from 'components/Button'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useGlobalContext } from 'store/global'
import useStyles from './styles'

const { search: DATA_SEARCH } = require('./constData.json')

const Search = ({ onSearch, term }) => {
  const { device } = useGlobalContext()
  const [text, setText] = useState(term)
  const [focusMobile, setFocusMobile] = useState(false)
  const theme = useTheme()
  const classes = useStyles()

  const handleChange = (event) => setText(event.target.value)
  const handleClick = () => onSearch(text)
  const handleClickInputBase = () => {
    if (device.isMobile) {
      setFocusMobile(true)
    }
  }
  const handleBlur = () => {
    if (device.isMobile && focusMobile) {
      setFocusMobile(false)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch(text)
    }
  }

  return (
    <Box className={classes.searchContainer}>
      <Stack
        className={classes.fakeInput}
        sx={{
          borderColor: focusMobile
            ? theme.palette.primary.main
            : theme.backgrounds[2],
          maxWidth: focusMobile ? '70%' : '95%',
        }}
      >
        <IconButton
          aria-label="search"
          name="search"
          onClick={handleClick}
          sx={{ p: '0 10px 0' }}
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          className={classes.inputBase}
          defaultValue={text}
          inputProps={{ 'aria-label': 'search' }}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={handleClickInputBase}
          onKeyDown={handleKeyDown}
          placeholder={DATA_SEARCH.PLACEHOLDER_SEARCH}
          type="search"
        />
      </Stack>
      {device.isMobile && focusMobile && (
        <Button className={classes.buttonMobile} onClick={handleClick}>
          {DATA_SEARCH.BUTTON_TEXT}
        </Button>
      )}
    </Box>
  )
}

export default Search

Search.propTypes = {
  onSearch: PropTypes.func,
  term: PropTypes.string,
}

Search.defaultProps = {
  onSearch: () => {},
  term: undefined,
}
