import { Chip as InputChip } from '@mui/material'
import { styled } from '@mui/material/styles'

const Chip = styled(InputChip)(({ theme }) => ({
  '& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover': {
    color: theme.palette.white.main,
    margin: '0 0 0 8px',
  },
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.text.light,
  },
  backgroundColor: theme.palette.text.light,
  color: theme.palette.white.main,
  marginRight: '6px',
  marginTop: '5px',
  maxWidth: '120px',
  paddingRight: '12px',
}))

export default Chip
