import MENU_ACTIONS from './actions'

export const initialsate = {
  open: false,
  selected: '',
}

const reducer = (state = initialsate, action) => {
  switch (action.type) {
    case MENU_ACTIONS.UPDATE_TOGGLE:
      return {
        ...state,
        open: action.open,
      }
    case MENU_ACTIONS.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      }
    default:
      return state
  }
}

export default reducer
