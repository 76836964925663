import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  banner: {
    '& img': {
      borderRadius: theme.borderRadius[0],
      boxSizing: 'border-box',
      display: 'block',
      height: '100%',
      margin: 'auto',
      minWidth: '80%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    backgroundColor: theme.backgrounds[4],
    border: `4px solid ${theme.palette.white.main}`,
    borderRadius: theme.borderRadius[0],
    display: 'flex',
    minHeight: 142,
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
      padding: '0 16px',
    },
  },
  bannerDots: {
    '& .MuiMobileStepper-dot': {
      background: theme.backgrounds[2],
      borderRadius: 5,
      cursor: 'pointer',
      height: 4,
      width: 24,
    },
    '& .MuiMobileStepper-dotActive': {
      background: theme.palette.secondary.main,
    },
    '& .MuiMobileStepper-dots': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    backgroundColor: 'transparent',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default useStyles
