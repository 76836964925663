import Paper from 'components/Paper'
import WrapIcon from 'components/WrapIcon'
import Button from 'components/Button'
import Typography from '@mui/material/Typography'
import { Thick } from 'components/Icons'
import { useTheme } from '@mui/material/styles'
import { STORE_ROUTE } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import ONBOARDING from 'components/Onboarding/constData.json'

const Loading = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Paper sx={{ maxWidth: 696, minHeight: 290 }}>
      <Typography align="center" variant="h1">
        {ONBOARDING.READY}
      </Typography>
      <WrapIcon
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
          height: 64,
          margin: '32px auto',
          padding: '30px 0px 10px 10px',
          width: 64,
        }}
      >
        <Thick size={2.5} />
      </WrapIcon>
      <Typography
        align="center"
        fontWeight={theme.fontWeight.regular}
        variant="h4"
      >
        {ONBOARDING.SUCCESS_MESSAGE}
      </Typography>
      <Button
        color="orange"
        onClick={() => navigate(STORE_ROUTE)}
        sx={{
          display: 'flex',
          fontWeight: theme.fontWeight.bold,
          height: 48,
          margin: '32px auto',
          maxWidth: 201,
        }}
        variant="large"
      >
        {ONBOARDING.BUTTON_SUCCESS}
      </Button>
    </Paper>
  )
}

export default Loading
