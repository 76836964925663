import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Button from 'components/Button'
import { useTheme } from '@mui/material/styles'
import LoadingButton from 'components/LoadingButton'

const SubmitButton = ({
  handleCancel,
  handleSubmit,
  buttonCancelText,
  buttonSubmitText,
  loading,
}) => {
  const theme = useTheme()

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{
        justifyContent: { sm: 'flex-end', xs: 'space-between' },
        mt: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
      }}
    >
      <Button
        onClick={() => handleCancel()}
        size="large"
        sx={{
          height: { xs: '48px' },
          mr: { sm: `${theme.space[5]}px`, xs: 0 },
          width: { sm: 236, xs: '48%' },
        }}
        variant="secondary-form"
      >
        {buttonCancelText}
      </Button>

      <LoadingButton
        color="orange"
        loading={loading}
        onClick={handleSubmit}
        size="submit"
        sx={{ height: { xs: '48px' }, width: { sm: 236, xs: '48%' } }}
        variant="primary-form"
      >
        {buttonSubmitText}
      </LoadingButton>
    </Stack>
  )
}

export default SubmitButton

SubmitButton.propTypes = {
  buttonCancelText: PropTypes.string.isRequired,
  buttonSubmitText: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

SubmitButton.defaultProps = {
  loading: false,
}
