const EMPTY_REPORT = {
  total_canceled_orders: 0,
  total_delivered_orders: 0,
  total_pending_orders: 0,
  total_sales_amount: 0,
  total_sales_count: 0,
  total_visit_count: 0,
  total_visit_count_desktop: 0,
  total_visit_count_mobile: 0,
}

export default EMPTY_REPORT
