import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
// import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import data from './data'

const TemplatePicker = (props) => {
  const theme = useTheme()
  const { handleClick, template } = props
  const { templates, labels: TP } = data

  const [templateSelected, setTemplateSelected] = useState(null)

  useEffect(() => {
    setTemplateSelected(template)
  }, [template])

  const selectTemplate = (e) => {
    const id = e.currentTarget.attributes.dataindex.value

    setTemplateSelected(id)
    handleClick(id, TP.TEMPLATE)
  }

  const Recommended = () => (
    <Box
      sx={{
        background: theme.palette.white.main,
        borderTopLeftRadius: theme.borderRadius[0],
        borderTopRightRadius: theme.borderRadius[0],
        width: '100%',
      }}
    >
      <Typography
        align="center"
        color={theme.palette.text.primary}
        fontWeight={theme.fontWeight.semibold}
        sx={{ textTransform: 'uppercase' }}
        variant="body2"
      >
        {TP.RECOMMENDED_FOR_YOU}
      </Typography>
    </Box>
  )

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        margin: `${theme.space[5]}px 0`,
        overflow: 'auto',
        whiteSpace: {
          sm: 'initial',
          xs: 'nowrap',
        },
      }}
    >
      {templates.map((t) => (
        <Paper
          key={t.id}
          dataindex={t.id}
          onClick={(e) => selectTemplate(e)}
          sx={{
            alignItems: 'center',
            backgroundColor: theme.backgrounds[0],
            border:
              t.id !== templateSelected
                ? `1px solid ${theme.backgrounds[2]}`
                : `1px solid ${theme.palette.primary.main}`,
            boxShadow: 'none',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            height: 168,
            justifyContent: 'space-evenly',
            minWidth: 188,
            padding: `${theme.space[1]}px 0`,
            width: 188,
          }}
        >
          {t.recommended ? Recommended() : ''}
          <Typography
            color="black"
            fontWeight={theme.fontWeight.semibold}
            variant="body1"
          >
            {t.name}
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            fontWeight={theme.fontWeight.regular}
            sx={{ padding: `0 ${theme.space[2]}px`, whiteSpace: 'initial' }}
            variant="body2"
          >
            {t.description}
          </Typography>
          {/*
          <Button href={t.button.url} type="link">
            {t.button.text}
          </Button>
        */}
        </Paper>
      ))}
    </Stack>
  )
}

export default TemplatePicker

TemplatePicker.propTypes = {
  handleClick: PropTypes.func.isRequired,
  template: PropTypes.string,
}

TemplatePicker.defaultProps = {
  template: 'retail',
}
