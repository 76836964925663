import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Paper from 'components/Paper'
import Avatar from '@mui/material/Avatar'
import Button from 'components/Button'
import CouponList from 'components/Coupon/list'
import Loader from 'components/Loader'
import { useTheme } from '@mui/material'
import { Plus } from 'components/Icons'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import {
  ENV,
  GET_COUPONS_ROUTE,
  PROMOTIONS_COUPON_ROUTE,
} from 'constants/routes'
import { fetchInfo } from 'helpers/fetchUtil'

const { discount_counts: DATA } = require('components/Coupon/constData.json')

const Coupons = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const urlBase = `${ENV.REACT_APP_API_URL}${GET_COUPONS_ROUTE}`
  const [coupons, setCoupons] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const { couponDispatch } = useCouponContext()

  useEffect(async () => {
    setLoadingList(true)
    const response = await fetchInfo(urlBase)

    if (response) {
      setCoupons(response.data)
      setLoadingList(false)
    }
  }, [])

  const handleChangeStatus = (coupon, status) => {
    setCoupons(
      coupons.map((c) => {
        if (c.coupon_code === coupon.coupon_code) {
          return { ...c, status }
        }

        return c
      })
    )
  }

  const handleClickNavigate = () => {
    // couponDispatch => fix issue MSMD-406
    // Clear coupon data when browsing back using the breadcrumb
    couponDispatch({ type: COUPON_ACTIONS.CLEAR_COUPON })
    navigate(PROMOTIONS_COUPON_ROUTE)
  }

  const emptyCoupon = () => (
    <Box sx={{ pb: '88px' }}>
      <Typography variant="h2">{DATA.TITLE}</Typography>
      <Stack alignItems="center" sx={{ mt: '110px' }}>
        <Avatar
          src="/images/empty-state.svg"
          sx={{ height: 200, padding: 3, width: 200 }}
        />
        <Typography
          fontWeight={theme.fontWeight.semibold}
          sx={{ mb: 2 }}
          textAlign="center"
          variant="h3"
        >
          {DATA.TITLE}
        </Typography>
        <Typography
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          sx={{ display: 'block', mb: 2, width: '320px' }}
          textAlign="center"
          variant="body1"
        >
          {DATA.DESCRIPTION}
        </Typography>
        <Button
          color="orange"
          onClick={() => handleClickNavigate()}
          size="large"
          startIcon={<Plus color={theme.palette.white.main} />}
          sx={{ p: { xs: `${theme.space[3]}px ${theme.space[4]}px` } }}
        >
          {DATA.NEW_CODE}
        </Button>
      </Stack>
    </Box>
  )

  const renderList = () =>
    coupons.length > 0 ? (
      <>
        <Grid container sx={{ mb: { sm: '70px', xs: 3 } }}>
          <Grid item sm={6} xs={12}>
            <Typography variant="h2">{DATA.TITLE}</Typography>

            <Typography
              fontWeight={theme.fontWeight.regular}
              sx={{ display: 'block', maxWidth: '320px' }}
              variant="body1"
            >
              {DATA.DESCRIPTION}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              color="orange"
              onClick={() => handleClickNavigate()}
              size="large"
              startIcon={<Plus color={theme.palette.white.main} />}
              sx={{
                float: { sm: 'right', xs: 'none' },
                marginTop: '24px',
                p: {
                  xs: `${theme.space[3]}px ${theme.space[4]}px`,
                },
                width: { sm: 'auto', xs: '100%' },
              }}
            >
              {DATA.NEW_CODE}
            </Button>
          </Grid>
        </Grid>
        <CouponList coupons={coupons} handleChangeStatus={handleChangeStatus} />
      </>
    ) : (
      emptyCoupon()
    )

  return (
    <Layout>
      <ContainerCard>
        <Paper
          sx={{
            height: '100%',
            p: { sm: '24px', xs: '16px' },
          }}
        >
          {loadingList ? (
            <Stack justifyContent="center" sx={{ minHeight: '100%' }}>
              <Loader />
            </Stack>
          ) : (
            renderList()
          )}
        </Paper>
      </ContainerCard>
    </Layout>
  )
}

export default Coupons
