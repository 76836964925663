import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'
import generateKey from 'utils/math'
import { Email, Phone, Whatsapp } from 'components/Icons'
import { useGlobalContext } from 'store/global'
import { cleverTap, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import HELP_MEDIA from './constData.json'

const HelpMedia = ({ clevertapEventName }) => {
  const icons = [Phone, Whatsapp, Email]
  const theme = useTheme()
  const { device } = useGlobalContext()

  const handleClick = (item) => {
    cleverTap.event(clevertapEventName, {
      Click: PV[item.clevertap],
      Device: device.type,
    })
  }

  return (
    <Grid container rowSpacing={3} sx={{ marginTop: `${theme.space[1]}px` }}>
      {HELP_MEDIA.items.map((item, idx) => (
        <Grid
          key={generateKey(idx)}
          item
          sm={4}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingRight: `${theme.space[5]}px`,
          }}
          xs={12}
        >
          <Typography
            sx={{ alignItems: 'center', display: 'flex' }}
            variant="h4"
          >
            {React.createElement(icons[idx])}
            <i>&nbsp;</i>
            {item.title}
          </Typography>
          <Typography
            component="p"
            fontWeight={theme.fontWeight.regular}
            sx={{
              mb: `${theme.space[2]}px`,
              mt: `${theme.space[1]}px`,
              width: { lg: '60%', xs: '100%' },
            }}
            variant="body1"
          >
            {item.text}
          </Typography>
          <Button
            href={`${item.type}${item.link}`}
            onClick={() => handleClick(item)}
            size="large"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
              color: theme.palette.primary.main,
            }}
            type="link"
          >
            {item.link}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

HelpMedia.propTypes = {
  clevertapEventName: PropTypes.string,
}

HelpMedia.defaultProps = {
  clevertapEventName: '',
}
export default HelpMedia
