const data = {
  banners: [
    {
      buttonText: 'Ver tutorial',
      description: 'Conoce como empezar a generar ventas en línea',
      imgUrl: '/images/Informative.svg',
      label: 'AYUDA',
      title: 'El 1,2,3 de vender en línea',
      url: '/tutoriales',
    },
  ],
}

export default data
