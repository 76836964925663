import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import RowGrid from 'components/RowGrid'

const RowGridProduct = ({ children, sx }) => {
  const theme = useTheme()

  return (
    <RowGrid
      sx={{
        ...sx,
        borderBottom: 'none',
        borderTop: `1px solid ${theme.backgrounds[1]}`,
      }}
    >
      {children}
    </RowGrid>
  )
}

export default RowGridProduct

RowGridProduct.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape({}),
}

RowGridProduct.defaultProps = {
  children: '',
  sx: null,
}
