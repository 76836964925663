import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    '& > .MuiCard-root': {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      height: 'max-content',
      margin: 0,
      padding: '0 0 100px',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  layout: {
    backgroundColor: theme.backgrounds[1],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  logoContainer: {
    display: 'flex',
    height: 64,
    width: 64,
    [theme.breakpoints.up('md')]: {
      left: '10vw',
      position: 'absolute',
      top: '5%',
    },
  },
}))

export default useStyles
