import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const Carnet = ({ size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 48 26">
    <svg
      height="26"
      viewBox="0 0 48 26"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M194.255,63.034l1.148-2.422,1.167,2.422Zm.259-3.949-3.3,6.538h1.815l.7-1.482h3.389l.737,1.482h1.861l-3.39-6.538Zm9.1,2.77h-1.264V60.19h1.333c.691,0,1.108.008,1.247.023a1.17,1.17,0,0,1,.643.257A.684.684,0,0,1,205.8,61a.717.717,0,0,1-.174.493.933.933,0,0,1-.478.277,8.353,8.353,0,0,1-1.535.081Zm2.634,1.434a4.029,4.029,0,0,0-.913-.548,3.053,3.053,0,0,0,1.673-.6,1.53,1.53,0,0,0,.56-1.216,1.582,1.582,0,0,0-.37-1.031,1.925,1.925,0,0,0-.986-.628,7.864,7.864,0,0,0-1.974-.176h-3.6v6.538h1.713V62.9h.347a3.641,3.641,0,0,1,.862.073,1.386,1.386,0,0,1,.516.271,10.031,10.031,0,0,1,.9.954l1.229,1.427h2.046l-1.035-1.273a7.8,7.8,0,0,0-.97-1.061Zm13.209-.547h4.247V61.635h-4.247V60.19h4.561V59.085h-6.274v6.538h6.441V64.516h-4.728Zm5.586-3.657V60.19h2.517v5.432h1.712V60.19h2.5V59.085Zm-36.8,5.187a2.022,2.022,0,0,1-1.171.335,2.139,2.139,0,0,1-1.5-.526A2.3,2.3,0,0,1,185,62.313a2.171,2.171,0,0,1,.583-1.692A2.2,2.2,0,0,1,187.1,60.1a2.148,2.148,0,0,1,1.154.3,1.384,1.384,0,0,1,.624.8l1.694-.31a2.5,2.5,0,0,0-.867-1.2,4.174,4.174,0,0,0-2.521-.707,4.363,4.363,0,0,0-2.869.9,3.1,3.1,0,0,0-1.09,2.533,2.974,2.974,0,0,0,1.086,2.432,4.232,4.232,0,0,0,2.771.89,4.372,4.372,0,0,0,2.25-.524,2.9,2.9,0,0,0,1.268-1.6l-1.656-.394a1.861,1.861,0,0,1-.705,1.054Zm26.1-.8-3.486-4.385h-1.659v6.538h1.583v-4.29l3.435,4.29h1.709V59.085h-1.582Zm-6.97-21.158c8.372,0,15.309,3.162,16.7,7.316a4.83,4.83,0,0,0,.268-1.521c0-4.881-7.594-8.838-16.965-8.838S190.4,43.225,190.4,48.106a4.8,4.8,0,0,0,.268,1.521c1.387-4.154,8.325-7.316,16.7-7.316Zm0,5.258c8.372,0,15.309,3.162,16.7,7.316a4.819,4.819,0,0,0,.268-1.521c0-4.881-7.594-8.838-16.965-8.838S190.4,48.484,190.4,53.365a4.791,4.791,0,0,0,.268,1.521c1.387-4.154,8.325-7.316,16.7-7.316Z"
        fill="#ec1c24"
        fillRule="evenodd"
        id="Path_1593"
        transform="translate(-183.227 -39.268)"
      />
    </svg>
  </SvgIcon>
)

export default Carnet

Carnet.propTypes = {
  size: PropTypes.number,
}

Carnet.defaultProps = {
  size: 1.5,
}
