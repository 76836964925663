const APPSFLYER_EVENTS = {
  checklist: {
    catalog: 'PMKT_MS_Checklist_Catalog_View_Screen',
    configuration: {
      completed: 'PMKT_MS_Checklist_Configuration_Success_View_Screen',
      view: 'PMKT_MS_Checklist_Configuration_View_Screen',
    },
    design: {
      completed: 'PMKT_MS_Checklist_Design_View_Screen',
      view: 'PMKT_MS_Checklist_Design_Success_View_Screen',
    },
  },
  onboarding: {
    completed: 'PMKT_MS_Store-Name_Completed',
    view: 'PMKT_MS_ViewOnboarding',
  },
  published: 'PMKT_MS_Store_Published_Success_View_Screen',
}

export default APPSFLYER_EVENTS
