import PropTypes from 'prop-types'
import { useState } from 'react'
import InputMask from 'react-input-mask'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from 'components/TextField'
import Button from 'components/Button'
import FieldSeparator from 'components/FieldSeparator'
import { Plus, Trash } from 'components/Icons'
import { getTimeMask, validateTime } from 'utils/form'
import SETTINGS from 'components/Settings/constData.json'
import data from './data.json'

const OfficeHours = ({ officeHour, onCancel, onSave }) => {
  const [daySince, setDaySince] = useState(
    officeHour ? officeHour.daySince : ''
  )
  const [dayUntil, setDayUntil] = useState(
    officeHour ? officeHour.dayUntil : ''
  )
  const [countIntervals, setCountIntervals] = useState(
    officeHour?.intervals?.length || 1
  )
  const [opening, setOpening] = useState(
    officeHour?.intervals.map((item) => item.opening) || []
  )
  const [closing, setClosening] = useState(
    officeHour?.intervals.map((item) => item.closing) || []
  )
  const [errors, setErrors] = useState([])
  const theme = useTheme()

  const handleChangeDaySince = (event) => setDaySince(event.target.value)
  const handleChangeDayUntil = (event) => setDayUntil(event.target.value)
  const checkDisableButton = () => {
    if (
      daySince === '' ||
      dayUntil === '' ||
      errors.length ||
      !opening.length ||
      !closing.length
    )
      return true

    return false
  }

  const handleBlur = (event) => {
    const { value } = event.target

    if (!validateTime(value)) {
      setErrors((arr) => [...arr, event.target.id])
    } else {
      const aux = errors.filter((err) => err !== event.target.id)

      setErrors(aux)
    }
  }

  const handleChangeIntervalField = (event) => {
    const typeHours = event.target.id.split('-')
    const index = parseInt(typeHours[1], 10)

    if (typeHours[0] === 'open') {
      // opening
      const aux = [...opening]

      aux[index] = event.target.value

      setOpening(aux)
    } else {
      // closing
      const aux = [...closing]

      aux[index] = event.target.value
      setClosening(aux)
    }
  }

  const handleSaveInterval = () => {
    const intervals = []

    if (daySince !== '' && dayUntil !== '') {
      for (let i = 0; i < opening.length; i += 1) {
        const newObj = { closing: closing[i], opening: opening[i] }

        intervals.push(newObj)
      }

      onSave({ officeHour: { daySince, dayUntil, intervals } })
    }
  }

  const renderSelectDays = () => (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ mb: 2.3, mt: 1.5 }}
      xs={12}
    >
      <FormControl sx={{ width: '44%' }}>
        <Select
          displayEmpty
          inputProps={{ 'data-testid': 'daySince' }}
          onChange={handleChangeDaySince}
          sx={{
            '&.MuiOutlinedInput-root.MuiInputBase-root': {
              borderRadius: `${theme.borderRadius[0]}px`,
              fontWeight: theme.fontWeight.semibold,
            },
          }}
          value={daySince}
        >
          <MenuItem disabled value="">
            {data.modal.days.since}
          </MenuItem>
          {data.modal.days.week.map((day, idx) => (
            <MenuItem key={`s-${day}`} selected={idx === daySince} value={idx}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FieldSeparator character={SETTINGS.SEPARATOR} />

      <FormControl sx={{ width: '44%' }}>
        <Select
          displayEmpty
          inputProps={{ 'data-testid': 'dayUntil' }}
          onChange={handleChangeDayUntil}
          sx={{
            '&.MuiOutlinedInput-root.MuiInputBase-root': {
              borderRadius: `${theme.borderRadius[0]}px`,
              fontWeight: theme.fontWeight.semibold,
            },
          }}
          value={dayUntil}
        >
          <MenuItem disabled value="">
            {data.modal.days.until}
          </MenuItem>
          {data.modal.days.week.map((day, idx) => (
            <MenuItem key={`u-${day}`} selected={idx === dayUntil} value={idx}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )

  const renderIntervalFields = () => {
    const intervalRow = []

    for (let i = 0; i < countIntervals; i += 1) {
      intervalRow.push(
        <Stack
          key={`k${i + 1}`}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            '& .MuiFilledInput-input': {
              paddingBottom: `${theme.space[4]}px`,
              paddingTop: `${theme.space[4]}px`,
            },
            mt: 1.5,
          }}
          xs={12}
        >
          <InputMask
            mask={getTimeMask(opening[i])}
            maskPlaceholder={SETTINGS.SEPARATOR}
            onBlur={handleBlur}
            onChange={handleChangeIntervalField}
            value={opening[i]}
          >
            <TextField
              defaultValue={officeHour?.intervals[i]?.opening || undefined}
              error={errors?.indexOf(`open-${i}`) >= 0}
              id={`open-${i}`}
              placeholder={SETTINGS.OPENING}
              sx={{ width: '44%' }}
              variant="filled"
            />
          </InputMask>

          <FieldSeparator character={SETTINGS.SEPARATOR} />

          <InputMask
            mask={getTimeMask(closing[i])}
            maskPlaceholder={SETTINGS.SEPARATOR}
            onBlur={handleBlur}
            onChange={handleChangeIntervalField}
            value={closing[i]}
          >
            <TextField
              defaultValue={officeHour?.intervals[i]?.closing || undefined}
              error={errors?.indexOf(`close-${i}`) >= 0}
              id={`close-${i}`}
              placeholder={SETTINGS.CLOSING}
              sx={{ width: '44%' }}
              variant="filled"
            />
          </InputMask>
        </Stack>
      )
    }

    return intervalRow
  }

  // Buttons in modal window
  const renderButtons = () => (
    <>
      {/* BUTTON ADD INTERVAL AND DELETE INTERVAL */}
      <Button
        onClick={() =>
          setCountIntervals(
            countIntervals < 2 ? countIntervals + 1 : countIntervals - 1
          )
        }
        size="small"
        startIcon={
          countIntervals < 2 ? (
            <Plus />
          ) : (
            <Trash color={theme.palette.primary.main} size={1.4} />
          )
        }
        sx={{ color: theme.palette.primary.main, mt: `${theme.space[5]}px` }}
        type="link"
      >
        {countIntervals < 2 ? SETTINGS.ADD_INTERVAL : SETTINGS.DELELTE_INTERVAL}
      </Button>

      <Stack flexDirection="row" justifyContent="space-between" sx={{ mt: 3 }}>
        {/* BUTTON CANCEL */}
        <Button
          onClick={() => onCancel()}
          size="small"
          sx={{
            backgroundColor: theme.backgrounds[0],
            color: theme.palette.text.primary,
            width: { sm: '40%', xs: '48%' },
          }}
        >
          {SETTINGS.CANCEL_BUTTON}
        </Button>

        {/* BUTTON ADD */}
        <Button
          color="orange"
          disabled={checkDisableButton()}
          onClick={handleSaveInterval}
          size="small"
          sx={{ width: { sm: '40%', xs: '48%' } }}
        >
          {SETTINGS.ADD_BUTTON}
        </Button>
      </Stack>
    </>
  )

  return (
    <>
      <Typography
        fontWeight={theme.fontWeight.bold}
        sx={{ mb: 1 }}
        variant="h2"
      >
        {SETTINGS.HOURS_STORE}
      </Typography>
      <Typography fontWeight={theme.fontWeight.bold} variant="h4">
        {data.modal.days.title}
      </Typography>
      {renderSelectDays()}

      <Typography fontWeight={theme.fontWeight.bold} variant="h4">
        {data.modal.hours.title}
      </Typography>

      {renderIntervalFields()}
      {renderButtons()}
    </>
  )
}

export default OfficeHours

OfficeHours.propTypes = {
  officeHour: PropTypes.shape({
    daySince: PropTypes.number,
    dayUntil: PropTypes.number,
    intervals: PropTypes.arrayOf(
      PropTypes.shape({
        closing: PropTypes.string,
        opening: PropTypes.string,
      })
    ),
  }),
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

OfficeHours.defaultProps = {
  officeHour: null,
}
