import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Button from 'components/Button'
import Paper from 'components/Paper'
import LoadingButton from 'components/LoadingButton'

const SaveButton = ({
  handleCancel,
  handleSave,
  cancelText,
  saveText,
  loading,
  withPaper,
  fullWidth,
  disabled,
  isSubmit,
}) => {
  const theme = useTheme()

  const getWidthButtonMain = () => {
    if (!cancelText) {
      return '100%'
    }

    return fullWidth ? '48%' : 'auto'
  }

  const renderButtons = () => (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{
        justifyContent: { sm: 'flex-end', xs: 'space-between' },
        mt: {
          sm: !withPaper && `${theme.space[5]}px`,
          xs: !withPaper && `${theme.space[4]}px`,
        },
      }}
    >
      {cancelText && (
        <Button
          onClick={handleCancel}
          size="large"
          sx={{
            height: { xs: 48 },
            mr: { sm: `${theme.space[5]}px`, xs: 0 },
            pl: !fullWidth && `${theme.space[5]}px`,
            pr: !fullWidth && `${theme.space[5]}px`,
            width: { sm: fullWidth ? '48%' : 'auto', xs: '48%' },
          }}
          variant="secondary-form"
        >
          {cancelText}
        </Button>
      )}

      <LoadingButton
        color="orange"
        disabled={disabled}
        loading={loading}
        onClick={handleSave}
        size="submit"
        sx={{
          height: { xs: 48 },
          pl: !fullWidth && `${theme.space[5]}px`,
          pr: !fullWidth && `${theme.space[5]}px`,
          width: { xs: getWidthButtonMain() },
        }}
        type={isSubmit ? 'submit' : null}
        variant="primary-form"
      >
        {saveText}
      </LoadingButton>
    </Stack>
  )

  if (withPaper) {
    return (
      <Paper
        sx={{
          bottom: 0,
          boxShadow: `0px -8px 16px ${theme.palette.shadows.card}`,
          left: 0,
          pl: { xs: `${theme.space[4]}px` },
          position: 'fixed',
          pr: { xs: `${theme.space[4]}px` },
        }}
      >
        {renderButtons()}
      </Paper>
    )
  }

  return renderButtons()
}

export default SaveButton

SaveButton.propTypes = {
  cancelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  saveText: PropTypes.string.isRequired,
  withPaper: PropTypes.bool,
}

SaveButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  isSubmit: false,
  loading: false,
  withPaper: false,
}
