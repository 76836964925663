import { ListItem as MuiListItem } from '@mui/material'
import { styled } from '@mui/material/styles'

const ListItem = styled(MuiListItem)(({ theme }) => ({
  '& .MuiListItemText-root .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  borderBottom: `1px solid ${theme.backgrounds[1]}`,
  cursor: 'pointer',
  height: 51,
  paddingRight: 6,
  width: 240,
}))

export default ListItem
