import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

const Button = styled(MuiButton, {
  // Configure which props should be forwarded on DOM
  name: 'MuiButton',
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === 'primary' && styles.primary,
    props.color === 'secondary' && styles.secondary,
  ],
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'variant' && prop !== 'sx',
  // We are specifying here how the styleOverrides are being applied based on props
  slot: 'Root',
})(({ theme }) => ({
  '&:hover': {
    opacity: 1,
  },

  [theme.breakpoints.down('xl')]: {
    lineHeight: 1,
  },
  borderRadius: theme.borderRadius[0],
  fontSize: 16,
  letterSpacing: 0,
  lineHeight: 16 / 24,
  minHeight: 30,
  minWidth: 100,
  padding: theme.space[3],
  textTransform: 'initial',
}))

export default Button
