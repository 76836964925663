import { useEffect, useState } from 'react'
import Paper from 'components/Paper'
import Box from '@mui/material/Box'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import StatusMessages from 'components/StatusMessages'
import StoreSwitch from 'components/Settings/StoreSwitch'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { useAuthState } from 'store/user'

import {
  ContactInfo,
  BusinessInfo,
  Shipments,
  Location,
  // Reviews,
} from 'components/Settings'
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from 'constants/page'

import { useTheme } from '@mui/material/styles'
import { isCompleteValidation } from 'utils/validation'
import recordAppsFlyerEvent from 'helpers/appsflyer'
import APPSFLYER_EVENTS from 'constants/appsflyer'
import Notifications from 'components/Settings/Notifications'

const Settings = () => {
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { storeConfig, merchantToken } = useAuthState()

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  const handleSuccess = () => {
    setSubmitSuccess(true)
  }

  useEffect(() => {
    cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
    recordAppsFlyerEvent(APPSFLYER_EVENTS.checklist.configuration.view, {
      merchant_id: merchantToken,
    })
  }, [])

  useEffect(() => {
    if (isCompleteValidation(storeConfig)) {
      recordAppsFlyerEvent(APPSFLYER_EVENTS.checklist.configuration.completed, {
        merchant_id: merchantToken,
      })
    }
  }, [storeConfig])

  return (
    <Layout>
      <ContainerCard>
        <Paper
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: { xs: 0 },
          }}
        >
          <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            {isCompleteValidation(storeConfig) && (
              <StoreSwitch
                onError={() => setSubmitError(true)}
                onSuccess={handleSuccess}
              />
            )}
            <BusinessInfo />
          </Box>
          <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            <Location />
          </Box>
          <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            <Shipments />
          </Box>
          <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            <ContactInfo />
          </Box>
          <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            <Notifications />
          </Box>
          {/* <Box sx={{ margin: `${theme.space[5]}px 0` }}>
            <Reviews />
          </Box> */}
          <StatusMessages
            errorFlag={submitError}
            errorMessage={ERROR_MESSAGE}
            handleClose={handleClose}
            successFlag={submitSuccess}
            successMessage={SUCCESS_MESSAGE}
          />
        </Paper>
      </ContainerCard>
    </Layout>
  )
}

export default Settings
