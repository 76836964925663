import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    '& > .MuiAccordionSummary-content': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: `${theme.space[4]}px`,
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
  },
  modal: {
    backgroundColor: theme.palette.white.main,
    borderRadius: theme.borderRadius[0],
    maxWidth: 516,
    padding: 24,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  shipmentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.space[4],
    paddingTop: theme.space[4],
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.backgrounds[1]}`,
    },
  },
  timeUnitButton: {
    borderRadius: theme.borderRadius[0],
    height: '45px',
    margin: '0 2px',
    minWidth: 'initial',
    width: '45px',
  },
  timeUnitButtonSelected: {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 8px 16px rgba(24, 26, 54, 0.12)',
  },
  timeUnitButtonUnselected: {
    '& .MuiTypography-root': {
      color: theme.palette.text.disabled,
    },
    backgroundColor: theme.backgrounds[0],
  },
}))

export default useStyles
