import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tip: {
    '& button': {
      height: 36,
      lineHeight: '22px',
      padding: '8px 15px',
      textTransform: 'capitalize',
      width: 140,
    },
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${theme.backgrounds[2]}`,
    borderRadius: theme.borderRadius[0],
    display: 'flex',
    margin: '24px 0',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
}))

export default useStyles
