import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Amex, Visa, Carnet, Mastercard } from 'components/Icons'
import { Typography } from '@mui/material'

const PaymentMethods = ({
  title,
  visaSize,
  masterSize,
  amexSize,
  carnetSize,
}) => (
  <Box data-testid="paymentMethods">
    <Typography
      color="text.primary"
      sx={{ fontSize: 8 }}
      textAlign="center"
      variant="body2"
    >
      {title}
    </Typography>
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <Visa size={visaSize} />
      <Mastercard size={masterSize} />
      <Amex size={amexSize} />
      <Carnet size={carnetSize} />
    </Stack>
  </Box>
)

export default PaymentMethods

PaymentMethods.propTypes = {
  amexSize: PropTypes.number,
  carnetSize: PropTypes.number,
  masterSize: PropTypes.number,
  title: PropTypes.string,
  visaSize: PropTypes.number,
}

PaymentMethods.defaultProps = {
  amexSize: 0.8,
  carnetSize: 1.2,
  masterSize: 1.2,
  title: 'Aceptamos todas las tarjetas',
  visaSize: 1.2,
}
