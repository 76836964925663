import moment from 'moment'

// utils for filters
const today = new Date()
const month = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

/**
 * Check if a date is today
 *
 * @param {datetime} "MM-DD-AAAA => 01-31-2022"
 * @return {boolean}
 */
const isToday = (datetime) => {
  if (!datetime) return false

  const date = new Date(datetime).toDateString()

  return today.toDateString() === date
}

/**
 * Check if a date is part of the week
 *
 * @param {datetime} "2021-02-16"
 * @return {boolean}
 */
const inThisWeek = (datetime) => {
  const date = new Date(datetime)

  if (today.getDate() - date.getDate() < 0) return false

  if (today.getDate() - date.getDate() <= today.getDay()) return true

  return false
}

/**
 * Check if a date is part of the month
 *
 * @param {datetime} "2021-02-16"
 * @return {boolean}
 */
const inThisMonth = (datetime) => {
  const date = new Date(datetime)

  return date.getMonth() === today.getMonth()
}

/**
 * Check if a date is part of the month
 *
 * @param {datetime} "2021-02-16"
 * @return {string} "16 Febrero"
 */
const getDateTitle = (datetime) => {
  const date = new Date(datetime)

  return `${date.getDate() + 1} ${month[date.getMonth()]}`
}

/**
 * Check if a date is part of the month
 *
 * @param {datetime} "2022-06-29T20:01:29.237Z"
 * @return {string} "17:30"
 */
const getTime = (datetime) => {
  const date = new Date(datetime) || new Date()
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

  return `${date.getHours()}:${minutes}`
}

/**
 * Returns last week dateFrom and dateTo
 *
 * @return {object} {dateFrom: "05/"}
 */
const getLastWeekDateInterval = () => ({
  dateFrom: moment().subtract(7, 'days').format('MM-DD-YYYY'),
  dateTo: moment().format('MM-DD-YYYY'),
})

/**
 * Returns last week dateFrom and dateTo
 *
 * @return {object} {dateFrom: "05/"}
 */
const getLastMonthToDateInterval = () => {
  const dateFrom = moment().startOf('month').format('MM-DD-YYYY')
  const dateTo = moment().endOf('month').format('MM-DD-YYYY')

  return {
    dateFrom,
    dateTo,
  }
}

const getDateIntervalHelper = (filterValue) => {
  const response = {
    dateFrom: null,
    dateTo: null,
  }

  switch (filterValue) {
    case 1:
      response.dateFrom = moment().format('MM-DD-YYYY')
      break
    case 2: {
      const { dateFrom, dateTo } = getLastWeekDateInterval()

      response.dateFrom = dateFrom
      response.dateTo = dateTo
      break
    }
    case 3: {
      const { dateFrom, dateTo } = getLastMonthToDateInterval()

      response.dateFrom = dateFrom
      response.dateTo = dateTo
      break
    }
    default:
  }

  return response
}

export {
  isToday,
  inThisWeek,
  inThisMonth,
  getDateTitle,
  getTime,
  getLastWeekDateInterval,
  getDateIntervalHelper,
}
