import PropTypes from 'prop-types'
import WrapIcon from 'components/WrapIcon'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import { ErrorIcon } from 'components/Icons'
import theme from 'theme'

import TIME from './constData.json'

const Message = (props) => {
  const { backgroundColor, fontColor, flag, handleClose, message, icon } = props

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      autoHideDuration={TIME.AUTO_HIDE_DURATION}
      onClose={handleClose}
      open={flag}
    >
      <SnackbarContent
        message={
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              width: { sm: 524, xs: '100%' },
            }}
          >
            {icon && (
              <WrapIcon
                sx={{ padding: '0 10px 0 0', transform: 'scale(0.75)' }}
              >
                {icon}
              </WrapIcon>
            )}
            <Typography sx={{ color: fontColor }} variant="body1">
              {message}
            </Typography>
          </Box>
        }
        sx={{
          backgroundColor,
        }}
      />
    </Snackbar>
  )
}

export default Message

Message.propTypes = {
  backgroundColor: PropTypes.string,
  flag: PropTypes.bool.isRequired,
  fontColor: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.element,
  message: PropTypes.string.isRequired,
}

Message.defaultProps = {
  backgroundColor: theme.palette.error.main,
  fontColor: theme.palette.error.light,
  icon: <ErrorIcon />,
}
