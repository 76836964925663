import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Thick = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 18 14"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1.50098L6 12.501L1 7.50098"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Thick

Thick.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Thick.defaultProps = {
  color: theme.palette.white.main,
  size: 1.5,
}
