export const MuiFormLabel = (theme) => ({
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        color: theme.palette.black.main,
      },
      color: theme.palette.black.main,
      fontSize: 14,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: '20px',
    },
  },
})
export const MuiFormControlLabel = () => ({
  styleOverrides: {
    root: {
      //   color: theme.palette.text.primary,
      color: 'red !important',
    },
  },
})
