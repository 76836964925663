import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const TextArea = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: theme.fontWeight.semibold,
    lineHeight: 24 / 16,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.disabled,
    fontSize: 14,
    fontWeight: theme.fontWeight.semibold,
    left: theme.space[4],
    lineHeight: 20 / 14,
    top: theme.space[2],
  },
  border: `1px solid ${theme.backgrounds[2]}`,
  borderRadius: `${theme.borderRadius[0]}px`,
  maxWidth: 400,
  padding: `${theme.space[2]}px ${theme.space[4]}px`,
  width: '100%',
  [theme.breakpoints.down('sm')]: {},
}))

export default TextArea
