import axios from 'axios'
import {
  ENV,
  // GET_CONFIG_ROUTE,
  PUBLIC_PROFILE_POST,
  GET_ORDER_DETAIL_ROUTE,
} from 'constants/routes'
import { getToken } from './tokenHelpers'

/**
 * Returns an options obj with necessary headers to make an authenticated
 * request to API Gateway
 *
 * */
export const getRequestOptions = () => {
  const token = getToken()

  const headers = {
    Accept: 'application/vnd.com.payclip.v1+json',
  }

  if (token) {
    headers.Authorization = token
  }

  return { headers }
}

/**
 * Returns a promise with the http fetch request results
 * */
export const fetchInfo = (url) => {
  const options = getRequestOptions()

  return axios.get(url, options)
}

/**
 * Returns a promise with the http fetch request results
 * */
export const postInfo = (url, body) => {
  const options = getRequestOptions()

  return axios.post(url, body, options)
}

export const updateInfo = (url, payload) =>
  axios.patch(url, payload, getRequestOptions())

export const patchOrder = (orderId, payload) => {
  const url = `${ENV.REACT_APP_API_URL}${GET_ORDER_DETAIL_ROUTE.replace(
    ':id',
    orderId
  )}`

  return axios.patch(url, payload, getRequestOptions())
}

export const savePublicProfile = async (
  alias,
  merchantId,
  publicName,
  publicDesc
) => {
  const payload = {
    merchant_id: merchantId,
    profile_config: {
      profile_active: true,
      show_address: true,
      show_email: true,
      show_phone: true,
    },
    request_source_type: 'MERCHANT_PUBLIC_PROFILE_UPDATE',
  }

  if (alias) {
    payload.alias = alias
  }

  if (publicName) {
    payload.public_name = publicName
  }

  if (publicDesc) {
    payload.public_description = publicDesc
  }

  const response = await postInfo(
    `${ENV.REACT_APP_API_URL}${PUBLIC_PROFILE_POST}`,
    payload
  )
  const { data } = response

  return data
}

// the product uses patch axios like method of update
export const updateProduct = async (url, payload) =>
  axios.patch(url, payload, getRequestOptions())

export const deleteCatalog = async (url) => {
  axios.delete(url, getRequestOptions())
}

export const updateCatalog = async (url, payload) =>
  axios.put(url, payload, getRequestOptions())
