import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import NumberFormat from 'react-number-format'
import Grid from '@mui/material/Grid'
import { TablePagination, useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { useGlobalContext } from 'store/global'
import RowGrid from 'components/RowGrid'
import { CATALOG_PRODUCT_ROUTE } from 'constants/routes'
import useStyles from './styles'

const { list: TABLE } = require('./constData.json')

const CatalogList = ({
  isLastPage,
  onPageChange,
  page,
  rowCount,
  rowData,
  scrollable,
}) => {
  const { device } = useGlobalContext()
  const theme = useTheme()
  const classes = useStyles()
  const [products, setProducts] = useState([])
  const navigate = useNavigate()

  const rowsPerPage = device.isMobile
    ? TABLE.ROWS_PER_PAGE / 2
    : TABLE.ROWS_PER_PAGE

  useEffect(() => {
    setProducts(rowData.slice(0, rowsPerPage))
  }, [rowData])

  const handleClickNav = (pid) =>
    navigate(CATALOG_PRODUCT_ROUTE, { state: { pid } })

  const renderText = (text, isBold, variant) => (
    <Typography
      color={isBold ? 'black.main' : 'text.primary'}
      fontWeight={!isBold && theme.fontWeight.regular}
      sx={{ display: 'inline-flex' }}
      variant={variant}
    >
      {text}
    </Typography>
  )

  const Head = () => (
    <RowGrid item sx={{ padding: `${theme.space[4]}px` }} xs={12}>
      <Grid item sm sx={{ paddingLeft: '72px' }}>
        {renderText(TABLE.PRODUCT_NAME, false, 'body1')}
      </Grid>
      <Grid item sm={2}>
        {renderText(TABLE.VARIANTS, false, 'body1')}
      </Grid>
      <Grid item sm={2}>
        {renderText(TABLE.STOCKSTAKING, false, 'body1')}
      </Grid>
      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {renderText(TABLE.PRICE, false, 'body1')}
      </Grid>
    </RowGrid>
  )

  const renderPrice = (price) =>
    renderText(
      <NumberFormat
        allowEmptyFormatting
        decimalScale="2"
        decimalSeparator="."
        displayType="text"
        fixedDecimalScale
        prefix="$"
        thousandSeparator=","
        thousandsGroupStyle="thousand"
        type="text"
        value={price}
      />,
      true
    )

  const renderAvatar = (productData) => (
    <Avatar
      alt={productData.product_name}
      className={classes.thumbnail}
      src={productData.image || '/images/product_default.png'}
    />
  )

  const renderRowGridMobile = (product) => (
    <RowGrid
      key={product.product_id}
      className={classes.rowGrid}
      item
      onClick={() => handleClickNav(product.product_id)}
      xs={12}
    >
      <Grid className={classes.gridItem} item xs={8}>
        {renderAvatar(product)}
        <Stack>
          {renderText(product.product_name, true, 'body1')}
          <Typography
            color="text.primary"
            fontWeight={theme.fontWeight.semibold}
            sx={{ display: 'inline-flex' }}
            variant="body2"
          >
            {`${product.number_of_variants} ${TABLE.VARIANT}`}
          </Typography>
        </Stack>
      </Grid>

      <Grid className={classes.gridItemLast} item xs={4}>
        {renderPrice(product.price)}
        {renderText(`${Number(product.stock)} ${TABLE.ITEMS}`, false, 'body2')}
      </Grid>
    </RowGrid>
  )
  const renderRowGridDesktop = (product) => (
    <RowGrid
      key={product.product_id}
      className={classes.rowGrid}
      item
      onClick={() => handleClickNav(product.product_id)}
      xs={12}
    >
      <Grid className={classes.gridItem} item sm>
        {renderAvatar(product)}
        {renderText(product.product_name, true, 'body1')}
      </Grid>
      <Grid className={classes.gridItem} item sm={2}>
        {renderText(
          `${product.number_of_variants} ${TABLE.VARIANT}`,
          false,
          'body1'
        )}
      </Grid>
      <Grid className={classes.gridItem} item sm={2}>
        {renderText(
          parseInt(product.stock, 10) >= 0
            ? `${product.stock} ${TABLE.ITEMS}`
            : TABLE.NONE,
          false,
          'body1'
        )}
      </Grid>
      <Grid
        className={classes.gridItem}
        item
        sm={2}
        sx={{ justifyContent: 'flex-end' }}
      >
        {renderPrice(product.price)}
      </Grid>
    </RowGrid>
  )

  const renderGrid = () => (
    <Grid className={classes.containerList} container>
      {!device.isMobile && <Head />}
      {products.map((p) =>
        device.isMobile ? renderRowGridMobile(p) : renderRowGridDesktop(p)
      )}
      <Grid className={classes.gridPagination} item xs={12}>
        {rowData.length > 0 && (
          <TablePagination
            component="div"
            count={isLastPage ? rowCount * page + rowData.length : -1}
            labelDisplayedRows={({ from, to, count }) =>
              !device.isMobile &&
              `${from}-${to} de ${
                count === -1 ? `más de ${rowCount * (page + 1)}` : count
              }`
            }
            nolabel={device.isMobile ? 'true' : undefined}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={rowCount}
            rowsPerPageOptions={[]}
          />
        )}
      </Grid>
    </Grid>
  )

  if (!scrollable) {
    return renderGrid()
  }

  return <Box className={classes.wrapper}>{renderGrid()}</Box>
}

export default CatalogList

CatalogList.propTypes = {
  isLastPage: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      number_of_variants: PropTypes.number,
      product_id: PropTypes.string,
      product_name: PropTypes.string,
      stock: PropTypes.number,
    })
  ).isRequired,
  scrollable: PropTypes.bool,
}
CatalogList.defaultProps = {
  isLastPage: false,
  onPageChange: () => {},
  scrollable: false,
}
