const MuiChip = (theme) => ({
  styleOverrides: {
    root: {
      fontFamily: 'Averta',
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
  variants: [
    // my variants
    {
      props: { shape: 'circle' },
      style: {
        '& .MuiChip-label': {
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
        '&.MuiChip-root': {
          color: theme.palette.primary.main,
          padding: 0,
        },
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
        borderRadius: theme.borderRadius[3],
      },
    },
    {
      props: { shape: 'circle', size: 'small' },
      style: {
        '&.MuiChip-root': {
          color: theme.palette.white.main,
        },
        backgroundColor: theme.palette.primary.main,
        fontSize: 10,
        height: 20,
        lineHeight: 16 / 10,
        width: 20,
      },
    },
    {
      props: { shape: 'circle', size: 'medium' },
      style: {
        height: 24,
        width: 24,
      },
    },
    {
      props: { shape: 'circle', size: 'large' },
      style: {
        height: 32,
        width: 32,
      },
    },
    {
      props: { shape: 'button' },
      style: {
        borderRadius: theme.borderRadius[3],
        height: 40,
        marginLeft: 0,
        marginRight: `${theme.space[4]}px`,
        marginTop: 0,
        padding: `10px ${theme.space[4]}px`,
        [theme.breakpoints.down('md')]: {
          fontSize: 14,
          lineHeight: 20 / 12,
          marginRight: `${theme.space[1]}px`,
        },
      },
    },
    {
      props: { shape: 'status' },
      style: {
        '& .MuiChip-label': {
          display: 'flex',
          justifyContent: 'center',
          padding: `3px ${theme.space[2]}px`,
        },
        borderRadius: theme.borderRadius[2],
        fontSize: 12,
        fontWeight: theme.fontWeight.semibold,
        height: 24,
        lineHeight: 18 / 12,
        marginLeft: `${theme.space[4]}px`,
        marginTop: 0,
        width: 'fit-content',
        [theme.breakpoints.down('md')]: {
          marginRight: `${theme.space[1]}px`,
        },
      },
    },
    {
      props: { status: 'payment_success' },
      style: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.main,
      },
    },
    {
      props: { status: 'delivery_delivered' },
      style: {
        backgroundColor: theme.backgrounds[1],
        color: theme.palette.text.primary,
      },
    },
    {
      props: { status: 'canceled_payment' },
      style: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
      },
    },
    {
      props: { status: 'active' },
      style: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
      },
    },
  ],
})

export default MuiChip
