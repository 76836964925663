import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import theme from 'theme'
import Button from '../components/Button'

import { TITLE, DESCRIPTION, BUTTON } from '../constants/notFound'

const NotFound = () => (
  <>
    <Helmet>
      <title>{TITLE}</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src="/images/undraw_page_not_found_su7k.png"
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              width: 400,
            }}
          />
        </Box>
        <Typography
          align="center"
          sx={{ m: `${theme.space[4]}px` }}
          variant="h1"
        >
          {TITLE}
        </Typography>
        <Typography
          align="center"
          color="text.primary"
          fontWeight={theme.fontWeight.regular}
          sx={{ m: `${theme.space[4]}px` }}
          variant="h4"
        >
          {DESCRIPTION}
        </Typography>
        <Button
          color="orange"
          href="/"
          size="small"
          sx={{
            display: 'flex',
            fontSize: 16,
            height: 48,
            margin: '30px auto',
          }}
          type="link"
        >
          {BUTTON}
        </Button>
      </Container>
    </Box>
  </>
)

export default NotFound
