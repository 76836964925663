const data = {
  labels: {
    RECOMMENDED_FOR_YOU: 'Recomendado para ti',
    TEMPLATE: 'templates',
  },
  templates: [
    {
      button: {
        text: 'Ver ejemplo',
        url: 'https://www.google.com',
      },
      description: 'Para venta de productos físicos o digitales.',
      id: 'retail',
      name: 'Grilla',
    },
    {
      button: {
        text: 'Ver ejemplo',
        url: 'https://www.google.com',
      },
      description: 'Para restaurantes o puestitos.',
      id: 'food',
      name: 'Listado',
    },
    {
      button: {
        text: 'Ver ejemplo',
        url: 'https://www.google.com',
      },
      description: 'Para personas que ofrecen sus servicios.',
      id: 'services',
      name: 'Simple',
    },
  ],
}

export default data
