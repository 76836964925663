const MuiLoadingButton = (theme) => ({
  variants: [
    // my variants
    {
      props: { color: 'orange' },
      style: {
        '&:hover': {
          backgroundColor: theme.palette.primary.strong,
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    },
    {
      props: { variant: 'primary-form' },
      style: {
        '& > .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.white.main,
          left: 'initial',
          marginRight: theme.space[2],
          position: 'relative',
        },
      },
    },
    {
      props: { variant: 'secondary-form' },
      style: {
        '& > .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.white.main,
          left: 'initial',
          marginRight: theme.space[2],
          position: 'relative',
        },
      },
    },
    {
      props: { size: 'onboarding' },
      style: {
        height: 48,
        maxWidth: 308,
        width: '90%',
      },
    },
    {
      props: { size: 'submit' },
      style: {
        height: 48,
        width: 236,
        [theme.breakpoints.down('sm')]: {
          height: 36,
          width: 140,
        },
      },
    },
    {
      props: { variant: 'only-icon' },
      style: {
        '& .MuiButton-endIcon': {
          marginLeft: 0,
          marginRight: 0,
        },
        '& .MuiButton-startIcon': {
          marginLeft: 0,
          marginRight: 0,
        },
        '&.MuiLoadingButton-loading > span': {
          display: 'none',
        },
        minHeight: 24,
        minWidth: 24,
      },
    },
  ],
})

export default MuiLoadingButton
