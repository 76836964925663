import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { ENV, MD_ACCOUNT_INFO } from 'constants/routes'
import SETTINGS from 'components/Settings/constData.json'

const ChangeDirectionStoreText = () => (
  <Typography
    sx={{ '& a': { color: (theme) => theme.palette.primary.main } }}
    variant="body1"
  >
    {SETTINGS.CHANGE_DIRECTION_STORE_TEXT}
    <i>&nbsp;</i>
    <Link
      href={`${ENV.REACT_APP_ME_DASHBOARD_URL}${MD_ACCOUNT_INFO}`}
      variant="body1"
    >
      {SETTINGS.MY_PROFILE}
    </Link>
  </Typography>
)

export default ChangeDirectionStoreText
