import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'

const OrderGrid = ({ children, sx }) => {
  const theme = useTheme()

  return (
    <Grid
      item
      sx={{
        ...sx,
        m: 'auto',
        maxWidth: { xs: 'calc(100% - 48px)' },
        p: `${theme.space[4]}px 0`,
      }}
      xs={12}
    >
      {children}
    </Grid>
  )
}

export default OrderGrid

OrderGrid.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
}

OrderGrid.defaultProps = {
  sx: undefined,
}
