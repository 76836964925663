import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    padding: 1,
    width: '100%',
  },
  couponPost: {
    display: 'flex',
    height: 140,
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  elementSelected: {
    alignItems: 'center',
    backgroundColor: theme.backgrounds[1],
    borderRadius: theme.borderRadius[0],
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.space[3],
    width: '100%',
  },
  inputBase: {
    width: '85%',
  },
  inputWrapper: {
    borderRadius: `${theme.borderRadius[0]}px`,
    flexDirection: 'row',
    height: 56,
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  suggest: {
    '& img': {
      margin: 8,
    },
    background: theme.backgrounds[0],
    borderRadius: 8,
    maxHeight: 200,
    minHeight: 80,
    overflow: 'auto',
    position: 'absolute',
    top: 60,
    width: '100%',
    zIndex: 1,
  },
}))

export default useStyles
